import { Component } from '@angular/core';
import { I18nMessagesService } from 'src/app/shared/services/i18n-messages.service';

@Component({
  templateUrl: './not-authorized.component.html',
  styleUrls: ['./not-authorized.css']
})
export class NotAuthorizedComponent {
  public readonly errText: string = this.msgs.notAuthErrorText;
  public readonly errCode: string = this.msgs.notAuthErrorCode;

  constructor(public msgs: I18nMessagesService) {}
}
