<div class="import-container">
    
    <div class="title">
        <h2>Repository Import, Talpification, & Analysis</h2>
    </div>

    <mat-tab-group [selectedIndex]="tabIdx" class="tab-group">
        <mat-tab label="Repository Info">
            <div class="input-block">
                <mat-form-field appearance="fill">
                    <mat-label>Repository Name</mat-label>
                    <input matInput [(ngModel)]="repoName" [required]>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Repository URL</mat-label>
                    <input matInput [(ngModel)]="repoUrl" placeholder="Public Repository URL" [required]>
                </mat-form-field>
                <button class="import-button" mat-raised-button color="primary" (click)="onImport()">Import</button>
            </div>
        </mat-tab>
        <mat-tab label="Status">
            <app-import-progress [progress]="progress" [repoInfo]="repoInfo"></app-import-progress>
        </mat-tab>
    </mat-tab-group>
    <div class="footer">
        <button mat-stroked-button (click)="onDialogClose()">Close</button>
    </div>
</div>