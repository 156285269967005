import { Injectable } from '@angular/core';

import { LoggerInterface } from '../interfaces/logger';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarService } from './snackbar.service';

@Injectable()
export class Logger implements LoggerInterface {
  private level: 'production' | 'development' = 'development';

  constructor(public snackBar: SnackbarService) {}

  public setLevel(level: 'production' | 'development') {
    this.level = level;
  }

  public info(message: string, ...data: any[]): void {
    console.groupCollapsed('INFO: ' + message);
    if (data.length) {
      console.info(data);
    }
    if (this.level === 'development') {
      console.trace();
    }
    console.groupEnd();

    this.snackBar.open(message, '', { panelClass: ['log-info'] });
  }

  public warn(message: string, ...data: any[]): void {
    console.groupCollapsed('WARNING: ' + message);
    if (data.length) {
      console.warn(data);
    }
    if (this.level === 'development') {
      console.trace();
    }
    console.groupEnd();

    this.snackBar.open(message, '', { panelClass: ['log-warn'] });
  }

  public error(message: string, ...data: any[]): void {
    console.groupCollapsed('ERROR: ' + message);
    if (data.length) {
      console.error(data);
    }
    if (this.level === 'development') {
      console.trace();
    }
    console.groupEnd();

    this.snackBar.open(message, '', { panelClass: ['log-error'] });
  }

  public debug(message: string, ...data: any[]): void {
    console.groupCollapsed('DEBUG: ' + message);

    if (data) {
      console.info(data);
    }

    if (this.level === 'development') {
      console.trace();
    }
    console.groupEnd();
  }

  // Debounced logging can be used inside render loop,for example, to only log messages
  // every n times.  Set the debounce rate to a higher number for less logging.
  debounceCounter: number = 0;
  public debounce(debounceRate: number, message: string, ...data: any[]): void {
    if (debounceRate > this.debounceCounter) {
      this.debounceCounter++;
      return;
    } else {
      this.debounceCounter = 0;
    }

    console.groupCollapsed('DEBOUNCED INFO: ' + message);
    if (data.length) {
      console.info(data);
    }
    if (this.level === 'development') {
      console.trace();
    }
    console.groupEnd();
  }
}
