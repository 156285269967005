import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SysInfo } from '../models/sysinfo-attribute';
import { ApiResponse } from '../models/api-response';
import { functionParser } from '../utils/response-parser';
import { SysInfoResource } from '../models/sysinfo-resource';
import { map } from 'rxjs/operators';
import { ConfigService } from 'src/app/config.service';

@Injectable({
  providedIn: 'root'
})
export class SystemService {

  constructor(private http: HttpClient) { }

  getSysInfo(): Observable<SysInfoResource> {
    let url  = ConfigService.getApiURL('/systeminfo');
    return this.http.get(url).pipe(
        map(data => {
          const res: ApiResponse<SysInfo> = data as ApiResponse<SysInfo>;
          return functionParser(res, SysInfoResource);
        })
      ) as Observable<SysInfoResource>;
  }

}
