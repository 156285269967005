import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UrlStringsBox } from 'src/app/shared/models/url_strings';
import { LegendItem } from '../../reuse/interfaces/legend-item';
import { ConfigService } from 'src/app/config.service';
import { I18nMessagesService } from 'src/app/shared/services/i18n-messages.service';
import { map } from 'rxjs/operators';
import { ApiResponse } from 'src/app/component/models/api-response';
import { FnDecompAttribute } from 'src/app/component/models/fn-decomp-attribute';
import { Resource } from 'src/app/component/models/resource';
import { FnDecompResource } from 'src/app/component/models/fn-deomp-resource';
import { functionParser } from 'src/app/component/utils/response-parser';

@Injectable()
export class FnDecompService {
  private projectUrlPart = UrlStringsBox.urlConstants.project.PROJECTS;

  private nodeTypes: any[] = [
    {
      type: 'function',
      name: this.msgs.cgServiceFunctionName,
      description: this.msgs.cgServiceFunctionDesc
    }
  ];

  private linkTypes: any[] = [
    {
      type: 'call',
      name: this.msgs.cgServiceFunctionCallName,
      description: this.msgs.cgServiceFunctionCallDesc
    }
  ];

  constructor(private http: HttpClient, public msgs: I18nMessagesService) {}

  getCodeForFile(projectName: string, fileName: string): Observable<any> {
    let cCodeParams: any = { fileName: fileName };
    let option: any = { params: cCodeParams, responseType: 'text' };
    let cCodeUrl: string = `${this.projectUrlPart}/${projectName}/controlflow/source`;
    return this.http.get(ConfigService.getApiURL(cCodeUrl), option);
  }

  importFile(formData: FormData): Observable<any> {
    let params = new HttpParams();

    return this.http.get(ConfigService.getApiURL(`/analytics/fnDecomp`), { params: params });
  }

  getLegendItems(): LegendItem[] {
    let items: LegendItem[] = [];

    this.nodeTypes.forEach(type => {
      items.push({
        type: 'node',
        draggable: false,
        node: {
          label: type.name,
          name: type.name,
          type: type.type,
          description: type.description
        }
      });
    });

    this.linkTypes.forEach(type => {
      items.push({
        type: 'link',
        draggable: false,
        link: {
          type: type.type,
          name: type.name,
          source: null,
          target: null,
          description: type.description
        }
      });
    });

    return items;
  }

  fetchGraphData(repo: string): Observable<FnDecompResource> {
    let url = `/repo/${repo}/fndecomp`;
    let requestUrl = ConfigService.getApiURL(url);

    return this.http.get(requestUrl).pipe(
      map(data => {
        const res: ApiResponse<FnDecompAttribute> = data as ApiResponse<FnDecompAttribute>;
        return functionParser(res, FnDecompResource);
      })
    ) as Observable<FnDecompResource>;

  }
  
}
