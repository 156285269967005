<div *ngIf="!isReady; else repoList" style="display: flex; justify-content: center; align-items: center;">
	<mat-spinner></mat-spinner>
</div>

<ng-template #repoList>
	<div *ngIf="repos.length > 0 && selectedRepo; else emptyList" class="dash-container">
		<div class="repo-selector">
			<span style="font-size: medium; align-self: end;">Current Repository : </span><button mat-flat-button color="primary" (click)="navigateToRepoList()">{{selectedRepo.attributes.name}}</button>
			<div *ngIf="!inProgressResource; else inProgressButton">
			<button mat-stroked-button color="primary" (click)="onAddRepo()" style="margin-left: 30px;">New Repository</button>
		</div>
		</div>
		<mat-divider></mat-divider>
		<div style="display: flex; margin-top: 50px; align-self: center;">
			<div *ngFor="let card of displayedCards">
				<mat-card style="background: rgb(242, 242, 241); width: 300px; margin-left: 20px; height: 220px">
					<mat-card-header>
						<mat-card-subtitle>{{card.subtitle}}</mat-card-subtitle>
						<mat-card-title>{{card.title}}</mat-card-title>
					</mat-card-header>
					<mat-card-content>
						<p>{{card.content}}</p>
					</mat-card-content>
					<mat-card-actions style="display: flex; flex-direction: row; justify-content: space-between;">
						<button mat-stroked-button color="primary" [disabled]="!card.isEnabled" (click)="onAction(card)">
							{{card.actionText}}
						</button>
						<mat-chip-list [ngClass]="{'hide-chip': card.isEnabled}">
							<mat-chip color="accent" selected>Coming Soon</mat-chip>
						</mat-chip-list>
					</mat-card-actions>
				</mat-card>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #emptyList>
	<div style="display: flex; justify-content: center; align-items: center;">
		<mat-card style="width: 400px;">
			<mat-card-header>
				<mat-card-title>No Repository Found!</mat-card-title>
			</mat-card-header>
			<mat-card-content>
				<p style="font-size: medium; margin-top: 20px;">
					<button mat-raised-button color="primary" (click)="onAddRepo()">Create</button> a new repository to get started
				</p>
			</mat-card-content>
		</mat-card>
	</div>
</ng-template>

<ng-template #inProgressButton>
	<div style="display: flex; justify-content: center; align-items: center;">
		<button mat-stroked-button color="primary" (click)="onAddRepo()" style="width: fit-content;">
			<div style="display: flex; flex-direction: row; align-items: center; gap: 8px;">
				<mat-spinner [strokeWidth]="2" [diameter]="20"></mat-spinner>
				<span>Importing..</span>
			</div>
		</button>
	</div>
</ng-template>