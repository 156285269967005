import { forceSimulation as d3forceSimulation } from 'd3-force';

export class ForceSimulation {
  simulation: any;

  _alpha: number;
  _alphaMin: number;
  _alphaDecay: number;
  _alphaTarget: number;
  _velocityDecay: number;

  public get alpha(): number {
    return this.simulation.alpha() as number;
  }
  public get alphaMin(): number {
    return this.simulation.alphaMin();
  }
  public get alphaDecay(): number {
    return this.simulation.alphaDecay();
  }
  public get alphaTarget(): number {
    return this.simulation.alphaTarget();
  }
  public get velocityDecay(): number {
    return this.simulation.velocityDecay();
  }

  public set alpha(val: number) {
    this.simulation.alpha(val);
  }
  public set alphaMin(val: number) {
    this.simulation.alphaMin(val);
  }
  public set alphaDecay(val: number) {
    this.simulation.alphaDecay(val);
  }
  public set alphaTarget(val: number) {
    this.simulation.alphaTarget(val);
  }
  public set velocityDecay(val: number) {
    this.simulation.velocityDecay(val);
  }

  defaultDecay: number;

  constructor() {
    this.simulation = d3forceSimulation().stop();
    this.defaultDecay = this.simulation.alphaDecay();
  }

  debug() {
    console.log(
      'alpha:' +
        this.simulation.alpha() +
        ', alphaMin: ' +
        this.simulation.alphaMin() +
        ', alphadecay: ' +
        this.simulation.alphaDecay() +
        ', alphatarget: ' +
        this.simulation.alphaTarget() +
        ', velocityDecay: ' +
        this.simulation.velocityDecay()
    );
  }

  addTickHandler(func: any) {
    this.simulation.on('tick', func);
  }

  isTicking() {
    return this.alpha > this.alphaMin;
  }

  setForce(name: string, force: any) {
    this.simulation.force(name, force);
    return this;
  }

  getForce(name: string) {
    return this.simulation.force(name);
  }

  getNodes(): any[] {
    return this.simulation.nodes();
  }

  setNodes(nodes: any) {
    this.simulation.nodes(nodes).stop();
  }

  restart() {
    this.simulation.restart();
  }

  stop() {
    this.simulation.stop();
  }

  tick(iterations: number = 1) {
    this.simulation.tick(iterations);
  }

  getSimulation() {
    return this.simulation;
  }
}
