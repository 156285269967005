import { Injectable } from '@angular/core';
import { DrawingDefaults } from '../reuse/interfaces/drawing-defaults';
import { ColorsService } from 'src/app/shared/services/colors.service';

@Injectable()
export class RenderService {
  constructor(protected colors: ColorsService) {}

  getDrawingDefaults(): DrawingDefaults {
    return {
      name: 'untitled',
      fontSize: 12,
      strokeColor: this.colors.mptTheme.primary.C900,
      strokeWidth: 1,
      fillColor: 'white',
      lineHeight: 14,
      selectionRadius: 80,
      selectionBackGroundFill: this.colors.mptTheme.grayscale.C300,
      selectionBackGroundStroke: this.colors.mptTheme.grayscale.C400,
      node: {
        radius: 40,
        width: 150,
        height: 65,
        unselectedOpacity: 0.2,
        strokeWidth: 1
      }
    };
  }
}
