import { Injectable } from '@angular/core';
import { I18nMessagesService } from 'src/app/shared/services/i18n-messages.service';

import {
  DiagramOptions,
  ToolbarOptions,
  SidebarOptions,
  RenderingOptions,
  LayoutConfig,
  RendererConfig
} from '../reuse/interfaces/diagram-options';

@Injectable()
export class DiagramOptionsService {

  constructor(public msgs: I18nMessagesService) {
  }

  defaultDiagramOptions(): DiagramOptions {
    let toolbarOptions: ToolbarOptions = {
      filter: { enabled: false },
      whiteList: { enabled: false },
      viewOptions: {
        enabled: false,
        style: { enabled: false, style: 'light' },
        showLoops: { title: this.msgs.diagOptionsServLoops, enabled: false, on: false },
        showTlp: { title: this.msgs.diagOptionsServTLP, enabled: false, on: false },
        showLargeKernels: { title: this.msgs.diagOptionsServKernels, enabled: false, on: true },
        showNewComponents: { title: this.msgs.diagOptionsServNewComp, enabled: false, on: false },
        showModifiedComponents: { title: this.msgs.diagOptionsServModComp, enabled: false, on: false },
        showLinkLabels: { title: this.msgs.diagOptionsServTF, enabled: false, on: false },
        showMinimap: { title: this.msgs.diagOptionsServMap, enabled: false, on: false },
        showDesignIds: { title: this.msgs.diagOptionsServId, enabled: false, on: false }
      },
      magnify: { enabled: false },
      sidebar: { enabled: true },
      refresh: { enabled: true },
      search: { enabled: false },
      help: { enabled: true },
      upLevel: { enabled: false },
      downLevel: { enabled: false },
      selectData: { enabled: false },
      selectLayout: { enabled: false },
      selectRenderer: { enabled: false },
      selectNode: { enabled: false },
      selectFunction: { enabled: false },
      print: { enabled: true },
      save: { enabled: true }
    };

    let sidebarOptions: SidebarOptions = {
      overview: {
        enabled: true,
        counts: { enabled: true, opened: true },
        levels: { enabled: false, opened: true },
        sequencing: { enabled: false, opened: false },
        parallelization: { enabled: false, opened: false },
        code: { enabled: false, opened: false },
        variables: { enabled: false, opened: true, dependencyAnalysis: false },
        cobolVariables: { enabled: false, opened: true },
        globals: { enabled: false, opened: false },
        jumps: { enabled: false, opened: false },
        functions: { enabled: false, opened: false },
        invokedFunctions: { enabled: false, opened: true },
        invokingFunctions: { enabled: false, opened: true },
        uploadedFiles: { enabled: false, opened: true },
        attach: { enabled: false, opened: false },
        tlpConfigurator: { enabled: false, opened: true },
        profile: { enabled: false, opened: false }
      },
      inspector: {
        enabled: true,
        selection: { enabled: true, opened: true },
        flowParameters: { enabled: false, opened: false },
        nodeActions: { enabled: true, opened: true },
        tasks: { enabled: false, opened: false },
        flows: { enabled: false, opened: false },
        nodeVariables: { enabled: false, opened: true },
        nodeGlobals: { enabled: false, opened: false },
        sourceCode: { enabled: false, opened: false, editorShortcut: false },
        log: { enabled: false, opened: false },
        tlpConfigurator: { enabled: false, opened: true }
      },
      legend: { enabled: true },
      talp: {enabled: false, selection: { enabled: false, opened: true }},
      execution: {
        enabled: false,
        inputs: { enabled: false, opened: false },
        console: { enabled: false, opened: false },
        results: { enabled: false, opened: false }
      },
      sourcemap: {
        enabled: false,
        selection: { enabled: false, opened: true }
      },
    };

    let renderingOptions: RenderingOptions = {
      centered: false,
      tooltipOptions: {
        width: 300,
        widthByType: [],
        showId: false
      },
      highlightNeighborsOnSelection: true,
      useSelectionBackground: false,
      layouts: [
        {
          enabled: true,
          shortName: 'dynamic1',
          name: 'Dynamic 1',
          priority: 1,
          useForceSimulation: true,
          allowNodeDragging: true,
          nodeDragging: true,
          allowNodePositioning: true,
          nodePositioning: false
        },
        {
          enabled: false,
          shortName: 'dynamic2',
          name: 'Dynamic 2',
          priority: 2,
          useForceSimulation: true,
          allowNodeDragging: true,
          nodeDragging: true,
          allowNodePositioning: true,
          nodePositioning: false
        },
        {
          enabled: false,
          shortName: 'transitionToFixed',
          name: 'Transition to Fixed',
          priority: 3,
          useForceSimulation: false,
          allowNodeDragging: true,
          nodeDragging: false,
          allowNodePositioning: false,
          nodePositioning: false
        },
        {
          enabled: false,
          shortName: 'fixed',
          name: 'Fixed',
          priority: 4,
          useForceSimulation: false,
          allowNodeDragging: true,
          nodeDragging: true,
          allowNodePositioning: true,
          nodePositioning: true
        }
      ],
      renderers: [
        { enabled: true, shortName: 'svg', name: 'SVG', priority: 1, supportsSave: true, dagreD3Positioning: false },
        {
          enabled: false,
          shortName: 'canvas-2d',
          name: 'Canvas 2D',
          priority: 2,
          supportsSave: false,
          dagreD3Positioning: false
        },
        {
          enabled: false,
          shortName: 'canvas-3d',
          name: 'Canvas 3D',
          priority: 3,
          supportsSave: false,
          dagreD3Positioning: false
        },
        {
          enabled: false,
          shortName: 'svg-design',
          name: 'Design',
          priority: 4,
          supportsSave: true,
          dagreD3Positioning: false
        },
        {
          enabled: false,
          shortName: 'svg-decomp',
          name: 'SVG - Decomposition',
          priority: 5,
          supportsSave: true,
          dagreD3Positioning: false
        },
        {
          enabled: false,
          shortName: 'svg-control-flow',
          name: 'SVG - Control Flow',
          priority: 6,
          supportsSave: true,
          dagreD3Positioning: false
        },
        {
          enabled: false,
          shortName: 'canvas-2d-control-flow',
          name: 'Canvas 2D - Control Flow',
          priority: 7,
          supportsSave: false,
          dagreD3Positioning: false
        },
        {
          enabled: false,
          shortName: 'svg-call-graph',
          name: 'SVG - Call Graph',
          priority: 8,
          supportsSave: true,
          dagreD3Positioning: false
        },
        {
          enabled: false,
          shortName: 'svg-omniview',
          name: 'Omniview',
          priority: 9,
          supportsSave: false,
          dagreD3Positioning: false
        },
        {
          enabled: false,
          shortName: 'components-table',
          name: 'HTML Table',
          priority: 10,
          supportsSave: false,
          dagreD3Positioning: false
        },
        {
          enabled: false,
          shortName: 'component-canvas-3d',
          name: 'Canvas 3D',
          priority: 11,
          supportsSave: false,
          dagreD3Positioning: false
        },
        {
          enabled: false,
          shortName: 'svg-design2',
          name: 'Design2',
          priority: 12,
          supportsSave: true,
          dagreD3Positioning: false
        },
        {
          enabled: false,
          shortName: 'svg-cobol-control-flow',
          name: 'SVG - COBOL Control Flow',
          priority: 13,
          supportsSave: true,
          dagreD3Positioning: false
        },
        {
          enabled: false,
          shortName: 'svg-call-graph2',
          name: 'Call Graph (forces)',
          priority: 14,
          supportsSave: true,
          dagreD3Positioning: false
        }
      ]
    };

    return {
      toolbar: toolbarOptions,
      sidebar: sidebarOptions,
      rendering: renderingOptions
    };
  }

  getDefaultRenderingLayout(layouts: LayoutConfig[]): LayoutConfig {
    let myLayout: LayoutConfig;
    layouts.forEach(layout => {
      if (layout.enabled) {
        if (!myLayout || layout.priority < myLayout.priority) {
          myLayout = layout;
        }
      }
    });

    if (!myLayout) {
      console.error('Unable to find layout');
    }

    return myLayout;
  }

  getDefaultRendererConfig(configs: RendererConfig[]): RendererConfig {
    let myConfig: RendererConfig;

    for (let i = 0; i < configs.length; i++) {
      let config = configs[i];
      if (config.enabled) {
        if (!myConfig || myConfig.priority > config.priority) {
          myConfig = config;
        }
      }
    }

    if (!myConfig) {
      console.log('Unable to find renderer configuration');
    }
    return myConfig;
  }
}
