<div style="display: flex; flex-direction: column;">
    <div style="display: flex; flex-direction: row; justify-content: space-around;">
        <h3><b>{{left.name}}</b></h3>
        <h3><b>{{right.name}}</b></h3>
    </div>

    <td-ngx-text-diff
    [left]="left.content"
    [right]="right.content"
    [showToolbar]="false"
    [outerContainerStyle]="{'font-family': 'Roboto,sans-serif', 'padding': '10px', 'font-size': '14px'}"
    [showBtnToolbar]="true"></td-ngx-text-diff>
</div>