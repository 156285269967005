import { SvgFormatterBase } from './svg-formatter.base';
import * as d3 from 'd3';
import { Vector } from '../../../../reuse/interfaces/point';

export class SvgFormatterPrint extends SvgFormatterBase {
  constructor() {
    super();
    this.printMode = true;
  }

  protected zoomFit() {
    const bounds = this.gTop.node().getBBox();
    const parentSvg = this.gTop.node().parentElement;
    const fullWidth = parentSvg.clientWidth;
    const fullHeight = parentSvg.clientHeight;

    const width = bounds.width;
    const height = bounds.height;
    console.log('w,h: ' + width + ', ' + height);

    const midX = bounds.x + width / 2;
    const midY = bounds.y + height / 2;

    if (width == 0 || height == 0) return; // nothing to fit

    this.orientation = width > height ? 'landscape' : 'portrait';
    console.log(this.orientation);

    const scale = this.paddingFactor / Math.max(width / fullWidth, height / fullHeight);

    let vector: Vector = {
      x: fullWidth / 2 - scale * midX,
      y: fullHeight / 2 - scale * midY
    };

    const heightAdjustment: number = this.topMargin + this.headerLines.length * this.lineHeight;
    console.log('height adjustment:', heightAdjustment);
    vector.y += heightAdjustment / 2;

    const transform = d3.zoomIdentity.translate(vector.x, vector.y).scale(scale);

    this.svg.call(this.renderer.getZoomBehavior().transform, transform);
  }

  protected localizeSvgOutput(): void {
    super.localizeSvgOutput();

    // where is this used?
    this.svg.selectAll('#gTop .output .nodes circle').attr('style', function(d) {
      let startStyle: string = d3.select(this).attr('style');

      if (!startStyle) {
        console.log('start style not found: ', startStyle);
        return;
      }

      const searchStr: string = 'url("';

      const index1: number = startStyle.indexOf(searchStr);
      const index2: number = startStyle.indexOf('#');
      if (index1 < 0 || index2 < 0) {
        console.log('index not found');
        return startStyle;
      }

      let newStyle: string =
        startStyle.substring(0, index1) + searchStr + window.location.pathname + startStyle.substring(index2);
      return newStyle;
    });
  }

  protected restoreSvgOutput() {
    super.restoreSvgOutput();

    // d3-dagre layout
    // restore node gradients
    this.svg.selectAll('#gTop .output .nodes circle').attr('style', function(d) {
      let startStyle: string = d3.select(this).attr('style');
      if (!startStyle) {
        console.log('start style not found: ', startStyle);
        return;
      }

      const searchStr: string = 'url("';
      const index1: number = startStyle.indexOf(searchStr);
      const index2: number = startStyle.indexOf('#');
      if (index1 < 0 || index2 < 0) {
        console.log('unable to change style.');
        return startStyle;
      }

      // remove /print segment
      const path: string = window.location.pathname;
      if (!path.endsWith('/print')) {
        console.warn('path not found');
      }
      const newPath = path.substring(0, path.length - '/print'.length);

      let newStyle: string = startStyle.substring(0, index1) + searchStr + newPath + startStyle.substring(index2);
      return newStyle;
    });
  }
}
