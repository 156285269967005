import { Inject, Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs/index';
import { CookieService } from 'ngx-cookie-service';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { map } from 'rxjs/internal/operators';
import { I18nMessagesService } from 'src/app/shared/services/i18n-messages.service';
import { MockService } from '../shared/services/mock.service';
import { Router } from '@angular/router';
import { SnackbarService } from '../component/workbench/reuse/logger/snackbar.service';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    public msgs: I18nMessagesService,
    private cookieService: CookieService,
    private mockService: MockService,
    private router: Router,
    private snackbar: SnackbarService
  ) {
    console.log('constructing auth interceptor');
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.cookieService.get('auth');

    let headers: HttpHeaders = new HttpHeaders();
    let params: HttpParams = req.params ? req.params : new HttpParams();
    let withCredentials = true;

    if (token) {
      headers = headers.append('Authorization', 'Bearer ' + token);
      headers = headers.append('content-type', 'application/json');
    } else {
      withCredentials = false;
    }

    const cloneAuth = req.clone({
      headers,
      params,
      withCredentials
    });

    if (req.url.startsWith('http') && this.mockService.active) {
      return this.mockService.get(req).pipe(
        map(res => {
          return res ? new HttpResponse({ status: 200, body: res }) : null;
        }),
        // Handle case where mockService returns null
        switchMap(response => {
          if (response) {
            return of(response);
          }
          // If no mock response, proceed with actual request
          return next.handle(cloneAuth).pipe(catchError(error => this.handleError(error)));
        })
      );
    }

    return next.handle(cloneAuth).pipe(catchError(error => this.handleError(error)));
  }

  handleError(error: HttpErrorResponse) {
    let errStr = '';

    switch (error.status) {
      case 401:
        errStr = 'Error : Unauthorized access';
        this.cookieService.delete('auth');
        this.snackbar.open(errStr, 'Close', { duration: 2000, panelClass: ['mpt-error-dark-snackbar'] });
        this.router.navigate(['/login']);
      case 403:
        errStr = 'Error : Forbidden Access';
        this.cookieService.delete('auth');
        this.snackbar.open(errStr, 'Close', { duration: 2000, panelClass: ['mpt-error-dark-snackbar'] });
        this.router.navigate(['/login']);
        return throwError(() => errStr);
      case 500:
        errStr = 'Server Error: Something went wrong!';
      default:
        errStr = 'Unknown Error';
    }

    return throwError(() => errStr);
  }
}
