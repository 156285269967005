import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { RepoService } from '../repo-importer/repo.service';
import { MatTableDataSource } from '@angular/material/table';
import { RepoResource } from '../models/repo-resource';
import { ImportResource } from '../models/import-resource';
import { ImportStatusAttribute } from '../models/import-status-attribute';
import { SelectionModel } from '@angular/cdk/collections';
import { Router } from '@angular/router';
import { MatSort, Sort } from '@angular/material/sort';

@Component({
  selector: 'app-repo-list',
  templateUrl: './repo-list.component.html',
  styleUrls: ['./repo-list.component.scss']
})
export class RepoListComponent implements OnInit {
  displayedColumns: string[] = ['select', 'repoName', 'createDate', 'numTalps'];
  inProgressColumns: string[] = ['repoName', 'percent', 'currentStage', 'startDateTime'];

  repoDataSource: MatTableDataSource<RepoResource> = new MatTableDataSource();
  inProgressDataSource: MatTableDataSource<ImportStatusAttribute> = new MatTableDataSource();
  selection: SelectionModel<RepoResource> = new SelectionModel<RepoResource>(false);
  isReady: boolean = false;
  prevInProgressCount: number = 0;

  @ViewChild(MatSort) set sort(sort: MatSort) {
    if (this.sort) {
      this.repoDataSource.sort = sort;
    }
  }

  constructor(private service: RepoService, private router: Router, private cdRef: ChangeDetectorRef) {}

  useRepo(): void {
    this.service.setSelectedRepoId(this.selection.selected[0].id);
    this.router.navigate(['/']);
  }

  onSortChange(state: Sort) {
    console.log('Sort changed..', state);
  }

  getRepoList() {
    this.service.fetchRepos().subscribe((res: RepoResource[]) => {
      if (res) {
        this.repoDataSource.data = res;

        this.repoDataSource.sortData = (data, sort) => {
          return data.sort((a, b) => {
            if (sort.active === 'createDate') {
              if (sort.direction === 'asc') {
                return new Date(a.attributes.created).getTime() - new Date(b.attributes.created).getTime();
              } else {
                return new Date(b.attributes.created).getTime() - new Date(a.attributes.created).getTime();
              }
            }
          });
        };
        this.cdRef.detectChanges();
        this.repoDataSource.sort = this.sort;
      }
    });
  }

  ngAfterViewInit(): void {
    this.repoDataSource.sort = this.sort;
  }

  ngOnInit(): void {
    this.getRepoList();

    this.service.statusObservable.subscribe((res: ImportResource[]) => {
      if (res) {
        if (res.length < this.prevInProgressCount) {
          this.getRepoList();
        }
        this.prevInProgressCount = res.length;
        console.log('status', res);
        this.inProgressDataSource.data = res.map(r => r.attributes);
        this.isReady = true;
      } else {
        this.isReady = true;
      }
    });
    this.service.pollForStatusList();
  }
}
