import { Force } from './force.base';
import { quadtree } from 'd3-quadtree';
// import {x, y} from "./simulation";

export function x(d) {
  return d.x;
}

export function y(d) {
  return d.y;
}

export class ManyBodyForce extends Force {
  get = function(): any {
    let constant = this.constant;
    let jiggle = this.jiggle;

    let nodes,
      node,
      alpha,
      strength = constant(-30),
      strengths,
      distanceMin2 = 1,
      distanceMax2 = Infinity,
      theta2 = 0.81;

    function force(_) {
      let i,
        n = nodes.length,
        tree = quadtree(nodes, x, y).visitAfter(accumulate);
      for (alpha = _, i = 0; i < n; ++i) {
        (node = nodes[i]), tree.visit(apply);
      }
    }

    function initialize() {
      if (!nodes) {
        return;
      }
      let i,
        n = nodes.length,
        node;
      strengths = new Array(n);
      for (i = 0; i < n; ++i) {
        (node = nodes[i]), (strengths[node.index] = +strength(node, i, nodes));
      }
    }

    function accumulate(quad) {
      let strength = 0,
        q,
        c,
        weight = 0,
        x,
        y,
        i;

      // For internal nodes, accumulate forces from child quadrants.
      if (quad.length) {
        for (x = y = i = 0; i < 4; ++i) {
          q = quad[i];
          c = Math.abs(q.value);
          if (q && c) {
            (strength += q.value), (weight += c), (x += c * q.x), (y += c * q.y);
          }
        }
        quad.x = x / weight;
        quad.y = y / weight;
      } else {
        // For leaf nodes, accumulate forces from coincident quadrants.
        q = quad;
        q.x = q.data.x;
        q.y = q.data.y;
        do {
          strength += strengths[q.data.index];
          q = q.next;
        } while (q);
      }

      quad.value = strength;
    }

    function apply(quad, x1, _, x2) {
      if (!quad.value) {
        return true;
      }

      let x = quad.x - node.x,
        y = quad.y - node.y,
        w = x2 - x1,
        l = x * x + y * y;

      // Apply the Barnes-Hut approximation if possible.
      // Limit forces for very close nodes; randomize direction if coincident.
      if ((w * w) / theta2 < l) {
        if (l < distanceMax2) {
          if (x === 0) {
            (x = jiggle()), (l += x * x);
          }

          if (y === 0) {
            (y = jiggle()), (l += y * y);
          }

          if (l < distanceMin2) {
            l = Math.sqrt(distanceMin2 * l);
          }
          node.vx += (x * quad.value * alpha) / l;
          node.vy += (y * quad.value * alpha) / l;
        }
        return true;
      } else if (quad.length || l >= distanceMax2) {
        // Otherwise, process points directly.
        return;
      }

      // Limit forces for very close nodes; randomize direction if coincident.
      if (quad.data !== node || quad.next) {
        if (x === 0) {
          (x = jiggle()), (l += x * x);
        }

        if (y === 0) {
          (y = jiggle()), (l += y * y);
        }
        if (l < distanceMin2) {
          l = Math.sqrt(distanceMin2 * l);
        }
      }

      do {
        if (quad.data !== node) {
          w = (strengths[quad.data.index] * alpha) / l;
          node.vx += x * w;
          node.vy += y * w;
          quad = quad.next;
        }
      } while (quad);
    }

    (force as any).initialize = function(_) {
      nodes = _;
      initialize();
    };

    (force as any).strength = function(_) {
      return arguments.length
        ? ((strength = typeof _ === 'function' ? _ : constant(+_)), initialize(), force)
        : strength;
    };

    (force as any).distanceMin = function(_) {
      return arguments.length ? ((distanceMin2 = _ * _), force) : Math.sqrt(distanceMin2);
    };

    (force as any).distanceMax = function(_) {
      return arguments.length ? ((distanceMax2 = _ * _), force) : Math.sqrt(distanceMax2);
    };

    (force as any).theta = function(_) {
      return arguments.length ? ((theta2 = _ * _), force) : Math.sqrt(theta2);
    };

    return force;
  };
}
