export enum CachedRoutes {
  Design = 0,
  Omniview,
  Decomposition,
  Callgraph,
  Controlflow,
  CobolControlflow,
  Dev1,
  ProjectList,
  FileViewer,
  ExportProject
}
