<div class="container">
   
    <h2>Generate Optimized Source</h2>
    
    <form [formGroup]="optimizationForm" (ngSubmit)="onSubmit()" class="form">
        <div class="row">
            <span>Input Value (Row Sizes) : </span>
            <mat-form-field appearance="fill">
                <input matInput formControlName="inputValue" type="number" placeholder="Enter row sizes" required>
                <mat-error *ngIf="optimizationForm.get('inputValue')?.hasError('required')">Row sizes is required</mat-error>
            </mat-form-field>
        </div>
    
        <div class="row">
            <span>Usable Cores (Available Cores : {{maxAvailableCores}}): </span>
            <mat-form-field appearance="fill">
                <input matInput formControlName="availableCores" type="number" placeholder="Enter available cores" required>
                <mat-error *ngIf="optimizationForm.get('availableCores')?.hasError('required')">Cores count is required</mat-error>
                <mat-error *ngIf="optimizationForm.get('availableCores')?.hasError('max')">Cannot exceed available cores</mat-error>
                <mat-error *ngIf="optimizationForm.get('availableCores')?.hasError('min')">Core count cannot be less than 1</mat-error>
            </mat-form-field>
        </div>
    
        <div class="row">
            <span>Optimization Type : </span>
            <div class="optimization-select">
                <mat-chip-list selectable="true">
                    <mat-chip (click)="onOptimizationTypeChange(item)" [color]="accent" class="select-chip" *ngFor="let item of optimizationTypes" [selected]="item.selected"><mat-icon>{{item.icon}}</mat-icon>&nbsp;{{item.name | labelFormat}}</mat-chip>
                </mat-chip-list>
            </div>
        </div>
        
        <div style="margin-top: 80px; display: flex; align-self: end; gap: 30px;">
            <button mat-stroked-button color="primary" type="submit">Generate Source</button>
            <button mat-raised-button color="primary" type="submit" [disabled]="!optimizationForm.valid" (click)="onRun()">Run</button>
        </div>
    </form>
  </div>