import { Injectable } from '@angular/core';
import { Scale } from '../../interfaces/scale';

// some of these methods are not being used currently.

export class ScalabilityHelper {
  scale: Scale = Scale.small;
  nodeCount: number = 0;

  constructor() {}

  public setScale(nodeCount: number) {
    this.nodeCount = nodeCount;
    if (nodeCount < 100) {
      this.scale = Scale.small;
    } else if (nodeCount < 1000) {
      this.scale = Scale.medium;
    } else {
      this.scale = Scale.large;
    }
    // console.log('scale is: ' + this.scale);
  }

  // need to tick the simulation while nodes are not visible when there
  // is a large number of nodes.
  getSimTickCount(fastSteps: boolean) {
    let count: number = 0;

    if (this.nodeCount < 20) {
      count = fastSteps ? 25 : 275;
    } else if (this.nodeCount < 100) {
      count = fastSteps ? 150 : 150;
    } else {
      count = fastSteps ? 300 : 0;
    }

    return count;
  }

  public getZoomFactor(): number {
    let scaleFactor: number;
    switch (this.scale) {
      case Scale.small:
        scaleFactor = 1;
        break;
      case Scale.medium:
        scaleFactor = 0.5;
        break;
      case Scale.large:
        scaleFactor = 0.1;
        break;
      default:
        console.log('unknown scale');
    }
    return scaleFactor;
  }

  public getCollisionIterations(): number {
    let collisionIterations: number;
    switch (this.scale) {
      case Scale.small:
        collisionIterations = 1;
        break;
      case Scale.medium:
        collisionIterations = 1;
        break;
      case Scale.large:
        collisionIterations = 1;
        break;
      default:
        console.log('unknown iterations');
    }
    return collisionIterations;
  }

  public getDecayFactor(): number {
    let decayFactor: number = 1;
    switch (this.scale) {
      case Scale.small:
        decayFactor = 1;
        break;
      case Scale.medium:
        decayFactor = 1;
        break;
      case Scale.large:
        decayFactor = 1;
        break;
      default:
        console.log('unknown decay factor');
    }
    return decayFactor;
  }

  getCollisionRadius(): number {
    return 2.5;
  }
}

@Injectable()
export class ScalabilityHelperService {
  constructor() {}

  createScalabilityHelper() {
    return new ScalabilityHelper();
  }
}
