import { Force } from './force.base';

export class YForce extends Force {
  get = function(y): any {
    let strength = this.constant(0.1),
      nodes,
      strengths,
      yz;

    let constant = this.constant;

    if (typeof y !== 'function') {
      y = constant(y == null ? 0 : +y);
    }

    function force(alpha) {
      for (let i = 0, n = nodes.length, node; i < n; ++i) {
        (node = nodes[i]), (node.vy += (yz[i] - node.y) * strengths[i] * alpha);
      }
    }

    function initialize() {
      if (!nodes) {
        return;
      }

      let i,
        n = nodes.length;
      strengths = new Array(n);
      yz = new Array(n);
      for (i = 0; i < n; ++i) {
        strengths[i] = isNaN((yz[i] = +y(nodes[i], i, nodes))) ? 0 : +strength(nodes[i], i, nodes);
      }
    }

    (force as any).initialize = function(_) {
      nodes = _;
      initialize();
    };

    (force as any).strength = function(_) {
      return arguments.length
        ? ((strength = typeof _ === 'function' ? _ : constant(+_)), initialize(), force)
        : strength;
    };

    (force as any).y = function(_) {
      return arguments.length ? ((y = typeof _ === 'function' ? _ : constant(+_)), initialize(), force) : y;
    };

    return force;
  };
}
