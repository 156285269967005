import { Inject, Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observable, of, pipe, throwError, timer } from 'rxjs/index';
import { CookieService } from 'ngx-cookie-service';
import { UserActionHttpParams } from '../shared/models/user-action-http-params';
import { EsHttpParams } from '../shared/models/es-http-params';
import { catchError, retryWhen, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
// import { SiteLoginService } from '../site-login/site-login.service';
// import { DataCacheService } from '../data-cache.service';
// import { MockService } from '../shared/services/mock.service';
// import { RetryStrategy } from './retry-strategy';
// import { AppState } from '../app.service';
// import { AppUtilityService } from '../shared/services/app-utility.service';
import { map } from 'rxjs/internal/operators';
import { I18nMessagesService } from 'src/app/shared/services/i18n-messages.service';
import { MockService } from '../shared/services/mock.service';

/**
 * Intercepts EVERY Http request and appends param/header values to the request object.
 * To add custom configuration, create a new class extending HttpParams.
 * @see UserActionHttpParams for example.
 */
@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    public msgs: I18nMessagesService,
    private http: HttpHandler,
    private cookieService: CookieService,
    private router: Router,
    // private cacheService: DataCacheService,
    private mockService: MockService,
    // private siteService: SiteLoginService,
    // private retryStrategy: RetryStrategy,
  ) {
    console.log('constructing auth interceptor');
  }

  // intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //   // Clone the request to add the new header.
  //   const clonedRequest = req.clone({ headers: req.headers.set('Authorization', 'Bearer your-token') });

  //   // Pass the cloned request instead of the original request to the next handle
  //   return next.handle(clonedRequest);
  // }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string = this.cookieService.get('api_tok');
    const userId: string = this.cookieService.get('userId');
    const orgName: string = this.cookieService.get('orgName');
    let cloneAuth: HttpRequest<any>;
    let headers: HttpHeaders = new HttpHeaders();
    let params: HttpParams = req.params ? req.params : new HttpParams();
    let withCredentials = false;

    // Check the cache for data if requested
    // if (req.headers.has('cacheKey')) {
    //   let cacheKey = req.headers.get('cacheKey');

    //   // For "GET" method, return data if exists in cache, else request from API
    //   if (req.method === 'GET') {
    //     let data: HttpEvent<any> = this.cacheService.getData(cacheKey);
    //     if (data) {
    //       // console.log('Request with url : ' + req.url + ' fulfilled by cache', data);
    //       return of(data);
    //     }
    //     // For other methods, clear cache.
    //   } else if (req.method === 'POST' || req.method === 'DELETE' || req.method === 'PUT') {
    //     console.log('Cleared cache data for request url : ' + req.url);
    //     this.cacheService.removeData(cacheKey);
    //   }
    // }

    // headers = headers.append('Authorization', token === 'undefined' ? '' : token);

    if (req.params instanceof UserActionHttpParams) {
      params = params.append('UserId', userId === 'undefined' ? '' : userId);
      params = params.append('OrgName', orgName === 'undefined' ? '' : orgName);
    } else if (req.params instanceof EsHttpParams) {
      headers = headers.delete('Authorization');
      headers = headers.append('Content-Type', 'application/json');
      withCredentials = false;
    }

    cloneAuth = req.clone({
      headers,
      params,
      withCredentials: withCredentials
    });

    if (req.url.startsWith('http') && this.mockService.active) {
      return this.mockService.get(req).pipe(
        map(res => {
          if (res) {
            return new HttpResponse({ status: 200, body: res });
          }
          return this.processResult(next, req, cloneAuth);
        }),
        catchError(err => {
          console.log(err);
          return this.processResult(next, req, cloneAuth);
        })
      );
    } else {
      let result$ = this.processResult(next, req, cloneAuth);
      return result$;
    }
  }

  /**
   * Do additional processing like handling fatal errors and/or retrying if applicable
   * @param next
   * @param req
   * @param cloneAuth
   */
  processResult(next, req, cloneAuth) {
    // Exclude urls from session expiration handling.
    const excludeExpireCheck = ['keep-alive', 'login', 'cub', 'coalition'];
    const fatalErrors = [0, 401, 408];
    let skipRetry: boolean;

    return next.handle(cloneAuth).pipe(
      tap(
        event => {
          // if (req.method === 'GET' && req.headers.has('cacheKey')) {
          //   if (event instanceof HttpResponse) {
          //     // console.log(`Adding item to cache: ${req.url}`, event);
          //     this.cacheService.setData(req.headers.get('cacheKey'), event);
          //   }
          // }
        },
        err => {
          // Retry only if not fatal or a GET request.
          skipRetry = req.method !== 'GET';

          // if (fatalErrors.includes(err.status)) {
          //   skipRetry = true;
          //   if (
          //     !this.appState.get('expired') &&
          //     err.status === 401 &&
          //     !this.appUtilityService.stringContainsArrayElement(req.url, excludeExpireCheck)
          //   ) {
          //     this.siteService.showExpireModal();
          //     this.appState.set('expired', true);
          //     return of(null);
          //   } else {
          //     this.handleFatalErrors(this.errMessage(err), false);
          //   }
          //   this.systemService.stopMonitoring();
          // }
        }
      ),
      // retryWhen(err => {
      //   let retries = 1;
      //   return this.retryStrategy.doRetry(err, retries, skipRetry);
      // }),
      catchError(err => {
        throw err;
      })
    );
  }

  //todo: Setting error text should be moved to a relevant service
  errMessage = err => {
    switch (err.status) {
      case 401:
        return err.error.errorBlock ? err.error.errorBlock.details : err.error.message;
      case 408:
        return this.msgs.authInterceptor408;
      case 0:
        return this.msgs.authInterceptorDefault;
      default:
        return '';
    }
  };

  /**
   *
   * @param message
   * @param logout
   */
  // handleFatalErrors(message?: string, logout?: boolean) {
  //   this.snackbarService.displaySnackAlert(
  //     message,
  //     'error',
  //     { close: true },

  //     {
  //       verticalPosition: 'bottom',
  //       duration: 5000
  //     }
  //   );

  //   if (logout) {
  //     // this.siteService.deleteSessionCookies();
  //     console.log('user logout forced');
  //     this.router.navigate(['/login']);
  //   }
  // }
}
