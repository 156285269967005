import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class SnackbarService {
  constructor(public sb: MatSnackBar) {}

  open(message: string, action?: string, config?: MatSnackBarConfig): MatSnackBarRef<SimpleSnackBar> {
    if (!config) {
      config = {
        duration: 3500
      };
    }

    if (!config.duration) {
      config.duration = 3500;
    }

    return this.sb.open(message, action, config);
  }
}
