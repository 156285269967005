export type DashboardCard = {
  title: string;
  id: string;
  content: string;
  subtitle?: string;
  actionText: string;
  action: string;
  actionParams?: string;
  isEnabled: boolean;
};

export function getDefaultCards(): DashboardCard[] {
  let dashboardCards: DashboardCard[] = [
    {
      title: 'Source Code',
      id: 'source',
      actionText: 'VIEW',
      content: 'View And Manage Source Code',
      action: '$id/source',
      isEnabled: true
    },
    {
      title: 'Functional Decomposition',
      id: 'funcDecomp',
      actionText: 'GENERATE',
      content: 'Generate Visual Functional Decomposition of Matrix Multiplication',
      action: '/workbench/$id/fnDecomp',
      isEnabled: true
    },
    {
      title: 'TALP Decomposition',
      actionText: 'GENERATE',
      id: 'talpDecomp',
      content: 'Generate visual Talp Decomposition',
      action: '/workbench/$id/talpDecomp',
      isEnabled: true
    },
    {
      title: 'TALP Analytics',
      id: 'analytics',
      actionText: 'GENERATE',
      content: 'Generate and view TALP analytics based on runtime data',
      action: '/analysis/$id/generate',
      isEnabled: true
    },
    {
      title: 'Optimized Application',
      id: 'executable',
      actionText: 'GENERATE',
      content: 'Generate and download application as an executable based on optimization preferences',
      action: '$id/generate',
      isEnabled: true
    },
  ];

  return dashboardCards;
}
