// import { FeatureConfigService } from '../../config/feature-config.service';

export class UrlStringsBox {
  static urlConstants = {
    users: {
      LOGGED_IN_USER: 'users/loggedInUser',
      REGISTER_USER: 'users/register',
      REGISTER_ALLOWED: 'users/registrationAllowed',
      USERS: 'users',
      USERS_BY_ROLE: 'users/withFilter?roleName=',
      USERS_BY_EMAIL: 'users/email'
    },
    user: {
      VERIFY_SESSION: 'user/verifySession',
      LOGIN: 'user/login',
      LOGIN_2FA: 'user/twoFactorLogin',
      QUESTIONS: 'user/questions',
      LOGOUT: 'user/logout',
      EXISTS: 'user/exists',
      USER_ORG: 'userInfo/userOrg',
      ORG_USERS: 'userInfo/users',
      DELETE_USER: 'users',
      GET_ACTIVITY_REPORT: 'report/activity',
      VERIFY_TOKEN: 'user/verifyToken',
      ACTIVATE_USER: 'user/activateAccount',
      RESET_PASSWORD: 'user/resetPassword',
      VALIDATE_EMAIL: 'user/validateEmail'
    },
    system: {
      CUB_STAT: 'status/cub',
      COAL_STAT: 'status/coalition',
      GET_BUILD_VERSION: 'assets/version.json'
    },
    project: {
      MANUAL_CREATE: 'projects/default-template',
      CODE_CREATE: 'projects/extract-code',
      REQUIREMENT_CREATE: 'projects/create-requirement',
      REQUIREMENT_EXTRACT: 'projects/extract-requirements',
      EXTRACTION_CONFIG: 'projects/extraction-config',
      PROJECT_EXISTS: 'projects/exists',
      PROJECTS: 'projects',
      // GET_DOXY: 'metrics/getDoxy',
      DELETE_PROJECT: 'project/deleteProject'
    },
    design: {
      HLD: 'hld',
      PROCESS: 'hld/process',
      ELEMENT: 'hld/element',
      FLOW: 'hld/flow',
      DECOMP_PROFILE: 'hld/tlpDecomps',
      TLP_STATUS: 'hld/tlpStatus',
      DATASTORE: 'hld/datastore',
      CONTROLLER: 'hld/controller',
      TLP_ANALYSIS: 'hld/analysis/tlp',
      OBJECT_SEARCH: 'hld/process/search',
      OBJECT_SEARCH_TEST: 'hld/process/searchWithTest',
      PROJECT_ANALYSIS_ERROR: 'hld/analysis/error/project',
      DECOMP_ANALYSIS_ERROR: 'hld/analysis/error/decomp',
      VIEW_DECOMP: 'hld/view/decomp',
      VIEW_OMNI: 'hld/view/omni',
      VIEW_LEVEL: 'hld/view/level',
      // LEVEL_DATA: 'hld/levels',
      GENERATE_ADF: 'hld/generate'
    },
    test: {
      TESTS: 'tests',
      TEST_OBJECT: 'tests/object',
      TEST_PROCESS: 'tests/process',
      TEST_ANALYSIS: 'tests/analysis'
    },
    cobolControlFlow: {
      GET_COBOL85_FILES: 'cobolcontrolflow/files',
      GET_COBOL_CODE: 'cobolcontrolflow/source',
      GET_FLOW_GRAPH: 'cobolcontrolflow/flowgraph'
    },
    controlFlow: {
      GET_LOOKUP_INFO: 'controlflow/filenFuncs',
      GET_C_CODE: 'controlflow/source',
      GET_FLOW_GRAPH: 'controlflow/flowgraph',
      GET_VARIABLE_DEPENDENCIES: 'controlflow/dependency',
      GET_GLOBAL_VARIABLES: 'controlflow/globals'
    },
    callGraph: {
      GET_CALL_GRAPH: 'callgraph/graph'
    },
    components: {
      OBJECT: 'store/object',
      CATEGORY: 'store/category',
      GET_COMPONENTS: 'store/components',
      // GET_COMPONENTS_METRICS: 'store/component-metrics-old',
      GET_SOURCE: 'store/source',
      GET_SOURCE_TEMPLATE: 'store/sourceTemplate',
      GET_DATATYPES: 'store/getTypes',
      GET_IOPARAMS: 'store/getCubObParams',
      GET_MATCHING_COMPONENTS: 'store/matchingComponents',
      GET_DEFINITION: 'store/getFields',
      GET_TASKS: 'store/getCubObTasks',
      GET_CATEGORY_TAGS: 'store/categoryTags',
      GET_CATEGORY_TAGS_LIST: 'store/categoryTagsList',
      GET_CATEGORY_BY_TAGS: 'store/categoryFilter',
      COPY_COMPONENTS: 'store/copyComponent',
      SAVE_TASKS_FOR_DESIGNELEMENT: 'store/task',
      SAVE_DEFINITION: 'store/saveDefinition',
      SAVE_PARAM: 'store/saveParam',
      SAVE_PARAMS: 'store/saveParams',
      ADD_OBJECT: 'store/addObject',
      UPDATE_OBJECT: 'store/updateObject',
      DELETE_OBJECT: 'store/deleteObject',
      SOURCE_CODE: 'source',
      PROFILE: '/profile',
      RUNTIME_FILES: 'rtFiles',
      DATA_FILES: 'dataFiles'
    },
    report: {
      GET_CODE_MAP_REPORT_FOR_PROJECT: 'report/codeMap'
    },
    gantt: {
      PROJECTS: 'ganttProjects',
      PROJECTS_WITH_SUMMARY: 'ganttProjects/summary',
      FUNCTIONAL_REQUIREMENT: 'ganttFuncReq',
      EXECUTIVE_SUMMARY: '/executive/',
      REQUIREMENTS: '/requirements/',
      LINK_TO_PROJECT: '/link',
      UNLINK_FROM_PROJECT: '/unlink',
      AGGREGATED_TASKS: '/allTasks',
      TASKS_FOR_PROJECT: '/tasks',
      INSIGHTS: '/insights'
    },
    email: {
      SEND_USER_INVITE_TO_ORGANIZATION: 'email/accountActivation',
      RESET_PASSWORD_EMAIL: 'email/resetPassword',
      SEND_USER_FEEDBACK: 'email/userFeedback',
      RESEND_ACTIVATE_REG: 'email/accountRegistration'
    },
    login: {
      ACTIVATION_REDIRECT_URL: 'activate-user?token=',
      RESET_PASSWORD_URL: 'reset-password?token=',
      ACTIVATE_REG_REDIRECT_URL: 'activate-new-user?token='
    }
  };
}
