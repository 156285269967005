import { I18nMessagesService } from 'src/app/shared/services/i18n-messages.service';

export class NavbarMenu {
  constructor(public msgs: I18nMessagesService) {}
  
   static readonly defaultMenu: MenuItem[] = [
    {
      label: $localize`:@@navbar.menu.project.library:Project Library`,
      icon: 'miscellaneous_services',
      route: '/projects',
      cyId: 'projects'
    },
    {
      label: $localize`:@@navbar.menu.component.library:Component Library`,
      icon: 'miscellaneous_services',
      feature: 'components',
      route: '/components',
      cyId: 'components'
    },
    {
      label: $localize`:@@navbar.menu.gantt.charts:Gantt Charts`,
      icon: 'timeline',
      feature: 'ganttchart',
      route: '/gantt',
      cyId: 'gantt'
    },
    {
      label: $localize`:@@navbar.menu.user.status:User Status`,
      icon: 'people',
      route: '/info/status',
      cyId: 'userInformation'
    },
    {
      label: $localize`:@@navbar.menu.account.settings:Account Settings`,
      icon: 'person',
      route: '/settings',
      cyId: 'userSettings'
    },
    {
      label: $localize`:@@navbar.menu.activity.log:Activity Log`,
      icon: 'event_note',
      route: '/log',
      feature: 'userLog'
    },
    // {
    //   label: 'Execution Engine Status',
    //   icon: 'wb_sunny',
    //   route: '/coalition-status'
    // },
    {
      label: $localize`:@@navbar.menu.debug:Debug`,
      icon: 'bug_report',
      feature: 'debug',
      action: 'doDebug',
      cyId: 'debug'
    },
    {
      label: $localize`:@@navbar.menu.toggle.fullscreen:Toggle Fullscreen`,
      icon: 'fullscreen',
      action: 'toggleFullScreen',
      cyId: 'toggle'
    },
    // {
    //   label: 'About Blue Cheetah',
    //   icon: 'fingerprint',
    //   route: '/about'
    // },
    {
      label: $localize`:@@navbar.menu.logout:Logout`,
      icon: 'login',
      action: 'doLogout',
      cyId: 'logout'
    }
  ];
}

export type MenuItem = {
  label: string;
  route?: string;
  action?: string;
  icon?: string;
  feature?: string;
  cyId?: string;
};
