<div class="mpt-container">
<h2>Repositories</h2>

<div *ngIf="isReady; else spinner" style="width: 50%;">
    <div *ngIf="inProgressDataSource.data.length > 0">
        <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
            <mat-panel-title> Repositories In Progress
                <mat-progress-bar mode="buffer" style="display: flex; align-self: center; margin-left: 30px; width: 150px;"></mat-progress-bar>
                </mat-panel-title>
            <mat-panel-description> Currently {{inProgressDataSource.data.length}} repos are being imported </mat-panel-description>
            </mat-expansion-panel-header>

            <table mat-table [dataSource]="inProgressDataSource">
            
                <ng-container matColumnDef="repoName">
                    <th mat-header-cell *matHeaderCellDef> Repository Name </th>
                    <td mat-cell *matCellDef="let repo"> {{repo.repoName}} </td>
                </ng-container>
                <ng-container matColumnDef="percent">
                    <th mat-header-cell *matHeaderCellDef> Completion Percent </th>
                    <td mat-cell *matCellDef="let repo"> {{repo.progress.percent}} </td>
                </ng-container>
                <ng-container matColumnDef="currentStage">
                    <th mat-header-cell *matHeaderCellDef> Current Stage </th>
                    <td mat-cell *matCellDef="let repo"> {{repo.progress.currentStep}} </td>
                </ng-container>
                <ng-container matColumnDef="startDateTime">
                    <th mat-header-cell *matHeaderCellDef> Start Time </th>
                    <td mat-cell *matCellDef="let repo"> {{repo.startTime}} </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="inProgressColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: inProgressColumns;"></tr>
            </table>
        </mat-expansion-panel>

    <mat-divider style="margin-top: 10px; margin-bottom: 10px;"></mat-divider>
    </div>

    <section class= "data-table">

        <table mat-table class="mat-elevation-z2" [dataSource]="repoDataSource">
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef> Select </th>
                <td mat-cell *matCellDef="let repo"> 
                    <mat-checkbox (change)="$event ? selection.toggle(repo) : null" 
                        [checked]="selection.isSelected(repo)">
                    </mat-checkbox> 
                </td>
            </ng-container>
            
            <ng-container matColumnDef="repoName">
                <th mat-header-cell *matHeaderCellDef> Repository Name </th>
                <td mat-cell *matCellDef="let repo"> {{repo.attributes.name}} </td>
            </ng-container>
            
            <ng-container matColumnDef="createDate">
                <th mat-header-cell *matHeaderCellDef> Creation Date </th>
                <td mat-cell *matCellDef="let repo"> {{repo.attributes.created}} </td>
            </ng-container>

            <ng-container matColumnDef="numTalps">
                <th mat-header-cell *matHeaderCellDef> Number of TALPs </th>
                <td mat-cell *matCellDef="let repo"> {{repo.relationships?.talps.data.length}} </td>
            </ng-container>
            <ng-container matColumnDef="deleteRepo">
                <th mat-header-cell *matHeaderCellDef> Actions </th>
                <td mat-cell *matCellDef="let repo"> <button mat-icon-button color="warn" (click)="deleteRepo(repo.id)"><mat-icon>delete</mat-icon></button></td>
            </ng-container>
        
            <!-- <ng-container matColumnDef="repoCreateDate">
                    <th mat-header-cell *matHeaderCellDef> Created Date </th>
                    <td mat-cell *matCellDef="let repo"> {{repo.createDate}} </td>
            </ng-container> -->
        
            <!-- <ng-container matColumnDef="repoStatus">
                <th mat-header-cell *matHeaderCellDef> Repository Status </th>
                <td mat-cell *matCellDef="let repo"> {{repo.predicted}} </td>
            </ng-container> -->
        
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </section>
    <button style="margin-top: 30px; margin-left: 20px;" [disabled]="!selection.hasValue()" mat-flat-button [color]="'primary'" (click)="useRepo()">Select</button>
</div>

</div>

<ng-template #spinner>
    <div style="display: flex; margin:auto; justify-content: center;">
        <mat-spinner [strokeWidth]="5" [diameter]="75"></mat-spinner>
    </div>
</ng-template>