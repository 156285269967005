import { ApiResponse } from "../models/api-response";
import { Resource } from "../models/resource";


/**
 * Parse API response and map to resource or resource array
 * @param response 
 * @param ResourceClass 
 * @returns 
 */
export function functionParser<T>(
    response: ApiResponse<T>,
    ResourceClass: new (
      id: string,
      type: string,
      links: any,
      included: any[],
      attributes: T,
      relationships: any
    ) => Resource<T>
  ): Resource<T>[] | Resource<T> {
    let resources: Resource<T>[] | Resource<T>;

    if(response.data instanceof Array) {
      resources = response.data.map(data => {
        return new ResourceClass(
          data.id,
          data.type,
          response.links,
          response.included,
          data.attributes,
          data.relationships
        );
      });
    } else {
      resources = new ResourceClass(
        response.data.id,
        response.data.type,
        response.links,
        response.included,
        response.data.attributes,
        response.data.relationships
      );
    }

    return resources;
  }