import { Component, Input, OnInit } from '@angular/core';

export type RunResult = {
  executionTime: string;
  memoryConsumption: string;
  powerConsumption: string;
  energyConsumption: string;
  cost: string;
  waterConsumption: string;
  carbonFootprint: string;
}

@Component({
  selector: 'app-run-result',
  templateUrl: './run-result.component.html',
  styleUrls: ['./run-result.component.scss']
})
export class RunResultComponent implements OnInit {

  runResult: RunResult;

  @Input('result') set result(res: RunResult) {
    if(res) {
      this.runResult = res;
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

  sortNull() {}

}
