import { AnalyticsAttribute, Link } from './analytics-attribute';
import { ImportStatusAttribute } from './import-status-attribute';
import { Resource } from './resource';

export class ImportResource extends Resource<ImportStatusAttribute> {
  constructor(
    id: string,
    type: string,
    links: any,
    included: any[],
    statusAttribute: ImportStatusAttribute,
    relationships: any
  ) {
    super(id, type, links as Link, included, statusAttribute, relationships);
  }
}
