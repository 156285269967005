import { Force } from './force.base';

export class XForce extends Force {
  get = function(x): any {
    let strength = this.constant(0.1),
      nodes,
      strengths,
      xz;

    // let constant = function(x) { return function() { return x; }; }
    let constant = this.constant;

    if (typeof x !== 'function') {
      x = this.constant(x == null ? 0 : +x);
    }

    function force(alpha) {
      for (let i = 0, n = nodes.length, node; i < n; ++i) {
        (node = nodes[i]), (node.vx += (xz[i] - node.x) * strengths[i] * alpha);
      }
    }

    function initialize() {
      if (!nodes) {
        return;
      }

      let i,
        n = nodes.length;
      strengths = new Array(n);
      xz = new Array(n);
      for (i = 0; i < n; ++i) {
        strengths[i] = isNaN((xz[i] = +x(nodes[i], i, nodes))) ? 0 : +strength(nodes[i], i, nodes);
      }
    }

    (force as any).initialize = function(_) {
      nodes = _;
      initialize();
    };

    (force as any).strength = function(_) {
      return arguments.length
        ? ((strength = typeof _ === 'function' ? _ : constant(+_)), initialize(), force)
        : strength;
    };

    (force as any).x = function(_) {
      return arguments.length ? ((x = typeof _ === 'function' ? _ : constant(+_)), initialize(), force) : x;
    };

    return force;
  };
}
