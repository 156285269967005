import { Component, OnInit } from '@angular/core';
import { Progress } from '../import-status/import-progress.component';

export type Stage = {
  displayName: string;
  name: string;
};

export type StageProgress = {
  stage: Stage;
  status: Status;
};

export enum Status {
  notStarted,
  inProgress,
  complete,
  error
}

@Component({
  selector: 'app-import-progress-bar',
  templateUrl: './import-progress-bar.component.html',
  styleUrls: ['./import-progress-bar.component.scss']
})
export class ImportProgressBarComponent implements OnInit {
  progressStages: StageProgress[] = [];
  currentStage: Stage = { displayName: 'Cloning', name: 'Cloning' };

  stages: Stage[] = [
    { displayName: 'Cloning', name: 'Cloning' },
    { displayName: 'Serial Time Profiling', name: 'Serial Time Measurement' },
    { displayName: 'Serial Memory Profiling', name: 'Serial Memory Measurement' },
    { displayName: 'Parallel Time Profiling', name: 'Parallel Time Measurement' },
    { displayName: 'Parallel Memory Profiling', name: 'Parallel Memory Measurement' },
    { displayName: 'Power Profiling', name: 'Parallel Power Measurement' },
    { displayName: 'Predictive Model Generation', name: 'Predictive Model Generation' }
  ];

  constructor() {}

  public update(currentStatus: Progress): void {
    let currentStep = currentStatus.currentStep;
    let currentStage: StageProgress = this.progressStages.find(stage => stage.stage.name === currentStep);
    let nextStage: StageProgress = this.progressStages.find(stage => stage.stage.name === currentStatus.nextStep);

    if (currentStatus.result.errorCode != 0) {
      currentStage.status = Status.error;
    } else {
      let idx = 0;

      if(currentStatus.percentComplete < 100) {
        currentStage.status = Status.inProgress;
        idx = this.progressStages.indexOf(currentStage);
      } else{
        idx = this.stages.length;
      }
      
      for (let i = 0; i < idx; i++) {
        this.progressStages[i].status = Status.complete;
      }      
    }
  }

  ngOnInit() {
    this.initializeBar();
  }

  private initializeBar() {
    let stageProgress: StageProgress[] = [];
    for (let stage of this.stages) {
      stageProgress.push({ stage: stage, status: Status.notStarted });
    }
    stageProgress[0].status = Status.inProgress;
    this.progressStages = stageProgress;
  }
}
