import { Injectable } from '@angular/core';
import { I18nMessagesService } from 'src/app/shared/services/i18n-messages.service';
// import { ProjectStatus } from 'src/app/projects/models/project-status';
// import {ExtractionOptionTag} from "../../../../../components/project-create/code-create/extraction-options/extraction-options.component";

//private readonly PROJ_LIB_COL_PROJ_NAME = select
export enum ProjectLibraryColumns {
  SELECT = 'select',
  PROJECT_NAME = 'projectName',
  PROJECT_DESCRIPTION = 'projectDescription',
  PROJECT_CODE_LANG = 'projectCodingLanguage',
  PROJECT_CREATE_DATE = 'projectCreationTime',
  PROJECT_LINES_OF_CODE = 'extractionInfo.linesOfCode',
  PROJECT_OWNER_NAME = 'ownerName',
  PROJECT_ANALYSIS_TYPE = 'dynamicAnalysis',
  PROJECT_ANALYSIS_DEPTH = 'analysisDepth',
  PROJECT_TLP_ALGO = 'tlpAlgorithms',
  PROJECT_STATUS = 'status'
}
@Injectable()
export class ConvertNodeLinkTypeToDisplayTextService {
  //node types //decomposition, components, design
  private readonly KERNEL: string = 'Kernel';
  private readonly ALGORITHM: string = 'Algorithm';
  private readonly CONTROLLER: string = 'Controller';
  private readonly TERMINATOR: string = 'Terminator';
  private readonly SYSTEM_PROCESS: string = 'SystemProcess';
  private readonly SHARED_PROCESS: string = 'SharedProcess';
  private readonly SHARED_CONTROLLER: string = 'SharedController';
  private readonly LOCAL_DATA_STORE: string = 'localDataStore';
  private readonly FILE_SCOPE_DATASTORE: string = 'FileScopeDataStore';
  private readonly PROCESS: string = 'Process';
  private readonly DATA_STORE: string = 'DataStore';
  private readonly FDATASTORE: string = 'FDataStore';
  private readonly CONNECTOR: string = 'Connector';
  private readonly GLOBAL_DATASTORE: string = 'globalDataStore';
  private readonly FUNCTION_PTR_PROCESS: string = 'FunctionPointerProcess';

  private readonly FLOWS: string = 'Flows';
  private readonly NODES: string = 'Nodes';
  private readonly LINKS: string = 'Links';
  private readonly FLOW: string = 'Flow';
  private readonly NODE: string = 'Node';
  private readonly COMPONENT: string = 'Component';
  private readonly COMPONENTS: string = 'Components';

  //callgraph
  private readonly FUNCTION: string = 'Function';
  private readonly FUNCTION_CALL: string = 'Function Call';

  //control flow
  private readonly BLOCKITEM: string = 'blockItem';
  private readonly FOR: string = 'For';
  private readonly IF: string = 'If';
  private readonly WHILE: string = 'While';
  private readonly TRUE: string = 'True';
  private readonly FALSE: string = 'False';
  private readonly TRUE_LOWER_CASE = 'true';
  private readonly FALSE_LOWER_CASE = 'false';
  private readonly TRUE_CAPS: string = 'TRUE';
  private readonly FALSE_CAPS: string = 'FALSE';
  //links or edges
  private readonly DATA: string = 'DATA';
  private readonly DECOMP: string = 'DECOMP';
  private readonly CONTROL: string = 'CONTROL';
  private readonly DUMMY: string = 'DUMMY';

  //design nodes
  private readonly DESIGN_NODES: string = 'Design Nodes';
  private readonly CONTAINS: string = 'contains';
  private readonly TLP_OPPORTUNITIES: string = 'TLP Opportunities';
  private readonly FLOW_NAME: string = 'Flow name';

  private readonly DESIGN_NODE: string = 'Design Node';
  //control flow diagram variable popups
  private readonly REFERENCE: string = 'reference';
  private readonly DECLARATION: string = 'declaration';
  private readonly MODIFICATION: string = 'modification';

  //project-profile
  private readonly PROF_TYPE_MANUAL = 'MANUAL';
  private readonly PROF_TYPE_AUTO = 'AUTO';
  private readonly PROF_TYPE_CUSTOM = 'CUSTOM';

  //component-library and object-editor
  private readonly CUB_OBJ_KERNEL = 'Kernel';
  private readonly CUB_OBJ_ALGORITHM = 'Algorithm';
  private readonly CUB_OBJ_SYSTEM_KERNEL = 'System Kernel';
  private readonly CUB_OBJ_FUNCTION_POINTER = 'Function Pointer';

  //execution status
  private readonly NOT_STARTED_STATUS = 'NOT STARTED';
  private readonly EXECUTING_STATUS = 'EXECUTING';
  private readonly EXECUTION_COMPLETE_STATUS = 'COMPLETED';
  private readonly FAILED_STATUS = 'FAILED';
  private readonly EXECUTION_CANCEL_STATUS = 'CANCELLED';

  private readonly DB_STATUS_CONNECTED = 'Connected';
  private readonly DB_STATUS_DISCONNECTED = 'Disconnected';
  private readonly DB_STATUS_CHECK_FAILED = 'Status check failed!';

  //  //Project TLP Analysis Depth
  //  private readonly TLP_ANALYSIS_DEPTH_ALL = 'ALL';
  //  private readonly TLP_ANALYSIS_DEPTH_FUNCTION = 'FUNCTION';

  constructor(public msgs: I18nMessagesService) {}

  getLinkNodeDisplayDescription(nodeType: string): string {
    // console.log(nodeType);
    let description: string = '';
    switch (nodeType) {
      case this.NODES:
        description = this.msgs.countNodesLabel;
        break;
      case this.LINKS:
        description = this.msgs.countLinksLabel;
        break;
      case this.FLOWS:
        description = this.msgs.countFlowsLabel;
        break;
      case this.FLOW:
        description = this.msgs.countFlowLabel;
        break;
      case this.COMPONENT:
        description = this.msgs.countComponentLabel;
        break;
      case this.NODE:
        description = this.msgs.countNodeLabel;
        break;
      //callgraph
      case this.FUNCTION:
        description = this.msgs.countFunctionLabel;
        break;
      case this.FUNCTION_CALL:
        description = this.msgs.countFunctionCallLabel;
        break;
      //controlflow
      case this.BLOCKITEM:
        description = this.msgs.countBlockItemLabel;
        break;
      case this.FOR:
        description = 'For';
        break;
      case this.IF:
        description = 'If';
        break;
      case this.WHILE:
        description = 'While';
        break;
      case this.TRUE:
        description = this.msgs.countTrueLabel;
        break;
      case this.FALSE:
        description = this.msgs.countFalseLabel;
        break;
      case this.TRUE_LOWER_CASE:
        description = this.msgs.countTrueLabel.toLowerCase();
        break;
      case this.FALSE_LOWER_CASE:
        description = this.msgs.countFalseLabel.toLowerCase();
        break;
      case this.TRUE_CAPS:
        description = this.msgs.countTrueLabel.toUpperCase();
        break;
      case this.FALSE_CAPS:
        description = this.msgs.countFalseLabel.toUpperCase();
        break;

      //decomposition, components, design
      case this.COMPONENTS:
        description = this.msgs.countComponentsLabel;
        break;
      case this.CONTROLLER:
        description = this.msgs.countControllerLabel;
        break;
      case this.TERMINATOR:
        description = this.msgs.countTerminatorLabel;
        break;
      case this.ALGORITHM:
        description = this.msgs.countAlgorithmLabel;
        break;
      case this.FILE_SCOPE_DATASTORE:
        description = this.msgs.countFileScopeDataStoreLabel;
        break;
      case this.LOCAL_DATA_STORE:
        description = this.msgs.countLocalDataStoreLabel;
        break;
      case this.SHARED_CONTROLLER:
        description = this.msgs.countSharedControllerLabel;
        break;
      case this.SYSTEM_PROCESS:
        description = this.msgs.countSystemProcessLabel;
        break;
      case this.KERNEL:
        description = this.msgs.countKernelLabel;
        break;
      case this.SHARED_PROCESS:
        description = this.msgs.countSharedProcessLabel;
        break;
      case this.PROCESS:
        description = this.msgs.countProcessLabel;
        break;
      case this.DATA_STORE:
        description = this.msgs.countDataStoreLabel;
        break;
      case this.FDATASTORE:
        description = this.msgs.countFDataStoreLabel;
        break;
      case this.CONNECTOR:
        description = this.msgs.countConnectorLabel;
        break;
      case this.GLOBAL_DATASTORE:
        description = this.msgs.countGlobalDataStoreLabel;
        break;
      case this.FUNCTION_PTR_PROCESS:
        description = this.msgs.countFunctionPointerLabel;
        break;
      case this.DESIGN_NODE:
        description = this.msgs.countDesignNodeLabel;
        break;
      //design nodes
      case this.DESIGN_NODES:
        description = this.msgs.countDesignNodesLabel;
        break;
      case this.CONTAINS:
        description = this.msgs.countContainsLabel;
        break;
      case this.TLP_OPPORTUNITIES:
        description = this.msgs.countTLPOpportunitiesLabel;
        break;
      case this.FLOW_NAME:
        description = this.msgs.svgRendererFlowName;
        break;
      //edges/links
      case this.DUMMY:
        description = this.msgs.countDummyLabel;
        break;
      case this.DECOMP:
        description = this.msgs.countDecompLabel;
        break;
      case this.DATA:
        description = this.msgs.countDataLabel;
        break;
      case this.CONTROL:
        description = this.msgs.countControlLabel;
        break;
      default:
        description = nodeType;
        console.log('No translation for ' + nodeType);
    }
    //console.log("converted value= " + description);
    return description;
  }

  getVariableTypeDisplayDescription(variableType: string): string {
    let description: string = '';
    switch (variableType) {
      case this.REFERENCE:
        description = this.msgs.controlFlowVariableReferenceLabel;
        break;
      case this.DECLARATION:
        description = this.msgs.controlFlowVariableDeclarationLabel;
        break;
      case this.MODIFICATION:
        description = this.msgs.controlFlowVariableModificationLabel;
        break;
      default:
        description = variableType;
        console.log('No translation for ' + variableType);
    }
    return description;
  }

  getProjectProfileTypeDisplayDescription(profileType: string): string {
    let description: string = '';
    switch (profileType) {
      case this.PROF_TYPE_MANUAL:
        description = this.msgs.profileTypeManual;
        break;
      case this.PROF_TYPE_AUTO:
        description = this.msgs.profileTypeAuto;
        break;
      case this.PROF_TYPE_CUSTOM:
        description = this.msgs.profileTypeCustom;
        break;
      default:
        description = profileType;
        console.log('No translation for ' + profileType);
    }
    return description;
  }

  getCubObjectTypeDisplayDescription(cubObjectType: string): string {
    let description: string = '';
    switch (cubObjectType) {
      case this.CUB_OBJ_KERNEL:
        description = this.msgs.objTypeKernel;
        break;
      case this.CUB_OBJ_ALGORITHM:
        description = this.msgs.objTypeAlgorithm;
        break;
      case this.CUB_OBJ_SYSTEM_KERNEL:
        description = this.msgs.objTypeSystemKernel;
        break;
      case this.CUB_OBJ_FUNCTION_POINTER:
        description = this.msgs.objTypeFunctionPointer;
        break;
      default:
        description = cubObjectType;
        console.log('No translation for ' + cubObjectType);
    }
    return description;
  }

  getExecutionStatusDescription(execStatus: string): string {
    let description: string = '';
    switch (execStatus) {
      case this.NOT_STARTED_STATUS:
        description = this.msgs.execStatusNotStarted;
        break;
      case this.EXECUTING_STATUS:
        description = this.msgs.execStatusExecuting;
        break;
      case this.EXECUTION_COMPLETE_STATUS:
        description = this.msgs.execStatusCompleted;
        break;
      case this.FAILED_STATUS:
        description = this.msgs.execStatusFailed;
        break;
      case this.EXECUTION_CANCEL_STATUS:
        description = this.msgs.execStatusCancelled;
        break;
      default:
        description = execStatus;
        console.log('No translation for ' + execStatus);
    }
    return description;
  }

  // getProjectStatusDisplayDescription(projectStatus: string): string {
  //   let description: string = '';
  //   switch (projectStatus) {
  //     case ProjectStatus.COMPLETED:
  //       description = this.msgs.projectInfoStatusCompleted;
  //       break;
  //     case ProjectStatus.CREATED:
  //       description = this.msgs.projectInfoStatusCreated;
  //       break;
  //     case ProjectStatus.IN_PROGRESS:
  //       description = this.msgs.projectInfoStatusInProgress;
  //       break;
  //     case ProjectStatus.UNKNOWN:
  //       description = this.msgs.projectInfoStatusUnknown;
  //       break;
  //     case ProjectStatus.SAVED:
  //       description = this.msgs.projectInfoStatusSaved;
  //       break;
  //     case ProjectStatus.ERROR:
  //       description = this.msgs.projectInfoStatusError;
  //       break;
  //     default:
  //       description = projectStatus;
  //       console.log('No translation for ' + projectStatus);
  //   }
  //   return description;
  // }
  //execution-engine.component.html
  getDatabaseConnectStatusDisplayDescription(dbConnectStatus: string): string {
    let description: string = '';
    switch (dbConnectStatus) {
      case this.DB_STATUS_CONNECTED:
        description = this.msgs.execEngineDBStatusConnected;
        break;
      case this.DB_STATUS_DISCONNECTED:
        description = this.msgs.execEngineDBStatusDisconnected;
        break;
      case this.DB_STATUS_CHECK_FAILED:
        description = this.msgs.execEngineDBStatusCheckFailed;
        break;
      default:
        description = dbConnectStatus;
        console.log('No translation for ' + dbConnectStatus);
    }
    return description;
  }

  // extraction-options.component.html
  // getExtractOptionText(option: string): string {
  //   let value: string = option;

  //   switch (option) {
  //     case ExtractionOptionTag.EXTRACTION_CONFIG_STATIC_ANALYSIS:
  //       value = this.msgs.extractConfigStatAnalysis;
  //       break;
  //     case ExtractionOptionTag.EXTRACTION_CONFIG_STATIC_ANALYSIS_DESC:
  //       value = this.msgs.extractConfigStatDesc;
  //       break;
  //     case ExtractionOptionTag.EXTRACTION_CONFIG_DYNAMIC_ANALYSIS:
  //       value = this.msgs.extractConfigDynAnalysis;
  //       break;
  //     case ExtractionOptionTag.EXTRACTION_CONFIG_DYNAMIC_ANALYSIS_DESC:
  //       value = this.msgs.extractConfigDynDesc;
  //       break;
  //     case ExtractionOptionTag.EXTRACTION_CONFIG_TASK_LEVEL:
  //       value = this.msgs.extractConfigTaskLevel;
  //       break;
  //     case ExtractionOptionTag.EXTRACTION_CONFIG_TASK_LEVEL_DESC:
  //       value = this.msgs.extractConfigTaskLevelDesc;
  //       break;
  //     case ExtractionOptionTag.EXTRACTION_CONFIG_TASK_LEVEL_DEPTH:
  //       value = this.msgs.extractConfigTaskLevelDepth;
  //       break;
  //     case ExtractionOptionTag.EXTRACTION_CONFIG_TASK_LEVEL_DEPTH_DESC:
  //       value = this.msgs.extractConfigTaskLevelDepthDesc;
  //       break;
  //     case ExtractionOptionTag.EXTRACTION_CONFIG_FUNCTION_LEVEL:
  //       value = this.msgs.extractConfigFunctionLevel;
  //       break;
  //     case ExtractionOptionTag.EXTRACTION_CONFIG_ALL_LEVELS:
  //       value = this.msgs.extractConfigAllLevels;
  //       break;
  //     default:
  //       console.log('No translation for ' + value);
  //   }
  //   return value;
  // }

  //project-library.component.html
  getProjectLibraryColumnName(option: string): string {
    let value: string = option;

    switch (option) {
      case ProjectLibraryColumns.SELECT:
        value = this.msgs.projectLibrarySelect;
        break;
      case ProjectLibraryColumns.PROJECT_NAME:
        value = this.msgs.projectLibraryProjectName;
        break;
      case ProjectLibraryColumns.PROJECT_DESCRIPTION:
        value = this.msgs.projectLibraryDescription;
        break;
      case ProjectLibraryColumns.PROJECT_CODE_LANG:
        value = this.msgs.projectLibraryLanguage;
        break;
      case ProjectLibraryColumns.PROJECT_CREATE_DATE:
        value = this.msgs.projectLibraryCreationDate;
        break;
      case ProjectLibraryColumns.PROJECT_LINES_OF_CODE:
        value = this.msgs.projectLibraryLinesOfCode;
        break;
      case ProjectLibraryColumns.PROJECT_OWNER_NAME:
        value = this.msgs.projectLibraryCreatedBy;
        break;
      case ProjectLibraryColumns.PROJECT_ANALYSIS_TYPE:
        value = this.msgs.projectLibraryAnalysisType;
        break;
      case ProjectLibraryColumns.PROJECT_ANALYSIS_DEPTH:
        value = this.msgs.projectLibraryAnalysisDepth;
        break;
      case ProjectLibraryColumns.PROJECT_TLP_ALGO:
        value = this.msgs.projectLibraryTLPAlgorithms;
        break;
      case ProjectLibraryColumns.PROJECT_STATUS:
        value = this.msgs.projectLibraryProjectStatus;
        break;
      default:
        console.log('No translation for ' + value);
    }
    return value;
  }
}
