import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ConfigService } from 'src/app/config.service';

export interface FileRepoResponse {
  name: string;
  directory: boolean;
  content?: string;
  children: [];
}

@Injectable({ providedIn: 'root' })
export class FileViewService {
  mimeMap: Map<string, string> = new Map();
  sourceMap: Map<string, string> = new Map<string, string>();
  directoryMap: Map<string, FileRepoResponse[]> = new Map();

  constructor(private http: HttpClient) {
    this.mimeMap.set('js', 'text/javascript');
    this.mimeMap.set('ts', 'text/typescript');
    this.mimeMap.set('json', 'text/javascript');
    this.mimeMap.set('css', 'text/css');
    this.mimeMap.set('html', 'text/html');
    this.mimeMap.set('scss', 'text/css');
    this.mimeMap.set('c', 'text/x-csrc');
    this.mimeMap.set('cpp', 'text/x-c++src');
    this.mimeMap.set('.cs', 'text/csharp');
    this.mimeMap.set('.java', 'text/x-java');
    this.mimeMap.set('.xml', 'application/xml');
  }

  getMime(fileName: string): string | undefined {
    let extension = this.getExtension(fileName);
    if (this.mimeMap.has(extension)) {
      return this.mimeMap.get(extension);
    }
  }

  getExtension(file: string): string {
    let index = file.lastIndexOf('.');
    return file.substr(index + 1, file.length);
  }

  fetchCommits(projectId: string): Observable<any> {
    const url: string = `projects/${projectId}/commits`;
    return this.http.get(ConfigService.getApiURL(url));
  }

  /**
   * Fetch all files for the project and path.
   * @param projectId
   * @param path
   * @param ref
   * @param commit
   * @param isDirectory
   */
  // fetchFiles(
  //   projectId: string,
  //   path: string = '',
  //   // ref: string = '',
  //   commit: string = '',
  //   isDirectory: boolean = true
  // ): Observable<GitRepoResponse[]> {
  //   const url: string = `projects/${projectId}/files`;
  //   let params: HttpParams = new HttpParams();

  //   if (path) {
  //     params = params.append('path', path);
  //   }
  //   if (ref) {
  //     params = params.append('ref', ref);
  //   }

  //   if (commit) {
  //     params = params.append('commit', commit);
  //   }

  //   if (isDirectory != null) {
  //     params = params.append('isDirectory', String(isDirectory));
  //   }
  //   return this.http.get(ConfigService.getApiURL(url), { params }).pipe(
  //     map((res: any) => {
  //       if (res && res.data) {
  //         this.updateFileMaps(path, res.data.files);
  //       }
  //       return res.data.files;
  //     })
  //   ) as Observable<any>;
  // }

  fetchFiles(repo: string, isDirectory: boolean = true, path: string): Observable<FileRepoResponse[]> {
    let params: HttpParams = new HttpParams();
    let url = ConfigService.getAuxURL(`/repo/${repo}/files`);

    // if (path) {
    //   params = params.append('path', path);
    // }

    // if (isDirectory != null) {
    //   params = params.append('isDirectory', String(isDirectory));
    // }

    return this.http.get(url, { params }).pipe(
      map((res: any) => {
        if (res) {
          this.updateFileMaps(path, res);
        }
        return res;
      })
    ) as Observable<any>;
  }

  /**
   * @param parentPath
   * @param files
   */
  updateFileMaps(parentPath, files: FileRepoResponse[]): void {
    // Set directory map to lookup files.
    this.directoryMap.set(parentPath, files);

    // Set source map to lookup content.
    for (let file of files) {
      let path = parentPath ? parentPath + '/' + file.name : file.name;
      if (!this.sourceMap.has(path)) {
        // let content: string = atob(file.content);
        this.sourceMap.set(path, file.content);
      }
    }
  }

  //
  // fetchFileCommits(path: string, sha: string = 'master'): Observable<any> {
  //   let apiUrl = this.getApiUrl();
  //
  //   apiUrl = `${apiUrl}/commits?sha=${sha}&path=${path}`;
  //
  //   let headers: HttpHeaders = new HttpHeaders();
  //   headers = headers.append('Authorization', 'token 1c1cc0df0d2002fa0607b5cd7e0526170bfc74c0');

  //   return this.http.get(apiUrl, { headers }) as Observable<any>;
  // }
}
