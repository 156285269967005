export class Resource<T> {
  id: string;
  type: string;
  links: any;
  included: any[];
  attributes: T;
  relationships: any;


  constructor(id: string, type: string, links: any, included: any[], attributes: T, relationships: any) {
    this.id = id;
    this.type = type;
    this.links = links;
    this.included = included;
    this.attributes = attributes;
    this.relationships = relationships;
  }
}
