export abstract class Force {
  constant = function(x) {
    return function() {
      return x;
    };
  };
  jiggle = function() {
    return (Math.random() - 0.5) * 1e-6;
  };
}
