import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export type CompareFile = {
  name: string;
  content: string;
}

@Component({
  selector: 'app-file-compare',
  templateUrl: './file-compare.component.html',
  styleUrls: ['./file-compare.component.scss']
})
export class FileCompareComponent  {

  left: CompareFile;
  right: CompareFile;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {left: CompareFile, right: CompareFile}) { 
    this.left = data?.left;
    this.right = data?.right;
  }

  onCompareResults($event: any) {
    throw new Error('Method not implemented.');
  }
    

}
