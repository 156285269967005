import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RunResult } from '../run-result/run-result.component';
import { OptimizationType } from '../../generate-source.component';

@Component({
  selector: 'app-generate-request-form',
  templateUrl: './generate-request-form.component.html',
  styleUrls: ['./generate-request-form.component.scss']
})
export class GenerateRequestFormComponent implements OnInit {

  optimizationForm: FormGroup;
  maxAvailableCores: number = 20;

  @Output('runEvent') runEvent = new EventEmitter<RunResult>();

  results: RunResult = {
    executionTime: '10ms',
    memoryConsumption: '10MB',
    powerConsumption: '10W',
    energyConsumption: '10J',
    cost: '0.003$',
    waterConsumption: '0.0002L',
    carbonFootprint: '.0002KG'
  }

  optimizationTypes: OptimizationType[] = [
    { name: 'Performance', value: 'Performance', icon: 'browse_gallery', selected: false },
    { name: 'Energy Efficiency', value: 'Energy Efficiency', icon: 'bolt', selected: true }
  ]; //[''Performance', 'Energy Efficiency'];

  constructor(private fb: FormBuilder) {
    this.optimizationForm = this.fb.group({
      inputValue: ['', Validators.required],
      availableCores: ['', [Validators.required, Validators.min(1), Validators.max(this.maxAvailableCores)]],
      optimizationType: ['Performance', Validators.required]
    });
  }

  ngOnInit(): void {}

  onSubmit() {
    console.log(this.optimizationForm.value);
  }

  onOptimizationTypeChange(type: OptimizationType) {
    for (let t of this.optimizationTypes) {
      t.selected = false;
    }
    type.selected = true;
  }

  onRun() {
    this.runEvent.emit(this.results);
  }
}
