import { HttpParams } from '@angular/common/http';
// import { HttpParamsOptions } from '@angular/common/http/src/params';

/**
 * Custom HTTP Headers for API calls that are specific to user-log.
 * When passed in a http call, the auth interceptor will handle the rest of params appropriately
 */
export class UserActionHttpParams extends HttpParams {
  constructor(params: { [param: string]: string | string[] }) {
    super({ fromObject: params });
  }
}
