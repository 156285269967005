<div>
	<div id="mpt-header-wrapper">
		<app-navbar></app-navbar>
	</div>
	<div id="outer-mpt-container" style="position:relative">

		<div #moduleloading id="module-loading-indicator" style="display:none;height:0">
			<mat-spinner [strokeWidth]="8" [diameter]="75"></mat-spinner>
		</div>
		<div #mptcontainer class="mpt-container">
			<router-outlet></router-outlet>
		</div>
	</div>
</div>