<div class="login-container">
    <div class="animated-background">
        <mat-card class="login-card">
            <mat-card-header>
                <mat-card-title>TALP Optimizer</mat-card-title>
            </mat-card-header>
            <mat-card-header>
                <mat-card-subtitle style="font-size: 18px;">Log in</mat-card-subtitle>
            </mat-card-header>

            <mat-card-content>
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    <mat-form-field appearance="outline">
                        <mat-label>Username</mat-label>
                        <input matInput formControlName="username" required>
                        <mat-error *ngIf="loginForm.get('username')?.hasError('required')">Username is
                            required</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Password</mat-label>
                        <input matInput type="password" formControlName="password" required>
                        <mat-error *ngIf="loginForm.get('password')?.hasError('required')">Password is
                            required</mat-error>
                    </mat-form-field>


                    <button mat-raised-button color="primary" type="submit" [disabled]="!loginForm.valid">
                        Login
                    </button>

                </form>
            </mat-card-content>
        </mat-card>
    </div>
</div>