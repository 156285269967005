<mat-toolbar id="mpt-navbar" class="mat-elevation-z2">
  <span>
    <a routerLink="/" id="mpt-logo-fs">
      <img height="140" width="140" src="./assets/img/TALP_transparent.png" />
    </a>
  </span>

  <section class="navbar-actions">
    <div class="nav-action-button">
      <button mat-stroked-button (click)="showSysInfo()">System Info</button>
    </div>
  </section>

  <section class="navbar-actions">
      <button mat-icon-button (click)="logout()"><mat-icon>logout</mat-icon></button>
  </section>

  <!-- <coal-status></coal-status> -->
  <!-- <app-about [matTooltip]="msgs.navbarAbout"></app-about> -->
  <!-- <language-picker [size]="'large'"></language-picker> -->

  <!-- <span id="navmainmenu">
    <button id="mainMenu" style="height:50px;width:50px;" [matTooltip]="msgs.navbarMainMenu" mat-icon-button
      [matMenuTriggerFor]="projectMenu">
      <mat-icon style="font-size:36px; height: 36px; width: 36px;line-height: 36px;">menu</mat-icon>
    </button>
    <mat-menu #projectMenu="matMenu">
      <div *ngFor="let item of menu">
        <button *ngIf="item.route && showMenuItem(item)" mat-menu-item routerLink="{{item.route}}"
          id="navBar_{{item.cyId}}">
          <mat-icon style="font-size:36px;" color="primary">{{item.icon}}</mat-icon>
          <span>{{item.label}}</span>
        </button>
        <button *ngIf="item.action && showMenuItem(item)" mat-menu-item (click)="this[item.action]()" mainMenu
          id="navBar_{{item.cyId}}">
          <mat-icon color="primary">{{item.icon}}</mat-icon>
          <span>{{item.label}}</span>
        </button>
      </div>
    </mat-menu>
  </span> -->

</mat-toolbar>