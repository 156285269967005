import { Injectable } from '@angular/core';
import { FileNode } from './file-list/file-list.component';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { FileRepoResponse, FileViewService } from './file-view.service';

@Injectable({ providedIn: 'root' })
export class FileDbService {
  directoryMap: Map<string, FileRepoResponse[]> = new Map();

  constructor(private service: FileViewService) {}

  getChildren(projectId: string, parent: FileNode): Observable<FileNode[]> | undefined {
    if (!this.directoryMap.has(parent.path)) {
      return this.service.fetchFiles(projectId, true, parent.path).pipe(
        map(res =>
          res.map(child => ({
            path: parent.path ? parent.path + '/' + child.name : child.name,
            level: parent.level + 1,
            isParent: child.directory,
            name: child.name
          }))
        )
      );
    } else {
      let children: FileRepoResponse[] = this.directoryMap.get(parent.path);
      return of(
        children.map(child => ({
          name: child.name,
          path: parent.path ? parent.path + '/' + child.name : child.name,
          isParent: child.directory,
          level: parent.level + 1
        }))
      );
    }
  }
}
