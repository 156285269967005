import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

export enum SocketType {
  EXTRACT_STAT = 'extStat',
  EXECUTE_STDIO = 'execStdio',
  COALITION_STAT = 'coalStat',
  SESSION_STAT = 'sessionStat'
}

@Injectable({ providedIn: 'root' })
export class ConfigService {
  private static readonly uiBasePath: string = environment.baseUiUrl;

  private static readonly httpProtocol: string = environment.secureProtocol === 'true' ? 'https://' : 'http://';
  private static readonly wsProtocol: string = environment.secureProtocol === 'true' ? 'wss://' : 'ws://';

  constructor() {}

  private static buildBaseApiUrl(): string {
    return `${ConfigService.httpProtocol}${environment.domainAPI}`;
  }

  private static buildBaseAuxUrl(): string {
    return `${ConfigService.httpProtocol}${environment.domainAux}`;
  }


  private static buildBaseUiUrl(): string {
    return `${ConfigService.httpProtocol}${environment.domainUI}`;
  }

  private static buildSocketUrl(): string {
    return `${ConfigService.wsProtocol}${environment.domainAPI}`;
  }

  // public static getSocket(sock: SocketType): string {
  //   return `${ConfigService.buildSocketUrl()}${ConfigService.apiBasePath}${sock}`;
  // }

  public static getApiURL(path: string): string {
    return `${ConfigService.buildBaseApiUrl()}${path}`;
  }

  public static getAuxURL(path: string): string {
    return `${ConfigService.buildBaseAuxUrl()}${environment.baseAuxUrl}${path}`;
  }

  public static getUiURL(urlConst: string): string {
    return `${ConfigService.buildBaseUiUrl()}${ConfigService.uiBasePath}${urlConst}`;
  }
}
