import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { DashboardCard, getDefaultCards } from '../models/dashboard-card';
import { RepoResource } from '../models/repo-resource';
import { MatDialog } from '@angular/material/dialog';
import { RepoImporterComponent } from '../repo-importer/repo-importer.component';
import { RepoService } from '../repo-importer/repo.service';
import { RepoListComponent } from '../repo-list/repo-list.component';
import { take, takeUntil } from 'rxjs/operators';
import { ImportStatusAttribute } from '../models/import-status-attribute';
import { ImportResource } from '../models/import-resource';
import { Observable } from 'rxjs-compat';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  unsubscribe: Subject<any> = new Subject<any>();

  readonly defaultCards: DashboardCard[] = getDefaultCards();
  displayedCards: DashboardCard[] = this.defaultCards;

  repos: RepoResource[] = [];
  selectedRepo: RepoResource = this.repos[0];
  isReady: boolean = false;
  createEnable: boolean = false;
  inProgressResource: ImportResource;
  selectedRepoId: string;

  constructor(
    private router: Router,
    private snackbar: MatSnackBar,
    private service: RepoService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.selectedRepoId = this.service.getSelectedRepoId();
    this.getRepoAndSelect();

    this.service.statusObservable.pipe(takeUntil(this.unsubscribe)).subscribe({
      next: (res: ImportResource[]) => {
        this.inProgressResource = res && res.length > 0 ? res[0] : null;
      },
      complete: () => {
        this.inProgressResource = null;
      }
    });
    this.service.pollForStatusList();
  }

  /**
   * Get list of repos and set the first one as default
   */
  getRepoAndSelect() {
    this.service.fetchRepos().subscribe(res => {
      if (res && (res as RepoResource[]).length > 0) {
        this.repos = res as RepoResource[];
        this.repos = this.service.sortReposByDate(this.repos);
        this.selectRepo();
      }
      this.isReady = true;
    });
  }

  selectRepo() {
    if (this.selectedRepoId) {
      this.selectedRepo = this.repos.find(r => r.id === this.selectedRepoId);
    }

    if (!this.selectedRepo) {
      this.selectedRepo = this.repos[0];
      this.service.setSelectedRepoId(this.selectedRepo.id);
    }
  }

  showCards() {
    this.displayedCards = this.defaultCards;
  }

  showRepoList() {
    let data: ImportStatusAttribute = null;

    this.dialog.open(RepoListComponent, {
      width: '2000px',
      height: '550px'
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  setDefaultDataAndNavigate() {
    throw new Error('Method not implemented.');
  }

  onAction(card: DashboardCard) {
    let url: string;
    if (card.action.indexOf('$id') >= 0) {
      url = card.action.replace('$id', this.selectedRepo.attributes.name);
    }
    this.router.navigate([url]);
  }

  onRepoChange() {
    // this.service.setCurrentRepoResource(this.selectedRepo);
    this.showCards();
  }

  navigateToRepoList() {
    this.router.navigate(['/repo']);
  }

  /**
   * Open repo importer and fetch repos when complete
   */
  onAddRepo() {
    let inProgressData: ImportStatusAttribute;

    if (this.inProgressResource) {
      inProgressData = this.inProgressResource.attributes as ImportStatusAttribute;
    }

    this.dialog
      .open(RepoImporterComponent, {
        width: '900px',
        height: '550px',
        data: inProgressData
      })
      .afterClosed()
      .subscribe(res => {
        this.service.pollForStatusList();

        if (res) {
          this.snackbar.open('Repository imported successfully', 'Close', { duration: 2000 });
        }
        this.getRepoAndSelect();
      });
  }
}
