import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/index';
import { environment } from '../../../environments/environment';
import { of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MockService {
  active: boolean = false;
  private http: HttpClient;

  // Exclude  urls with these end segments
  readonly excludeFromMock = ['keep-alive', 'cub', 'coalition', 'status'];

  constructor(private handler: HttpBackend) {
    this.http = new HttpClient(handler);
  }

  /**
   * Return mock data from assets/mock
   * If request => "{baseurl}/projects/{projectId}/data
   * mock data will be returned from  =>  src/assets/mock/projects/data.json
   *
   * mock data should follow the structure -
   *
   * {
   *   data : {
   *    json_data
   *   }
   *   enable: boolean
   * }
   *
   * 'data' is key to the exact API response structure.
   * 'enable' is to toggle mock data active state.
   *
   * @param request
   */
  public get(request: HttpRequest<any>): Observable<any> {
    let segments: string[] = request.url.split('/');
    let file = segments[segments.length - 1];

    if (this.excludeFromMock.findIndex(s => s === file) >= 0) {
      return of(null);
    }

    // let dir = '';

    // let baseApi = environment.baseApiUrl.replace(/\/+/gm, '');
    // let idx = segments.findIndex(s => s === baseApi);
    // if (idx >= 0 && idx + 1 < segments.length) {
    //   dir = segments[idx + 1];
    // }

    // let path = `assets/${dir}/${file}.json`;
    let path = `assets/mock/${file}.json`;

    return this.http.get(path, { responseType: 'json' });
  }
}
