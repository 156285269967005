import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/modules/shared.module';
import { CookieService } from 'ngx-cookie-service';
import { AuthInterceptor } from './interceptor/auth.interceptor';
import { CustomReuseStrategy } from './shared/models/custom-reuse-strategy';
import { ConfigService } from 'src/app/config.service';
import { Logger } from './component/workbench/reuse/logger/logger.service';
import { SnackbarService } from './component/workbench/reuse/logger/snackbar.service';
import { DiagramOptionsService } from './component/workbench/diagrams/diagram-options.service';
import { WorkbenchService } from './component/workbench/workbench.service';
import { ScalabilityHelperService } from './component/workbench/reuse/extra/scalability-helper/scalability-helper.service';
import { SvgUtils } from './component/workbench/render/svg/services/svg-utils.service';
import { SvgFormatterService } from './component/workbench/render/svg/services/svg-formatter.service';
import { RenderService } from './component/workbench/render/render.service';
import { SvgDefsService } from './component/workbench/render/svg/services/svg-defs.service';
import { SimulationService } from './component/workbench/render/force/simulation-service.service';
import { I18nMessagesService } from './shared/services/i18n-messages.service';
import { ConvertNodeLinkTypeToDisplayTextService } from './component/workbench/reuse/extra/display-text-helper/convert-node-link-type-to-display-text.service';
import { MockService } from './shared/services/mock.service';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { FileViewComponent } from './component/project-files/file-view/file-view.component';
import { FileListComponent } from './component/project-files/file-list/file-list.component';
import { RepoImporterComponent } from './component/repo-importer/repo-importer.component';
import { ImportProgressComponent } from './component/import-status/import-progress.component';
import { RepoListComponent } from './component/repo-list/repo-list.component';
import { ImportProgressBarComponent } from './component/import-progress-bar/import-progress-bar.component';
import { SysInfoComponent } from './component/sys-info/sys-info.component';
import { RunResultComponent } from './component/generate-source/component/run-result/run-result.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileCompareComponent } from './component/project-files/file-compare/file-compare.component';
import { GenerateSourceComponent } from './component/generate-source/generate-source.component';
import { GenerateRequestFormComponent } from './component/generate-source/component/generate-request-form/generate-request-form.component';
import { AuthComponent } from './component/auth/auth.component';

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    DashboardComponent,
    GenerateRequestFormComponent,
    RunResultComponent,
    GenerateSourceComponent,
    FileViewComponent,
    FileListComponent,
    RepoImporterComponent,
    ImportProgressComponent,
    RepoListComponent,
    ImportProgressBarComponent,
    SysInfoComponent,
    FileCompareComponent,
    AuthComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    SharedModule,
    
  ],
  exports: [],
  providers: [
    { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    Logger,
    CookieService,
    SnackbarService,
    DiagramOptionsService,
    MockService,
    WorkbenchService,
    ScalabilityHelperService,
    I18nMessagesService,
    SvgUtils,
    SvgFormatterService,
    RenderService,
    SvgDefsService,
    SimulationService,
    ConfigService,
    ConvertNodeLinkTypeToDisplayTextService,
  ]
})
export class AppModule {
  constructor() {}
}
