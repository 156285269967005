import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConfigService } from 'src/app/config.service';
import { ImportStatusAttribute } from '../models/import-status-attribute';
import { ApiResponse } from '../models/api-response';
import { map } from 'rxjs/operators';
import { ImportResource } from '../models/import-resource';
import { RepoResource } from '../models/repo-resource';
import { Repo } from '../models/repo';
import { functionParser } from '../utils/response-parser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class RepoService {
  private statusBehaviorSubject: BehaviorSubject<ImportResource[]> = new BehaviorSubject<ImportResource[]>(null);
  public statusObservable: Observable<ImportResource[]> = this.statusBehaviorSubject.asObservable();
  pollId: number;
  inProgressCount: number;

  constructor(private http: HttpClient, private snackbar: MatSnackBar, private cookieService: CookieService) {}

  public importRepo(repoName: string, repoUrl: string): Observable<ImportResource> {
    let url = ConfigService.getApiURL(`/repo`);

    return this.http.post(url, { name: repoName, location: repoUrl }).pipe(
      map(data => {
        const apiResponse: ApiResponse<ImportStatusAttribute> = data as ApiResponse<ImportStatusAttribute>;
        return functionParser(apiResponse, ImportResource);
      })
    ) as Observable<ImportResource>;
  }

  getSelectedRepoId(): string {
    return this.cookieService.get('selectedRepo');
  }

  sortReposByDate(repos: RepoResource[]): RepoResource[] {
    repos.sort((a, b) => {
      let d1 = new Date(a.attributes.created);
      let d2 = new Date(b.attributes.created);
      return d2.getTime() - d1.getTime();
    });
    return repos;
  }

  setSelectedRepoId(repoId: string) {
    this.cookieService.set('selectedRepo', repoId);
  }

  fetchRepos(id?: string): Observable<RepoResource[] | RepoResource> {
    let url = `/repo`;

    if (id) {
      url = `/repo/${id}`;
    }

    const apiUrl = ConfigService.getApiURL(url);

    return this.http.get(apiUrl).pipe(
      map(data => {
        const apiResponse: ApiResponse<Repo> = data as ApiResponse<Repo>;
        return functionParser(apiResponse, RepoResource);
      })
    ) as Observable<RepoResource[] | RepoResource>;
  }

  pollForStatusList(): void {
    if (this.pollId) {
      return;
    }

    this.pollId = window.setInterval(() => {
      this.fetchImportStatus().subscribe(
        res => {
          if (res.length > 0) {
            let inProgress = res.filter(r => r.attributes.progress.percent < 100);

            if (inProgress.length > 0) {
              this.statusBehaviorSubject.next(inProgress);
              this.inProgressCount = res.length;
            } else {
              if (this.inProgressCount > 0) {
                this.inProgressCount = 0;
                this.snackbar.open('Repository import complete', 'Close', { duration: 2000 });
              }
              this.stopPolling();
            }
          } else {
            if (this.inProgressCount > 0) {
              this.inProgressCount = 0;
            }
            this.stopPolling();
          }
        },
        error => {
          this.stopPolling();
        }
      );
    }, 1000);
  }

  stopPolling() {
    clearInterval(this.pollId);
    this.pollId = null;
    this.statusBehaviorSubject.next([]);
  }

  fetchImportStatus(): Observable<ImportResource[]> {
    let url = ConfigService.getApiURL(`/analysis`);
    return this.http.get(url).pipe(
      map(data => {
        const apiResponse: ApiResponse<ImportStatusAttribute> = data as ApiResponse<ImportStatusAttribute>;
        return functionParser(apiResponse, ImportResource);
      })
    ) as Observable<ImportResource[]>;
  }
}
