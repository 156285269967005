import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {
  title: any;
  message: any;

  @Output('onConfirm') onConfirm = new EventEmitter<any>();

  constructor(@Inject(MAT_DIALOG_DATA) public data, private matDialogRef: MatDialogRef<ConfirmationModalComponent>) {
    this.title = data.title;
    this.message = data.message;
  }

  ngOnInit(): void {
    this.matDialogRef.updateSize('550px', '250px');
  }

  onConfirmDelete() {
    this.matDialogRef.close(true);
  }

  onCancelDelete() {
    this.matDialogRef.close();
  }
}
