import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ImportProgressBarComponent } from '../import-progress-bar/import-progress-bar.component';

export type Progress = {
  currentStep: string;
  nextStep: string;
  percentComplete: number;
  status: string;
  result: {
    errorCode: number;
  }
};

export type Info = {
  startTime: string;
  repoName: string;
  id: string;
};

@Component({
  selector: 'app-import-progress',
  templateUrl: './import-progress.component.html',
  styleUrls: ['./import-progress.component.scss']
})
export class ImportProgressComponent implements OnInit {
  currentProgress: Progress = {
    result: {
      errorCode: 0,
    },
    status: '',
    currentStep: '',
    nextStep: '',
    percentComplete: 0
  };

  info: Info = {
    startTime: '',
    repoName: '',
    id: ''
  };

  @ViewChild('progressBar') progressBar: ImportProgressBarComponent;

  @Input('progress') set progress(progress: Progress) {
    if (progress) {
      this.currentProgress = progress;
      if(this.progressBar) {
        this.progressBar.update(this.currentProgress);
      }
    }
  }

  @Input('repoInfo') set repoInfo(info: Info) {
    if (info && info.id) {
      this.info = info;
    }
  }


  constructor() {}

  ngOnInit(): void {}
}
