import { Force } from './force.base';

export class CenterForce extends Force {
  get = function(x, y) {
    let nodes;

    if (x == null) {
      x = 0;
    }
    if (y == null) {
      y = 0;
    }

    function force() {
      let i,
        n = nodes.length,
        node,
        sx = 0,
        sy = 0;

      for (i = 0; i < n; ++i) {
        (node = nodes[i]), (sx += node.x), (sy += node.y);
      }

      for (sx = sx / n - x, sy = sy / n - y, i = 0; i < n; ++i) {
        (node = nodes[i]), (node.x -= sx), (node.y -= sy);
      }
    }

    (force as any).initialize = function(_) {
      nodes = _;
    };

    (force as any).x = function(_) {
      return arguments.length ? ((x = +_), force) : x;
    };

    (force as any).y = function(_) {
      return arguments.length ? ((y = +_), force) : y;
    };

    return force;
  };
}
