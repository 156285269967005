import { Injectable, TemplateRef, ViewContainerRef } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';

@Injectable({ providedIn: 'root' })
export class OverlayService {
  constructor(private overlay: Overlay) {}

  newOverlay(config: OverlayConfig): OverlayRef {
    config.positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();

    return this.overlay.create(config);
  }

  public attachOverlayToPortal(
    overlayRef: OverlayRef,
    templateRef: TemplateRef<any>,
    vcRef: ViewContainerRef
  ): void {
    overlayRef.attach(new TemplatePortal(templateRef, vcRef));
  }

  public detachOverlayFromPortal(overlayRef: OverlayRef) {
    overlayRef.detach();
  }
}
