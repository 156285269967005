import { Router } from '@angular/router';
import * as d3 from 'd3';
import { D3Selection } from '../../../../reuse/extra/d3-more-types';
import { SvgPrintLine } from '../../../../reuse/interfaces/svg-print-line';
import { ZoomTransform as d3ZoomTransform } from 'd3-zoom';
import { zoomTransform as d3zoomTransformFunction } from 'd3-zoom';

import { AbstractRenderer } from '../../../abstract-renderer';
import { FormatOptions, SvgFormatter } from '../../../../reuse/interfaces/svg-formatter';
import { RenderingOptions } from '../../../../reuse/interfaces/diagram-options';
import { Vector } from '../../../../reuse/interfaces/point';
import { Orientation } from '../../../../reuse/interfaces/svg-formatter';
export abstract class SvgFormatterBase implements SvgFormatter {
  public static router: Router;

  readonly topMargin: number = 15;
  readonly lineHeight: number = 22;
  readonly transitionDuration: number = 1000;
  readonly paddingFactor: number = 0.85;
  readonly exportFooter: string =
    'Copyright ' + new Date().getFullYear() + ' Massively Parallel Technologies. All Rights Reserved.';
  headerLines: SvgPrintLine[] = [];
  renderer: AbstractRenderer;
  svg: D3Selection;
  gTop: D3Selection;
  options: FormatOptions;
  savedSvgWidth: string;
  savedSvgHeight: string;
  savedAspectRatio: string;
  savedSvgTransform: string;
  startingTransform: d3ZoomTransform;

  orientation: Orientation;
  protected printMode: boolean;

  constructor() {}

  protected zoomFit() {
    const bounds = this.gTop.node().getBBox();
    const parent = this.gTop.node().parentElement;
    const fullWidth = parent.clientWidth;
    let fullHeight = parent.clientHeight;

    const width = bounds.width;
    const height = bounds.height;
    console.log('w,h: ' + width + ', ' + height);

    const midX = bounds.x + width / 2;
    const midY = bounds.y + height / 2;

    if (width == 0 || height == 0) return; // nothing to fit

    if (width > height) {
      this.orientation = 'landscape';
    } else if (height > width) {
      this.orientation = 'portrait';
    } else {
      this.orientation = 'portrait';
      console.log('height and width are equal');
    }
    console.log(this.orientation);

    // let scale: number;
    // if ( this.orientation === 'portrait') {

    //   scale = this.paddingFactor / fullWidth;
    // } else {

    //   scale = this.paddingFactor / fullHeight;
    // }

    const scale = this.paddingFactor / Math.max(width / fullWidth, height / fullHeight);

    let vector: Vector = {
      x: fullWidth / 2 - scale * midX,
      y: fullHeight / 2 - scale * midY
    };

    const heightAdjustment: number = this.topMargin + this.headerLines.length * this.lineHeight;
    console.log('height adjustment:', heightAdjustment);
    vector.y += heightAdjustment / 2;

    const transform = d3.zoomIdentity.translate(vector.x, vector.y).scale(scale);

    this.svg.call(this.renderer.getZoomBehavior().transform, transform);
  }

  format(renderer: AbstractRenderer, options: FormatOptions) {
    (function init(): void {
      this.renderer = renderer;
      this.svg = renderer.getSvg();
      this.gTop = this.svg.select('#gTop');
      this.options = options;

      if (options.startingTransform) {
        this.startingTransform = options.startingTransform;
      } else {
        this.startingTransform = d3zoomTransformFunction(this.svg.node());
      }

      this.savedSvgWidth = this.svg.attr('width');
      this.savedSvgHeight = this.svg.attr('height');
      this.headerLines = [];
      this.renderer.title.forEach((line, index) => {
        if (line) {
          this.headerLines.push({ line: line, bold: index === 0 });
        }
      });

      this.printMode = SvgFormatterBase.router.url.endsWith('/print') ? true : false;
      console.log('****** begin format ******* ');
      console.log('starting transform: ' + this.startingTransform);
      console.log('renderer: ', this.renderer);
      console.log('svg: ', this.svg);
      console.log('gTop: ', this.gTop);
      // console.log('options: ', this.options);
    }.bind(this)());

    if (this.options.showHeader) {
      this.addHeader();
    }
    if (this.options.showFooter) {
      this.addFooter();
    }
    if (this.options.showBorder) {
      this.addBorder();
    }

    this.zoomFit();
    this.localizeSvgOutput();

    if (this.options.emitRendering) {
      const data = this.svg.node().outerHTML;
      this.renderer.onSaveComplete.emit(data);
    }

    if (this.options.autoRestore) {
      this.restore(renderer, options);
    }

    console.log(this.renderer.getZoomTransform());
    console.log('****** end format ******* ');
  }

  private addHeader(): void {
    this.svg
      .append('g')
      .attr('id', 'diagram-header')
      .attr('transform', 'translate(0,-50)')
      .selectAll('text')
      .data(this.headerLines)
      .enter()
      .append('text')
      .classed('text-line', true)
      .attr('text-anchor', 'middle')
      .attr('x', '50%')
      .attr('y', (d, i) => {
        return this.topMargin + (i + 1) * this.lineHeight;
      })
      .style('fill', 'black')
      .style('font-weight', d => (d.bold ? 'bold' : 'normal'))
      .attr('font-size', '16px')
      .text(d => d.line);

    this.svg.select('#diagram-header').attr('transform', 'translate(0,0)');
  }

  private addFooter(): void {
    this.svg
      .append('g')
      .attr('id', 'diagram-footer')
      .append('text')
      .attr('text-anchor', 'middle')
      .attr('x', '50%')
      .attr('y', (d, i) => parseFloat(this.svg.attr('height')) - 10)
      .style('fill', 'black')
      .attr('font-size', '10px')
      .text(d => 'Copyright ' + new Date().getFullYear() + ' Massively Parallel Technologies. All Rights Reserved.');
  }

  private addBorder(): void {
    let bounds: DOMRect = this.gTop.node().getBBox();
    const gap: number = 5;

    const x = bounds.x - gap;
    const y = bounds.y - gap;
    const width = bounds.width + gap * 2;
    const height = bounds.height + gap * 2;

    this.gTop
      .insert('g', ':first-child')
      .attr('id', 'diagram-border')
      .insert('rect')
      .attr('x', () => x)
      .attr('y', () => y)
      .attr('rx', 5)
      .attr('ry', 5)
      .attr('width', width)
      .attr('height', height)
      .style('stroke', '#cccccc')
      .style('fill', 'white');
  }

  // need to strip out full urls from fill urls
  // i.e. fill: url(/pages/projects/xyz#arrow) => fill: url(#arrow)
  // restore them after outputing file.
  protected localizeSvgOutput(): void {
    const renderingOptions: RenderingOptions = this.renderer.options.rendering;
    if (!renderingOptions.centered) {
      this.savedAspectRatio = this.svg.attr('preserveAspectRatio');
      this.savedSvgTransform = this.svg.attr('transform');
      this.svg.attr('viewBox', null); // no need to save - will be reset on resize()
      this.svg.attr('preserveAspectRatio', null);
    }

    // d3-dagre layout
    // handle arrows on edges.
    this.svg.selectAll('#gTop .edgePaths .path').attr('marker-end', function(d) {
      let markerEnd: string = d3.select(this).attr('marker-end');

      const index = markerEnd.indexOf('#');
      if (index < 0) {
        console.log('unable to find end marker.');
        return;
      }

      return 'url(' + markerEnd.substring(index);
    });

    // d3-force layout
    this.svg.selectAll('#gTop > .nodes circle').attr('fill', function(d) {
      let startfill: string = d3.select(this).attr('fill');
      if (!startfill) {
        return null;
      }
      const index1: number = startfill.indexOf('url(');
      const index2: number = startfill.indexOf('#');
      if (index1 < 0 || index2 < 0) {
        console.log('d3 index not found');
        return startfill;
      }

      let newStyle: string = startfill.substring(0, index1) + 'url(' + startfill.substring(index2);
      return newStyle;
    });
  }

  restore(diagram: AbstractRenderer, options: FormatOptions) {
    console.log('restore');
    this.svg.select('#diagram-header').remove();
    this.svg.select('#diagram-footer').remove();
    this.svg.select('#diagram-border').remove();

    const renderingOptions: RenderingOptions = this.renderer.options.rendering;
    if (!renderingOptions.centered) {
      // this.svg.attr('viewbox', null); // no need to save - will be reset on resize()
      this.svg.attr('preserveAspectRatio', this.savedAspectRatio);
      this.svg.attr('transform', this.savedSvgTransform);
    }

    this.restoreSvgOutput();

    this.svg.attr('width', this.savedSvgWidth).attr('height', this.savedSvgHeight);
    this.svg.call(this.renderer.getZoomBehavior().transform, this.startingTransform);
  }

  protected restoreSvgOutput() {
    const self = this;
    console.log('self', self);

    // d3-dagre layout
    // restore arrows on edges
    this.svg.selectAll('#gTop .edgePaths .path').attr('marker-end', function(d) {
      let markerEnd: string = d3.select(this).attr('marker-end');

      const index = markerEnd.indexOf('#');
      if (index < 0) {
        console.log('unable to find end marker.');
        return;
      }

      return 'url(' + window.location.pathname + markerEnd.substring(index);
    });

    // d3-force layout
    // restore fill to circles
    this.svg.selectAll('#gTop > .nodes circle').attr('fill', function(d) {
      let startfill: string = d3.select(this).attr('fill');
      if (!startfill) {
        console.log('no startfill');
        return null;
      }
      const index1: number = startfill.indexOf('url(');
      const index2: number = startfill.indexOf('#');
      if (index1 < 0 || index2 < 0) {
        console.log('index not found');
        return startfill;
      }

      const myPathName: string = window.location.pathname;

      let myPath: string;
      if (self.printMode) {
        myPath = myPathName.substring(0, myPathName.length - '/print'.length);
      } else {
        myPath = myPathName;
      }

      const newFill: string = startfill.substring(0, index1) + 'url(' + myPath + startfill.substring(index2);
      return newFill;
    });
  }
}
