import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RunResult } from './component/run-result/run-result.component';

export type OptimizationType = {
  name: string;
  icon: string;
  value: string;
  selected: boolean;
};

@Component({
  selector: 'app-generate-source',
  templateUrl: './generate-source.component.html',
  styleUrls: ['./generate-source.component.scss']
})
export class GenerateSourceComponent {
  runResult: RunResult;

  onRun(result: RunResult) {
    console.log(result);
    this.runResult = result;
  }
}
