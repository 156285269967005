<div class="mpt-page-title">File Viewer</div>

<div class="view-container">
  <div class='file-bar'>
    <!-- <p>{{selectedFile && selectedFile.name}}</p> -->
    <div fxFlex fxFlexAlign="center" style="height: inherit;">
      <mat-button-toggle-group (valueChange)="onChange()" [(value)]="theme" style="align-items: center; height: inherit;">
        <mat-button-toggle value="ttcn">
          <mat-icon>wb_sunny</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="monokai">
          <mat-icon>bedtime</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>

  <section class="content-panel">
    <as-split direction="horizontal" [gutterSize]=6>
      <as-split-area [size]=20 [minSize]=10>
        <section *ngIf="resultFiles && resultFiles.length > 0">
          <div class="list-action-bar" style="display: flex; justify-content: end;">
            <button mat-stroked-button color="primary" (click)="onCompareFile()">Compare</button>
          </div>
          <file-list #fileList (select)="onFileSelect($event)" [files]="resultFiles"></file-list>
        </section>
      </as-split-area>

      <as-split-area [size]=80>
        <div class="h-100" [@.disabled]="true">
          <mat-tab-group [selectedIndex]="selected.value" (selectedTabChange)="onTabChange($event)">
            <div style='height: inherit'>
              <mat-tab class="code-tab" *ngFor="let file of openFiles; let i = index" [label]="file.name">
                <ng-template mat-tab-label>
                  {{file.name}}
                  <button mat-icon-button (click)="removeTab(i)">
                    <mat-icon>close</mat-icon>
                  </button>
                </ng-template>
                <ngx-codemirror #CodeMirror class='cm-window' [options]='options' [(ngModel)]="source"></ngx-codemirror>
              </mat-tab>
            </div>
          </mat-tab-group>
        </div>
      </as-split-area>
    </as-split>
  </section>
</div>

<!-- <overlay-spinner [status]="spinnerStatus"></overlay-spinner> -->