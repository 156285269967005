import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { RepoService } from './repo.service';
import { Info, Progress } from '../import-status/import-progress.component';
import { ImportResource } from '../models/import-resource';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ImportStatusAttribute } from '../models/import-status-attribute';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-repo-importer',
  templateUrl: './repo-importer.component.html',
  styleUrls: ['./repo-importer.component.scss']
})
export class RepoImporterComponent implements OnInit, OnDestroy {
  repoUrl: string = '';
  repoName: string = '';

  repoInfo: Info;
  progress: Progress;
  unsub: Subject<any> = new Subject<any>();
  timerId: NodeJS.Timeout;

  tabIdx = 0;
  prefetchData: ImportStatusAttribute;

  constructor(
    private service: RepoService,
    private diaglogRef: MatDialogRef<RepoImporterComponent>,
    @Inject(MAT_DIALOG_DATA) public importData: ImportStatusAttribute
  ) {}

  ngOnInit(): void {
    if (this.importData) {
      this.repoName = this.importData.name;
      this.repoUrl = this.importData.repoName;
      this.progress = this.getProgressFromResponse(this.importData);
      this.pollImportStatus();
      this.repoInfo = {
        id: this.importData.id,
        repoName: this.importData.name,
        startTime: this.importData.startTime
      };
      this.tabIdx = 1;
    }
  }

  /**
   * Initiate repo import and poll import status
   */
  onImport() {
    this.service
      .importRepo(this.repoName, this.repoUrl)
      .pipe(takeUntil(this.unsub))
      .subscribe((res: ImportResource) => {
        if (res) {
          this.tabIdx = 1;
          this.parseAndSetData(res.attributes);
          this.pollImportStatus();
        }
      });
  }

  /**
   * Poll import status until repo is imported or results in error
   */
  pollImportStatus(): void {
    this.timerId = setInterval(() => {
      console.log('polling..');
      this.service
        .fetchImportStatus()
        .pipe(takeUntil(this.unsub))
        .subscribe((res: ImportResource[]) => {
          if (res) {
            let statusAttr: ImportStatusAttribute = res
              .filter(r => r.attributes.id === this.repoInfo.id)
              .map(r => r.attributes)[0];
            this.parseAndSetData(statusAttr);
          }
        });
    }, 500);
  }

  ngOnDestroy(): void {
    this.unsub.next(null);
    clearInterval(this.timerId);
  }

  /**
   * Set initial progress data
   * @param response
   */
  parseAndSetData(response: ImportStatusAttribute) {
    this.repoInfo = { id: response.id, repoName: response.name, startTime: response.startTime };
    this.progress = this.getProgressFromResponse(response);
    if (this.progress.percentComplete === 100) {
      clearInterval(this.timerId);
    }
  }

  getProgressFromResponse(response: ImportStatusAttribute): Progress {
    return {
      result: response.result,
      status: response.status,
      currentStep: response.progress.currentStep,
      nextStep: response.progress.nextStep,
      percentComplete: response.progress.percent
    };
  }

  onDialogClose() {
    this.diaglogRef.close(this.progress?.percentComplete === 100);
  }
}
