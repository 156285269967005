import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { MenuItem, NavbarMenu } from './models/navbar-menu';
// import { FeatureConfigService } from '../../../config/feature-config.service';
import { I18nMessagesService } from 'src/app/shared/services/i18n-messages.service';
import { MatDialog } from '@angular/material/dialog';
import { SysInfoComponent } from 'src/app/component/sys-info/sys-info.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  @Output() onFullScreen = new EventEmitter<any>();
  @Output() onDebug = new EventEmitter<any>();
  @Output() onLogout = new EventEmitter<any>();

  menu = NavbarMenu.defaultMenu;

  constructor(public msgs: I18nMessagesService, private matDialog: MatDialog) {}

  ngOnInit(): void {}

  toggleFullScreen(): void {
    this.onFullScreen.emit(true);
  }

  doDebug(): void {
    this.onDebug.emit({});
  }

  doLogout(): void {
    this.onLogout.emit(true);
  }

  showMenuItem(item: MenuItem): boolean {
    if (!item.feature) {
      return true;
    }
    // return FeatureConfigService.isFeatureOn(item.feature);
  }

  showSysInfo() {
    this.matDialog.open(SysInfoComponent);
  }
}
