<div class="progress-container">
  <div class="mat-elevation-z1" style="padding: 15px; align-items: center;">
    <div style="display: flex; flex-direction: row; gap: 5px;">
      <b>Completed : </b> {{currentProgress.percentComplete}} % 
      <div style="display: flex; flex-direction: row; gap: 10px;">
        <span style="margin-left: 20px">
          <b>Status :</b> {{currentProgress.status}}
        </span>
      </div>
    </div>
    <app-import-progress-bar #progressBar></app-import-progress-bar>
  </div>
</div>