export interface DiagramParams {
  node?: string; // any diagram
  link?: string; // any diagram
  processId?: string; // decomp
  functionName?: string; // control flow
  fileName?: string; // control flow
}

export function validateDiagramParams(params: DiagramParams): boolean {
  let allowed: string[] = ['node', 'link', 'processId', 'functionName', 'fileName'];
  for (let property in params) {
    if (params.hasOwnProperty(property)) {
      if (allowed.indexOf(property) < 0) {
        return false;
      }
    }
  }

  return true;
}

export function equivalentDiagramParams(a: DiagramParams, b: DiagramParams): boolean {
  if (a && !b) {
    return false;
  }

  if (b && !a) {
    return false;
  }

  if (a.node !== b.node) {
    return false;
  }
  if (a.link !== b.link) {
    return false;
  }
  if (a.processId !== b.processId) {
    return false;
  }
  if (a.functionName !== b.functionName) {
    return false;
  }
  if (a.fileName !== b.fileName) {
    return false;
  }

  return true;
}

export function emptyDiagramParams(params: DiagramParams): boolean {
  if (
    !params.node &&
    !params.link &&
    !params.processId &&
    params.processId !== '' &&
    !params.functionName &&
    !params.fileName
  ) {
    return true;
  }

  return false;
}
