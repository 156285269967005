<mat-selection-list (selectionChange)="onSelectionChange($event)">
  <mat-tree [dataSource]="dataSource" [treeControl]="fileTreeCtrl">
    <mat-tree-node matTreeNodePadding *matTreeNodeDef="let file" class="file-node child-node">
      <mat-list-option [value]="file">
        <mat-icon>text_snippet</mat-icon>
        {{file.name}}
      </mat-list-option>
    </mat-tree-node>
    <mat-tree-node matTreeNodePadding *matTreeNodeDef="let file; when: hasChild" class="file-node parent-node">
      <mat-list-option [value]="file">
        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
          <button mat-icon-button matTreeNodeToggle>
            <mat-icon class="mat-icon-rtl-mirror">
              {{fileTreeCtrl.isExpanded(file) ? 'remove' : 'add'}}
            </mat-icon>
          </button>
          <span>
            <mat-icon>folder</mat-icon>
          </span>

          <span>{{file.name}}</span>

          <span>
            <mat-spinner [diameter]="20" *ngIf="file.isLoading"></mat-spinner>
          </span>
        </div>
      </mat-list-option>
    </mat-tree-node>
  </mat-tree>
</mat-selection-list>