<div class="info-container">
    <div class="info-header">
        System Information
    </div>
    <div class="info-sub-header">
        <mat-icon>location_on</mat-icon>{{location}}
    </div>

    <div class="section-select">
        <mat-chip-list selectable="true">
            <mat-chip (click)="selectedInfo = item.key" [color]="accent" class="select-chip" *ngFor="let item of sysInfo | keyvalue"><mat-icon>{{icons[item.key]}}</mat-icon>&nbsp;{{item.key | labelFormat}}</mat-chip>
        </mat-chip-list>
    </div>

    <div class="section" *ngIf="sysInfo">
        <div style="display: flex; flex-direction: row; max-height: inherit">
            <div class="section-content">
                <mat-list>
                    <mat-list-item *ngFor="let item of sysInfo[selectedInfo] | keyvalue">
                        <b>{{item.key | labelFormat}}</b>
                    </mat-list-item>
                </mat-list>
            </div>
            <div class="section-content">
                <mat-list>
                    <mat-list-item *ngFor="let item of sysInfo[selectedInfo] | keyvalue">
                        {{item.value}}
                    </mat-list-item>
                </mat-list>
            </div>

        </div>
    </div>

</div>