import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MptRoutes } from './shared/models/mpt-route';
import { NotAuthorizedComponent } from './shared/components/not-authorized/not-authorized.component';
import { FileViewComponent } from './component/project-files/file-view/file-view.component';
import { RepoListComponent } from './component/repo-list/repo-list.component';
import { GenerateSourceComponent } from './component/generate-source/generate-source.component';
import { AppComponent } from './app.component';
import { AuthComponent } from './component/auth/auth.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';

const appRoutes: MptRoutes = [
  {
    path: '',
    component: AppComponent,
    children: [
      {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
      },

      {
        path: 'login',
        component: AuthComponent
      },
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'workbench/:id',
        loadChildren: () => import('./component/workbench/workbench.module').then(m => m.WorkbenchModule)
      },
      {
        path: 'analysis/:id',
        loadChildren: () => import('./component/runtime/analysis.module').then(m => m.AnalysisModule)
      },
      {
        path: 'repo',
        component: RepoListComponent
      },
      {
        path: ':id/source',
        component: FileViewComponent
      },
      {
        path: ':id/generate',
        component: GenerateSourceComponent
      },
      { path: 'unauthorized', component: NotAuthorizedComponent }
    ]
  },
  { path: 'unauthorized', component: NotAuthorizedComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { enableTracing: false, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
