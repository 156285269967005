import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { Observable, forkJoin } from 'rxjs';
import { select as d3select } from 'd3-selection';

import { Node } from './reuse/interfaces/node';
import { Link } from './reuse/interfaces/link';
import { Point } from './reuse/interfaces/point';
import { SequencedCall } from './reuse/interfaces/sequenced-call';

import { SorterComponent } from './reuse/extra/drag-sorter/sorter.component';

import { ScalabilityHelperService } from './reuse/extra/scalability-helper/scalability-helper.service';
import { ColorsService } from 'src/app/shared/services/colors.service';

import { DiagramParams, validateDiagramParams } from './reuse/interfaces/diagram-params';
import { LanguageType } from './reuse/interfaces/language-type';

import { UrlStringsBox } from 'src/app/shared/models/url_strings';
import { UserActionHttpParams } from 'src/app/shared/models/user-action-http-params';
import { DiagramOptionsService } from './diagrams/diagram-options.service';
import { ConfigService } from 'src/app/config.service';
import { SvgFormatterService } from './render/svg/services/svg-formatter.service';
import { FnDecompService } from './diagrams/fn-decomp/fn-decomp.service';

@Injectable()
export class WorkbenchService {
  designUrl: any = UrlStringsBox.urlConstants.design;

  public circularTypes = [
    'algorithm',
    'designlevel',
    'controller',
    'kernel',
    'sharedcontroller',
    'functionpointerprocess',
    'sharedprocess',
    'systemprocess',
    'function'
  ];
  public nonCircularTypes = ['filescopedatastore', 'globaldatastore', 'localdatastore', 'terminator'];

  constructor(
    public colorService: ColorsService,
    public fnDecompService: FnDecompService,
    public dialog: MatDialog,
    public scalabilityHelperService: ScalabilityHelperService,
    public router: Router,
    public route: ActivatedRoute,
    public titleService: Title,
    public diagramOptionsService: DiagramOptionsService,
    public svgFormatterService: SvgFormatterService
  ) {}

  /*
    Use the functions below to navigate between diagrams (within the same project).

    All navigations can specify a node or link id for deep linking into a diagram.

    Decomp view needs a parent id.  If parent id is empty or missing, the context
    level will be shown.

    Control flow needs a function name and a file name, or both can be missing which will show main.
    */
  navigateToDiagram(diagramPath: string, params: DiagramParams, options: any = {}): void {
    if (!validateDiagramParams(params)) {
      console.log('Invalid routing parameters.', params);
      return;
    }

    let url = this.router.url;
    let index: number = url.indexOf('workbench/');
    if (index < 0) {
      console.log('unable to find path.');
      return;
    }
    url = url.substring(0, index) + 'workbench/' + diagramPath;

    this.router.navigate([url, params], options);
  }

  extractDiagramParamsFromRouter(): DiagramParams {
    let parseTree: UrlTree = this.router.parseUrl(this.router.url);

    let segments = parseTree.root.children.primary.segments;

    if (segments.length < 4) {
      console.error('Unable to find path segment.', segments);
      return {};
    }

    let params: DiagramParams = segments[3].parameters;
    // let params: DiagramParams = parseTree.root.children.primary.segments[5].parameters;

    if (!validateDiagramParams(params)) {
      console.log('invalid navigation parameters.', params);
    }

    return params;
  }

  openDragSortDialog(name: string, className: string): void {
    let dialogRef = this.dialog.open(SorterComponent, {
      width: '500px',
      data: { name: name, className: className }
    });
  }

  // helper function
  getProjectName(): string {
    // const segments: string[] = window.location.href.split('/');
    // if (segments.length < 5) {
    //   console.log('unable to determine project name');
    //   return;
    // }

    // const projectsIndex = segments.indexOf('projects');
    // if (projectsIndex === -1) {
    //   console.log('unable to determine project name');
    //   return;
    // }

    // const pName = segments[projectsIndex + 1];
    return 'matmul';
  }

  /**
   * a translation {dx,dy} from the given line segment, such that the distance
   * between the given line segment and the translated line segment equals
   * targetDistance
   */
  calcTranslationExact(targetDistance, point0, point1) {
    let x1X0 = point1.x - point0.x,
      y1Y0 = point1.y - point0.y,
      x2X0,
      y2Y0;
    if (y1Y0 === 0) {
      x2X0 = 0;
      y2Y0 = targetDistance;
    } else {
      let angle = Math.atan(x1X0 / y1Y0);
      x2X0 = -targetDistance * Math.cos(angle);
      y2Y0 = targetDistance * Math.sin(angle);
    }
    return {
      dx: x2X0,
      dy: y2Y0
    };
  }

  // this function used by diagrams and renderers. depends on a data structure called linkedById
  // which is created by the diagram.
  isConnected(linkedById, a: Node, b: Node) {
    return linkedById[a.id + ',' + b.id] || linkedById[b.id + ',' + a.id] || a.id == b.id;
  }

  hasConnections(linkedById, node: Node) {
    for (let property in linkedById) {
      let s = property.split(',');
      if ((s[0] == String(node.id) || s[1] == String(node.id)) && linkedById[property]) {
        return true;
      }
    }
    return false;
  }

  getConnections(links: Link[], node: Node) {
    let linksToNode: Link[] = [];
    let linksFromNode: Link[] = [];

    links.forEach(link => {
      if (link.source.id === node.id) {
        linksFromNode.push(link);
      } else if (link.target.id === node.id) {
        linksToNode.push(link);
      }
    });

    return { from: linksFromNode, to: linksToNode };
  }

  public fetchCodeForCallGraphNode(pName: string, node: Node): Observable<any> {
    return this.fnDecompService.getCodeForFile(pName, node.fileName);
  }

  // part of the explore in omniview feature, rootedProcesses set up an additional filter for nodes.
  protected _rootedProcesses: Node[] = [];
  public get rootedProcesses() {
    return this._rootedProcesses;
  }
  public set rootedProcesses(nodes: Node[]) {
    this._rootedProcesses = nodes;
  }

  convertPointsToString(points: Point[]): string {
    let str: string = '';

    for (let i = 0; i < points.length; i++) {
      let point = points[i];

      if (i > 0) {
        str += ' ';
      }
      str += point.x + ',' + point.y;
    }

    return str;
  }

  rotatePoint(cx: number, cy: number, degrees: number, p: Point): Point {
    let angle = degrees * (Math.PI / 180);

    let s = Math.sin(angle);
    let c = Math.cos(angle);

    // translate point back to origin:
    p.x -= cx;
    p.y -= cy;

    // rotate point
    let xnew = p.x * c - p.y * s;
    let ynew = p.x * s + p.y * c;

    p.x += cx;
    p.y += cy;

    // translate point back:
    return {
      x: xnew + cx,
      y: ynew + cy
    };
  }

  rectContainsRect(outer: DOMRect, inner: DOMRect): boolean {
    let contained: boolean = true;
    if (inner.top < outer.top || inner.right > outer.right || inner.bottom > outer.bottom || inner.left < outer.left) {
      contained = false;
    }

    return contained;
  }

  getElementBounds(selector: string): DOMRect {
    let element = d3select(selector);
    if (element.size() === 0) {
      console.log('Unable to find element.', selector);
      return null;
    } else if (element.size() > 1) {
      console.log('More than one element found.', selector);
      return null;
    }
    return (element as any).node().getBoundingClientRect();
  }

  getElementHeight(selector: string): number {
    let element = d3select(selector);

    if (element.size() === 0) {
      console.log('Unable to find element.', selector);
      return 0;
    } else if (element.size() > 1) {
      console.log('More than one element found.', selector);
      return 0;
    }

    let height = (element as any).node().getBoundingClientRect().height;
    // console.log('height for selector ' + selector, height);
    return height;
  }

  getElementWidth(selector: string): number {
    let element = d3select(selector);

    if (element.size() === 0) {
      console.log('Unable to find element.', selector);
      return 0;
    } else if (element.size() > 1) {
      console.log('More than one element found.', selector);
      return 0;
    }

    let width = (element as any).node().getBoundingClientRect().width;
    // console.log('width for selector ' + selector, width);
    return width;
  }
}
