<div class="modal-container">
    <div class="modal-content">
        <h2>{{title}}</h2>
        <span class="modal-message"><p>{{message}}</p></span>
    </div>
    <div class="modal-footer">
        <button mat-stroked-button (click)="onCancelDelete()" color="warn">Cancel</button>
        <button mat-button (click)="onConfirmDelete()" color="primary">Confirm</button>
    </div>
</div>
