import { CollectionViewer, DataSource, SelectionChange } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FileDbService } from '../file-db.service';

export interface FileNode {
  path: string;
  level: number;
  isParent: boolean;
  name: string;
  isLoading?: boolean;
}

export class DynamicFileDataSource implements DataSource<FileNode> {
  change: BehaviorSubject<FileNode[]> = new BehaviorSubject([]);
  public projectId: string;

  constructor(private treeControl: FlatTreeControl<FileNode>, private dbService: FileDbService) {}

  disconnect(collectionViewer: CollectionViewer): void {}

  connect(collectionViewer: CollectionViewer): Observable<FileNode[] | ReadonlyArray<FileNode>> {
    this.treeControl.expansionModel.changed.subscribe(res => {
      if (res.added || res.removed) {
        this.handleTreeChange(res);
      }
    });
    return merge(collectionViewer.viewChange, this.change).pipe(map(() => this.data));
  }

  get data(): FileNode[] {
    return this.change.value;
  }

  set data(value: FileNode[]) {
    this.treeControl.dataNodes = value;
    this.change.next(value);
  }

  handleTreeChange(change: SelectionChange<FileNode>): void {
    change.added.forEach(dir => this.toggleDirectory(dir, true));
    change.removed.forEach(dir => this.toggleDirectory(dir, false));
  }

  /**
   * Handles event for directory expand/collapse
   * @param dir
   * @param expand
   */
  toggleDirectory(dir: FileNode, expand: boolean): void {
    dir.isLoading = true;
    this.dbService.getChildren(this.projectId, dir).subscribe(children => {
      let curIndex = this.data.indexOf(dir);
      if (curIndex < 0 || children.length == 0) {
        return;
      }

      if (expand) {
        this.data.splice(curIndex + 1, 0, ...children);
      } else {
        let count = 0;
        for (let i = curIndex + 1; i < this.data.length && this.data[i].level > this.data[curIndex].level; i++) {
          count++;
        }
        this.data.splice(curIndex + 1, count);
      }

      dir.isLoading = false;
      this.change.next(this.data);
    });
  }
}

@Component({
  selector: 'file-list',
  styleUrls: ['./file-list.component.scss'],
  templateUrl: './file-list.component.html'
})
export class FileListComponent implements AfterViewInit {
  dataSource: DynamicFileDataSource;

  @Input() set files(files: FileNode[]) {
    if (this.dataSource) {
      console.log('data is set');
      this.dataSource.data = files;
      this.cdr.detectChanges();
    }
  }

  @ViewChild('selectionList') selectionList!: MatSelectionList;

  fileTreeCtrl: FlatTreeControl<FileNode>;

  @Output() select: EventEmitter<FileNode> = new EventEmitter();

  @Input() set projectId(id: string) {
    if (id && this.dataSource) {
      this.dataSource.projectId = id;
    }
  }

  hasChild = (_: number, node: FileNode) => node.isParent;

  getLevel = (node: FileNode) => node.level;
  isParent = (node: FileNode) => node.isParent;

  constructor(dbService: FileDbService, private cdr: ChangeDetectorRef) {
    this.fileTreeCtrl = new FlatTreeControl<FileNode>(this.getLevel, this.isParent);
    this.dataSource = new DynamicFileDataSource(this.fileTreeCtrl, dbService);
  }

  ngAfterViewInit(): void {
    // this.cdr.detectChanges();
    // const options = this.selectionList.options.toArray();
    // console.log('performing toggle');
    // options[0].toggle();
    // console.log(options[0].selected);
    // this.cdr.detectChanges();
  }

  onSelectionChange(change: MatSelectionListChange): void {
    this.select.emit(change.option.value);
    console.log('emitting file change');
  }
}
