import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import 'codemirror/mode/clike/clike.js';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/mode/xml/xml';
import 'codemirror/mode/css/css';
import { FileListComponent, FileNode } from '../file-list/file-list.component';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { CodemirrorComponent } from '@ctrl/ngx-codemirror';
import { FormControl } from '@angular/forms';
import { FileDbService } from '../file-db.service';
import { FileRepoResponse, FileViewService } from '../file-view.service';
// import { ProjectsService } from 'src/app/projects/projects.service';
import { ActivatedRoute, Router } from '@angular/router';
// import { Commit, CompareData, FileCompareComponent } from '../file-compare/file-compare.component';
import { MatDialog } from '@angular/material/dialog';
import { CompareFile, FileCompareComponent } from '../file-compare/file-compare.component';
// import { FeatureConfigService } from 'src/app/config/feature-config.service';
// import {
//   OverlaySpinnerComponent,
//   SpinnerStatus
// } from '../../../../../../shared/components/overlay-spinner/overlay-spinner.component';

@Component({
  selector: 'file-view',
  styleUrls: ['./file-view.component.scss'],
  templateUrl: './file-view.component.html'
})
export class FileViewComponent implements OnDestroy, AfterViewInit {
  options = {
    lineNumbers: true,
    mode: 'text/javascript',
    readOnly: true,
    theme: 'monokai',
    autoRefresh: true
  };

  compareCode: boolean;
  selectedFile: FileNode;

  source: string;
  resultFiles: FileNode[] = [];
  openFiles: FileNode[] = [];
  selected = new FormControl(0);

  codeViewRef: CodemirrorComponent;
  repoName: string;

  @ViewChild('CodeMirror') set codeMirror(cm: CodemirrorComponent) {
    if (cm) {
      this.codeViewRef = cm;
      this.codeViewRef.options = this.options;
    }
  }

  fileList: FileListComponent;

  @ViewChild('fileList') set list(list: FileListComponent) {
    if (list) {
      console.log('fileList', list);
      this.fileList = list;
      // this.onFileSelect(this.resultFiles[0]);
    }
}

  theme: string = 'monokai';

  constructor(
    private dbService: FileDbService,
    private viewService: FileViewService,
    private route: ActivatedRoute,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private dialog: MatDialog
  ) {
    let segments = this.router.url.split('/');
    this.repoName = segments[segments.length - 2];
    this.options.theme = this.theme;
  }

  ngOnInit(): void {
    console.log('getting files')
    this.getFiles();
  }

  ngOnDestroy() {
    // this.spinner.detachOverlay();
  }

  ngAfterViewInit(): void {
    // this.onFileSelect(this.resultFiles[0]);
    this.cdRef.detectChanges();
  }

  /**
   * Compare source of serial and parallel implementation
   */
  onCompareFile(): void {
    let left: CompareFile;
    let right: CompareFile;

    for (let file of this.resultFiles) {
      const content = this.viewService.sourceMap.get(file.path);
      const isOriginal = file.name.indexOf('original') > -1;

      if (isOriginal) {
        left = { name: file.name, content: content };
      } else {
        right = { name: file.name, content: content };
      }
    }

    this.dialog.open(FileCompareComponent, {
      width: '1400px',
      height: '800px',
      data: { left, right }
    });
  }

  /**
   *
   * @param file
   */
  onFileSelect(file: FileNode): void {
    if (!this.fileList.isParent(file)) {
      let index = this.openFiles.indexOf(file);

      // Not a recently opened file
      if (index < 0) {
        this.openFiles.push(file);
        this.selected.setValue(this.openFiles.length - 1);
        this.onFileOpen(file);
      } else {
        // If tab already exists, set tab index. When tab change is complete, code is set in viewer
        this.selected.setValue(index);
      }
    }
  }

  /**
   * Display the selected file content on code view
   * @param file
   */
  onFileOpen(file: FileNode) {
    if (this.codeViewRef) {
      this.selectedFile = file;
      this.source = this.viewService.sourceMap.get(file.path);
      //this.cdRef.detectChanges();
      this.setMimeMode(this.selectedFile.name);
      this.codeViewRef.codeMirror.refresh();
    }
  }

  /**
   * fetch the mime based on the file extension and set the code view options
   * @param file
   */
  setMimeMode(file: string) {
    let mime = this.viewService.getMime(file) || '';
    if (this.codeViewRef) {
      this.codeViewRef.codeMirror.setOption('mode', mime);
    }
  }

  /**
   * Fetch root directory files
   */
  getFiles(): void {
    this.openFiles = [];
    let parentPath: string = '';

    // Quick fetch if map contains the requested path
    if (this.dbService.directoryMap.has(parentPath)) {
      let res = this.dbService.directoryMap.get(parentPath);
      this.setFiles(parentPath, res);
      // this.spinnerStatus = 'stop';
    } else {
      this.viewService.fetchFiles(this.repoName, true, parentPath).subscribe(
        res => {
          this.setFiles(parentPath, res);
          // this.spinnerStatus = 'stop';
        },
        err => {
          console.error(err);
          // this.snackbarService.displaySnackAlert(this.messageService.fileViewFetchingError, 'error', {
          //   close: true
          // });
          // this.spinnerStatus = 'stop';
        }
      );
    }
  }

  // getCommits(): void {
  //   this.viewService.fetchCommits(this.currentProjectId).subscribe(
  //     res => {
  //       if (res && res.data) {
  //         this.commits = res.data.commits;
  //       }
  //     },
  //     err => {
  //       console.error(err);
  //       // this.snackbarService.displaySnackAlert(this.messageService.fileViewFetchingCommitError, 'error', {
  //       //   close: true
  //       // });
  //     }
  //   );
  // }

  /**
   * Set current list of files from the response
   * @param parentPath
   * @param files
   */
  setFiles(parentPath, files: FileRepoResponse[]) {
    let fileNodes: FileNode[] = [];

    for (let file of files) {
      const fileNode = {
        isParent: file.directory,
        level: 0,
        name: file.name,
        path: parentPath ? parentPath + '/' + file.name : file.name
      };
      fileNodes.push(fileNode);
    }

    this.resultFiles = JSON.parse(JSON.stringify(fileNodes));
    if (this.resultFiles.length > 0) {
      this.selectedFile = this.resultFiles[0];
    }
  }

  onTabChange(change: MatTabChangeEvent) {
    let selectedFile = this.openFiles[change.index];
    this.onFileOpen(selectedFile);
  }

  removeTab(index: number) {
    this.openFiles.splice(index, 1);
  }

  /**
   * Refresh viewer
   */
  onChange() {
    if (this.codeViewRef != null) {
      this.options.theme = this.theme;
      this.codeViewRef.codeMirror.refresh();
    }
  }
}
