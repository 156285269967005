<div class="result-container">
    <h2>Run Result</h2>

    <ng-container *ngIf="runResult; else noResult">
        <div class="row" *ngFor="let result of runResult | keyvalue: sortNull">
            <span><b>{{result.key | labelFormat}} : </b></span>
            <span>{{result.value}}</span>
        </div>
    </ng-container>
</div>

<ng-template #noResult>
    <h3>Click on run with specified parameters to view the results.</h3>
</ng-template>  