import { SvgFormatterBase } from './svg-formatter.base';
import * as d3 from 'd3';

export class SvgFormatterExport extends SvgFormatterBase {
  constructor() {
    super();
    this.printMode = false;
  }

  protected localizeSvgOutput(): void {
    super.localizeSvgOutput();

    // d3-dagre layout
    // handle node gradients
    this.svg.selectAll('#gTop .output .nodes circle').attr('style', function(d) {
      let startStyle: string = d3.select(this).attr('style');
      const index1: number = startStyle.indexOf('url(');
      const index2: number = startStyle.indexOf('#');
      if (index1 < 0 || index2 < 0) {
        return startStyle;
      }

      let newStyle: string = startStyle.substring(0, index1) + 'url("' + startStyle.substring(index2);
      // console.log('format new style', newStyle);
      return newStyle;
    });
  }

  protected restoreSvgOutput() {
    super.restoreSvgOutput();

    // d3-dagre layout
    // restore node gradients
    this.svg.selectAll('#gTop .output .nodes circle').attr('style', function(d) {
      let startStyle: string = d3.select(this).attr('style');
      const index1: number = startStyle.indexOf('url(');
      const index2: number = startStyle.indexOf('#');
      if (index1 < 0 || index2 < 0) {
        // console.log('unable to change style.');
        return startStyle;
      }

      let newStyle: string =
        startStyle.substring(0, index1) + 'url("' + window.location.pathname + startStyle.substring(index2);
      //console.log('newStyle: ' + newStyle);
      return newStyle;
    });
  }
}
