import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
}) 
export class SliderComponent implements OnInit {
  @Input('label') label: string = '';
  @Input('max') max: number = 0;
  @Input('min') min: number = 1;
  @Input('step') step: number = 1;

  sliderValue: number = 0;

  @Input('reset') set reset(rst: boolean) {
    if (rst) {
      this.sliderValue = 0;
    }
  }

  @Output('change') change: EventEmitter<any> = new EventEmitter();


  constructor() {}

  ngOnInit(): void {}

  onChange($event: any) {
    this.change.emit(this.sliderValue);
  }
}
