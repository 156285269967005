import { Injectable } from '@angular/core';
import { ForceSimulation } from './simulation';

import { CenterForce } from './forces/center';
import { XForce } from './forces/x';
import { YForce } from './forces/y';
import { CollideForce } from './forces/collide';
import { LinkForce } from './forces/link';
import { ManyBodyForce } from './forces/manyBody';

import { AttractForce } from './forces/attract';
import { RepulseForce } from './forces/repulse';
import { ClusterForce } from './forces/cluster';

@Injectable()
export class SimulationService {
  // static simulations: ForceSimulation[] = [];

  centerForce: CenterForce;
  xForce: XForce;
  yForce: YForce;
  collideForce: CollideForce;
  linkForce: LinkForce;
  manyBodyForce: ManyBodyForce;

  attractForce: AttractForce;
  repulseForce: RepulseForce;
  clusterForce: ClusterForce;

  constructor() {
    this.centerForce = new CenterForce();
    this.xForce = new XForce();
    this.yForce = new YForce();
    this.collideForce = new CollideForce();
    this.linkForce = new LinkForce();
    this.manyBodyForce = new ManyBodyForce();

    this.attractForce = new AttractForce();
    this.repulseForce = new RepulseForce();
    this.clusterForce = new ClusterForce();
  }

  createSimulation(): ForceSimulation {
    let sim = new ForceSimulation();
    // SimulationService.simulations.push(sim);
    return sim;
  }

  // public get simulations() { return SimulationService.simulations; }
}
