import { Component, AfterViewInit, ViewChildren, QueryList } from '@angular/core';

class Sortable {
  id: string;
  color: string;
}

@Component({
  selector: 'sorter',
  templateUrl: './sorter.component.html',
  styleUrls: ['./sorter.component.scss']
})
export class SorterComponent implements AfterViewInit {
  @ViewChildren('sortable') sortables: QueryList<Sortable>;

  items: Sortable[] = [
    { id: '1', color: 'red' },
    { id: '2', color: 'green' },
    { id: '3', color: 'blue' },
    { id: '4', color: 'yellow' },
    { id: '5', color: 'magenta' },
    { id: '6', color: 'cyan' }
  ];

  constructor() {}

  ngAfterViewInit() {
    let self = this;

    this.sortables.forEach(function(el) {
      let element: HTMLElement = (el as any).nativeElement;
      element.addEventListener('dragstart', self.handleDragStart, false);
      element.addEventListener('dragenter', self.handleDragEnter, false);
      element.addEventListener('dragover', self.handleDragOver, false);
      element.addEventListener('dragleave', self.handleDragLeave, false);
      element.addEventListener(
        'dragend',
        e => {
          self.handleDragEnd(e);
        },
        false
      );

      element.addEventListener(
        'drop',
        function(e) {
          // this / e.target is current target element.
          if (e.stopPropagation) {
            e.stopPropagation();
          } // stops the browser from redirecting.

          let dragFromPosition: string = e.dataTransfer.getData('data-index');
          let dragToPosition: number = (this as any).getAttribute('data-index');

          // console.log('drag from/to: ' + dragFromPosition + ', ' + dragToPosition);
          let fromElement = self.items[dragFromPosition];

          // now, delete dragged item from data and re-insert at new position
          self.items.splice(+dragFromPosition, 1);
          self.items.splice(dragToPosition, 0, fromElement);
          return false;
        },
        false
      );
    });
  }

  handleDragStart(e) {
    // e.target.style.opacity = '1.0';  // this / e.target is the source node.
    e.dataTransfer.setData('data-index', e.target.getAttribute('data-index'));
  }

  handleDragEnter(e) {
    (this as any).classList.add('drag-over');
  }

  handleDragOver(e) {
    if (e.preventDefault) {
      e.preventDefault();
    } // Necessary. Allows us to drop.
    e.dataTransfer.dropEffect = 'move';
    return false;
  }

  handleDragLeave(e) {
    (this as any).classList.remove('drag-over'); // this / e.target is previous target element.
  }

  handleDragDrop(e) {
    // this / e.target is current target element.
    if (e.stopPropagation) {
      e.stopPropagation(); // stops the browser from redirecting.
    }

    let dragFromPosition: number = e.dataTransfer.getData('data-index');
    let dragToPosition: number = (this as any).getAttribute('data-index');

    console.log('drag from/to: ' + dragFromPosition + ', ' + dragToPosition);

    // now swap positions of the data
    // let fromElement = this.items[dragFromPosition];
    // let toElement = this.items[dragToPosition];

    // this.items[dragFromPosition] = fromElement;
    // this.items[dragToPosition] = toElement;

    return false;
  }

  handleDragEnd(e) {
    this.sortables.forEach(function(el) {
      let element: HTMLElement = (el as any).nativeElement;
      element.classList.remove('drag-over');
    });
  }
}
