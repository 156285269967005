import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewRef,
} from '@angular/core';
import { OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { OverlayService } from './overlay.service';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';

type SpinnerSettings = {
  mode: ProgressSpinnerMode;
  diameter: number;
  width: number;
};

export type SpinnerStatus = 'start' | 'stop';

/**
 *  A simple full page overlay spinner.
 */
@Component({
  selector: 'overlay-spinner',
  templateUrl: './overlay-spinner.component.html',
  styleUrls: ['./overlay-spinner.component.scss'],
})
export class OverlaySpinnerComponent implements OnDestroy {
  settings: SpinnerSettings = {
    diameter: 100,
    mode: 'indeterminate',
    width: 5,
  };
  overlayConfig: OverlayConfig = new OverlayConfig();

  overlayRef: OverlayRef | null = null;

  @ViewChild('spinner', { static: true }) spinnerRef: TemplateRef<any> | null =
    null;

  @Input() set diameter(dia: number) {
    this.settings.diameter = dia;
  }

  @Input() set width(width: number) {
    this.settings.width = width;
  }

  // Required to control the spinner.
  @Input() set status(status: SpinnerStatus) {
    if (status === 'start') {
      this.attachOverlay();
    }
    if (status === 'stop') {
      console.log('detaching');
      this.detachOverlay();
    }
  }

  constructor(
    private vcRef: ViewContainerRef,
    private overlayService: OverlayService
  ) {}

  // Attach overlay to the template
  private attachOverlay() {
    this.overlayConfig.hasBackdrop = true;
    if (!this.overlayRef) {
      this.overlayRef = this.overlayService.newOverlay(this.overlayConfig);
    }
    if (!this.overlayRef.hasAttached() && this.spinnerRef) {
      this.overlayService.attachOverlayToPortal(
        this.overlayRef,
        this.spinnerRef,
        this.vcRef
      );
    }
  }

  ngOnDestroy(): void {
    this.detachOverlay();
  }

  // Detach overlay from template. Can be used directly to detach overlay when parent component is destroyed.
  public detachOverlay() {
    if (this.overlayRef) {
      this.overlayService.detachOverlayFromPortal(this.overlayRef);
    }
  }
}
