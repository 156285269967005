import { Injectable } from '@angular/core';
import { Node } from '../../../reuse/interfaces/node';
import { NodeBadge } from '../../../reuse/interfaces/node-badge';
import { DrawingDefaults } from '../../../reuse/interfaces/drawing-defaults';
import { ColorsService } from 'src/app/shared/services/colors.service';
import { ViewOptionsInfo } from '../../../reuse/interfaces/toolbar';

@Injectable()
export class SvgUtils {
  constructor(protected colors: ColorsService) {}

  // this is for the call graph version of a function
  drawCallGraphFunction(selection, defaults: DrawingDefaults) {
    selection
      .append('rect')
      .attr('x', d => {
        return -d.width / 2;
      })
      .attr('y', d => {
        return -d.height / 2;
      })
      .attr('rx', 5) // rounded corners
      .attr('ry', 5) // rounded corners
      .attr('height', d => {
        return d.height;
      })
      .attr('width', d => {
        return d.width;
      })
      .style('stroke', defaults.other.functionColor1)
      .attr('stroke-width', defaults.strokeWidth)
      .style('fill', defaults.other.functionColor2);
  }

  public getWordDataForTextWrap(words: string, radius: number) {
    let wordCount = words.length;
    let yOffset;
    switch (wordCount) {
      case 1:
        yOffset = 12;
        break;
      case 2:
        yOffset = 3;
        break;
      case 3:
        yOffset = 2;
        break;
      case 4:
        yOffset = -5;
        break;
      default:
        yOffset = 0;
    }

    let wordData = [];
    for (let k = 0; k < words.length; k++) {
      let letterCount = words[k];

      let childX = -radius / 2 + 21;
      // let childX = 0;
      let childY = yOffset + k * 14;
      wordData.push({ word: words[k], x: childX, y: childY });
    }

    return wordData;
  }

  findBadgesInNode(node: Node, id, viewOptions: ViewOptionsInfo): NodeBadge[] {
    let viewLoops: boolean = viewOptions.enabledOptions.indexOf('showLoops') > -1;
    let viewTlp: boolean = viewOptions.enabledOptions.indexOf('showTlp') > -1;
    let viewLargeKernels: boolean = viewOptions.enabledOptions.indexOf('showLargeKernels') > -1;
    let viewNewComponents: boolean = viewOptions.enabledOptions.indexOf('showNewComponents') > -1;
    let viewModifiedComponents: boolean = viewOptions.enabledOptions.indexOf('showModifiedComponents') > -1;

    let badges: NodeBadge[] = [];
    let type = node.type.toLowerCase();

    if (type === 'terminator' || type.endsWith('store')) {
      return [];
    }

    if (viewLoops && node.loops && node.loops.length) {
      badges.push({
        type: 'icon',
        nodeType: node.type.toLowerCase(),
        radius: 10,
        icon: '#loop-icon' + id,
        textOrIconColor: 'white',
        circleBorderColor: this.colors.mptComponents.kernel.C900
      });
    }

    if (viewTlp && Number.isInteger(node.tlpGroupId) && node.tlpGroupId > 0) {
      badges.push({
        type: 'text',
        nodeType: node.type.toLowerCase(),
        tlpEnabled: node.tlpEnabled,
        radius: 10,
        text: node.tlpGroupId.toString(),
        textOrIconColor: 'white',
        circleBorderColor: this.colors.mptComponents.kernel.C900
      });
    }

    if (
      viewLargeKernels &&
      (node.type.toLowerCase() === 'algorithm' || node.type.toLowerCase() === 'kernel') &&
      node.largeKernel
    ) {
      badges.push({
        type: 'text',
        nodeType: node.type.toLowerCase(),
        radius: 10,
        text: 'L',
        textOrIconColor: 'white',
        circleBorderColor: this.colors.mptComponents.kernel.C900
      });
    }

    if (viewNewComponents && node.dragNdrop) {
      badges.push({
        type: 'text',
        nodeType: node.type.toLowerCase(),
        radius: 10,
        text: 'N',
        textOrIconColor: 'white',
        circleBorderColor: this.colors.mptComponents.kernel.C900
      });
    }

    if (viewModifiedComponents && node.modificationStatus === 'MODIFIED') {
      badges.push({
        type: 'text',
        nodeType: node.type.toLowerCase(),
        radius: 10,
        text: 'M',
        textOrIconColor: 'white',
        circleBorderColor: this.colors.mptComponents.kernel.C900
      });
    }

    return badges;
  }

  positionNodeBadges(badges: NodeBadge[]): NodeBadge[] {
    // badges are displayed around the perimeter of nodes (usually circles)
    // so calculate how many degrees to rotate each badge from 12 o'clock.
    let odd: boolean = badges.length % 2 === 1;
    let rotationDegreesPerNode = 35;
    let currentDegrees: number = 90; // 12 oclock is 90 degress

    if (badges.length === 0) {
      currentDegrees = 90;
    } else if (odd) {
      let steps: number = (badges.length - 1) / 2;
      currentDegrees = currentDegrees + rotationDegreesPerNode * steps;
    } else {
      let steps: number = badges.length / 2 - 1;
      currentDegrees = currentDegrees + rotationDegreesPerNode * steps + rotationDegreesPerNode / 2;
    }

    for (let i = 0; i < badges.length; i++) {
      badges[i].rotationDegrees = currentDegrees;
      badges[i].rotationRadians = (currentDegrees + 180) * (Math.PI / 180);
      currentDegrees -= rotationDegreesPerNode;
    }

    return badges;
  }

  // https://stackoverflow.com/questions/38224875/replacing-d3-transform-in-d3-v4
  // console.log(getTranslation("translate(20,30)"))  // simple case: should return [20,30]
  // console.log(getTranslation("rotate(45) skewX(20) translate(20,30) translate(-5,40)"))
  getTranslation(transform) {
    // Create a dummy g for calculation purposes only. This will never
    // be appended to the DOM and will be discarded once this function
    // returns.
    let g = document.createElementNS('http://www.w3.org/2000/svg', 'g');

    // Set the transform attribute to the provided string value.
    g.setAttributeNS(null, 'transform', transform);

    // consolidate the SVGTransformList containing all transformations
    // to a single SVGTransform of type SVG_TRANSFORM_MATRIX and get
    // its SVGMatrix.
    let matrix = g.transform.baseVal.consolidate().matrix;

    // As per definition values e and f are the ones for the translation.
    return [matrix.e, matrix.f];
  }

  public fadeInNodes(svg: any, radius: number, duration: number = 750) {
    svg
      .select('#TerminatorFadeIn')
      .attr('transform', 'scale(.001) translate (-123, -110)')
      .transition()
      .duration(duration)
      .attr('transform', 'scale(2.2) translate (-123, -110)');

    svg
      .selectAll('#GlobalDataStoreFadeIn, #LocalDataStoreFadeIn, #FileScopeDataStoreFadeIn')
      .attr('transform', 'scale(.001) translate (-27, -20)')
      .transition()
      .duration(duration)
      .attr('transform', 'scale(1.5) translate (-27, -20)');

    svg
      .selectAll('.fadeIn')
      .attr('r', 1e-6)
      .transition()
      .duration(duration)
      .attr('r', radius);
  }

  public fadeOutNodes(svg: any, radius: number, duration: number = 750) {
    svg
      .select('#TerminatorFadeOut')
      .attr('transform', 'scale(.9) translate (-123, -110)')
      .transition()
      .duration(duration)
      .attr('transform', 'scale(1e-6) translate (-123, -110)');

    svg
      .selectAll('#GlobalDataStoreFadeOut, #LocalDataStoreFadeOut, #FileScopeDataStoreFadeOut')
      .attr('transform', 'scale(.6) translate (-27, -20)')
      .transition()
      .duration(duration)
      .attr('transform', 'scale(1e-6) translate (-27, -20)');

    svg
      .selectAll('.fadeOut')
      .attr('r', d => {
        return radius;
      })
      .attr('fill', 'green')
      .transition()
      .duration(duration)
      .attr('r', 1e-6)
      .style('fill', 'white');
  }
}
