import { Force } from './force.base';
import { quadtree } from 'd3-quadtree';

export class CollideForce extends Force {
  static funcX = function x(d) {
    return d.x + d.vx;
  };
  static funcY = function y(d) {
    return d.y + d.vy;
  };

  get = function(radius): any {
    let nodes,
      radii,
      strength = 1,
      iterations = 1;

    let constant = this.constant;
    let jiggle = this.jiggle;
    let x = CollideForce.funcX;
    let y = CollideForce.funcY;

    if (typeof radius !== 'function') {
      radius = constant(radius == null ? 1 : +radius);
    }

    function force() {
      let i,
        n = nodes.length,
        tree,
        node,
        xi,
        yi,
        ri,
        ri2;

      for (let k = 0; k < iterations; ++k) {
        tree = quadtree(nodes, x, y).visitAfter(prepare);
        for (i = 0; i < n; ++i) {
          node = nodes[i];
          (ri = radii[node.index]), (ri2 = ri * ri);
          xi = node.x + node.vx;
          yi = node.y + node.vy;
          tree.visit(apply);
        }
      }

      function apply(quad, x0, y0, x1, y1) {
        let data = quad.data,
          rj = quad.r,
          r = ri + rj;
        if (data) {
          if (data.index > node.index) {
            let x = xi - data.x - data.vx,
              y = yi - data.y - data.vy,
              l = x * x + y * y;
            if (l < r * r) {
              if (x === 0) {
                (x = jiggle()), (l += x * x);
              }
              if (y === 0) {
                (y = jiggle()), (l += y * y);
              }
              l = ((r - (l = Math.sqrt(l))) / l) * strength;
              node.vx += (x *= l) * (r = (rj *= rj) / (ri2 + rj));
              node.vy += (y *= l) * r;
              data.vx -= x * (r = 1 - r);
              data.vy -= y * r;
            }
          }
          return;
        }
        return x0 > xi + r || x1 < xi - r || y0 > yi + r || y1 < yi - r;
      }
    }

    function prepare(quad) {
      if (quad.data) {
        return (quad.r = radii[quad.data.index]);
      }
      for (let i = (quad.r = 0); i < 4; ++i) {
        if (quad[i] && quad[i].r > quad.r) {
          quad.r = quad[i].r;
        }
      }
    }

    function initialize() {
      if (!nodes) {
        return;
      }
      let i,
        n = nodes.length,
        node;
      radii = new Array(n);
      for (i = 0; i < n; ++i) {
        (node = nodes[i]), (radii[node.index] = +radius(node, i, nodes));
      }
    }

    (force as any).initialize = function(_) {
      nodes = _;
      initialize();
    };

    (force as any).iterations = function(_) {
      return arguments.length ? ((iterations = +_), force) : iterations;
    };

    (force as any).strength = function(_) {
      return arguments.length ? ((strength = +_), force) : strength;
    };

    (force as any).radius = function(_) {
      return arguments.length ? ((radius = typeof _ === 'function' ? _ : constant(+_)), initialize(), force) : radius;
    };

    return force;
  };
}
