import { Injectable } from '@angular/core';

// Material Design Color Palettes
// https://material.io/design/color/the-color-system.html#tools-for-picking-colors

// These colors are provided as an angular service so they can be used from typescript
// code (rather than within a css stylesheet)

@Injectable({
  providedIn: 'root'
})
export class ColorsService {
  constructor() {}

  matRed = {
    C50: '#ffebee',
    C100: '#ffcdd2',
    C200: '#ef9a9a',
    C300: '#e57373',
    C400: '#ef5350',
    C500: '#f44336',
    C600: '#e53935',
    C700: '#d32f2f',
    C800: '#c62828',
    C900: '#B71c1c',
    A100: '#ff8A80',
    A200: '#ff5252',
    A400: '#ff1744',
    A700: '#d50000'
  };

  matPink = {
    C50: '#fce4ec',
    C100: '#f8bbd0',
    C200: '#f48fb1',
    C300: '#f06292',
    C400: '#ec407a',
    C500: '#e91e63',
    C600: '#d81b60',
    C700: '#c2185b',
    C800: '#ad1457',
    C900: '#88oe4f',
    A100: '#ff80ab',
    A200: '#ff4081',
    A400: '#f50057',
    A700: '#c51162'
  };

  matPurple = {
    C50: '#f3e5f5',
    C100: '#e1bee7',
    C200: '#ce93d8',
    C300: '#ba68c8',
    C400: '#AB47BC',
    C500: '#9C27B0',
    C600: '#8E24AA',
    C700: '#7B1FA2',
    C800: '#6A1B9A',
    C900: '#4A148C',
    A100: '#EA80FC',
    A200: '#E040FB',
    A400: '#D500F9',
    A700: '#AA00FF'
  };

  matDeepPurple: any = {
    C50: '#EDE7F6',
    C100: '#D1C4E9',
    C200: '#B39DDB',
    C300: '#9575CD',
    C400: '#7E57C2',
    C500: '#673AB7',
    C600: '#5E35B1',
    C700: '#512DA8',
    C800: '#4527A0',
    C900: '#311B92',
    A100: '#B388FF',
    A200: '#7C4DFF',
    A400: '#651FFF',
    A700: '#6200EA'
  };

  matIndigo: any = {
    C50: '#E8EAF6',
    C100: '#C5CAE9',
    C200: '#9FA8DA',
    C300: '#7986CB',
    C400: '#5C6BC0',
    C500: '#3F51B5',
    C600: '#3949AB',
    C700: '#303F9F',
    C800: '#283593',
    C900: '#1A237E',
    A100: '#8C9EFF',
    A200: '#536DFE',
    A400: '#3D5AFE',
    A700: '#304FFE'
  };

  matBlue: any = {
    C50: '#E3F2FD',
    C100: '#BBDEFB',
    C200: '#90CAF9',
    C300: '#64B5F6',
    C400: '#42A5F5',
    C500: '#2196F3',
    C600: '#1E88E5',
    C700: '#1976D2',
    C800: '#1565C0',
    C900: '#0D47A1',
    A100: '#82B1FF',
    A200: '#448AFF',
    A400: '#2979FF',
    A700: '#2962FF'
  };

  matLightBlue: any = {
    C50: '#e1f5fe',
    C100: '#b3e5fc',
    C200: '#81d4fa',
    C300: '#4fc3f7',
    C400: '#29b6f6',
    C500: '#03a9f4',
    C600: '#039be5',
    C700: '#0288d1',
    C800: '#0277bd',
    C900: '#01579b',
    A100: '#80d8ff',
    A200: '#40c4ff',
    A400: '#00b0ff',
    A700: '#0091ea'
  };

  matCyan: any = {
    C50: '#E0F7FA',
    C100: '#B2EBF2',
    C200: '#80DEEA',
    C300: '#4DD0E1',
    C400: '#26C6DA',
    C500: '#00BCD4',
    C600: '#00ACC1',
    C700: '#0097A7',
    C800: '#00838F',
    C900: '#006064',
    A100: '#84FFFF',
    A200: '#18FFFF',
    A400: '#00E5FF',
    A700: '#00B8D4'
  };

  matTeal: any = {
    C50: '#E0F2F1',
    C100: '#B2DFDB',
    C200: '#80CBC4',
    C300: '#4DB6AC',
    C400: '#26A69A',
    C500: '#009688',
    C600: '#00897B',
    C700: '#00796B',
    C800: '#00695C',
    C900: '#004D40',
    A100: '#A7FFEB',
    A200: '#64FFDA',
    A400: '#1DE9B6',
    A700: '#00BFA5'
  };

  matGreen: any = {
    C50: '#E8F5E9',
    C100: '#C8E6C9',
    C200: '#A5D6A7',
    C300: '#81C784',
    C400: '#66BB6A',
    C500: '#4CAF50',
    C600: '#43A047',
    C700: '#388E3C',
    C800: '#2E7D32',
    C900: '#1B5E20',
    A100: '#B9F6CA',
    A200: '#69F0AE',
    A400: '#00E676',
    A700: '#00C853'
  };

  matLightGreen: any = {
    C50: '#f1f8e9',
    C100: '#dcedc8',
    C200: '#c5e1a5',
    C300: '#aed581',
    C400: '#9ccc65',
    C500: '#8bc34a',
    C600: '#7cb342',
    C700: '#689f38',
    C800: '#558b2f',
    C900: '#33691e',
    A100: '#ccff90',
    A200: '#b2ff59',
    A400: '#76ff03',
    A700: '#64dd17'
  };

  matLime: any = {
    C50: '#F9FBE7',
    C100: '#F0F4C3',
    C200: '#E6EE9C',
    C300: '#DCE775',
    C400: '#D4E157',
    C500: '#CDDC39',
    C600: '#C0CA33',
    C700: '#AFB42B',
    C800: '#9E9D24',
    C900: '#827717',
    A100: '#F4FF81',
    A200: '#EEFF41',
    A400: '#C6FF00',
    A700: '#AEEA00'
  };

  matYellow: any = {
    C50: '#FFFDE7',
    C100: '#FFF9C4',
    C200: '#FFF59D',
    C300: '#FFF176',
    C400: '#FFEE58',
    C500: '#FFEB3B',
    C600: '#FDD835',
    C700: '#FBC02D',
    C800: '#F9A825',
    C900: '#F57F17',
    A100: '#FFFF8D',
    A200: '#FFFF00',
    A400: '#FFEA00',
    A700: '#FFD600'
  };

  matAmber = {
    C50: '#fff8e1',
    C100: '#ffecb3',
    C200: '#ffe082',
    C300: '#ffd54f',
    C400: '#ffca28',
    C500: '#ffc107',
    C600: '#ffb300',
    C700: '#ffa000',
    C800: '#ff8f00',
    C900: '#ff6f00',
    A100: '#ffe57f',
    A200: '#ffd740',
    A400: '#ffc400',
    A700: '#ffab00'
  };

  matOrange: any = {
    C50: '#FFF3E0',
    C100: '#FFE0B2',
    C200: '#FFCC80',
    C300: '#FFB74D',
    C400: '#FFA726',
    C500: '#FF9800',
    C600: '#FB8C00',
    C700: '#F57C00',
    C800: '#EF6C00',
    C900: '#E65100',
    A100: '#FFD180',
    A200: '#FFAB40',
    A400: '#FF9100',
    A700: '#FF6D00'
  };

  matDeepOrange: any = {
    C50: '#FBE9E7',
    C100: '#FFCCBC',
    C200: '#FFAB91',
    C300: '#FF8A65',
    C400: '#FF7043',
    C500: '#FF5722',
    C600: '#F4511E',
    C700: '#E64A19',
    C800: '#D84315',
    C900: '#BF360C',
    A100: '#FF9E80',
    A200: '#FF6E40',
    A400: '#FF3D00',
    A700: '#DD2C00'
  };

  matBrown: any = {
    C50: '#EFEBE9',
    C100: '#D7CCC8',
    C200: '#BCAAA4',
    C300: '#A1887F',
    C400: '#8D6E63',
    C500: '#795548',
    C600: '#6D4C41',
    C700: '#5D4037',
    C800: '#4E342E',
    C900: '#3E2723'
  };

  matGray: any = {
    C50: '#FAFAFA',
    C100: '#F5F5F5',
    C200: '#EEEEEE',
    C300: '#E0E0E0',
    C400: '#BDBDBD',
    C500: '#9E9E9E',
    C600: '#757575',
    C700: '#616161',
    C800: '#424242',
    C900: '#212121'
  };

  matBlueGray: any = {
    C50: '#ECEFF1',
    C100: '#CFD8DC',
    C200: '#B0BEC5',
    C300: '#90A4AE',
    C400: '#78909C',
    C500: '#607D8B',
    C600: '#546E7A',
    C700: '#455A64',
    C800: '#37474F',
    C900: '#263238'
  };

  // changes here need to be reflected in mpt-material-theme.scss
  mptTheme: any = {
    primary: this.matLightBlue,
    accent: this.matLightGreen,
    accent2: this.matAmber,
    accent3: this.matRed,
    grayscale: this.matGray
  };

  // changes here need to be reflected in mpt-material-theme.scss
  mptComponents: any = {
    algorithm: this.matBlue,
    controller: this.matDeepPurple,
    filescopedatastore: this.matBlueGray,
    globaldatastore: this.matDeepOrange,
    kernel: this.matGreen,
    localdatastore: this.matBrown,
    sharedcontroller: this.matBlueGray,
    sharedprocess: this.matOrange,
    systemprocess: this.matAmber,
    functionpointerprocess: this.matIndigo,
    terminator: this.matGray,
    default: this.matGray
  };

  mptChips: any = {
    success: this.matGreen.A400,
    warning: this.matDeepOrange.C300
  };

  mptCallGraph: any = {
    function: this.matBlue
  };

  // most node types use a gradient as a fill
  getNodeFillColor(type: string): string {
    type = type.toLowerCase();
    switch (type) {
      case 'designlevelwithtlp':
        return this.matPink.C300;
      default:
        console.log('fill color not found for type: ' + type);
        return '#999999';
    }
  }

  getNodeStrokeColor(type: string): string {
    let cColors = this.mptComponents;
    type = type.toLowerCase();

    switch (type) {
      case 'designlevel':
      case 'designlevelwithtlp':
      case 'algorithm':
      case 'functionPointerProcess':
      case 'algorithmbadge':
        return cColors.algorithm.C800;

      case 'controller':
      case 'controllerbadge':
        return cColors.controller.C800;

      case 'kernel':
      case 'kernelbadge':
        return cColors.kernel.C800;

      case 'sharedcontroller':
      case 'sharedcontrollerbadge':
        return cColors.sharedcontroller.C900;

      case 'sharedprocess':
      case 'sharedprocessbadge':
        return cColors.sharedprocess.C800;

      case 'systemprocess':
      case 'systemprocessbadge':
        return cColors.systemprocess.C900;

      // case 'function':
      //   return this.mptCallGraph.function.C800;

      default:
        console.log('color not found for type: ' + type);
        return '#999999';
    }
  }
}
