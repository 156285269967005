import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SystemService } from './system.service';
import { SysInfo } from '../models/sysinfo-attribute';

@Component({
  selector: 'app-sys-info',
  templateUrl: './sys-info.component.html',
  styleUrls: ['./sys-info.component.scss']
})
export class SysInfoComponent implements OnInit {

  sysInfo: SysInfo;
  selectedInfo: string = 'cpu';
  location: string;

  icons = {
    cpu: 'memory',
    memory: 'storage',
  }

  constructor(private matDialogRef: MatDialogRef<SysInfoComponent>, private service: SystemService) {}

  ngOnInit(): void {
    navigator.geolocation.getCurrentPosition((position) => {
      console.log(position);
    })
    this.matDialogRef.updateSize('600px', '700px');
    this.service.getSysInfo().subscribe(res => {
      if (res && res.attributes) {
        this.sysInfo = res.attributes;
        this.location = res.attributes.dataCenter.location;
        delete this.sysInfo.dataCenter;
      }
    });
  }

  // moveMap(event: google.maps.MapMouseEvent) {
  //   this.center = (event.latLng.toJSON());
  // }

  // move(event: google.maps.MapMouseEvent) {
  //   this.display = event.latLng.toJSON();
  // }

  onChange() {
    console.log('change');
  }
}
