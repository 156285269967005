import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'mpt-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'talp-diagram';

  constructor(private router: Router) {}

  isLogin(): any {
    let segments = this.router.url.split('/');
    let lastSeg = segments[segments.length - 1];
    return lastSeg === 'login';
  }
}
