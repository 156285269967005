import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { SvgFormatter } from '../../../reuse/interfaces/svg-formatter';

import { SvgFormatterBase } from './svg-formatters/svg-formatter.base';
import { SvgFormatterExport } from './svg-formatters/svg-formatter-export';
import { SvgFormatterPrint } from './svg-formatters/svg-formatter-print';

@Injectable()
export class SvgFormatterService {
  printFormatter: SvgFormatter = new SvgFormatterPrint();
  exportFormatter: SvgFormatter = new SvgFormatterExport();

  constructor(private router: Router) {
    SvgFormatterBase.router = router;
  }
}
