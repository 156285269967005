import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class I18nMessagesService {
  constructor() {}

  readonly welcomeMessageStore = $localize`:@@hm.welcome.msg.store:Welcome to the Blue Cheetah CUB Analysis`;
  readonly welcomeMessage = $localize`:@@hm.welcome.msg.full:Welcome to Blue Cheetah - The site for Full Spectrum Analysis and Multi-Core Optimization`;

  // register-org.ts
  readonly alertTitleSuccess: string = 'Success!';
  //readonly orgRegMessageSuccess: string = 'Organization registered';
  readonly alertTitleFailure = $localize`:@@rg.gen.error:Error!`;
  readonly alertRegError = $localize`:@@rg.error:Unable to register organization`;
  readonly userCountLimitError = $localize`:@@rg.limit.error:We are currently not accepting new registrations. Thank you for your interest!`;
  readonly activateRequest = $localize`:@@rg.email.sent:An email with an activation link is sent to your registered email address. Please activate your account before logging in.`;

  //register.component.ts
  readonly registerEmailError = $localize`:@@register.email.error:Error validating email`;
  readonly registerEmailValError = $localize`:@@register.email.val.error:Error checking email validity`;
  readonly registerUserValError = $localize`:@@register.user.val.error:Error checking user validity!`;
  readonly registerSecQuestionError = $localize`:@@register.seq.quest.error:Error fetching security questions!`;
  // Rest of the code...

  readonly extractionFailure = $localize`:@@exrtractiom.failure:Extraction failed!`;
  readonly modalRemoveParameterTitle = $localize`:@@modal.remove.param.title:Remove Parameter(s) ?`;
  readonly modalRemoveParameterContent = $localize`:@@model.remove.param.counter:Removing parameters will also remove existing test procedures for the selected requirement. Continue?`;

  readonly alertSearchCompFailure = $localize`:@@cmp.search.failure:Error fetching components`;
  readonly alertCopyCompSuccess = $localize`:@@cmp.copy.success:Component copied successfully`;
  readonly alertCopyCompFailure = $localize`:@@cmp.copy.compile.fail:Error occured while copying component`;
  readonly modalCopyCompContent = $localize`:@@cmp.copy.content:Copy source code, IO Parmeters and source related information from component`;
  readonly modalCopyCompTitle = $localize`:@@cmp.copy.title:Confirm copy?`;
  readonly alertCategoryAddSuccess = $localize`:@@cmp.category.add:Category created successfully`;
  readonly alertCategoryEditSuccess = $localize`:@@cmp.category.edit.success:Category edit successfully`;
  readonly alertCategoryDeleteSuccess = $localize`:@@cmp.category.delete.success:Category deleted successfully`;
  readonly modalDeleteTitle = $localize`:@@cmp.confirm.attach:Confirm Attach?`;
  readonly modalDeleteCubObject = $localize`:@@cmp.delete.confirm:This action will delete the component from the workspace and cannot be undone. Continue?`;
  readonly alertTestRunSuccess = $localize`:@@cmp.test.run.success:Test(s) completed successfully!`;
  readonly alertTestRunFailure = $localize`:@@cmp.test.run.failure:Something went wrong while running test!`;
  readonly alertTestDeleteSuccess = $localize`:@@cmp.test.delete.success:Test(s) deleted successfully!`;
  readonly alertTestDeleteFailure = $localize`:@@cmp.test.delete.failure:Test(s) deletion failed!`;
  readonly alertTestSaveSuccess = $localize`:@@cmp.test.save.success:Test saved successfully!`;
  readonly alertTestSaveFailure = $localize`:@@cmp.test.save.failure:Error occured saving test!`;
  readonly alertTestFetchError = $localize`:@@cmp.test.fetch.error:Error fetching results of test`;

  readonly alertIoFetchFailure = $localize`:@@pf.alert.fetch.fail:Error fetching I/O parameters!`;
  readonly alertProfilingFailure = $localize`:@@pf.alert.failure:Error occurred while profiling!`;

  readonly alertCategoryDeleteFailure = $localize`:@@alert.cat.delete.fail:Category delete failed!`;
  readonly alertCategorySaveFailure = $localize`:@@alert.cat.save.fail:Category save failed!`;

  readonly alertAttachFailure = $localize`:@@alert.attach.fail:An error occurred while attaching object(s) to element`;
  readonly alertAttachSuccess = $localize`:@@alert.attach.success:Object(s) Attached Successfully!`;
  readonly alertDetachSuccess = $localize`:@@alert.detach.success:Object Detached Successfully!`;
  readonly alertDetachFailure = $localize`:@@alert.detach.fail:Error occurred while detaching object!`;
  readonly alertWorkspaceSearch = $localize`:@@alert.workspace.search:Error! Select any one Workspace to search`;

  readonly alertFlowSaveFailed = $localize`:@@alert.flow.save.fail:Error occurred while saving flow`;
  readonly alertFlowSaveSuccess = $localize`:@@alert.flow.save.success:Data Flow saved successfully`;

  readonly alertLoopAddFailure = $localize`:@@alert.loop.add.failure:Loop add failed!`;
  readonly alertLoopEditFailure = $localize`:@@alert.loop.edit.failure:Loop save failed!`;
  readonly alertLoopAddSuccess = $localize`:@@alert.loop.and.success:Loop added successfully!`;
  readonly alertLoopEditSuccess = $localize`:@@alert.loop.edit.success:Loop saved successfully!`;
  readonly alertLoopDeleteFailure = $localize`:@@alert.loop.delete.failure:Loop delete failed!`;

  readonly alertRequirementSaveSuccess = $localize`:@@alert.requ.save.success:Requirement progress saved successfully`;
  readonly alertRequirementSaveFailure = $localize`:@@alert.requ.save.fail:Error saving progress`;
  readonly alertSaveDataFound = $localize`:@@alert.save.data.found:Do you want to restore previously saved data?`;
  readonly tooltipSuccessStat = $localize`:@@tooltip.save.data.found:No Project Errors`;
  readonly tooltipErrorStat = $localize`:@@tooltip.error.stat:Project contains errors`;
  readonly tooltipInProgStat = $localize`:@@tooltip.inprog.stat:Project is being processed`;
  readonly tooltipWarningStat = $localize`:@@tooltip.warning.stat:Project completion status unknown`;
  readonly alertGeneralRequestFailure = $localize`:@@alert.general.requ.failure:Error fulfilling request. Try again later.`;

  // Input field prompts
  readonly gitUrlFormatPrompt = $localize`:@@gitUrl.format.prompt:URL should have the format "https://example.git`;
  readonly requiredInputPrompt = $localize`:@@requ.input.prompt:This field is required`;
  readonly validStates: string[] = [
    'Alaska',
    'Alabama',
    'Arkansas',
    'American Samoa',
    'Arizona',
    'California',
    'Colorado',
    'Connecticut',
    'District of Columbia',
    'Delaware',
    'Florida',
    'Georgia',
    'Guam',
    'Hawaii',
    'Iowa',
    'Idaho',
    'Illinois',
    'Indiana',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Massachusetts',
    'Maryland',
    'Maine',
    'Michigan',
    'Minnesota',
    'Missouri',
    'Mississippi',
    'Montana',
    'North Carolina',
    'North Dakota',
    'Nebraska',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'Nevada',
    'New York',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Puerto Rico',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Virginia',
    'Virgin Islands',
    'Vermont',
    'Washington',
    'Wisconsin',
    'West Virginia',
    'Wyoming'
  ];

  readonly termsId = $localize`:@@terms.id:524a1ef8-4ac2-477a-a1a4-2e337e69b295`;
  readonly privacyId = $localize`:@@privacy.id:b39fbe1e-8c81-49dd-ab6a-cb26eb44c277`;

  // unicode
  readonly uniCross = $localize`:@@uniCross:❌`;
  readonly uniCheck = $localize`:@@uniCheck:✔️`;
  readonly uniExclaim = $localize`:@@uniExclaim:❗`;
  //readonly uniCeleb = '🎉';

  //diagram-overlay.component.html
  readonly fetchingData = $localize`:@@fetching.data:Please wait fetching data...`;
  readonly renderingGraphics = $localize`:@@rendering.grpahics:Please wait rendering graphics...`;

  //user session detail - session end event types
  readonly eventEnded = $localize`:@@event.ended:Ended By User`;
  readonly eventExpire = $localize`:@@event.expire:Expired`;

  // error-detail-dialog.component.html
  readonly eddHeader = $localize`:@@edd.header:Error Details`;
  readonly eddCode = $localize`:@@edd.code:Code`;
  readonly eddMsg = $localize`:@@edd.msg:Message`;
  readonly eddDetails = $localize`:@@edd.details:Details`;
  readonly eddSeverity = $localize`:@@edd.severity:Severity`;
  readonly eddRecovery = $localize`:@@edd.recovery:Recovery`;
  readonly eddSourceCodeErrors = $localize`:@@edd.source.code.errors:Source Code Errors`;
  readonly eddCloseButton = $localize`:@@edd.close.button:CLOSE`;

  //two-factor-code-verify.component.html
  readonly tfHeader = $localize`:@@tf.header:Two Factor Code Verification`;
  readonly tfSubHeader = $localize`:@@tf.sub.header:A one time code has been sent to your registered email address. Please enter the code to continue.`;
  readonly tfVerifyButton = $localize`:@@tf.verify.button:Verify`;
  readonly tfCancelButton = $localize`:@@tf.cancel.button:Cancel`;

  //variable-detail-editor.component.html
  readonly vdeVinName = $localize`:@@vde.vin.name:Parameter Name`;
  readonly vdeNameReq = $localize`:@@vde.name.req:Name is required`;
  readonly vdeParamDescription = $localize`:@@vde.param.description:Parameter Description`;
  readonly vdeDataType = $localize`:@@vde.data.type:Data Type`;
  readonly vdeVinDirection = $localize`:@@vde.vin.direction:Indirection`;
  readonly vdeValue = $localize`:@@vde.value:Value`;
  readonly vdeDimensions = $localize`:@@vde.dimensions:Dimensions`;
  readonly vdeDimensionSizes = $localize`:@@vde.dimension.sizes:Dimension Sizes`;
  readonly vdeLimitError = $localize`:@@vde.limit.error:Dimension sizes cannot exceed 4`;
  readonly vdeNonNumErrorCancel = $localize`:@@vde.non.num.error:Only numeric values are allowed`;
  readonly vdeSizeErrorCancel = $localize`:@@vde.size.error:Number of dimensions are incorrect`;
  readonly vdeReturnChk = $localize`:@@vde.return.chk:Variable is returned from function`;
  readonly vdeVarDetail = $localize`:@@vde.var.detail:Variable Detail`;
  readonly vdeCancelButton = $localize`:@@vde.cancel.button:CANCEL`;
  readonly vdeSaveButton = $localize`:@@vde.save.button:SAVE`;

  //variable-list-editor.component.html
  readonly vleSearchByName = $localize`:@@vle.search.by.name:Search By Name`;
  readonly vleNewButton = $localize`:@@vle.new.button:New`;
  readonly vleParamName = $localize`:@@vle.param.name:Parameter Name`;
  readonly vleDataType = $localize`:@@vle.data.type:Data Type`;
  readonly vleActions = $localize`:@@vle.actions:Actions`;

  //variable-list-modal.component.html
  readonly varListModalTitle = $localize`:@@var.list.modal.title:Variable Editor`;
  readonly varListModalParamCreated = $localize`:@@var.list.modal.param.created:Parameter created`;
  readonly varListModalErrorAdding = $localize`:@@var.list.modal.error.add:Error adding parameter`;
  readonly varListModalParamSaved = $localize`:@@var.list.modal.param.saved:Parameter saved`;
  readonly varListModalErrorUpdating = $localize`:@@var.list.modal.error.update:Error updating parameter`;
  readonly varListModaParamDeleted = $localize`:@@var.list.modal.param.deleted:Parameter deleted successfully!`;

  //variable-detail-editor-modal.component.html
  readonly varDetailEditorModalTitle = $localize`:@@var.detail.modal.title:Variable Detail`;
  readonly varDetailEditorCancelBtn = $localize`:@@var.detail.modal.cancel.btn:CANCEL`;
  readonly varDetailEditoSaveBtn = $localize`:@@var.detail.modal.save.btn:SAVE`;

  //invalid-session.component.html
  readonly isHeader = $localize`:@@is.header:Invalid / Expired Session`;
  readonly isErrorMsgOne = $localize`:@@is.error.msg.one:The session is either expired or invalid.`;
  readonly isErrorMsgTwo = $localize`:@@is.error.msg.two:Please return to a current valid session or log in again by clicking below. (This will invalidate any other active session)`;
  readonly isBackMatRaisedButton = $localize`:@@is.back.mat.raised.button:Back to Log in`;
  readonly isTooManyAttempts = $localize`:@@is.too.many.attempts:<p>Session either expired or inactivated due to multiple logins. </p>Please continue in a previous active session or log in again to create new session`;

  //forgot-password.component.html
  readonly fpEmailAddress = $localize`:@@fp.email.address:Email Address`;
  readonly fpSendButton = $localize`:@@fp.send.button:Send Email`;
  readonly fpStatusMsgOne = $localize`:@@fp.status.msg.one:Email with the reset instructions has been sent`;
  readonly fpStatusMsgTwo = $localize`:@@fp.status.msg.two:Error:`;
  readonly fpStatusMsgThree = $localize`:@@fp.status.msg.three:. Please contact the administrator.`;
  readonly fpLogin = $localize`:@@fp.login:Login`;
  readonly fpSubHeader = $localize`:@@fp.sub.header:A link will be sent to your email address to reset your password.`;
  readonly fpHeader = $localize`:@@fp.header:Reset Password`;

  //reset-password.component.html
  readonly rpLinkErrorMsg = $localize`:@@rp.link.error.msg:The link is either invalid or has expired. Click below to request for a new link :`;
  readonly rpResetPassword = $localize`:@@rp.reset.password:Reset Password`;
  readonly rpResetPasswordSuccess = $localize`:@@rp.reset.password.success:Password changed successfully`;
  //disclosures.component.html
  readonly discCloseButton = $localize`:@@disc.close.button:CLOSE`;

  //activate-register-account.component.html
  readonly acraMessage = $localize`:@@acra.msg:We encountered an error while processing your request. Please contact the administrator.`;
  readonly acraCloseButton = $localize`:@@acra.close.button:CLOSE`;
  readonly acraInvalidToken = $localize`:@@acra.invalid.token:Token is either invalid or has expired! Please click below to resend the email for account activation`;
  readonly acraAccountVerified = $localize`:@@acra.accnt.verif:Account has been verified and activated! Please click below to log in.`;
  readonly acraOkButton = $localize`:@@acra.ok.button:OK`;
  readonly acraActivateTxt = $localize`:@@acra.activate.button:Activation Email Sent`;
  readonly acraErrorTxt = $localize`:@@acra.error.msg:Error!`;
  readonly acraResendTxt = $localize`:@@acra.resend.msg:Re-send`;
  readonly acraLoginTxt = $localize`:@@acra.login.msg:log in`;
  //activate-account.component.html
  //activate-account.component.html
  readonly acacHeader = $localize`:@@acac.header:Activate Account`;
  //readonly acacHeaderError = $localize`:@@acac.header.error:''`;
  readonly acacSecQuestion = $localize`:@@acac.sec.quest:Security Question`;
  // readonly acacSecQuestions = $localize`:@@acac.sec.quests:''`;
  readonly acacSecAnswer = $localize`:@@acac.sec.ans:Security Answer`;
  readonly acacSecAnsError = $localize`:@@acac.sec.ans.error:Must be greater than 3 characters`;
  readonly acacUserName = $localize`:@@acac.user.name:User Name`;
  readonly acacUserNameError = $localize`:@@acac.user.name.error:Must be greater than 3 characters and cannot contain '#'`;
  readonly acacPassword = $localize`:@@acac.password:Password`;
  readonly acacRepPassword = $localize`:@@acac.repeat.password:Repeat Password`;
  readonly acacRepPasswordError = $localize`:@@acac.repeat.password.error:Passwords do not match`;
  readonly acacSubmitButton = $localize`:@@acac.submit.button:SUBMIT`;

  //register.component.html
  readonly rgEmailTaken = $localize`:@@rg.email.taken:Email is already taken`;
  readonly rgOrgEmail = $localize`:@@rg.org.email:Organization Email`;
  readonly rgUserEmail = $localize`:@@rg.user.email:User Email`;
  readonly rgFullName = $localize`:@@rg.full.name:Full Name`;
  readonly rgPassword = $localize`:@@rg.password:Password`;
  readonly rgRepeatPassword = $localize`:@@rg.repeat.password:Repeat Password`;
  readonly rgHeader = $localize`:@@rg.header:Sign up`;
  readonly rgAlreadyMem = $localize`:@@rg.already.mem:Already a member? Login`;
  readonly rgOrgNameError = $localize`:@@rg.org.name.error: Must be greater than 3 characters and cannot contain '#'`;
  readonly rgOrgEmailError = $localize`:@@rg.org.email.error:Format must follow example@domain.com`;
  readonly rgUserEmailReqError = $localize`:@@rg.user.email.req.error:This field is required`;
  readonly rgUserEmailInvalidError = $localize`:@@rg.user.email.invalid.error:Please enter a valid email address`;
  readonly rgInvalidEmail = $localize`:@@rg.invalid.email:Invalid email format`;
  readonly rgValidEmailDomain = $localize`:@@rg.email.domain:Accepted domain - ".edu"`;
  readonly rgUserNameError = $localize`:@@rg.user.name.error:Must be greater than 3 characters and cannot contain '#'`;
  readonly rgUserPasswordError = $localize`:@@rg.user.passwrd.error:Must be greater than 4 characters`;
  readonly rgUserPasswordMatchError = $localize`:@@rg.user.passwrd.match.error:Passwords do not match`;
  readonly rgTermsCheck = $localize`:@@rg.terms.check:I have read and agree to the`;
  readonly rgTermsOfUse = $localize`:@@rg.terms.of.use:Terms Of Use`;
  readonly rgPrivacyCheck = $localize`:@@rg.privacy.check:I have read and agree to the `;
  readonly rgPrivacyNotice = $localize`:@@rg.privacy.notice:Privacy Notice`;
  readonly rgButtonSignUp = $localize`:@@rg.button.signup:SIGN UP`;
  readonly rgBack = $localize`:@@rg.back:Back`;

  //login.component.html
  readonly lgnHeader = $localize`:@@lgn.header:Login`;
  readonly lgnRegister = $localize`:@@lgn.register:New to Blue Cheetah? Sign up!`;
  readonly lgnLogin = $localize`:@@lgn.login:LOGIN`;
  readonly lgnForgotPassword = $localize`:@@lgn.forgot.password:Forgot password?`;
  readonly lgnUserPassword = $localize`:@@lgn.user.password:Password`;
  readonly lgnUserEmail = $localize`:@@lgn.user.email:Email`;

  //footer.component.html
  readonly footMsgCopyright = $localize`:@@foot.msg.copyright:Copyright ©;`;
  readonly footMsg = $localize`:@@foot.msg: Massively Parallel Technologies. All rights reserved.`;

  //feedback-modal.component.html
  readonly fbmHeader = $localize`:@@fbm.header:Send Feedback`;
  readonly fbmCancelButton = $localize`:@@fbm.cancel.button:Cancel Feedback`;
  readonly fbmSendButton = $localize`:@@fbm.send.button:Send`;

  //redirection-template.component.html
  readonly redirGoToHomePage = $localize`:@@redir.go.to:Go To Home Page`;

  //timeout-modal.component.html
  readonly tomHeader = $localize`:@@tom.header:Notice`;
  readonly tomMsg = $localize`:@@tom.msg:Your session has expired. Please logout and log back in.`;
  readonly tomLogoutButton = $localize`:@@tom.logout.button:LOGOUT`;

  // file-uploader.component.html
  readonly fuHeaderOne = $localize`:@@fu.header.one:Stdin file upload`;
  readonly fuHeaderTwo = $localize`:@@fu.header.two:Source file upload`;
  readonly fuUpload = $localize`:@@fu.upload:Upload All Project Files`;
  readonly fuDrag = $localize`:@@fu.drag:Drag files here OR `;
  readonly fuBrowseButton = $localize`:@@fu.browse.button:Browse`;
  readonly fuHelpOutLine = $localize`:@@fu.help.outline:help_outline`;

  readonly placeHolderWorkspace = $localize`:@@plcHolder.workspace.name:Workspace`;
  readonly placeHolderOrganization = $localize`:@@plcHolder.org.name:Organization`;

  // jpt added
  // dashboard.component.ts
  readonly dashboardUntitled = $localize`:@@dash.untitled:Untitled`;

  // dashboard-card.component.ts
  readonly dashboardCardTitle = $localize`:@@dashcard.title:Untitled`;
  readonly dashboardCardId = $localize`:@@dashcard.id:Untitled`;

  // dashboard-header.component.html
  readonly dashboardHeaderFiles = $localize`:@@dashheader.files:Files`;
  readonly dashboardHeaderFunctions = $localize`:@@dashheader.functions:Functions`;
  readonly dashboardHeaderLines = $localize`:@@dashheader.lines:Lines`;
  readonly dashboardHeaderVariables = $localize`:@@dashheader.variables:Variables`;
  readonly dashboardHeaderStatements = $localize`:@@dashheader.statements:Statements`;
  readonly dashboardHeaderLibraries = $localize`:@@dashheader.libraries:Libraries`;

  //user.status-card.component.html
  readonly userStatusCardTitle = $localize`:@@usc.title:User Status`;
  readonly userStatusCardMsg = $localize`:@@usc.msg:Manage user and organization information.`;
  readonly userStatusCardButton = $localize`:@@usc.button:View`;

  //new-project-card.component.html
  readonly newProjectCardTitle = $localize`:@@npc.title: Get Started!`;
  readonly newProjectCardHeader = $localize`:@@npc.header:No Projects Found`;
  readonly newProjectCardMsg = $localize`:@@npc.no.proj.msg:You don't have any projects yet. Click below to create a new Blue Cheetah project.`;
  readonly newProjectCardButton = $localize`:@@npc.button:Create a new Project`;

  //account-settings-card.component.html
  readonly accountSettingsCardTitle = $localize`:@@asc.title:Account Settings`;
  readonly accountSettingsCardMsg = $localize`:@@asc.msg:Manage your account settings.`;
  readonly accountSettingsCardButton = $localize`:@@asc.button:View`;

  //activity-log-card.component.html
  readonly activityLogCardTitle = $localize`:@@alc.title:Activity Log`;
  readonly activityLogCardDateTime = $localize`:@@alc.date.time:Date & Time`;
  readonly activityLogCardLevel = $localize`:@@alc.level:Level`;
  readonly activityLogCardContent = $localize`:@@alc.content:Content`;
  readonly activityLogCardCategory = $localize`:@@alc.category:Category`;
  readonly activityLogCardUser = $localize`:@@alc.user:User`;
  readonly activityLogCardButton = $localize`:@@alc.button:Details`;

  //about-card.component.html
  readonly aboutCardTitle = $localize`:@@ac.title:About Blue Cheetah`;
  readonly aboutCardMsg = $localize`:@@ac.msg:View information about this build of Blue Cheetah.`;
  readonly aboutCardButton = $localize`:@@ac.button:View`;

  //execution-engine-card.component.html
  readonly exEngineCardTitle = $localize`:@@eec.title:Execution Engine`;
  readonly exEngineCardButton = $localize`:@@eec.button:Details`;
  readonly exEngineCardInvalidData = $localize`:@@eec.invaliddata:Invalid data`;
  readonly exEngineCardAvailable = $localize`:@@eec.available:Available`;
  readonly exEngineCardUnAvailable = $localize`:@@eec.unAvailable:Unavailable`;
  readonly exEngineCardEngineStatus = $localize`:@@eec.engine.status:Engine Status`;
  readonly exEngineCardServersAvailable = $localize`:@@eec.servers.avail:Servers Available`;
  readonly exEngineCardDatabase = $localize`:@@eec.database:Database`;

  //projects-card.component.html
  readonly projectsCardTitle = $localize`:@@pc.title:Projects`;
  readonly projectsCardName = $localize`:@@pc.name:Name`;
  readonly projectsCardCreated = $localize`:@@pc.created:Created`;
  readonly projectsCardLines = $localize`:@@pc.lines:Lines`;
  readonly projectsCardStatus = $localize`:@@pc.status:Status`;
  readonly projectsCardType = $localize`:@@pc.type:Type`;
  readonly projectsCardLibraryButton = $localize`:@@pc.library.button:Project Library`;
  readonly projectsCardNewProjectButton = $localize`:@@pc.new.project.button:New Project`;
  readonly projectsCardNavToDashboard = $localize`:@@pc.nav.to.dashboard:Navigate to Project Dashboard`;
  readonly projectsCardDynamic = $localize`:@@pc.dynamic:Dynamic`;
  readonly projectsCardStatic = $localize`:@@pc.static:Static`;
  readonly projectsCardDynamicD = $localize`:@@pc.dynamic.d:D`;
  readonly projectsCardStaticS = $localize`:@@pc.static.s:S`;

  //diagram-component.ts
  readonly diagramBCProject = $localize`:@@diagram.bc.project:Projects`;
  readonly diagramBCDashboard = $localize`:@@diagram.bc.dashboard:Dashboard`;
  readonly diagramActionInspect = $localize`:@@diagram.action.inspect:Inspect`;
  readonly diagramActionExecute = $localize`:@@diagram.action.execute:Execute`;
  readonly diagramActionDecompose = $localize`:@@diagram.action.decompose:Decompose`;
  readonly diagramSelectionPropType = $localize`:@@diagram.selection.prop.type:Type`;
  readonly diagramSelectionPropSubtype = $localize`:@@diagram.selection.prop.subtype:Subtype`;
  readonly diagramSelectionPropName = $localize`:@@diagram.selection.prop.name:Name`;
  readonly diagramSelectionPropLabel = $localize`:@@diagram.selection.prop.label:Label`;
  readonly diagramSelectionPropDescription = $localize`:@@diagram.selection.prop.desc:Description`;
  readonly diagramSelectionPropID = $localize`:@@diagram.selection.prop.id:ID`;
  readonly diagramSelectionPropFileName = $localize`:@@diagram.selection.prop.file.name:File Name`;
  readonly diagramSelectionPropExecTime = $localize`:@@diagram.selection.prop.exec.time:Execution Time`;
  readonly diagramSelectionPropTLPEnabled = $localize`:@@diagram.selection.prop.tlp.enabled:TLP Enabled`;
  readonly diagramSelectionPropExecTimeMS = $localize`:@@diagram.selection.prop.exec.time.ms:ms`;
  readonly diagramSelectionPropSource = $localize`:@@diagram.selection.prop.source:Source`;
  readonly diagramSelectionPropTarget = $localize`:@@diagram.selection.prop.target:Target`;
  readonly diagramPreparingExport = $localize`:@@diagram.prepare.export:Preparing export...`;
  readonly diagramNoResultsFound = $localize`:@@diagram.no.results.found:No Results Found`;
  readonly diagramAlreadyInitialized = $localize`:@@diagram.already.init:Diagram already initialized.`;
  readonly diagramNoLegendItemsProvided = $localize`:@@diagram.no.legends:Legend items not provided.`;
  readonly diagramUnableToDrawChild = $localize`:@@diagram.no.drawable.child:Unable to find drawable child.`;
  readonly diagramUnknownError = $localize`:@@diagram.unknown.error:Unknown sidebar error.`;
  readonly diagramCompAlreadySelected = $localize`:@@diagram.comp.already.selected:Component already selected.`;
  readonly diagramActionNotImplemented = $localize`:@@diagram.action.not.implem:Action not implemented.`;
  readonly diagramUnableToLoadSource = $localize`:@@diagram.source.for.kernel:Unable to load source code for kernel.`;
  readonly diagramNoCodeReceived = $localize`:@@diagram.no.code.data.received:No code data received.`;
  readonly diagramUnableSelectNode = $localize`:@@diagram.unable.to.select.node:Unable to select node.`;

  //home.component.html
  readonly homeCompTitle = $localize`:@@home.title:Home`;
  readonly homeCompDontShowTooltip = $localize`:@@hc.dont.display.tltip:Don't display this message again`;

  // jpt added
  // project dashboard cards
  // bulk-export-card.component.html
  readonly bulkExportCardTitle = $localize`:@@be.title:Bulk Export`;
  readonly bulkExportCardMsg = $localize`:@@be.msg:Export the data and diagrams for this project. Items available for export include:`;
  readonly bulkExportCardMsgLine1 = $localize`:@@be.msgline1:Call Graph`;
  readonly bulkExportCardMsgLine2 = $localize`:@@be.msgline2:Control Flow graphs`;
  readonly bulkExportCardMsgLine3 = $localize`:@@be.msgline3:Component, design and decomposition level data`;
  readonly bulkExportCardButton = $localize`:@@be.button:Configure`;

  // call-graph-card.component.html
  readonly callGraphCardTitle = $localize`:@@cg.title:Call Graph`;
  readonly callGraphCardSubtitle = $localize`:@@cg.subtitle:Interactive Diagram`;
  readonly callGraphCardMsg = $localize`:@@cg.msg:Analyze and navigate function calls.`;
  readonly callGraphCardButton = $localize`:@@cg.button:Launch`;

  // code-card.component.html
  readonly codeCardTitle = $localize`:@@cc.title:Code`;
  readonly codeCardMsg = $localize`:@@cc.msg:Complete listing of all the files in this project.`;
  readonly codeCardButton = $localize`:@@cc.button:View`;

  // control-flow-card.component.html
  readonly controlFlowCardTitle = $localize`:@@cfc.title:Control Flow`;
  readonly controlFlowCardSubtitle = $localize`:@@cfc.subtitle:Interactive Diagram`;
  readonly controlFlowCardMsg = $localize`:@@cfc.msg:Analyze your C functions with this interactive tool.`;
  readonly controlFlowCardButton = $localize`:@@cfc.button:Launch`;

  // metrics-card.component.html
  readonly metricsCardTitle = $localize`:@@mc.title:Metrics`;
  readonly metricsCardSubtitle = $localize`:@@mc.subtitle:Dashboard`;
  readonly metricsCardMsg = $localize`:@@mc.msg:Detailed static analysis of your C code.`;
  readonly metricsCardButton = $localize`:@@mc.button:View Dashboard`;

  // optimization-card.component.html
  readonly optimizationCardTitle = $localize`:@@oc.title.opt:Optimization`;
  readonly optimizationCardSubtitle = $localize`:@@oc.subtitle:Dashboard`;
  readonly optimizationCardMsg = $localize`:@@oc.msg:Use the optimization functionality to execute and profile your code to maximize performance.`;
  readonly optimizationCardButton = $localize`:@@oc.button:View Dashboard`;
  readonly optimizationCardQuickLinks = $localize`:@@oc.quick.links:Quick Links`;

  // optimization-card.component.ts
  readonly optimizationCardUnableToLoad = $localize`:@@oc.unable.to.load:Unable to load metric data`;
  readonly optimizationCardExecs = $localize`:@@oc.execs:Executions`;
  readonly optimizationCardProcesses = $localize`:@@oc.processes:Processes`;
  readonly optimizationCardTLPGroups = $localize`:@@oc.tlp.groups:TLP Groups`;
  readonly optimizationCardFastestExec = $localize`:@@oc.fastest.exec:Fastest Execution`;
  readonly optimizationCardProfile = $localize`:@@oc.profile:Profiles`;

  // project-activity-card.component.html
  readonly projectActivityCardTitle = $localize`:@@pa.title:Project Activity`;
  readonly projectActivityCardMsg = $localize`:@@pa.msg:Recent activity related to this project.`;
  readonly projectActivityCardButton = $localize`:@@pa.button:Details`;
  readonly projectActivityCardDateTime = $localize`:@@pa.datetime:Date & Time`;
  readonly projectActivityCardContent = $localize`:@@pa.content:Content`;

  // project-info-card.component.html
  readonly projectInfoCardTitle = $localize`:@@pi.title:Project Info`;
  readonly projectInfoCardName = $localize`:@@pi.name:Name`;
  readonly projectInfoCardLanguage = $localize`:@@pi.language:Language`;
  readonly projectInfoCardCreated = $localize`:@@pi.created:Created`;
  readonly projectInfoCardOwner = $localize`:@@pi.owner:Owner`;
  readonly projectInfoCardType = $localize`:@@pi.ext.type:Extraction Type`;
  readonly projectInfoCardDesc = $localize`:@@pi.project.info:Project Info`;
  readonly projectInfoCardButton = $localize`:@@pi.details:Details`;
  readonly projectInfoCardDynamic = $localize`:@@pi.dynamic:Dynamic`;
  readonly projectInfoCardStatic = $localize`:@@pi.static:Static`;

  // versioning-card.component.html
  readonly versioningCardTitle = $localize`:@@vc.title:Versioning`;
  readonly versioningCardRepository = $localize`:@@vc.repository:Repository`;
  readonly versioningCardRemoteName = $localize`:@@vc.remote:Remote Name`;
  readonly versioningCardButton = $localize`:@@vc.button:Edit`;

  // optimization dashboard and cards
  // optimization-dashboard.component.html
  readonly optimizationDashboardTitle = $localize`:@@od.title:Optimization Dashboard`;
  readonly optimizationDashboardWait = $localize`:@@od.wait:Please Wait`;

  //index.html
  readonly indexTitle = $localize`:@@index.title:Blue Cheetah`;

  // components-card.component.html
  readonly componentsCardTitle = $localize`:@@comcard.title:Components View`;
  readonly componentsCardSubtitle = $localize`:@@comcard.subtitle:Interactive Diagram`;
  readonly componentsCardButton = $localize`:@@comcard.button:Launch`;
  readonly componentsCardAlgorithms = $localize`:@@comcard.algorithms:Algorithms`;
  readonly componentsCardProcesses = $localize`:@@comcard.processes:Processes`;
  readonly componentsCardDecomposableProcesses = $localize`:@@comcard.decomposableprocesses:Decomposable Processes`;
  readonly componentsCardKernels = $localize`:@@comcard.kernels:Kernels`;
  readonly componentsCardLoops = $localize`:@@comcard.loops:Loops`;
  readonly componentsCardAverageComponentsPerDecomposition = $localize`:@@comcard.averagecomponentsperdecomposition:Average Components per Decomposition`;
  readonly componentsCardSourceMappedProcesses = $localize`:@@comcard.sourcemappedprocesses:Source Mapped Processes`;
  readonly componentsCardSharedProcesses = $localize`:@@comcard.sharedprocesses:Shared Processes`;
  readonly componentsCardLargeKernels = $localize`:@@comcard.largekernels:Large Kernels`;

  // decomposition-card.component.html
  readonly decompCardTitle = $localize`:@@decompcard.title:Decomposition View`;
  readonly decompCardSubtitle = $localize`:@@decompcard.subtitle:Interactive Diagram`;
  readonly decompCardMsg = $localize`:@@decompcard.msg:View, manage, and optimize decomposed design nodes.`;
  readonly decompCardButton = $localize`:@@decompcard.button:Decomposition`;

  // design-card.component.html
  readonly designCardTitle = $localize`:@@designcard.title:Design View`;
  readonly designCardSubtitle = $localize`:@@designcard.subtitle:Interactive Diagram`;
  readonly designCardMsg = $localize`:@@designcard.msg:Browse and navigate the high-level design nodes of this project.`;
  readonly designCardButton = $localize`:@@designcard.button:Launch`;

  // executions-card.component.html
  readonly executionsCardTitle = $localize`:@@eccard.title:Executions`;
  readonly executionsCardButton = $localize`:@@eccard.button:Details`;

  readonly executionsCardExecutionId = $localize`:@@eccard.executionid:Execution ID`;
  readonly executionsCardMilliSeconds = $localize`:@@eccard.milli.seconds:Milliseconds`;

  readonly executionsCardTotalRuns = $localize`:@@eccard.totalruns:Total Runs`;
  readonly executionsCardSuccessfulRuns = $localize`:@@eccard.successfulruns:Successful Runs`;
  readonly executionsCardTLPEnabledRuns = $localize`:@@eccard.tlpenabledruns:TLP Enabled Runs`;
  readonly executionsCardAverageRunTime = $localize`:@@eccard.averageruntime:Average Run Time`;
  readonly executionsCardFastestRun = $localize`:@@eccard.fastestrun:Fastest Run`;
  readonly executionsCardSlowestRun = $localize`:@@eccard.slowestrun:Slowest Run`;

  // executions-card-create.component.html
  readonly executionsCardCreateTitle = $localize`:@@ecccard.title:Execute Your Code`;
  readonly executionsCardCreateMsg1 = $localize`:@@ecccard.msg1:No Executions Found`;
  readonly executionsCardCreateMsg2 = $localize`:@@ecccard.msg2:You don't have any executions yet.`;
  readonly executionsCardCreateButton = $localize`:@@ecccard.button:Details`;

  // free-range-apps-card.component.html (BC Optimized Source Code)
  readonly freeRangeAppsCardTitle = $localize`:@@fracard.title:BC Optimized Source Code`;
  readonly freeRangeAppsCardButton = $localize`:@@fracard.button:Details`;

  // free-range-creation-card.component.html
  readonly freeRangeAppsCreateCardTitle = $localize`:@@fraccard.title:Create Optimized Code`;
  readonly freeRangeAppsCreateCardMsg1 = $localize`:@@fraccard.msg1:No optimized code found`;
  readonly freeRangeAppsCreateCardMsg2 = $localize`:@@fraccard.msg2:You don't have any optimized code yet. Click below to create.`;
  readonly freeRangeAppsCreateCardButton = $localize`:@@fraccard.button:Create`;

  // profiles-card.component.html
  readonly profilesCardTitle = $localize`:@@profilescard.title:Profiles`;
  readonly profilesCardButton = $localize`:@@profilescard.button:Details`;
  readonly profilesCardExecutionID = $localize`:@@profilescard.executionid:Execution ID`;
  readonly profilesCardMilliSeconds = $localize`:@@profilescard.millli.seconds:Milliseconds`;
  readonly profilesCardDataNotFound = $localize`:@@profilescard.datanotfound:DataNotFound`;
  readonly profilesCardTotalRuns = $localize`:@@profilescard.totalruns:Total Runs`;
  readonly profilesCardAverageRunTime = $localize`:@@profilescard.averagetuntime:Average Run Time`;
  readonly profilesCardTLPEnabledRuns = $localize`:@@profilescard.tlpenabledruns:TLP Enabled Runs`;
  readonly profilesCardBaseRunTime = $localize`:@@profilescard.baseruntime:Base Run Time`;
  readonly profilesCardAutoProfiles = $localize`:@@profilescard.autoprofiles:Auto Profiles`;
  readonly profilesCardMaxSpeedImprovement = $localize`:@@profilescard.maxspeedimprovement:Max Speed Improvement`;
  readonly profilesCardMaxCoresUsed = $localize`:@@profilescard.maxcoresused:Max Cores Used`;
  readonly profilesCardFastestRun = $localize`:@@profilescard.fastestrun:Fastest Run`;
  readonly profilesCardSlowestRun = $localize`:@@profilescard.slowestrun:Slowest Run`;
  readonly profilesCardMSUnit = $localize`:@@profilescard.ms.unit:ms`;
  // profiles-card-create.component.html
  readonly profilesCreateTitle = $localize`:@@profcreatecard.title:Create Profile`;
  readonly profilesCreateMsg1 = $localize`:@@profcreatecard.msg1:No profiles found`;
  readonly profilesCreateMsg2 = $localize`:@@profcreatecard.msg2:You don't have any profiles yet. Use the link below to begin profiling and executing your code.`;
  readonly profilesCreateButton = $localize`:@@profcreatecard.button:Create`;

  // tlp-no-result-card.component.html
  readonly tlpNoResultTile = $localize`:@@tlpresult.title: No TLP Found`;
  readonly tlpNoResultMsg1 = $localize`:@@tlpresult.msg1: No TLP groups were identified in this project. Please select another project to view this information.`;

  // tlp-view.component.html
  readonly tlpTitleLabel = $localize`:@@tlp.title.label:TLP`;

  // tlp-groups-card.component.html
  readonly tlpGroupsCardTitle = $localize`:@@tlpcard.title:TLP Groups`;
  readonly tlpGroupsCardButton = $localize`:@@tlpcard.button:Configure`;
  readonly tlpGroupsCardDataNofFound = $localize`:@@tlpcard.datanotfound:Tlp data not found`;
  readonly tlpGroupsCardEnabledGroups = $localize`:@@tlpcard.enabledgroups:Enabled Groups`;
  readonly tlpGroupsCardChangedStatus = $localize`:@@tlpcard.changedstatus:Changed Status`;
  readonly tlpGroupsCardAverageComponentsPerGroup = $localize`:@@tlpcard.averagecomponentspergroup:Average Components per Group`;
  readonly tlpGroupsCardTLPGroups = $localize`:@@tlpcard.tlpgroups:TLP Groups`;
  readonly tlpGroupsCardProfiledGroups = $localize`:@@tlpcard.profiledgroups:Profiled Groups`;

  // metrics dashboard and cards
  // metrics.component.html
  readonly metricsDashboardTitle = $localize`:@@mdb.title:Project Metrics`;

  //metrics-card.component.ts
  readonly metricsDataNotFound = $localize`:@@mdb.data.not.found:Metric data not found`;
  readonly metricsFilesLabel = $localize`:@@mdb.file.label:Files`;
  readonly metricsFunctionsLabel = $localize`:@@mdb.function.label:Functions`;
  readonly metricsLOCLabel = $localize`:@@mdb.loc.label:Lines of Code`;
  readonly metricsVariablesLabel = $localize`:@@mdb.variables.label:Variables`;
  readonly metricsStatementsLabel = $localize`:@@mdb.statements.label:Statements`;
  readonly metricsStandardLibsLabel = $localize`:@@mdb.standard.libs.label:Standard Libraries`;

  // files-card.component.html
  readonly filesCardTitle = $localize`:@@filescard.title:Files`;
  readonly filesCardCodeFiles = $localize`:@@filescard.codefiles:Code Files`;
  readonly filesCardHeaderFiles = $localize`:@@filescard.headerfiles:Header Files`;
  readonly filesCardOtherFiles = $localize`:@@filescard.otherfiles:Other Files`;
  readonly filesCardDataNotFound = $localize`:@@filescard.datanotfound:Metrics data not found`;

  // functions-card.component.html
  readonly functionsCardTitle = $localize`:@@functionscard.title:Functions`;
  readonly functionsCardDataNotFound = $localize`:@@functionscard.datanotfound:Metrics data not found`;
  readonly functionsCardUtilizedFunctions = $localize`:@@functionscard.utilizedfunctions:Utilized Functions`;
  readonly functionsCardTotalFunctions = $localize`:@@functionscard.totalfunctions:Total Functions`;
  readonly functionsCardAverageLocalVariableCount = $localize`:@@functionscard.averagelocalvariablecount:Average Local Variable Count`;
  readonly functionsCardAverageLines = $localize`:@@functionscard.averagelines:Average Lines`;
  readonly functionsCardRecursiveFunctions = $localize`:@@functionscard.recursivefunctions:Recursive Functions`;
  readonly functionsCardFunctionPointers = $localize`:@@functionscard.functionpointers:Function Pointers`;
  readonly functionsCardAverageFunctionPointers = $localize`:@@functionscard.averagefunctionpointers:Average Function Pointers`;

  // lines-card.component.html
  readonly linesCardTitle = $localize`:@@linescard.title:Lines`;
  readonly linesCardDataNotFound = $localize`:@@linescard.datanotfound:Metrics data not found`;
  readonly linesCardSourceLines = $localize`:@@linescard.sourcelines:Source Lines`;
  readonly linesCardBlankLines = $localize`:@@linescard.blanklines:Blank Lines`;
  readonly linesCardComments = $localize`:@@linescard.comments:Comments`;

  // variables-card.component.html
  readonly variablesCardTitle = $localize`:@@varcard.title:Variables`;
  readonly variablesCardDataNotFound = $localize`:@@varcard.datanotfound:Metrics data not found`;
  readonly variablesCardLocalVariables = $localize`:@@varcard.localvariables:Local Variables`;
  readonly variablesCardUtilizedGlobalVariables = $localize`:@@varcard.utilizedglobalvariables:Utilized Global Variables`;
  readonly variablesCardVariableReferences = $localize`:@@varcard.variablereferences:Variable References`;
  readonly variablesCardVariableModifications = $localize`:@@varcard.variablemodifications:Variable Modifications`;

  // statements-card.component.html
  readonly statementsCardTitle = $localize`:@@statementscard.title:Statements`;
  readonly statementsCardDataNotFound = $localize`:@@statementscard.datanotfound:Metrics data not found`;
  readonly statementsCardCalls = $localize`:@@statementscard.calls:Calls`;
  readonly statementsCardConditionals = $localize`:@@statementscard.conditionals:Conditionals`;
  readonly statementsCardJumps = $localize`:@@statementscard.jumps:Jumps`;
  readonly statementsCardLoops = $localize`:@@statementscard.loops:Loops`;

  // libraries-card.component.html
  readonly librariesCardTitle = $localize`:@@slibscard.title:Libraries`;
  readonly librariesCardDataNotFound = $localize`:@@slibscard.datanotfound:Metrics data not found`;
  readonly librariesCardSystemIncludes = $localize`:@@slibscard.systemincludes:System Includes`;
  readonly librariesCardUserIncludes = $localize`:@@slibscard.userincludes:User Includes`;
  readonly librariesCardNoResultsFound = $localize`:@@slibscard.no.results.found:No Results Found`;
  readonly librariesCardNoLibraries = $localize`:@@slibscard.no.libraries:Code does not include any libraries`;

  //sidebar.component.html
  readonly sidebarOverview = $localize`:@@sdbar.overview:Overview`;
  readonly sidebarInspector = $localize`:@@sdbar.inspector:Inspector`;
  readonly sidebarLegend = $localize`:@@sdbar.legend:Legend`;
  readonly sidebarExecution = $localize`:@@sdbar.execution:Execution`;
  readonly sidebarSourceMap = $localize`:@@sdbar.sourceMap:Source-map`;

  //inspector.component.html
  readonly inspectNoSelection = $localize`:@@inspect.no.selection:No Selection`;

  //flow-parameters.component.html
  readonly flowParamTitle = $localize`:@@flwparam.parameters:Parameters`;
  readonly flowParamName = $localize`:@@flwparam.name:Name`;
  readonly flowParamType = $localize`:@@flwparam.type:Type`;
  readonly flowParamDimensions = $localize`:@@flwparam.dimensions:Dimensions`;
  readonly flowParamIndirectionLevels = $localize`:@@flwparam.indirection.levels:Indirection Levels`;
  readonly flowParamInitialValue = $localize`:@@flwparam.initial.value:Initial Value`;

  //log.component.html
  readonly logTitle = $localize`:@@log.title:Component Log`;

  //node-actions.component.html
  readonly nodeActionsTitle = $localize`:@@ndact.title:Action`;
  readonly nodeActionsNoItemsFound = $localize`:@@ndact.no.items:No Items Found`;

  //node-globals.component.html
  readonly nodeGlobalTitle = $localize`:@@ndglbl.title:Included Library Variables`;
  readonly nodeGlobalName = $localize`:@@ndglbl.name:Name`;
  readonly nodeGlobalType = $localize`:@@ndglbl.type:Type`;
  readonly nodeGlobalReferences = $localize`:@@ndglbl.reference:References`;

  //node-in.component.html
  readonly nodeIncomingFlowsTitle = $localize`:@@ndin.flows.title:Incoming Flows`;
  readonly nodeInType = $localize`:@@ndin.name:Type`;
  readonly nodeInName = $localize`:@@ndin.type:Name`;
  readonly nodeInParameter = $localize`:@@ndin.params:Parameters`;
  readonly nodeInSourceType = $localize`:@@ndin.source.type:Source Type`;
  readonly nodeInSource = $localize`:@@ndin.source:Source`;

  //node-out.component.html
  readonly nodeOutgoingTitles = $localize`:@@ndout.flows.title:Outgoing Flows`;
  readonly nodeOutType = $localize`:@@ndout.type:Type`;
  readonly nodeOutName = $localize`:@@ndout.name:Name`;
  readonly nodeOutParameters = $localize`:@@ndout.params:Parameters`;
  readonly nodeOutTargetType = $localize`:@@ndout.target.type:Target Type`;
  readonly nodeOutTarget = $localize`:@@ndout.target:Target`;

  //node-variables.component.html
  readonly nodeVarsTitle = $localize`:@@ndvar.title:Declared Variables`;
  readonly nodeVarType = $localize`:@@ndvar.type:Type`;
  readonly nodeVarName = $localize`:@@ndvar.name:Name`;
  readonly nodeVarScope = $localize`:@@ndvar.scope:Scope`;
  readonly nodeVarRef = $localize`:@@ndvar.reference:References`;
  readonly nodeVarModif = $localize`:@@ndvar.modif:Modifications`;

  //profile-sidebar.component.html
  readonly profSideTitle = $localize`:@@profside.title:Profile`;
  readonly profSideNoActive = $localize`:@@profside.no.active:No active profile found.`;
  readonly profSideSelectProfile = $localize`:@@profside.select.prof:Select a profile`;
  readonly profSideActiveProfile = $localize`:@@profside.active.prof:Active Profile`;
  readonly profSideChange = $localize`:@@profside.change:Change`;
  readonly profSideElemName = $localize`:@@profside.elem.name:Design Element Name`;
  readonly profSideExecTime = $localize`:@@profside.exec.time:Execution Time`;
  readonly profSideTLPEnabled = $localize`:@@profside.tlp.enable:TLP Enabled`;
  readonly profSideTLPEnabledY = $localize`:@@profside.tlp.enable.yes:Yes`;
  readonly profSideTLPEnabledN = $localize`:@@profside.tlp.enable.no:No`;

  //selection.component.html
  readonly selectionTitle = $localize`:@@selection.title:Selection`;
  readonly selectProperty = $localize`:@@selection.property:Property`;
  readonly selectValue = $localize`:@@selection.value:Value`;
  readonly selectNoItems = $localize`:@@selection.no.items:No items found`;

  //source-code.component.html
  readonly sourceCodeTitle = $localize`:@@srcCode.title:Source Code`;
  readonly sourceLine = $localize`:@@src.line:line`;
  readonly sourceLines = $localize`:@@src.lines:lines`;

  //legend.component.html
  readonly legendActions = $localize`:@@legend.actions:Actions`;
  readonly legendDragTooltip = $localize`:@@legend.drag.tooltip:Drag onto the diagram to add.`;

  //attach-panel.component.html
  readonly attachPanelTitle = $localize`:@@attchPanel.title:Attach`;
  readonly attachPanelDesignElement = $localize`:@@attchPanel.design:Design Element`;
  readonly attachPanelAttachedObject = $localize`:@@attchPanel.attach:Attached Object`;

  //cobol-variables.component.html
  readonly cobolVarTitle = $localize`:@@cobol.var.title:Variables`;
  readonly cobolVarName = $localize`:@@cobol.var.name:Name`;
  readonly cobolVarDeclar = $localize`:@@cobol.var.declar:Declaration`;
  readonly cobolVarUsage = $localize`:@@cobol.var.usage:Usage`;
  readonly cobolVarHghUsage = $localize`:@@cobol.var.hgh.usage:Highlight variable usage`;
  readonly cobolVarHghDeclar = $localize`:@@cobol.var.hgh.declar:Highlight variable declaration`;
  readonly cobolVarHighlight = $localize`:@@cobol.var.highlight:highlight`;

  //code-panel.component.html
  readonly codeTitle = $localize`:@@code.title:Code`;

  //counts.component.html
  readonly countsTitle = $localize`:@@count.title:Counts`;
  readonly countsProperty = $localize`:@@count.property:Property`;
  readonly countsTotal = $localize`:@@count.total:Total`;
  readonly countsVisible = $localize`:@@count.visible:Visible`;

  //globals.component.html
  readonly globalVarTitle = $localize`:@@glob.var.title:Included Library Variables`;
  readonly globalVarName = $localize`:@@glob.var.name:Name`;
  readonly globalVarType = $localize`:@@glob.var.type:Type`;
  readonly globalVarRef = $localize`:@@glob.var.reference:References`;

  //invoked-functions.component.html
  readonly calledFuncTitle = $localize`:@@called.func.title:Called Functions`;
  readonly calledFuncName = $localize`:@@called.func.name:Name`;
  readonly calledFuncCount = $localize`:@@called.func.count:Count`;
  readonly calledFuncFilename = $localize`:@@called.func.file.name:File Name`;
  readonly calledFuncFuncName = $localize`:@@called.func.func.name:Function Name`;
  readonly calledFuncHighlight = $localize`:@@called.func.high:Highlight function call`;

  //invoking-functions.component.html
  readonly callingFuncTitle = $localize`:@@calling.func.title:Calling Functions`;
  readonly callingFuncName = $localize`:@@calling.func.name:Name`;
  readonly callingFuncCount = $localize`:@@calling.func.count:Count`;
  readonly callingFuncFuncName = $localize`:@@calling.func.func.name:Function Name`;
  readonly callingFuncFilename = $localize`:@@calling.func.file.name:File Name`;

  //jumps-panel.component.html
  readonly jumpTitle = $localize`:@@jump.title:Jumps`;
  readonly jumpDesc = $localize`:@@jump.desc:Select from the available code jumps (goto, break, continue) to have them rendered on the graph. Optionally set an intensity level for the jumps.`;
  readonly jumpGoto = $localize`:@@jump.goto:goto`;
  readonly jumpStatements = $localize`:@@jump.statement:statements`;
  readonly jumpBreak = $localize`:@@jump.break:break`;
  readonly jumpContinue = $localize`:@@jump.continue:continue`;
  readonly jumpIntensity = $localize`:@@jump.intensity:Intensity:`;

  //levels.component.html
  readonly levelsTitle = $localize`:@@levels.title:Design `;
  readonly levelsLevel = $localize`:@@levels.level:Level`;
  readonly levelsDepth = $localize`:@@levels.depth:Depth`;
  readonly levelProcess = $localize`:@@levels.process:Processes`;
  readonly levelsTLP = $localize`:@@levels.tlp:TLP`;
  readonly levelsChildren = $localize`:@@levels.children:Children`;

  //parallelization.component.html
  readonly parallelTitle = $localize`:@@parallel.title:Parallelization Opportunities`;
  readonly parallelName = $localize`:@@parallel.name:Name`;
  readonly parallelType = $localize`:@@parallel.type:Type`;
  readonly parallelLevel = $localize`:@@parallel.level:Level`;
  readonly parallelId = $localize`:@@parallel.id:Id`;
  readonly parallelExportTitle = $localize`:@@parallel.export.title:Profile: Parallelization Opportunities`;

  //sequence.component.html
  readonly sequenceTitle = $localize`:@@seq.title:Call Sequence`;
  readonly sequenceProcess = $localize`:@@seq.process:Process`;
  readonly sequenceCondition = $localize`:@@seq.condition:Condition`;
  readonly sequenceExportTitle = $localize`:@@seq.export.title:Call Sequence`;

  //tlp-configurator.component.html
  readonly tlpConfigTitle = $localize`:@@tlp.conf.title:TLP Configuration`;
  readonly tlpConfigExportTitle = $localize`:@@tlp.conf.export.title:TLP Configuration`;
  readonly tlpConfigExportGroupNumber = $localize`:@@tlp.confexport.grp.nmbr:Group Number`;
  readonly tlpConfigExportName = $localize`:@@tlp.conf.export.name:Name`;
  readonly tlpConfigExportType = $localize`:@@tlp.conf.export.type:Type`;
  readonly tlpConfigExportEnabled = $localize`:@@tlp.conf.export.enabled:Enabled`;

  //uploaded-files.component.html
  readonly uploadedFilesTitle = $localize`:@@upld.files.title:Uploaded Files`;
  readonly uploadedFileName = $localize`:@@upld.files.name:File Name`;

  //variables.component.html
  readonly varsTitle = $localize`:@@vars.title:Variables`;
  readonly varsAll = $localize`:@@vars.all:All`;
  readonly varsGlobal = $localize`:@@vars.global:Global`;
  readonly varsLocal = $localize`:@@vars.local:Local`;
  readonly varsName = $localize`:@@vars.name:Name`;
  readonly varsType = $localize`:@@vars.type:Type`;
  readonly varsScope = $localize`:@@vars.scope:Scope`;
  readonly varsDeclaration = $localize`:@@vars.declar.name:Declaration`;
  readonly varsModification = $localize`:@@vars.mods:Modification`;
  readonly varsReferences = $localize`:@@vars.refs:References`;
  readonly varsDependencies = $localize`:@@vars.deps:Dependencies`;
  readonly varsHighlightDec = $localize`:@@vars.highlight.dec:Highlight variable declaration`;
  readonly varsHighlightMod = $localize`:@@vars.highlight.mod:Highlight variable modifications`;
  readonly varsHighlightRef = $localize`:@@vars.highlight.ref:Highlight variable references`;
  readonly varsHighlightDep = $localize`:@@vars.highlight.dep:Highlight variable dependencies`;

  //paging text
  readonly pagingItem = $localize`:@@pag.item:item`;
  readonly pagingItems = $localize`:@@pag.items:items`;
  readonly pagingGroup = $localize`:@@pag.group:group`;
  readonly pagingGroups = $localize`:@@pag.groups:groups`;
  //variable-detail-editor.component.html
  readonly vdeHighlight = $localize`:@@vde.highlight:Should contain comma separated values`;

  //printer.component.html
  readonly printTitle = $localize`:@@print.title:Print`;
  readonly printMsg = $localize`:@@print.msg:Select print options below, and click the Print button.`;
  readonly printShwTitle = $localize`:@@print.shw.title:Show Title`;
  readonly printShwBorder = $localize`:@@print.shw.border:Show Border`;
  readonly printShwFooter = $localize`:@@print.shw.footer:Show Footer`;
  readonly printCancelBtn = $localize`:@@print.cancel.btn:Cancel`;
  readonly printPrintLink = $localize`:@@print.print.link:Print`;

  // print.component.ts
  readonly printDescription = $localize`:@@print.description:Print`;
  readonly printDisabled = $localize`:@@print.disabled:Print Disabled`;
  readonly printButton = $localize`:@@print.button:Print`;

  //svg-tooltip.component.html
  readonly svgTltipDeclar = $localize`:@@svg.tltip.declar:Declaration`;
  readonly svgTltipMod = $localize`:@@svg.tltip.mod:Modification`;
  readonly svgTltipMods = $localize`:@@svg.tltip.mods:Modifications`;
  readonly svgTltipDep = $localize`:@@svg.tltip.dep:Dependency`;
  readonly svgTltipDeps = $localize`:@@svg.tltip.deps:Dependencies`;
  readonly svgTltipRef = $localize`:@@svg.tltip.ref:Reference`;
  readonly svgTltipRefs = $localize`:@@svg.tltip.refs:References`;
  readonly svgTltipUsage = $localize`:@@svg.tltip.usage:Usage`;
  readonly svgTltipUsages = $localize`:@@svg.tltip.usages:Usages`;

  //view-options.component.html
  readonly vwopOptionsTitle = $localize`:@@vwop.title:View Options`;
  readonly vwopOptionsBtn = $localize`:@@vwop.options.btn:View Options`;
  readonly vwopStyle = $localize`:@@vwop.style:Style:`;
  readonly vwopResetBtn = $localize`:@@vwop.reset.btn:Reset`;
  readonly vwopCancelBtn = $localize`:@@vwop.cancel.btn:Cancel`;
  readonly vwopSaveBtn = $localize`:@@vwop.save.btn:Save`;

  //down-level.component.html
  readonly downLevelBtn = $localize`:@@down.level.btn:Down`;

  //filters.component.html
  readonly filterBtn = $localize`:@@filt.filter.btn:Filters`;
  readonly filterAllBtn = $localize`:@@filt.all.btn:All`;
  readonly filterNoneBtn = $localize`:@@filt.none.btn:None`;
  readonly filterDefaultsBtn = $localize`:@@filt.defaults.btn:Defaults`;
  readonly filterCloseBtn = $localize`:@@filt.close.btn:Close`;
  readonly filterApplyBtn = $localize`:@@filt.apply.btn:Apply`;

  //filters.component.ts
  readonly filterFilters = $localize`:@@filt.filters:Filters`;
  readonly filterCompsWithLoops = $localize`:@@filt.comp.with.loops:Components with Loops`;
  readonly filterShortLoops = $localize`:@@filt.short.loops:loops`;
  readonly filterTaskLevelPara = $localize`:@@filt.task.level.para:Task Level Parallelism (TLP)`;
  readonly filterShortTLP = $localize`:@@filt.short.tlp:tlp`;
  readonly filterLargeKernels = $localize`:@@filt.large.kernels:Large Kernels`;
  readonly filterShortKernels = $localize`:@@filt.short.kernels:largekernels`;
  readonly filterNewComponents = $localize`:@@filt.new.components:New Components`;
  readonly filterShortComponents = $localize`:@@filt.short.components:New`;
  readonly filterModifiedComponents = $localize`:@@filt.modified.components:Modified Components`;
  readonly filterShortModified = $localize`:@@filt.short.modified:modified`;

  //diagram-help.component.html
  readonly diagramHelpTltip = $localize`:@@diagram.help.tltip:Help tool`;

  //help-modal.component.html
  readonly helpModalOpen = $localize`:@@hlpModal.open:This diagram should open task add dialog.`;
  readonly helpModalHierarch = $localize`:@@hlpModal.hierarch:This diagram displays a hierarchy of algorithms, which the user can inspect and decompose.`;
  readonly helpModalDesign = $localize`:@@hlpModal.design:A hierarchical graphic design tool allowing the developer to understand the underlying design features of the code. This tool also allows the developers to collaboratively extend designs, add test procedures to the designs, track completion of the design work, and attach code to the design.`;
  readonly helpModalOmniview = $localize`:@@hlpModal.omni: Omni View Shows the entire design as a single integrated graph. It allows administrators to execute regression tests and to merge and unmerge design processes.`;
  readonly helpModalOrder = $localize`:@@hlpModal.order:This diagram shows the order in which statements, instructions, and function calls are executed.`;
  readonly helpModalDemonStrat = $localize`:@@hlpModal.demonstrat:This diagram is for development/demonstration purposes only.`;
  readonly helpModalAlgo = $localize`:@@hlpModal.algo:This diagram displays a hierarchy of algorithms, which the user can inspect and decompose.`;
  readonly helpModalGesture = $localize`:@@hlpModal.gesture:Mouse Gesture`;
  readonly helpModalAction = $localize`:@@hlpModal.action:Action`;
  readonly helpModalClick = $localize`:@@hlpModal.click:Click`;
  readonly helpModalSelectInspect = $localize`:@@hlpModal.select:Select/Inspect`;
  readonly helpModalHover = $localize`:@@hlpModal.hover:Hover`;
  readonly helpModalShowInf = $localize`:@@hlpModal.show:Show Information`;
  readonly helpModalDouble = $localize`:@@hlpModal.double:Double Click`;
  readonly helpModalDecompose = $localize`:@@hlpModal.decompose:Decompose`;
  readonly helpModalRight = $localize`:@@hlpModal.right:Right Click`;
  readonly helpModalAdditional = $localize`:@@hlpModal.add:Show additional actions available for the node`;
  readonly helpModalScroll = $localize`:@@hlpModal.scroll:Scroll`;
  readonly helpModalZoom = $localize`:@@hlpModal.zoom:Zoom in/out`;
  readonly helpModalClickDrag = $localize`:@@hlpModal.click.drg:Click and Drag`;
  readonly helpModalPan = $localize`:@@hlpModal.pan:Move Diagram`;
  readonly helpModalCloseBtn = $localize`:@@hlpModal.close:Close`;
  readonly helpModalDiagramControlFlow = $localize`:@@hlpModal.title.control.flow:Control Flow`;
  readonly helpModalDiagramCobalControlFlow = $localize`:@@hlpModal.title.ccontrol.flow:Cobol Control Flow`;
  readonly helpModalDiagramCallGraph = $localize`:@@hlpModal.title.call.graph:Call Graph`;
  readonly helpModalDiagramDecomposition = $localize`:@@hlpModal.title.decomp:Decomposition`;
  readonly helpModalDiagramDesign = $localize`:@@hlpModal.title.design:Design`;
  readonly helpModalDiagramOmniview = $localize`:@@hlpModal.title.omniview:Omniview`;
  readonly helpModal = $localize`:@@hlpModal:Help`;

  //search.component.html
  readonly searchSearch = $localize`:@@srch.search:Search`;

  //select-data.component.html
  readonly selectDataSource = $localize`:@@:select.data.source:Data Source`;

  //select-function.component.html
  readonly selectFunctionAll = $localize`:@@select.function.all:All Functions`;

  //select-layout.component.html
  readonly selectLayout = $localize`:@@select.layout:Layout`;

  //select-node.component.html
  //none

  //select-renderer.component.html
  readonly selectRenderer = $localize`:@@select.renderer:Renderer Type`;
  readonly selectRendererTooltip = $localize`:@@select.renderer.tooltip:Change how the graph is rendered`;

  //up-level.component.html
  readonly upLevelBtn = $localize`:@@uplevel.btn:Up`;

  //invite-user-modal.component.html
  readonly inviteUserJoin = $localize`:@@invuser.join:Invite user to join`;
  readonly inviteUserRcpName = $localize`:@@invuser.rcp.name:Recipient Name`;
  readonly inviteUserRcpEmail = $localize`:@@invuser.rcp.email:Recipient Email`;
  readonly inviteUserUrlFormat = $localize`:@@invuser.format:Format must follow example@domain.com`;
  readonly inviteUserRole = $localize`:@@invuser.role:User Role`;
  readonly inviteUserAdmin = $localize`:@@invuser.admin:Admin`;
  readonly inviteUserDeveloper = $localize`:@@invuser:Developer`;
  readonly inviteUserCancel = $localize`:@@invuser.cancel:CANCEL`;
  readonly inviteUserInvite = $localize`:@@invuser.send:SEND INVITE`;

  //invite-user-modal.component.html
  readonly inviteUserSendError1 = $localize`:@@invuser.add.send.error1:Invitation failed to send!`;
  readonly inviteUserSendError2 = $localize`:@@invuser.add.new.send.error2:Invitation failed to send!`;
  readonly inviteUserAdminRequestError = $localize`:@@invuser.request.error:An error occurred with the request!`;
  readonly inviteUserSendSuccess = $localize`:@@invuser.send.success:Invitation has been sent!`;
  readonly inviteUserSendError3 = $localize`:@@invuser.add.send.error3:Invitation failed to send!`;

  //organization-user.component.html
  readonly orgUserUsers = $localize`:@@orgusr.users:Users`;
  readonly orgUserInvite = $localize`:@@orgusr.invite: INVITE USER`;
  readonly orgUserName = $localize`:@@orgusr.name:Username`;
  readonly orgUserActive = $localize`:@@orgusr.active:Active`;
  readonly orgUserEmail = $localize`:@@orgusr.email:Email`;
  readonly orgUserRole = $localize`:@@orgusr.usrRole: User Role`;
  readonly orgUserOrgEmail = $localize`:@@orgusr.org:Organization Email`;
  readonly orgUserActions = $localize`:@@orgusr.actions:Actions`;
  readonly orgUserResend = $localize`:@@orgusr.resend:RE-SEND`;
  readonly orgUserDelete = $localize`:@@orgusr.delete:DELETE`;

  //organization-user.component.ts
  readonly orgUserInvitationSent = $localize`:@@orgusr.invit.sent:Invitation has been sent!`;
  readonly orgUserInvitationError = $localize`:@@orgusr.invit.error:Error occurred sending invitation!`;
  readonly orgUserConfirm = $localize`:@@orgusr.confirm:Are you sure you want to delete this user?`;
  readonly orgUserDeleteBtn = $localize`:@@orgusr.delete.btn:DELETE`;
  readonly orgUserRemoveSuccess = $localize`:@@orgusr.remvoe.success:User has been removed from organization!`;
  readonly orgUserRemoveError = $localize`:@@orgusr.remove.error:Error occurred removing user from organization!`;

  //organization-and-user-pane.component.html
  readonly orgAndUserStatus = $localize`:@@orgnusr.status:User Status`;

  //profile-editor-modal.component.html
  readonly profEditTitle = $localize`:@@prfed.title:Edit Profile`;
  readonly profEditUsername = $localize`:@@prfed.uname:Username`;
  readonly profEditUserNameMsg = $localize`:@@prfed.uname.msg:Must be greater than 3 characters and cannot contain '#'`;
  readonly profEditSecQuestion = $localize`:@@prfed.sec.quest:Security Question`;
  readonly profEditSecAnswer = $localize`:@@prfed.sec.answ:Security Answer`;
  readonly profEditSecQuestionMsg = $localize`:@@prfed.secans.msg:Must be greater than 3 characters`;
  readonly profEditPassword = $localize`:@@prfed.password:Password`;
  readonly profEditEditPassword = $localize`:@@prfed.ed.password:Must be greater than 4 characters`;
  readonly profEditRepeatPassword = $localize`:@@prfed.rep.passwrd:Repeat Password`;
  readonly profEditPasswordMsg = $localize`:@@prfed.passwrd.match:Passwords do not match`;
  readonly profEditCancel = $localize`:@@prfed.cancel:CANCEL`;
  readonly profEditSave = $localize`:@@prfed.save:SAVE`;

  //user-information-pane.component.html
  readonly userInformProfile = $localize`:@@usrinf.profile:Profile`;
  readonly userInformUser = $localize`:@@usrinf.user:User`;
  readonly userInformStatus = $localize`:@@usrinf.status:Status`;
  readonly userInformActive = $localize`:@@usrinf.active:Active`;
  readonly userInformNotActive = $localize`:@@usrinf.nactive:Not Active`;

  //user-profile-editor.component.html
  readonly userProfEdit = $localize`:@@uprfed.profile:Edit Profile`;
  readonly userProfEditName = $localize`:@@uprfed.name:Username`;
  readonly userProfEditNameMsg = $localize`:@@uprfed.name.msg: Must be greater than 3 characters and cannot contain '#'`;
  readonly userProfEditSecQues = $localize`:@@uprfed.sec.ques:Security Question`;
  readonly userProfEditSecAns = $localize`:@@uprfed.sec.ans:Security Answer`;
  readonly userProfEditSecMsg = $localize`:@@uprfed.sec.msg:Must be greater than 3 characters`;
  readonly userProfEditCancel = $localize`:@@uprfed.cancel:CANCEL`;
  readonly userProfEditSave = $localize`:@@uprfed.save:SAVE`;
  readonly userProfEditChangePwd = $localize`:@@uprfed.chng.pwd:Change Password`;
  readonly userProfEditPassword = $localize`:@@uprfed.pwd:Password`;
  readonly userProfEditChngPwdMsg = $localize`:@@uprfed.pwd.msg:Must be greater than 4 characters`;
  readonly userProfEditRptPwd = $localize`:@@uprfed.rpt.pwd:Repeat Password`;
  readonly userProfEditRptMsg = $localize`:@@uprfed.rpt.msg:Passwords do not match`;
  readonly userProfEditPwdCancel = $localize`:@@uprfed.pwd.cancel:CANCEL`;
  readonly userProfEditPwdSave = $localize`:@@uprfed.pwd.save:SAVE`;

  //user-session-details-pane.component.html
  readonly userSessionTitle = $localize`:@@usrses.title:User Sessions`;
  readonly userSessionId = $localize`:@@usrses.id:Session Id`;
  readonly userSessionStart = $localize`:@@usrses.start: Start Time`;
  readonly userSessionEnd = $localize`:@@usrses.end:End Time`;
  readonly userSessionType = $localize`:@@usrses.type:Type`;
  readonly userSessionEventEnded = $localize`:@@usrses.event.ended:Ended By User`;
  readonly userSessionEventExpire = $localize`:@@usrses.event.expire:Expired`;
  readonly userSessionListError = $localize`:@@usrses.session.list.error:Failed to get list of sessions!`;
  //information.component.html
  //none

  //user-log.component.html
  readonly userLogTitle = $localize`:@@usrlog.title:Activity Log`;
  readonly userLogSearchLog = $localize`:@@usrlog.title.srch:Search Log Text`;
  readonly userLogFromDate = $localize`:@@usrlog.from:Select From Date`;
  readonly userLogToDate = $localize`:@@usrlog.to:Select To Date`;
  readonly userLogDateReq = $localize`:@@usrlog.date:Date is required`;
  readonly userLogApply = $localize`:@@usrlog.apply:APPLY`;
  readonly userLogDateTime = $localize`:@@usrlog.date.time:Date & Time`;
  readonly userLogLevel = $localize`:@@usrlog.level:Level`;
  readonly userLogContent = $localize`:@@usrlog.content:Content`;
  readonly userLogCategory = $localize`:@@usrlog.category:Category`;
  readonly userLogUser = $localize`:@@usrlog.user:User`;
  readonly userLogEventId = $localize`:@@usrlog.eventid:Event Id :`;
  readonly userLogDetails = $localize`:@@usrlog.details:Details :`;
  readonly userLogNone = $localize`:@@usrlog.none:None`;

  //code-create.component.html
  readonly codeCreateFileSrc = $localize`:@@codecrt.file.src:File Source`;
  readonly codeCreateLocalSys = $localize`:@@codecrt.local:Local System`;
  readonly codeCreateRemRep = $localize`:@@codecrt.remote:Remote Repository`;
  readonly codeCreateLanguage = $localize`:@@codecrt.lang:Language`;
  readonly codeCreateRemoveAll = $localize`:@@codecrt.remove:Remove All`;
  readonly codeCreateFileName = $localize`:@@codecrt.fileNm:File Name`;
  readonly codeCreateFileSize = $localize`:@@codecrt.file:File Size (KB)`;
  readonly codeCreateActions = $localize`:@@codecrt.actions: Actions`;

  //extract-status-modal.component.html
  readonly extractStatusTitle = $localize`:@@extstatus.title:Extraction Status`;
  readonly extractStatusTime = $localize`:@@extstatus.time: Time elapsed`;
  readonly extractStatusStatus = $localize`:@@extstatus.status:Status`;
  readonly extractStatusShowBtn = $localize`:@@extstatus.show:Show`;
  readonly extractStatusCloseBtn = $localize`:@@extstatus.close:Close`;

  //extraction-options.component.html
  readonly extractOptionsTitle = $localize`:@@extops.title:Extraction Options`;

  //change-password-pane.component.html
  readonly changePasswordEditBtn = $localize`:@@chpwd.edit.btn:Edit`;
  readonly changePasswordReset = $localize`:@@chpwd.reset:Reset Password`;
  readonly changePasswordOldPwd = $localize`:@@chpwd.old.pwd:Old Password`;
  readonly changePasswordRequired = $localize`:@@chpwd.required:Required`;
  readonly changePasswordNewPwd = $localize`:@@chpwd.new.pwd:New Password`;
  readonly changePasswordNewPwdMsg = $localize`:@@chpwd.new.pwd.msg:Must be greater than 4 characters`;
  readonly changePasswordRepeatPwd = $localize`:@@chpwd.repeat.pwd:Repeat Password`;
  readonly changePasswordNotMatchMsg = $localize`:@@chpwd.not.match.msg: Passwords do not match`;
  readonly changePasswordTwoFactor = $localize`:@@chpwd.two.factor:Two Factor Authentication`;
  readonly changePasswordEnableTwoFactor = $localize`:@@chpwd.en.two.factor:Enable Two Factor Authentication`;
  readonly changePasswordCancel = $localize`:@@chpwd.cancel:CANCEL`;
  readonly changePasswordSave = $localize`:@@chpwd.save:SAVE`;
  readonly changePasswordUpdateSuccess = $localize`:@@chpwd.update.success:User settings were updated!`;
  readonly changePasswordUpdateError = $localize`:@@chpwd.update.error:Error updating user settings!`;
  readonly changePasswordConfirmClose = $localize`:@@chpwd.confirm.close:Your changes have not been saved. Are you sure you want to cancel?`;
  readonly changePasswordFetchError = $localize`:@@chpwd.fetch.error:Error fetching current user`;
  readonly changePasswordResetPwd = $localize`:@@chpwd.reset.password:Reset Password`;
  readonly changePasswordAuthentication = $localize`:@@chpwd.authentication:Authentication`;

  //change-personal-information-pane.component.html
  readonly changePersonInfTitle = $localize`:@@chpwd.inf.title:Personal Information`;
  readonly changePersonInfEditBtn = $localize`:@@chpwd.edit:Edit`;
  readonly changePersonInfAddress1 = $localize`:@@chpwd.address1:Address Line 1`;
  readonly changePersonInfAddress2 = $localize`:@@chpwd.address2:Address Line 2`;
  readonly changePersonInfState = $localize`:@@chpwd.state:State`;
  readonly changePersonInfSelect = $localize`:@@chpwd.select:Select`;
  readonly changePersonInfZipCode = $localize`:@@chpwd.zip:Zip Code`;
  readonly changePersonInfPhone = $localize`:@@chpwd.phone:Phone Number`;
  readonly changePersonInfCancel = $localize`:@@chpwd.cancel:CANCEL`;
  readonly changePersonInfSave = $localize`:@@chpwd.save:SAVE`;
  readonly changePersonInfUpdateSuccess = $localize`:@@chpwd.info.update.success:User profile was updated!`;
  readonly changePersonInfUpdateError = $localize`:@@chpwd.info.update.error:Could not edit user profile at this time!`;
  readonly changePersonInfCloseConfirm = $localize`:@@chpwd.close.confirm:Your changes have not been saved. Are you sure you want to cancel?`;
  readonly changePersonInfCountry = $localize`:@@chpwd.country:Country`;
  //change-profile-information-pane.component.html
  readonly changeProfileTitle = $localize`:@chgprof.title:Profile`;
  readonly changeProfileEdit = $localize`:@chgprof.edit:Edit`;
  readonly changeProfileFullName = $localize`:@chgprof.full.name:User Full Name`;
  readonly changeProfileUserNameMsg = $localize`:@chgprof.uname.msg:Must be greater than 3 characters and cannot contain '#'`;
  readonly changeProfileCancelBtn = $localize`:@@chgprof.cancel:CANCEL`;
  readonly changeProfileSaveBtn = $localize`:@@chgprof.save:SAVE`;
  readonly changeProfileUpdateSuccess = $localize`:@@chgprof.update.success:User profile was updated!`;
  readonly changeProfileUpdateError = $localize`:@@chgprof.update.error:Error updating user profile`;
  readonly changeProfileCloseConfirm = $localize`:@@chgprof.close.confirm:Your changes have not been saved. Are you sure you want to cancel?`;

  //user-settings.component.html
  readonly userSettingsTitle = $localize`:@@usrset.title:Account Settings`;

  //settings.component.html
  //none

  //app-about.component.html
  readonly appAboutTooltip = $localize`:@@appabt.tooltip:About Blue Cheetah`;

  // execution-request.dialog.component.html
  readonly execProfileTitle = $localize`:@@exec.profile.title:Profiling`;
  readonly execInfoLabel = $localize`:@@exec.step.label:Execution Info`;
  readonly execDescription = $localize`:@@exec.input.desc:Description`;
  readonly execProfileToggle = $localize`:@@exec.profile.label:Enable Profile`;
  readonly execSelectLabel = $localize`:@@exec.selected.label:Selected`;
  readonly profAutoHint = $localize`:@@exec.auto.hint:Creates a set of profiles based on available TLP opportunities`;
  readonly profAutoLabel = $localize`:@@exec.auto.label:Auto`;
  readonly profManLabel = $localize`:@@exec.man.label:Manual`;
  readonly profBaseLabel = $localize`:@@exec.base.label:Baseline`;
  readonly profManHint = $localize`:@@exec.man.hint:Creates a single profile based on current TLP configuration`;
  readonly profBaseHint = $localize`:@@exec.base.hint:Sets created profile as baseline`;
  readonly execNoDetails = $localize`:@@exec.no.det:No Execution Details Found.`;
  readonly execInclUserTlp = $localize`:@@exec.include.usr.tlp:Include User TLP selections`;
  readonly execInclTlp = $localize`:@@exec.include.tlp:Include TLP selections`;
  readonly execModalClose = $localize`:@@exec.modal.close:Close`;
  readonly execStepNext = $localize`:@@exec.next.label:Next`;
  readonly execStepPrev = $localize`:@@exec.prev.label:Back`;
  readonly execParamLabel = $localize`:@@exec.param.label:Parameters`;
  readonly execResTab = $localize`:@@exec.res.tab:Result`;
  readonly execHistoryTab = $localize`:@@exec.history.tab:History`;
  readonly execStatus = $localize`:@@exec.cur.status:Current Status`;
  readonly execRunTime = $localize`:@@exec.total.time:Running Time`;
  readonly execTime = $localize`:@@exec.run.time:Execution Time`;
  readonly execInputStep = $localize`:@@exec.file.label:Inputs and Files`;
  readonly execDlFileType = $localize`:@@exec.file.type:File Type`;
  readonly execResDownload = $localize`:@@exec.res.download:Downloads`;
  readonly execDlBtnLabel = $localize`:@@exec.btn.download:DOWNLOAD`;
  readonly execSubmitBtn = $localize`:@@exec.btn.submit:Execute`;
  readonly termStatConnect = $localize`:@@term.stat.connect:Connected`;
  readonly alertNoExecDesc = $localize`:@@exec.desc.empty.error:Description field cannot be empty`;
  readonly execHistReqCol = $localize`:@@exec.hist.reqcol:Request Id`;
  readonly execHistStatCol = $localize`:@@exec.hist.statcol:Status`;
  readonly execHistSubCol = $localize`:@@exec.hist.subcol:Submitted`;
  readonly execHistTimeCol = $localize`:@@exec.hist.timecol:Execution Time`;
  readonly execHistTimeColUnits = $localize`:@@exec.hist.timecol.units: (ms)`;

  // execution-inputs-files.component.html
  readonly execOutputLabel = $localize`:@@exec.output.label:Output`;
  readonly execInputLabel = $localize`:@@exec.input.label:Input`;
  readonly execInputsLabel = $localize`:@@exec.inputs.label:Inputs`;
  readonly execIoNameCol = $localize`:@@exec.io.namecol:Name`;
  readonly execIoTypeCol = $localize`:@@exec.io.typecol:Type`;
  readonly execIoDimCol = $localize`:@@exec.io.dimcol:Dimensions`;
  readonly execIoRetCol = $localize`:@@exec.io.retcol:Variable is returned from function`;
  readonly execIoValCol = $localize`:@@exec.io.valcol:Value`;
  readonly execFileStdin = $localize`:@@exec.file.stdin.label:Stdin File`;
  readonly execFileInput = $localize`:@@exec.file.input.label:Input File`;
  readonly execFileSelect = $localize`:@@exec.file.select:Choose File`;
  readonly execFileNameCol = $localize`:@@exec.file.namecol:File Name`;
  readonly execFileSizeCol = $localize`:@@exec.file.sizecol:File Size`;
  readonly execFilePathCol = $localize`:@@exec.file.pathcol:Path`;
  readonly execFileActCol = $localize`:@@exec.file.actcol:Action`;
  readonly execFileSelEmpty = $localize`:@@exec.file.empty:No file selected`;
  readonly execProfDesc = $localize`:@@exec.prof.desc:Profile Description`;
  readonly execFileNoParams = $localize`:@@exec.file.no.params: No parameters available`;
  readonly execFileYes = $localize`:@@exec.file.yes:Yes`;
  readonly execFileNo = $localize`:@@exec.file.no:No`;

  // execution-detail.component.html
  readonly execDetObjLabel = $localize`:@@exec.det.obj.label:Object`;
  readonly execDetSubLabel = $localize`:@@exec.det.sub.label:Submitted`;
  readonly execDetStatLabel = $localize`:@@exec.det.stat.label:Status`;
  readonly execDetInputExp = $localize`:@@exec.det.input.exp:Inputs`;
  readonly execDetConfigExp = $localize`:@@exec.det.config.exp:Inputs and Configuration`;
  readonly execDetNameCol = $localize`:@@exec.det.namecol:Name`;
  readonly execDetTypeCol = $localize`:@@exec.det.typecol:Type`;
  readonly execDetDimCol = $localize`:@@exec.det.dimcol:Dimensions`;
  readonly execDetValCol = $localize`:@@exec.det.valcol:Value`;
  readonly execDetResExp = $localize`:@@exec.det.res.exp:Result`;
  readonly execDetStatExp = $localize`:@@exec.det.stat.exp:Results and Statistics`;
  readonly execDetTimeCol = $localize`:@@exec.det.time.label:Execution Time`;
  readonly execDetStdoutExp = $localize`:@@exec.det.stdout.exp:Standard Output`;
  readonly execDetStdInExp = $localize`:@@exec.det.stdin.exp:Standard Input`;
  readonly execDetStdErrExp = $localize`:@@exec.det.stderr.exp:Standard Error`;
  readonly execDetDnlBtn = $localize`:@@exec.det.dnl.btn:Download`;
  readonly execDetTimeMS = $localize`:@@exec.det.time.ms:ms`;

  //execution-dialog.component.html
  readonly execDialogTitle = $localize`:@@exec.dialog.title:Execution Status`;
  readonly execDialogCloseBtn = $localize`:@@exec.dialog.dnl.btn:Close`;

  // project-profile.component.html
  readonly profTitleLabel = $localize`:@@profile.title.label:Profile`;
  readonly profSearchLabel = $localize`:@@profile.search.label:Search Profile Data`;
  readonly profFilterLabel = $localize`:@@profile.filter.label:Filter By Profile Type`;
  readonly profNewBtn = $localize`:@@profile.new.btn:CREATE`;
  readonly profNewCustomBtn = $localize`:@@profile.newcustom.btn:ADD CUSTOM PROFILE`;
  readonly profListIdCol = $localize`:@@profile.list.idcol:Profile Id`;
  readonly profListDescCol = $localize`:@@profile.list.desccol:Description`;
  readonly profListTimeCol = $localize`:@@profile.list.time:Execution Time`;
  readonly profListChangeCol = $localize`:@@profile.list.change:Percent Change`;
  readonly profListTypeCol = $localize`:@@profile.list.type:Type`;
  readonly profListCoreCol = $localize`:@@profile.list.core:Cores Used`;
  readonly profListCdateCol = $localize`:@@profile.list.cdatacol:Creation Date`;
  readonly profListActiveCol = $localize`:@@profile.list.active:Active`;
  readonly profListActionCol = $localize`:@@profile.list.action:Actions`;
  readonly profSetBaselineBtn = $localize`:@@profile.set.baseline:Set Baseline`;
  readonly alertBaselineSet = $localize`:@@profile.err.set.baseline:Profile with execution time of 0.0 cannot be set as baseline.`;
  readonly profBaseline = $localize`:@@profile.baseline:Baseline`;
  readonly profTLPConfig = $localize`:@@profile.tlp.conf:TLP Configuration for Profile ID`;
  readonly profInputDiffers = $localize`:@@profile.input.differs:Input differs from selected baseline`;
  readonly profileTypeAll = $localize`:@@profile.type.all:All`;
  readonly profileTypeManual = $localize`:@@profile.type.manual:Manual`;
  readonly profileTypeAuto = $localize`:@@profile.type.auto:Auto`;
  readonly profileTypeCustom = $localize`:@@profile.type.custom:Custom`;
  readonly profUnitToggle = $localize`:@@profile.unit.toggle:Timing in seconds`;
  readonly profExecUpHelp = $localize`:@@profile.exec.up.help:Slower than baseline`;
  readonly profExecDownHelp = $localize`:@@profile.exec.down.help:Faster than baseline`;

  // project-profile-detail.component.html
  readonly profDetIdCol = $localize`:@@profile.det.id.col:TLP Group Id`;
  readonly profDetTimeCol = $localize`:@@profile.det.time.col:Execution Time`;
  readonly profDetDecompIdCol = $localize`:@@profile.det.decompcol:Decomposition Id`;
  readonly profDetTlpStatCol = $localize`:@@profile.det.tlpstatcol:TLP Status`;
  readonly profDetChangeCol = $localize`:@@profile.det.changecol:Percentage Change`;
  readonly profDetExecDet = $localize`:@@profile.det.execdet:Execution Details`;
  readonly profDetResult = $localize`:@@profile.det.result:Profile Result`;
  readonly profDetTlpOn = $localize`:@@profile.det.tlp.on:On`;
  readonly profDetTlpOff = $localize`:@@profile.det.tlp.off:Off`;
  readonly profDetNoExec = $localize`:@@profile.det.no.exec:No Execution Details Found`;
  readonly profDetNoTlp = $localize`:@@profile.det.no.tlp:No TLP Groups Found`;

  //material-dropdowns.component.html

  //confirmation-modal.component.html
  readonly confirmModalCloseBtn = $localize`:@@cnfrmmodal.close.btn:CLOSE`;
  readonly confirmModalNoBtn = $localize`:@@cnfrmmodal.no.btn:NO`;

  //coalition-status.component.html
  readonly coalStatusTitle = $localize`:@@coalstat.title:Execution Engine Status`;
  readonly coalStatusAvail = $localize`:@@coalstat.avail:Hardware Availability`;
  readonly coalStatusStatus = $localize`:@@coalstat.status:Status`;
  readonly coalStatusLabel1 = $localize`:@@coalstat.label1:Compute server information unavailable`;
  readonly coalStatusCoreServe = $localize`:@@coalstat.core.serve:Cores per Server`;
  readonly coalStatusProcServe = $localize`:@@coalstat.proc.serve:Processors per Server`;
  readonly coalStatusCoreProc = $localize`:@@coalstat.core.proc:Cores per Processor`;
  readonly coalStatusServerAvail = $localize`:@@coalstat.server.avail:Servers Available`;
  readonly coalStatusBandwidth = $localize`:@@coalstat.bandwidth:Bandwidth Availability`;
  readonly coalStatusCore = $localize`:@@coalstat.core:Core to Core`;
  readonly coalStatusProcessor = $localize`:@@coalstat.processor:Processor to Processor`;
  readonly coalStatusServer = $localize`:@@coalstat.server:Server to Server`;
  readonly coalStatusDBConnection = $localize`:@@coalstat.db.connect:Database Connection`;

  //coal-status.component.ts
  readonly coalStatusTooltip = $localize`:@@coalstat.tooltip:Execution Engine Status: `;
  readonly coalStatusTooltipPending = $localize`:@@coalstat.tooltip.pending:Pending`;
  readonly coalStatusTooltipAvail = $localize`:@@coalstat.tooltip.available:Available`;
  readonly coalStatusTooltipUnavail = $localize`:@@coalstat.tooltip.unavailable:Unavailable`;
  readonly coalStatusdbConnectedStatus = $localize`:@@coalstat.tooltip.db.connected.status:Waiting..`;

  //task-pane.component.html
  readonly taskPaneTitle = $localize`:@@taskpane.title:Assigned Task`;
  readonly taskPaneNoTask = $localize`:@@taskpane.no.tasks:No tasks assigned`;
  readonly taskPaneTask = $localize`:@@taskpane.task:Task`;
  readonly taskPaneStatus = $localize`:@@taskpane.status:Status`;
  readonly taskPaneDueDate = $localize`:@@taskpane.duedate:Due Date`;

  //task-pane.component.ts
  readonly taskPaneTaskStatusUpdateSuccess = $localize`:@@taskpane.status.update.success:Task status was updated!`;
  readonly taskPaneTaskStatusUpdateError = $localize`:@@taskpane.not.updated:Task status could not be updated!`;
  readonly taskPaneTaskGetAssignedError = $localize`:@@taskpane.get.assigned.error:Could not get tasks assigned to user!`;

  //information-component.ts
  readonly informRoutingHome = $localize`:@@informrting.title.home:Home`;
  readonly informRoutingUserStatus = $localize`:@@informrting.title.user.stat:User Status`;
  readonly informRoutingActivity = $localize`:@@informrting.title.activity:Activity`;

  //no-results.component.ts
  readonly noResultsMsg = $localize`:@@noresults.msg:No Results`;

  //site-login.service.ts
  readonly siteLoginServBack = $localize`:@@site.login.back:Back to Login`;
  readonly siteLoginServTitle = $localize`:@@site.login.title:Expired/Inactive Session`;
  readonly siteLoginServMsg1 = $localize`:@@site.login.msg1:<p>Session either expired or inactivated due to multiple logins. </p>`;
  readonly siteLoginServMsg2 = $localize`:@@site.login.msg2:Please continue in a previous active session or log in again to create new session`;

  //diagram-options.service.ts
  readonly diagOptionsServLoops = $localize`:@@diag.opts.serv.loops:Loops`;
  readonly diagOptionsServTLP = $localize`:@@diag.opts.serv.tlp:Task Level Parallelism (TLP)`;
  readonly diagOptionsServKernels = $localize`:@@diag.opts.serv.kernels:Large Kernels`;
  readonly diagOptionsServNewComp = $localize`:@@diag.opts.serv.new.comp:New Components`;
  readonly diagOptionsServModComp = $localize`:@@diag.opts.serv.mod.comp:Modified Components`;
  readonly diagOptionsServTF = $localize`:@@diag.opts.serv.tf:True/False Labels`;
  readonly diagOptionsServMap = $localize`:@@diag.opts.serv.map:Smart Map`;
  readonly diagOptionsServId = $localize`:@@diag.opts.serv.id:Show Design IDs`;

  //project.service.ts
  readonly projServiceTitle1 = $localize`:@@project.service.title1:Project Error`;
  readonly projServiceMsg1 = $localize`:@@project.service.msg1:Project contains errors which may prevent some features from working correctly.`;
  readonly projServiceBtn1 = $localize`:@@project.service.btn1:CONTINUE`;
  readonly projServiceTitle2 = $localize`:@@project.service.title2:Project Status Unknown`;
  readonly projServiceMsg2 = $localize`:@@project.service.msg2:Project status is unknown and may contain errors preventing some features from working correctly.`;
  readonly projServiceBtn2 = $localize`:@@project.service.btn2:CONTINUE`;
  readonly projServiceTitle3 = $localize`:@@project.service.title3:In Progress`;
  readonly projServiceMsg3 = $localize`:@@project.service.msg3:Project is currently being processed and is unavailable to view. Please try again when the project is ready.`;
  readonly projServiceBtn3 = $localize`:@@project.service.btn3:CLOSE`;
  readonly projServiceRejectError = $localize`:@@project.service.reject:Error fetching project with name - `;
  readonly projServiceErrorEncountered = $localize`:@@project.service.error:Error encountered `;
  //project-create.component.html
  readonly projCreateProjInfo = $localize`:@@project.crt.proj.info:Project Information`;
  readonly projCreateFileUpload = $localize`:@@project.crt.file.upload:File Upload`;
  readonly projCreateProject = $localize`:@@project.crt.project:Project - `;
  readonly projCreateSaveBtn = $localize`:@@project.crt.save.btn:SAVE`;
  readonly projCreateCreateBtn = $localize`:@@project.crt.create.btn:CREATE`;

  //project-init.component.html
  readonly projInitRandFill = $localize`:@@project.init.rand.fill:Random Fill`;
  readonly projInitReset = $localize`:@@project.init.reset:Reset`;
  readonly projInitPrjName = $localize`:@@project.init.name:Project Name`;
  readonly projInitPrjDesc = $localize`:@@project.init.desc:Project Description`;
  readonly projInitNext = $localize`:@@project.init.next:NEXT`;

  //project-init.component.ts
  readonly projInitProjectName = $localize`:@@project.init.proj.name:You must enter a project name`;
  readonly projInitExists = $localize`:@@project.init.exists:Project already exists`;
  readonly projInitProjNameLengthError = $localize`:@@project.init.proj.name.length:Project should contain more than 4 and less than 26 characters`;
  readonly projInitProjNameCharError = $localize`:@@project.init.name.char.error:Project name should contain only alphanumeric and underscore characters`;
  readonly projInitEnterDescription = $localize`:@@project.init.enter.desc:You must enter a description`;
  // readonly projInitCreation = $localize`:@@project.init.creation:Project creation started on`;
  readonly projInitCreation = 'Project creation started on';
  readonly projInitErrorCreating = $localize`:@@project.init.error.creating:Error creating project`;

  //remote-connect-form.component.html
  readonly remoteConnPrivRep = $localize`:@@remote.conn.private.rep:Private Repository`;
  readonly remoteConnProtType = $localize`:@@remote.conn.prot.type:Protocol Type`;
  readonly remoteConnSourceUrl = $localize`:@@remote.conn.source.url:Source URL`;
  readonly remoteConnUrlError1 = $localize`:@@remote.conn.error1:This field is required`;
  readonly remoteConnUrlError2 = $localize`:@@remote.conn.error2:Invalid url for selected protocol`;
  readonly remoteConnUserName = $localize`:@@remote.conn.name:User Name`;
  readonly remoteConnNameError = $localize`:@@remote.conn.name.error:This field is required`;
  readonly remoteConnPassword = $localize`:@@remote.conn.pwd:Password`;
  readonly remoteConnPwdError = $localize`:@@remote.conn.pwd.error:This field is required`;
  readonly remoteConnKey = $localize`:@@remote.conn.key:Public Key`;
  readonly remoteConnKeyError = $localize`:@@remote.conn.key.error:This field is required`;

  //timer.component.html
  //none
  //title-br.component.html
  //none
  //title-bar-fullscreen.html
  //none
  //breadcrumb2.service.ts
  readonly breadCrumbHome = $localize`:@@breadcrumb.home.title:Home`;
  readonly breadCrumbProject = $localize`:@@breadcrumb.project.title:Project`;
  readonly breadCrumbOptimization = $localize`:@@breadcrumb.opt.title:Optimization`;
  readonly breadCrumbUntitled = $localize`:@@breadcrumb.un.title:Untitled`;

  //refresh.component.ts
  readonly refreshResetButton = $localize`:@@refresh.reset.btn:Reset`;
  readonly refreshRestTooltip = $localize`:@@refresh.reset.tltip:Reset the UI (filters, view options, etc)`;

  //save.component.ts
  readonly saveExportButton = $localize`:@@save.export.btn:Export`;
  readonly saveTooltip = $localize`:@@save.tltip:Export this diagram to svg, csv, etc. formats`;

  //expand-node.component.ts
  readonly expandNodeTooltip = $localize`:@@expand.node.tltip:Expand node`;

  //expand-all-node.component.ts
  readonly expandAllNodesTooltip = $localize`:@@expand.all.node.tltip:Expand all nodes`;

  //filter.component.ts
  readonly filterTooltip = $localize`:@@filter.tltip:Filter out components by type`;
  readonly filterButton = $localize`:@@filter.btn:Filter`;

  //magnify.component.ts
  readonly magnifyTooltip = $localize`:@@magnify.tltip:Zoom in`;

  //toolbar-sidebar.component.ts
  readonly toolbarSidebarTooltip = $localize`:@@toolbar.sidebar.tltip:Toggle Sidebar`;

  //code-map.component.ts
  readonly codeMapProjectAvail = $localize`:@@code.map.project.avail:No projects with source maps found.`;
  readonly codeMapProjectFetchError = $localize`:@@code.mapproj.fetch.error:Something went wrong while fetching projects.`;
  readonly codeMapUnavailError = $localize`:@@code.map.unavail.error:Source map for project unavailable.`;
  readonly codeMapDirectHoriz = $localize`:@@code.map.direct:horizontal`;
  readonly codeMapSnackMsg1 = $localize`:@@code.map.snak.msg1:Error navigating to design`;
  readonly codeMapContentCol = $localize`:@@code.map.content.col:Content`;
  readonly codeMapObjCol = $localize`:@@code.map.obj.col:Object`;
  readonly codeMapObjSrcCol = $localize`:@@code.map.src.col:Object Source`;
  readonly codeMapSelectProject = $localize`:@@code.map.sel.proj:Select Project`;
  readonly codeMapSelectFile = $localize`:@@code.map.sel.file:Select File`;
  readonly codeMapFilter = $localize`:@@code.map.filter:Filter`;

  //http-error.service.ts
  readonly httpErrorService500 = $localize`:@@http.error.service.500:Server is not responding. Please try again later.`;
  readonly httpErrorService401 = $localize`:@@http.error.service.401:Incorrect username or password.`;
  readonly httpErrorService408 = $localize`:@@http.error.service.408:Request timed out. Please check your internet connection and try again.`;
  readonly httpErrorServiceDefault = $localize`:@@http.error.service.default:Something went wrong. Please try again later.`;

  //auth-interceptor.component.html
  readonly authInterceptor408 = $localize`:@@auth.intercept.408:Request timed out. Please try again later`;
  readonly authInterceptorDefault = $localize`:@@auth.intercept.401:Server is not responding. Please try again later`;

  //source-highlighter.component.html
  //none

  //material-dropdowns.component.html
  readonly matDropDimension = $localize`:@@mat.drop.dimension:Dimension Selector`;

  //tlp-config-editor.component.html
  readonly tlpConfigEditorGroups = $localize`:@@tlp.config.editor.groups:Edit TLP Groups`;
  readonly tlpConfigEditorGroup = $localize`:@@tlp.config.editor.group:Group`;
  readonly tlpConfigEditorReset = $localize`:@@tlp.config.editor.reset:Reset`;
  readonly tlpConfigEditorClose = $localize`:@@tlp.config.editor.close:Close`;
  readonly tlpConfigEditorSave = $localize`:@@tlp.config.editor.save:Save`;
  readonly tlpConfigEditorError1 = $localize`:@@tlp.config.editor1:Invalid TLP Configuration for Group `;
  readonly tlpConfigEditorError2 = $localize`:@@tlp.config.editor.error2:. Choose at least 2 processes (or zero) to run in parallel for each group.`;
  //readonly tlpConfigEditorError3 = $localize`:@@tlp.config.editor.error3: Choose at least 2 processes (or zero) to run in parallel for each group.`;
  readonly tlpConfigEditorError3 = $localize`:@@tlp.config.editor.error3:Unable to save - no changes have been made.`;

  //create-free-range.component.html
  readonly createFreeDialogTitle = $localize`:@@crt.free.dialog.title:Create Optimized Source Code Application`;
  readonly createFreeDialogDesc = $localize`:@@crt.free.dialog.desc:Description`;
  readonly createFreeDialogCloseBtn = $localize`:@@crt.free.dialog.close:Close`;
  readonly createFreeDialogCreateBtn = $localize`:@@crt.free.dialog.create:Create`;

  //free-range-apps.component.html
  readonly freeRangeAppsDeleteBtn = $localize`:@@free.range.apps.delete.btn:Delete`;
  readonly freeRangeAppsCreateBtn = $localize`:@@free.range.apps.create.btn:Create`;
  readonly freeRangeAppsId = $localize`:@@free.range.apps.id:Id`;
  readonly freeRangeAppsDescription = $localize`:@@free.range.apps.description:Description`;
  readonly freeRangeAppsCreated = $localize`:@@free.range.apps.created:Created`;
  readonly freeRangeAppsActions = $localize`:@@free.range.apps.actions:Actions`;
  readonly freeRangeAppsDownload = $localize`:@@free.range.apps.download:Download`;
  readonly freeRangeAppsUpload = $localize`:@@free.range.apps.upload:Upload`;
  readonly freeRangeAppsOptimize = $localize`:@@free.range.apps.optimize:Optimized Source Code Applications`;
  readonly freeRangeAppsZip = $localize`:@@free.range.apps.zip:zip`;
  readonly freeRangeAppsSnackBar1 = $localize`:@@free.range.apps.snackbar1:Failed to get list of projects!`;
  readonly freeRangeAppsSnackBar2 = $localize`:@@free.range.apps.snackbar2:Failed to get list of projects!`;
  readonly freeRangeAppsSnackBar3 = $localize`:@@free.range.apps.snackbar3:Successfully saved Optimized Source Code Application(s)!`;
  readonly freeRangeAppsSnackBar4 = $localize`:@@free.range.apps.snackbar4:Could not delete Optimized Source Code Application(s) at this time!`;
  readonly freeRangeAppsSnackBar5 = $localize`:@@free.range.apps.snackbar5:Failed to download application!`;
  readonly freeRangeAppsDeleteMsg = $localize`:@@free.range.apps.delete.msg:Are you sure you want to delete optimized source code app(s)?`;
  readonly freeRangeAppsDelete = $localize`:@@free.range.apps.delete:Delete`;
  readonly freeRangeAppsDeletedSuccess = $localize`:@@free.range.apps.delete.suc:Successfully deleted Optimized Source Code Application(s)!`;
  readonly freeRangeAppsDeleteFail = $localize`:@@free.range.apps.zdelete.fail:Could not delete Optimized Source Code Application(s) at this time!`;
  readonly freeRangeAppsRemoteError = $localize`:@@free.range.apps.remote.err:Error fetching remote for project`;
  readonly freeRangeAppsTitle = $localize`:@@free.range.apps.title:Optimized Source Code Applications`;

  //remote-push-dialog.component.html
  readonly remotePushDialogTitle = $localize`:@@remote.push.dialog.title:Upload To Remote Repository`;
  readonly remotePushDialogRemote = $localize`:@@remote.push.dialog.remote.url:Remote Url`;
  readonly remotePushDialogRequiredField = $localize`:@@remote.push.dialog.reuired:This field is required`;
  readonly remotePushDialogUrlExample = $localize`:@@remote.push.dialog.example:URL should have the format "https://example.git`;
  readonly remotePushDialogUserName = $localize`:@@remote.push.dialog.uname:User Name`;
  readonly remotePushDialogPassword = $localize`:@@remote.push.dialog.pwd:Password`;
  readonly remotePushDialogCancel = $localize`:@@remote.push.tltipmote.push.dialog.cancel:Cancel`;
  readonly remotePushDialogUpload = $localize`:@@remote.push.dialog.upload:Upload`;
  readonly remotePushDialogSuccess = $localize`:@@remote.push.dialog.success:Successfully pushed application to remote`;
  readonly remotePushDialogError = $localize`:@@remote.push.dialog.error:Error sending application to remote`;

  //print.compopnent.ts
  readonly printTltip1 = $localize`:@@print.tltip1:Print`;
  readonly printTltip2 = $localize`:@@print.tltip2:Print Disabled`;
  readonly printPrintTitle = $localize`:@@print.title:Print`;

  //mat-paginator
  readonly itemsPerPageLabel = $localize`:@@mp.items.per.page:Items per Page`;
  readonly nextPageLabel = $localize`:@@mp.next.page.label:Next page`;
  readonly previousPageLabel = $localize`:@@mp.previous.page.label:Previous page`;
  readonly firstPageLabel = $localize`:@@mp.first.page.label:First page`;
  readonly lastPageLabel = $localize`:@@mp.last.page.label:Last page`;
  readonly ofPageLabel = $localize`:@@mp.of.page.label:of`;

  //export-project-dialog.component.html
  readonly exportProjectDiagTitle = $localize`:@@exp.proj.diag.title:Project Export`;
  readonly exportProjectDiagWait = $localize`:@@exp.proj.diag.wait:Please Wait...`;
  readonly exportProjectDiagCurrentItem = $localize`:@@exp.proj.diag.time:Current Item:`;
  readonly exportProjectDiagCurrentOf = $localize`:@@exp.proj.diag.of:of`;
  readonly exportProjectDiagItems = $localize`:@@exp.proj.diag.items:items`;
  readonly exportProjectDiagCancelBtn = $localize`:@@exp.proj.diag.cancel.btn:Cancel`;
  readonly exportProjectDiagCloseBtn = $localize`:@@exp.proj.diag.close.btn:Close`;
  readonly exportProjectDiagMsg = $localize`:@@exp.proj.diag.msg:Export Complete. Your download will begin shortly.`;

  //export-project.component.html
  readonly exportProjectTitle = $localize`:@@exp.proj.title:Export Project`;

  //export-project.component.html
  readonly exportProjectDesignDiagram = $localize`:@@exp.proj.design.title:Design`;
  readonly exportProjectDesignWrkShtTitle = $localize`:@@exp.proj.wrk.sht.title:Design View`;
  readonly exportProjectDecompDiagram = $localize`:@@exp.proj.decomp.title: Decomposition `;
  readonly exportProjectDecompProcessId = $localize`:@@exp.proj.process.id: Process Id: `;
  readonly exportProjectDecomp = $localize`:@@exp.proj.decomp:Decomp `;
  readonly exportProjectComponentDiagram = $localize`:@@exp.proj.omniview.diagram:Component View`;
  readonly exportProjectComponentViewTitle = $localize`:@@exp.proj.component.view.title:Component View`;
  // readonly exportProjectComponentViewSvg = $localize`:@@exp.proj.component.view.svg:componentview`;
  readonly exportProjectCallGraphDiagram = $localize`:@@exp.proj.callgraph.diagram:Call Graph`;
  readonly exportProjectCallGraphTitle = $localize`:@@exp.proj.callgraph.title:Call Graph`;

  readonly exportProjectControlFlowDiagram = $localize`:@@exp.proj.control.flow.diagram:Control Flow`;
  readonly exportProjectControlFlowTitle = $localize`:@@exp.proj.control.flow.title:Control Flow`;
  readonly exportProjectControlFlowFile = $localize`:@@exp.proj.control.flow.file:Control Flow File:`;
  readonly exportProjectControlFlowFunction = $localize`:@@exp.proj.compcontrolflow.function:Function:`;
  readonly exportProjectControlFlowCF = $localize`:@@exp.proj.controlflow.cf:CF `;

  readonly exportDecompostionFileName = $localize`:@@exp.decomp.filename:Decomposition`;
  readonly exportCallgraphFileName = $localize`:@@exp.callgraph.filename:Callgraph`;
  readonly exportControFlowFileName = $localize`:@@exp.controlflow.filename:Controlflow`;
  readonly exportDesignFileName = $localize`:@@exp.design.filename:Design`;
  readonly exportComponentFileName = $localize`:@@exp.component.filename:Component`;

  //export-project-stepper.component.html
  readonly exportProjectStepperTitle = $localize`:@@exp.proj.stepper.title:Select Diagrams to Include`;
  readonly exportProjectStepperNext = $localize`:@@exp.proj.stepper.next:Next`;
  readonly exportProjectStepperBack = $localize`:@@exp.proj.stepper.back:Back`;
  readonly exportProjectStepperReady = $localize`:@@exp.proj.stepper.ready:Ready for Export`;
  readonly exportProjectStepperExport = $localize`:@@exp.proj.stepper.export:Export Project`;
  readonly exportProjectStepperReset = $localize`:@@exp.proj.stepper.reset:Reset`;
  readonly exportProjectStepperSelect = $localize`:@@exp.proj.stepper.select:Select Export Content`;
  readonly exportProjectStepperSelectFile = $localize`:@@exp.proj.stepper.select.file:Select Control Flow Files to include in Export`;
  readonly exportProjectStepDiagAll = $localize`:@@exp.proj.step.diag.all:All`;
  readonly exportProjectStepDiagDesign = $localize`:@@exp.proj.step.diag.design:Design`;
  readonly exportProjectStepDiagDecomp = $localize`:@@exp.proj.step.diag.decomp:Decomposition`;
  readonly exportProjectStepDiagComponent = $localize`:@@exp.proj.step.diag.component:Component View`;
  readonly exportProjectStepDiagCallGraph = $localize`:@@exp.proj.step.diag.callgraph:Call Graph`;
  readonly exportProjectStepDiagControlFlow = $localize`:@@exp.proj.step.diag.controlflow:Control Flow`;
  readonly exportProjectStepContentSvg = $localize`:@@exp.proj.step.diag.svg:Diagrams`;
  readonly exportProjectStepContentSidebar = $localize`:@@exp.proj.step.diag.sidebar:Sidebar Overview Data`;
  readonly exportProjectStepContentBoth = $localize`:@@exp.proj.step.diag.both:Both`;

  //export-project-stepper.component.ts
  readonly exportProjectStepFindProjectError = $localize`:@@exp.proj.step.find.proj.error:Unable to find project data.`;

  //file-and-function-tree.component.html
  readonly exportProjectFilenFuncAll = $localize`:@@exp.proj.file.func.all:All`;
  readonly exportProjectFilenFuncDisplayError = $localize`:@@exp.proj.file.func.display.error:Unable to display file and function tree checklist.`;

  //excel-export.service.ts
  readonly exportExcelProjectName = $localize`:@@exp.excel.project.name:Project Name:`;
  readonly exportExcelDiagramName = $localize`:@@exp.excel.diagram.name:Diagram Name:`;
  readonly exportExcelNodeName = $localize`:@@exp.excel.node.name:Node Name:`;
  readonly exportExcelFileName = $localize`:@@exp.excel.file.name:File Name:`;
  readonly exportExcelFunctionName = $localize`:@@exp.excel.function.name:Function Name:`;
  readonly exportExcelPanelName = $localize`:@@exp.excel.panel.name:Panel Name:`;
  readonly exportExcelComponentTable = $localize`:@@exp.excel.comp.table:Component Table`;
  readonly exportExcelComponentView = $localize`:@@exp.excel.comp.view:Component View`;
  readonly exportExcelYes = $localize`:@@exp.excel.yes:yes`;
  readonly exportExcelNo = $localize`:@@exp.excel.no:no`;

  readonly excelColHdCompType = $localize`:@@excel.col.comp.type:Type`;
  readonly excelColHdCompName = $localize`:@@excel.col.comp.name:Name`;
  readonly excelColHdCompId = $localize`:@@excel.col.comp.id:ID`;
  readonly excelColHdlCompLevel = $localize`:@@excel.col.comp.level:Level`;
  readonly excelColHdCompLoops = $localize`:@@excel.col.comp.loops:Loops`;
  readonly excelColHdCompTlp = $localize`:@@excel.col.comp.tlp:TLP`;

  readonly excelNodeWrkSheet = $localize`:@@excel.node.worksheet:Nodes`;
  readonly excelColHdNodeId = $localize`:@@excel.col.node.id:Id`;
  readonly excelColHdNodeType = $localize`:@@excel.col.node.type:Type`;
  readonly excelColHdNodeName = $localize`:@@excel.col.node.name:Name`;
  readonly excelColHdNodeLabel = $localize`:@@excel.col.node.label:Label`;
  readonly excelColHdNodeDesc = $localize`:@@excel.col.node.desc:Description`;
  readonly excelColHdNodex = $localize`:@@excel.col.node.x:x`;
  readonly excelColHdNodey = $localize`:@@excel.col.node.y:y`;
  readonly excelColHdNodefx = $localize`:@@excel.col.nodefx:fx`;
  readonly excelColHdNodefy = $localize`:@@excel.col.node.fy:fy`;

  readonly excelLinkWrkSheet = $localize`:@@excel.link.worksheet:Links`;
  readonly excelColHdLinkId = $localize`:@@excel.col.link.id:Id`;
  readonly excelColHdLinkType = $localize`:@@excel.col.link.type:Type`;
  readonly excelColHdLinkName = $localize`:@@excel.col.link.source:Source`;
  readonly excelColHdLinkLabel = $localize`:@@excel.col.link.target:Target`;
  readonly excelWkShtTableOfContentsGoTo = $localize`:@@excel.wk.sht.goto:Go to `;
  readonly excelWkShtTableOfContentsTitle = $localize`:@@excel.wk.sht.toc.title:Table of Contents`;
  readonly excelWkShtTOCCol1Title = $localize`:@@excel.wk.sht.toc.col1.title:Worksheet Name `;
  readonly excelWkShtTOCCol2Title = $localize`:@@excel.wk.sht.toc.col2.title:Worksheet Link`;
  readonly excelWkShtGoToTableOfContents = $localize`:@@excel.wk.sht.toc.goto:Go to Table of Contents`;

  //export-project.component.ts
  readonly excelWkShtTableOfContents = $localize`:@@excel.wk.sht.toc:Table of Contents`;
  readonly exportProjectUnableFetch = $localize`:@@export.proj.unable.fetch:Unable to fetch project info.`;
  readonly exportProjectReqDataNotReceived1 = $localize`:@@export.proj.data.not.received1:Required data not received.`;
  readonly exportProjectReqDataNotReceived2 = $localize`:@@export.proj.data.not.received2:Required data not received.`;
  readonly exportProjectNoDataReceived1 = $localize`:@@export.proj.no.data.received1:No data received.`;
  readonly exportProjectNoDataReceived2 = $localize`:@@export.proj.no.data.received2:No data received.`;

  //task-details-modal.component.html
  readonly taskDetailModalTitle = $localize`:@@task.detail.modal.title:Task Details`;
  readonly taskDetailModalAssigned = $localize`:@@task.detail.modal.assigned:Assigned on `;
  readonly taskDetailModalDesc = $localize`:@@task.detail.modal.desc:Description: `;
  readonly taskDetailModalStatus = $localize`:@@task.detail.modal.status:Status`;
  readonly taskDetailModalClose = $localize`:@@task.detail.modal.close:CLOSE`;
  readonly taskDetailModalUpdate = $localize`:@@task.detail.modal.update:UPDATE`;

  //extract-status-code.ts
  readonly extractStatusInitiate = $localize`:@@extract.status.initiate:Initiate project`;
  readonly extractStatusTransfer = $localize`:@@extract.status.transfer:Transfer data`;
  readonly extractStatusParsing = $localize`:@@extract.status.parsing:Parsing code`;
  readonly extractStatusProject = $localize`:@@extract.status.project:Project structuring`;
  readonly extractStatusCreateDesign = $localize`:@@extract.status.create.design:Creating design`;
  readonly extractStatusAnalyzing = $localize`:@@extract.status.analyz:Analyzing code`;
  readonly extractStatusCreateComp = $localize`:@@extract.status.create.comp:Creating components`;
  readonly extractStatusSavingDes = $localize`:@@extract.status.save.des:Saving design`;
  readonly extractStatusExtComplete = $localize`:@@extract.status.ext.comp:Extraction complete`;
  readonly extractStatusError = $localize`:@@extract.status.error:ERROR`;

  //extract-status-modal.component.ts
  readonly extractReqStatusInitiate = $localize`:@@extract.status.req.initiate:Initiate project`;
  readonly extractReqStatusSavingReq = $localize`:@@extract.status.req.save.req:Saving requirements`;
  readonly extractReqStatusSavingGraph = $localize`:@@extract.status.req.save.graph:Saving graph`;

  //extract-status-modal.component.ts
  readonly extractStatusModalInProgress = $localize`:@@extract.status.modal.in.prog:In Progress`;
  readonly extractStatusModaErrorOccurred = $localize`:@@extract.status.modal.error.occ:Error occurred`;

  //not-found.component.ts
  readonly notFoundErrorText = $localize`:@@not.found.error.text:Error occurred`;
  readonly notFoundErrorCode = $localize`:@@not.found.error.code:404`;

  //not-authorized.component.ts
  readonly notAuthErrorText = $localize`:@@not.auth.error.text:Sorry, but you are not authorized to access this page`;
  readonly notAuthErrorCode = $localize`:@@not.auth.error.code:403`;

  //multi-select-search.component.html
  readonly multiSelectSearchSelect = $localize`:@@multi.select.search.select:Select`;

  //rename-node.component.html
  readonly renameNodeTitle = $localize`:@@rename.node.title:Rename Element`;
  readonly renameNodeRename = $localize`:@@rename.node.renmae:Rename element`;
  readonly renameNodeTo = $localize`:@@rename.node.to:to :`;
  readonly renameNodeOk = $localize`:@@rename.nodeok:OK`;
  readonly renameNodeName = $localize`:@@rename.node.name:Name`;
  readonly renameNodeCancel = $localize`:@@rename.node.cancel:Cancel`;

  //alert-modal.component.html
  readonly alertModalYesBtn = $localize`:@@alert.modal.yes.btn:Yes`;
  readonly alertModalNoBtn = $localize`:@@alert.modal.no.btn:No`;
  readonly alertModalCancelBtn = $localize`:@@alert.modal.cancel.btn:Cancel`;

  // jpt added
  // navigation.service.ts
  readonly navServiceProjectInfo = $localize`:@@navserv.projectinfo:Project Info`;
  readonly navServiceOptimization = $localize`:@@navserv.optimization:Optimization`;
  readonly navServiceTlp = $localize`:@@navserv.tlp:Tlp`;
  readonly navServiceCode = $localize`:@@navserv.code:Code`;
  readonly navServiceMetrics = $localize`:@@navserv.metrics:Metrics`;
  readonly navServiceCallGraph = $localize`:@@navserv.callgraph:Call Graph`;
  readonly navServiceControlFlow = $localize`:@@navserv.controlflow:Control Flow`;
  readonly navServiceProjectActivity = $localize`:@@navserv.projectcctivity:Project Activity`;
  readonly navServiceVersioning = $localize`:@@navserv.versioning:Versioning`;
  readonly navServiceExport = $localize`:@@navserv.export:Export`;
  readonly navServiceExecutions = $localize`:@@navserv.executions:Executions`;
  readonly navServiceProfiles = $localize`:@@navserv.profiles:Profiles`;
  readonly navServiceTLPGroups = $localize`:@@navserv.tlpgroups:TLP Groups`;
  readonly navServiceDesign = $localize`:@@navserv.design:Design View`;
  readonly navServiceDecomposition = $localize`:@@navserv.decomposition:Decomposition View`;
  readonly navServiceComponents = $localize`:@@navserv.components:Components View`;
  readonly navServiceBCOptimizedSourceCode = $localize`:@@navserv.bcoptimizedsourcecode:BC Optimized Source Code`;

  // minimap.component.ts
  readonly minimapTurnOn = $localize`:@@minimap.turnon:Smart map turned on.`;
  readonly minimapTurnOff = $localize`:@@minimap.turnoff:Smart map turned off.`;

  // renderer-group.component.ts
  readonly rendererGroupError1 = $localize`:@@rg.error1:Unable to find renderer configuration.`;
  readonly rendererGroupError2 = $localize`:@@rg.error2:Unable to change renderer.`;
  readonly rendererGroupError3 = $localize`:@@rg.error3:Unable to find renderer.`;

  //renerere.base.ts
  readonly rendererBaseNoSave = $localize`:@@rb.no.save:Save not implemented for this renderer.`;

  // workbench and diagrams follow
  // design.component.ts
  readonly designNodeType = $localize`:@@wb.rnd.node:Node`;
  readonly designNodeTypePlural = $localize`:@@wb.rnd.nodes:Nodes`;
  readonly designName = $localize`:@@design.name:Design Node`;
  readonly designNamePlural = $localize`:@@design.nameplural:Design Nodes`;
  readonly designContext = $localize`:@@design.context:Context`;
  readonly designType = $localize`:@@design.type:Type`;
  readonly designId = $localize`:@@design.id:ID`;
  readonly designDecompId = $localize`:@@design.decompid:Decomposition ID`;
  readonly designProcessID = $localize`:@@design.processid:Process ID`;
  readonly designChildren = $localize`:@@design.children:Children`;
  readonly designDescendants = $localize`:@@design.descendants:Descendants`;
  readonly designProcesses = $localize`:@@design.processes:Processes`;
  readonly designTLPavailable = $localize`:@@design.tlpavailable:TLP available`;
  readonly designYes = $localize`:@@design.yes:Yes`;
  readonly designNo = $localize`:@@design.no:No`;
  readonly designNodeWithTlp = $localize`:@@design.nodewithtlp:Design Node with TLP`;
  readonly designNodeWithTlpDesc = $localize`:@@design.nodewithtlpdesc:A design node with opportunities for task level parallelization (TLP).`;
  readonly designNotAllowed = $localize`:@@design.notallowed:Decomposing a leaf node is not allowed.`;
  readonly designView = $localize`:@@design.view:View`;
  readonly designExplore = $localize`:@@design.explore:Explore`;
  readonly designTLPChangesSaved = $localize`:@@design.tlp.changes.saved:TLP changes saved.`;
  readonly designDesign = $localize`:@@design.design:Design`;

  readonly designNoDataReceived1 = $localize`:@@design.no.data.received1:No data received.`;
  readonly designNoDataReceived2 = $localize`:@@design.no.data.received2:No data received.`;
  readonly designObjectType = $localize`:@@design.object.type:Unable to determine object type.`;

  // decomposition.component.ts
  readonly decompName = $localize`:@@decomp.name:Decomposition`;
  readonly decompNodeType = $localize`:@@decomp.nodetype:Component`;
  // readonly decompLinkType = $localize`:@@decomp.linktype:Flow`;
  readonly decompNoData = $localize`:@@decomp.nodata:No data received.`;
  readonly decompNoDataFound = $localize`:@@decomp.nodatafound:No data found.`;
  readonly decompActionDelete = $localize`:@@decomp.actiondelete:Delete`;
  readonly decompActionRename = $localize`:@@decomp.actionrename:Rename`;
  readonly decompActionVariables = $localize`:@@decomp.actionvariables:Variables`;
  readonly decompActionPassData = $localize`:@@decomp.actionpassdata:Pass data`;
  readonly decompActionSearchAttach = $localize`:@@decomp.actionsearchattach:Search/Attach`;
  readonly decompActionLoops = $localize`:@@decomp.actionloops:Loops`;
  readonly decompActionViewSource = $localize`:@@decomp.actionviewsource:View Source`;
  readonly decompActionViewInMap = $localize`:@@decomp.actionviewinmap:View In Map`;
  readonly decompActionTestProcedures = $localize`:@@decomp.actiondtestprocedures:Test Procedures`;
  readonly decompActionGenerate = $localize`:@@decomp.actiongenerate:Generate`;
  readonly decompError1 = $localize`:@@decomp.error1:Something went wrong in fetching source.`;
  readonly decompError2 = $localize`:@@decomp.error2:Not attached to a component.`;
  readonly decompError3 = $localize`:@@decomp.error3:Components cannot be added to the context level.`;
  readonly decompError4 = $localize`:@@decomp.error4:Unable to add node due to invalid response.`;
  readonly decompError5 = $localize`:@@decomp.error5:Unable to add node - link source not found.`;
  readonly decompError6 = $localize`:@@decomp.error6:Unable to add node - link target not found.`;
  readonly decompRename = $localize`:@@decomp.rename:Rename component`;
  readonly decompErrorRenamingNode = $localize`:@@decomp.error.renaming:Error in  renaming selected Node`;

  // omniview.component.ts
  readonly componentsViewName = $localize`:@@compview.name:Components`;
  readonly componentsViewExperimental = $localize`:@@compview.experimental:Experimental`;
  readonly componentsViewxNoData = $localize`:@@compview.nodata:No data received.`;
  readonly componentsViewInvalidData = $localize`:@@compview.invaliddata:Invalid data`;
  readonly componentsViewShowLevel = $localize`:@@compview.showlevel:Show Level`;
  readonly componentsViewPrintEnabled = $localize`:@@compview.printenabled:Print`;
  readonly componentsViewPrintDisabled = $localize`:@@compview.printdisabled:Print disabled for this renderer.`;

  // call-graph.component.ts
  readonly callGraphName = $localize`:@@callgraph.name:Functional Decomposition`;
  readonly callGraphNoData = $localize`:@@callgraph.nodata:No data received.`;
  readonly callGraphControlFlow = $localize`:@@callgraph.controlflow:Control Flow`;
  readonly callGraphFunction = $localize`:@@callgraph.function:Function`;
  readonly callGraphFunctionCall = $localize`:@@callgraph.function.call:Function Call`;
  readonly callGraphFunctions = $localize`:@@callgraph.functions:Functions`;
  readonly callGraphFunctionCalls = $localize`:@@callgraph.function.calls:Function Calls`;

  // control-flow.component.ts
  readonly controlFlowName = $localize`:@@cfgraph.name:Control Flow`;
  readonly controlFlowHLFunctionCall = $localize`:@@cfgraph.hl.function.call:Highlight Function Calls`;
  readonly controlFlowInvalidParms = $localize`:@@cfgraph.invalidparms:Unable to determine diagram parameters.`;
  readonly controlFlowNoFileData = $localize`:@@cfgraph.nofiledata:No file data received.`;
  readonly controlFlowNoData = $localize`:@@cfgraph.nodata:No data received.`;
  readonly controlFlowNoFlowData = $localize`:@@cfgraph.noflowdata:No flow data received.`;
  readonly controlFlowNoCallGraphData = $localize`:@@cfgraph.nocallgraphata:No call graph data received.`;
  readonly controlFlowNoCallChain = $localize`:@@cfgraph.nocallchain:Unable to determine call chain.`;
  readonly controlFlowInvalidParams = $localize`:@@cfgraph.invalidparams:Invalid parameters.`;
  readonly controlFlowInspect = $localize`:@@cfgraph.inspect:Inspect`;
  readonly controlFlowHighlightFuncs = $localize`:@@cfgraph.highlightfuncs:Highlight Function Calls`;

  readonly controlFlowhHighlightDeclaration = $localize`:@@cfgraph.highlightdeclaration:Highlight Declaration`;
  readonly controlFlowhHighlightReferences = $localize`:@@cfgraph.highlightrefs:Highlight References`;
  readonly controlFlowhHighlightMods = $localize`:@@cfgraph.highlightmods:Highlight Modifications`;
  readonly controlFlowhHighlightDependencies = $localize`:@@cfgraph.highlightdependencies:Highlight Dependencies`;

  readonly controlFlowVariableReferenceLabel = $localize`:@@cf.variable.reference:Variable reference`;
  readonly controlFlowVariableDeclarationLabel = $localize`:@@cf.variable.declaration:Variable declaration`;
  readonly controlFlowVariableModificationLabel = $localize`:@@cf.variable.modification:Variable modification`;

  // workbench.service.ts
  readonly wbServiceKernelWithBadgesName = $localize`:@@wbs.kernelwithbadgesname:Kernel With Badges`;
  readonly wbServiceKernelWithBadgesLabel = $localize`:@@wbs.kernelwithbadgeslabel:Kernel With Badges`;
  readonly wbServiceKernelWithBadgesDesc = $localize`:@@wbs.kernelwithbadgesdesc:Some components can have circular badges on them which indicate additional functionality or information.  Hover your cursor over the individual badges to learn more.`;
  readonly wbServiceKernelName = $localize`:@@wbs.kernelname:Kernel`;
  readonly wbServiceKernelDesc = $localize`:@@wbs.kerneldesc:A kernel contains only linear code, no control statements. A kernel process is un-decomposed.`;
  readonly wbServiceAlgoName = $localize`:@@wbs.algoname:Algorithm`;
  readonly wbServiceAlgoDesc = $localize`:@@wbs.algodesc:An algorithm process is decomposable into control statement linked kernel and/or other algorithm processes.`;
  readonly wbServiceDesignNodeName = $localize`:@@wbs.designnodename:Design Node`;
  readonly wbServiceDesignNodeDesc = $localize`:@@wbs.designnodedesc:An algorithm process is decomposable into control statement linked kernel and/or other algorithm processes. The algorithms in this diagram are grouped together by levels.  Double clicking on an algorithm will show the decomposition view of the level.`;
  readonly wbServiceSystemProcessName = $localize`:@@wbs.systemprocessname:System Process`;
  readonly wbServiceSystemProcessDesc = $localize`:@@wbs.systemprocessdesc:A system process is a special function that has been written by the authors of Blue Cheetah for customer use. Typically, system processes are used to allocate and de-allocate memory.`;
  readonly wbServiceTerminatorName = $localize`:@@wbs.terminatorname:Terminator`;
  readonly wbServiceTerminatorDesc = $localize`:@@wbs.terminatordesc:A terminator represents an externally defined component attached to the current system. It can be an external computer system or, more often, a display screen.`;
  readonly wbServiceControllerName = $localize`:@@wbs.controllername:Controller`;
  readonly wbServiceControllerDesc = $localize`:@@wbs.controllerdesc:A control process is automatically generated by Blue Cheetah and represents the control logic necessary to access the processes of a decomposition level.`;
  readonly wbServiceDataStoreName = $localize`:@@wbs.datastorename:Data Store`;
  readonly wbServiceDataStoreDesc = $localize`:@@wbs.datastoredesc:This type of component is used to store variables.`;
  readonly wbServiceLocalDataStoreName = $localize`:@@wbs.localdatastorename:Local Data Store`;
  readonly wbServiceLocalDataStoreDesc = $localize`:@@wbs.localdatastoredesc:Defines a group of variables, vectors, matrices, and structures for the current decomposition level.`;
  readonly wbServiceGlobalDataStoreName = $localize`:@@wbs.globaldatastorename:Global Data Store`;
  readonly wbServiceGlobalDataStoreDesc = $localize`:@@wbs.globaldatastoredesc:Represents the use of a previously defined group of variables, vectors, matrices, and structures for the current decomposition level.  At the context (top) level it represents the definition of a group of variables, vectors, matrices, and structures.`;
  readonly wbServiceFileScopeDataStoreName = $localize`:@@wbs.filescopedatastorename:File Scope Data Store`;
  readonly wbServiceFileScopeDataStoreDesc = $localize`:@@wbs.filescopedatastoredesc:This type of data store represents the definition and utilization of a file or SQL database or NoSQL database.`;
  readonly wbServiceSharedControllerName = $localize`:@@wbs.sharedcontrollername:Shared Controller`;
  readonly wbServiceSharedControllerDesc = $localize`:@@wbs.sharedcontrollerdesc:Shared controllers are generated automatically by Blue Cheetah. They represent the control flow between decomposition levels.`;
  readonly wbServiceSharedProcessName = $localize`:@@wbs.sharedprocessname:Shared Process`;
  readonly wbServiceSharedProcessDesc = $localize`:@@wbs.sharedprocessdesc:Shared processes are generated automatically by Blue Cheetah.`;
  readonly wbServiceFunctionPointerProcessName = $localize`:@@wbs.functionpointerprocessname:Function Pointer Process`;
  readonly wbServiceFunctionPointerProcessDesc = $localize`:@@wbs.functionpointerprocessdesc:description not available`;
  readonly wbServiceControlFlowLinkName = $localize`:@@wbs.controlflowlinkname:Control Flow`;
  readonly wbServiceControlFlowLinkDesc = $localize`:@@wbs.controlflowlinkdesc:Represents the conditions under which a process is activated.`;
  readonly wbServiceDataFlowLinkName = $localize`:@@wbs.dataflowlinkname:Data Flow`;
  readonly wbServiceDataFlowLinkDesc = $localize`:@@wbs.dataflowlinkdesc:Represent the variables that move between processes, data stores, F-data stores, and terminators.`;

  // control-flow.service.ts
  // readonly cfServiceFunctionName = $localize`:@@cfs.functionname:Function`;
  readonly cfServiceFunctionName = $localize`:@@cfs.function.name:Function`;
  readonly cfServiceFunctionDesc = $localize`:@@cfs.functiondesc:Represents a function written in C.`;
  // readonly cfServiceBlockItemName = $localize`:@@cfs.blockitemname:BlockItem`;
  readonly cfServiceBlockItemName = $localize`:@@cfs.blockitem.name:Code Block`;
  readonly cfServiceBlockItemDesc = $localize`:@@cfs.blockitemdesc:A block of C code.`;
  // readonly cfServiceIfName = $localize`:@@cfs.ifname:If`;
  readonly cfServiceIfName = 'If';
  readonly cfServiceIfDesc = $localize`:@@cfs.ifdesc:A basic branching statement for C programs.`;
  // readonly cfServiceElseName = $localize`:@@cfs.elsename:Else`;
  readonly cfServiceElseName = 'Else';
  readonly cfServiceElseDesc = $localize`:@@cfs.elsedesc:An optional part to an "if" statement in C.`;
  // readonly cfServiceForName = $localize`:@@cfs.forname:For`;
  readonly cfServiceForName = 'For';
  readonly cfServiceForDesc = $localize`:@@cfs.fordesc:The beginning of a for loop. Used for iteration.`;
  // readonly cfServiceWhileName = $localize`:@@cfs.whilename:While`;
  readonly cfServiceWhileName = 'While';
  readonly cfServiceWhileDesc = $localize`:@@cfs.whiledesc:An alternative to an if statement. Also used for iteration but, unlike a for loop, the iteration condition is checked after each iteration.`;
  readonly cfServiceControlFlowName = $localize`:@@cfs.controlflowname:Control Flow`;
  readonly cfServiceControlFlowDesc = $localize`:@@cfs.controlflowdesc:Directed lines (i.e. a line with an arrow) show the flow of control between blocks`;
  readonly cfServiceControlStatementName = $localize`:@@cfs.controlstatementname:Control Statement`;
  readonly cfServiceControlStatementDesc = $localize`:@@cfs.controlstatementdesc:Determines whether other statements will be executed. The different kinds of control statements are IF, ELSE, FOR, WHILE, and CASE.`;
  readonly cfServiceMoreLines = $localize`:@@cfs.morelines:more lines`;

  // call-graph.service.ts
  readonly cgServiceFunctionName = $localize`:@@cgs.functionname:Function`;
  readonly cgServiceFunctionDesc = $localize`:@@cgs.functiondesc:Represents a function written in C.`;
  readonly cgServiceFunctionCallName = $localize`:@@cgs.functioncallname:Function call`;
  readonly cgServiceFunctionCallDesc = $localize`:@@cgs.functioncalldesc:A directed line indicates that one function calls another. The circled number shows how many times the function calls the other function.`;

  // cobol-control-flow.service.ts
  // readonly cobolcfServiceFunctionName = $localize`:@@cobolcfs.functionname:Function`;
  readonly cobolcfServiceFunctionName = 'Function';
  readonly cobolcfServiceFunctionDesc = $localize`:@@cobolcfs.functiondesc:Represents a function written in COBOL.`;
  // readonly cobolcfServiceBlockItemName = $localize`:@@cobolcfs.blockitemname:BlockItem`;
  readonly cobolcfServiceBlockItemName = 'BlockItem';
  readonly cobolcfServiceBlockItemDesc = $localize`:@@cobolcfs.blockitemdesc:A block of COBOL code.`;
  // readonly cobolcfServiceIfName = $localize`:@@cobolcfs.ifname:If`;
  readonly cobolcfServiceIfName = 'If';
  readonly cobolcfServiceIfDesc = $localize`:@@cobolcfs.ifdesc:A basic branching statement for COBOL programs.`;
  // readonly cobolcfServiceElseName = $localize`:@@cobolcfs.elsename:Else`;
  readonly cobolcfServiceElseName = 'Else';
  readonly cobolcfServiceElseDesc = $localize`:@@cobolcfs.elsedesc:An optional part to an "if" statement in COBOL.`;
  // readonly cobolcfServiceForName = $localize`:@@cobolcfs.forname:For`;
  readonly cobolcfServiceForName = 'For';
  readonly cobolcfServiceForDesc = $localize`:@@cobolcfs.fordesc:The beginning of a for loop. Used for iteration.`;
  // readonly cobolcfServiceWhileName = $localize`:@@cobolcfs.whilename:While`;
  readonly cobolcfServiceWhileName = 'While';
  readonly cobolcfServiceWhileDesc = $localize`:@@cobolcfs.whiledesc:An alternative to an if statement. Also used for iteration but, unlike a for loop, the iteration condition is checked after each iteration.`;
  readonly cobolcfServicePerformName = 'Perform';
  readonly cobolcfServicePerformDesc = $localize`:@@cobolcfs.performdesc:An alternative to an if statement. Also used for iteration but, unlike a for loop, the iteration condition is checked after each iteration.`;
  readonly cobolcfServiceEndPerformName = 'EndPerform';
  readonly cobolcfServiceEndPerformDesc = $localize`:@@cobolcfs.endperformdesc:An alternative to an if statement. Also used for iteration but, unlike a for loop, the iteration condition is checked after each iteration.`;
  readonly cobolcfServiceEndIfName = 'EndIf';
  readonly cobolcfServiceEndIfDesc = $localize`:@@cobolcfs.endifdesc:An alternative to an if statement. Also used for iteration but, unlike a for loop, the iteration condition is checked after each iteration.`;
  readonly cobolcfServiceEvaluateName = 'Evaluate';
  readonly cobolcfServiceEvaluateDesc = $localize`:@@cobolcfs.evaluatedesc:An alternative to an if statement. Also used for iteration but, unlike a for loop, the iteration condition is checked after each iteration.`;
  readonly cobolcfServiceEvaluateWhenName = 'EvaluateWhen';
  readonly cobolcfServiceEvaluateWhenDesc = $localize`:@@cobolcfs.evaluate.whendesc:An alternative to an if statement. Also used for iteration but, unlike a for loop, the iteration condition is checked after each iteration.`;
  readonly cobolcfServiceEvaluateWhenOtherName = 'EvaluateWhenOther';
  readonly cobolcfServiceEvaluateWhenOtherDesc = $localize`:@@cobolcfs.evaluate.when.other.desc: An alternative to an if statement. Also used for iteration but, unlike a for loop, the iteration condition is checked after each iteration.`;
  readonly cobolcfServiceEndEvaluateName = 'EndEvaluate';
  readonly cobolcfServiceEndEvaluateDesc = $localize`:@@cobolcfs.end.evaluate.desc:An alternative to an if statement. Also used for iteration but, unlike a for loop, the iteration condition is checked after each iteration.`;
  readonly cobolcfServiceDivisionName = 'Division';
  readonly cobolcfServiceDivisionDesc = $localize`:@@cobolcfs.division.desc:An alternative to an if statement. Also used for iteration but, unlike a for loop, the iteration condition is checked after each iteration.`;
  readonly cobolcfServiceSectionName = 'Section';
  readonly cobolcfServiceSectionDesc = $localize`:@@cobolcfs.section.desc: An alternative to an if statement. Also used for iteration but, unlike a for loop, the iteration condition is checked after each iteration.`;
  readonly cobolcfServiceParagraphName = 'Paragraph';
  readonly cobolcfServiceParagraphDesc = $localize`:@@cobolcfs.paragragh.desc: An alternative to an if statement. Also used for iteration but, unlike a for loop, the iteration condition is checked after each iteration.`;
  readonly cobolcfServiceControlFlowName = $localize`:@@cobolcfs.name:Control Flow`;
  readonly cobolcfServiceControlFlowDesc = $localize`:@@cobolcfs.desc: Directed lines (i.e. a line with an arrow) show the flow of control between blocks`;
  readonly cobolcfServiceControlStatementName = $localize`:@@cobolcfs.statement:Control Statement`;
  readonly cobolcfServiceControlStatementDesc = $localize`:@@cobolcfs.statement.name:Determines whether other statements will be executed.  The different kinds of control statements are IF, ELSE, FOR, WHILE, and CASE.`;

  // cobol-control-flow.component.ts
  readonly cobolcfParams = $localize`:@@cobolcf.params:Unabled to determine diagram parameters`;
  readonly cobolcfNoDataReceived1 = $localize`:@@cobolcf.no.data.received1:No data received.`;
  readonly cobolcfNoDataReceived2 = $localize`:@@cobolcf.no.data.received2:No data received.`;
  readonly cobolcfNoDataInvalidParams = $localize`:@@cobolcf.invalid.params:Invalid parameters.`;
  readonly cobolcfLoadError = $localize`:@@cobolcf.load.error:Unable to load control flow data.`;

  // renderers
  // svg-renderer.ts
  readonly svgRendererFlowName = $localize`:@@svgr.flowname:Flow name`;
  readonly svgRendererUntitled = $localize`:@@svgr.untitled:untitled`;
  readonly svgRendererUnitMS = $localize`:@@svgr.unit.ms:ms`;

  readonly svgRendererFunctionName = $localize`:@@svgr.functionname:Name`;
  readonly svgRendererFileName = $localize`:@@svgr.filename:File`;
  readonly svgRendererExecutionTime = $localize`:@@svgr.executiontime:Execution Time`;
  readonly svgRendererTLPEnabled = $localize`:@@svgr.tlpenabled:TLP Enabled`;
  readonly svgRendererID = $localize`:@@svgr.id:ID`;
  readonly svgRendererDescription = $localize`:@@svgr.description:Description`;
  readonly svgRendererType = $localize`:@@svgr.type:Type`;
  readonly svgRendererParameters = $localize`:@@svgr.parameters:Parameters`;

  // design-svg-renderer.component.ts
  readonly designRendererDesignNode = $localize`:@@designr.designnode:Design Node`;
  readonly designRendererID = $localize`:@@designr.id:ID`;
  readonly designRendererChildren = $localize`:@@designr.children:Children`;
  readonly designRendererDescendants = $localize`:@@designr.descendants:Descendants`;
  readonly designRendererProcesses = $localize`:@@designr.processes:Processes`;
  readonly designRendererYes = $localize`:@@designr.yes:Yes`;
  readonly designRendererNo = $localize`:@@designr.no:No`;
  readonly designRendererTLPAvailable = $localize`:@@designr.tlpavailable:TLP available`;

  // control-flow-svg-renderer.component.ts
  readonly controlFlowRendererCentered = $localize`:@@cfr.centered:Diagram centered on click.`;
  readonly controlFlowRendererSelected = $localize`:@@cfr.selected:Node selected from minimap.`;
  readonly controlFlowRendererName = $localize`:@@cfr.name:Name`;
  readonly controlFlowRendererType = $localize`:@@cfr.type:Type`;
  readonly controlFlowRendererScope = $localize`:@@cfr.scope:Scope`;
  readonly controlFlowRendererVariable = $localize`:@@cfr.variable:Variable`;
  readonly controlFlowColorful = $localize`:@@cfr.colorful:Colorful`;
  readonly controlFlowGrayscale = $localize`:@@cfr.grayscale:Grayscale`;

  // components-table-renderer.ts, .html
  readonly componentsTableNodeNotFound = $localize`:@@comptable.nodenotfound:Unable to find sorted node.`;
  readonly componentsTableJumpToPage = $localize`:@@comptable.jumptopage:Jump to page`;
  readonly componentsTableIcon = $localize`:@@comptable.icon:Icon`;
  readonly componentsTableName = $localize`:@@comptable.name:Name`;
  readonly componentsTableType = $localize`:@@comptable.type:Type`;
  readonly componentsTableId = $localize`:@@comptable.id:ID`;
  readonly componentsTableLevel = $localize`:@@comptable.level:Level`;
  readonly componentsTableLoops = $localize`:@@comptable.loops:Loops`;
  readonly componentsTableTlp = $localize`:@@comptable.tlp:TLP`;
  readonly componentsTableYes = $localize`:@@comptable.yes:Yes`;
  readonly componentsTableNo = $localize`:@@comptable.no:No`;

  // project-info.component.ts
  readonly projectInfoProjectInformation = $localize`:@@pinfo.projectinformation:Project Information`;
  readonly projectInfoName = $localize`:@@pinfo.name:Name`;
  readonly projectInfoLanguage = $localize`:@@pinfo.language:Language`;
  readonly projectInfoCreated = $localize`:@@pinfo.created:Created`;
  readonly projectInfoOwner = $localize`:@@pinfo.owner:Owner`;
  readonly projectInfoStatus = $localize`:@@pinfo.status:Status`;
  readonly projectInfoCreationMode = $localize`:@@pinfo.creationmode:Creation Mode`;
  readonly projectInfoDescription = $localize`:@@pinfo.description:Description`;
  readonly projectInfoRepository = $localize`:@@pinfo.repository:Repository`;
  readonly projectInfoNotApplicable = $localize`:@@pinfo.na:N/A (files directly uploaded)`;
  readonly projectInfoExtractionConfiguration = $localize`:@@pinfo.extractionconfiguration:Extraction Configuration`;
  readonly projectInfoExtractionType = $localize`:@@pinfo.extractiontype:Extraction Type`;
  readonly projectInfoExtractionDynamic = $localize`:@@pinfo.dynamic:Dynamic`;
  readonly projectInfoExtractionStatic = $localize`:@@pinfo.static:Static`;
  readonly projectInfoAnalysisDepth = $localize`:@@pinfo.analysisdepth:Analysis Depth`;
  readonly projectInfoTLPAlgorithms = $localize`:@@pinfo.tlpalgorithms:TLP Algorithms`;
  readonly projectInfoExtractionResults = $localize`:@@pinfo.extractionresults:Extraction Results`;
  readonly projectInfoExtractionDuration = $localize`:@@pinfo.extractionduration:Extraction Duration`;
  readonly projectInfoSeconds = $localize`:@@pinfo.seconds:seconds`;
  readonly projectInfoFunctionLineCount = $localize`:@@pinfo.functionlinecount:Function Line Count`;
  readonly projectInfoLinesOfCode = $localize`:@@pinfo.linesofcode:Lines of Code`;
  readonly projectInfoSourceFiles = $localize`:@@pinfo.sourcefiles:Source Files`;
  readonly projectInfoTlpAlgoTrue = $localize`:@@pinfo.tlpalgo.true:True`;
  readonly projectInfoTlpAlgoFalse = $localize`:@@pinfo.tlpalgo.false:False`;
  readonly projectInfoStatusCompleted = $localize`:@@pinfo.status.completed:Completed`;
  readonly projectInfoStatusCreated = $localize`:@@pinfo.status.created:Created`;
  readonly projectInfoStatusInProgress = $localize`:@@pinfo.status.inprogress:In Progress`;
  readonly projectInfoStatusUnknown = $localize`:@@pinfo.status.unknown:Unknown`;
  readonly projectInfoStatusSaved = $localize`:@@pinfo.status.saved:Saved`;
  readonly projectInfoStatusError = $localize`:@@pinfo.status.error:Error`;
  readonly projectInfoAllLevels = $localize`:@@pinfo.all.levels:All Levels`;
  readonly projectInfoFunctionLevel = $localize`:@@pinfo.function.level:Function Level`;

  // versioning.component.html
  readonly versioningVersioning = $localize`:@@versioning.versioning:Versioning`;
  readonly versioningConnection = $localize`:@@versioning.connection:Connection`;
  readonly versioningProtocolType = $localize`:@@versioning.protocoltype:Protocol Type`;
  readonly versioningSourceURL = $localize`:@@versioning.sourceurl:Source URL`;
  readonly versioningFieldRequired = $localize`:@@versioning.fieldrequired:This field is required`;
  readonly versioningInvalidURL = $localize`:@@versioning.invalidurl:Invalid url for selected protocol`;
  readonly versioningUserName = $localize`:@@versioning.username:User Name`;
  readonly versioningPassword = $localize`:@@versioning.password:Password`;
  readonly versioningPublicKey = $localize`:@@versioning.publickey:Public Key`;
  readonly versioningSave = $localize`:@@versioning.save:Save`;
  readonly versioningTestConnection = $localize`:@@versioning.testconnection:Test Connection`;

  // sequence-editor.component.html
  readonly sequenceEditorEditCall = $localize`:@@seqeditor.editcall:Edit Call`;
  readonly sequenceEditorName = $localize`:@@seqeditor.name:Name`;
  readonly sequenceEditorExecuteAfter = $localize`:@@seqeditor.executeafter:Execute After`;
  readonly sequenceEditorCondition = $localize`:@@seqeditor.condition:Condition (optional)`;
  readonly sequenceEditorClickToAdd = $localize`:@@seqeditor.clicktoadd:Click to add to condition`;
  readonly sequenceEditorSaveChanges = $localize`:@@seqeditor.savechanges:Save Changes`;
  readonly sequenceEditorNo = $localize`:@@seqeditor.no:No`;
  readonly sequenceEditorYes = $localize`:@@seqeditor.yes:Yes`;

  // no-results-card.component.html
  readonly noResultsCardNotAvailable = $localize`:@@nrcard.notavailable:not available`;
  readonly noResultsCardCreateNew = $localize`:@@nrcard.createnew:CREATE NEW`;

  // mpt-header-fullscreen.component.ts
  readonly headerFullscreenMainMenu = $localize`:@@hfs.mainmenu:Main Menu`;
  readonly headerFullscreenSecondaryMenu = $localize`:@@hfs.secondarymenu:Secondary Menu`;

  // navbar.component.html
  readonly navbarAbout = $localize`:@@nav.about:About Blue Cheetah`;
  readonly navbarMainMenu = $localize`:@@nav.mainmenu:Main Menu`;

  // project-library.component.ts
  readonly projectLibraryError1 = $localize`:@@projlib.error1:Failed to get list of projects`;
  readonly projectLibraryDeleteConfirm = $localize`:@@projlib.deleteconfirm:Are you sure you want to delete project(s)?`;
  readonly projectLibraryDeleteButton = $localize`:@@projlib.deletebutton:DELETE`;
  readonly projectLibraryDeleteSuccess = $localize`:@@projlib.deletesuccess:Successfully deleted project(s)!`;
  readonly projectLibraryDeleteFailure = $localize`:@@projlib.deletefailure:Could not delete project(s) at this time!`;
  readonly projectLibraryProjectLibrary = $localize`:@@projlib.projectlibrary:Project Library`;
  readonly projectLibraryProjects = $localize`:@@projlib.projects:Projects`;
  readonly projectLibraryTooltip = $localize`:@@projlib.tooltip:View and Manage Projects`;
  readonly projectLibraryDelete = $localize`:@@projlib.delete:DELETE`;
  readonly projectLibraryFilter = $localize`:@@projlib.filter:Filter Project Data`;
  readonly projectLibraryNewProject = $localize`:@@projlib.newproject:New Project`;
  readonly projectLibraryColumns = $localize`:@@projlib.columns:Columns`;

  readonly projectLibraryNavigate = $localize`:@@projlib.navigate:Navigate to Project Dashboard`;
  readonly projectLibraryCreationType = $localize`:@@projlib.creationtype:Creation Type`;
  readonly projectLibraryDynamicOption = $localize`:@@projlib.dynamic.option:Dynamic`;
  readonly projectLibraryStaticOption = $localize`:@@projlib.static.option:Static`;
  readonly projectLibraryAllOption = $localize`:@@projlib.all.option:All`;
  readonly projectLibraryFunctionOption = $localize`:@@projlib.function.option:Function`;

  readonly projectLibrarySelect = $localize`:@@projlib.select:Select`;
  readonly projectLibraryProjectName = $localize`:@@projlib.projectname:Project Name`;
  readonly projectLibraryDescription = $localize`:@@projlib.description:Description`;
  readonly projectLibraryLanguage = $localize`:@@projlib.language:Language`;
  readonly projectLibraryCreationDate = $localize`:@@projlib.creationdate:Creation Date`;
  readonly projectLibraryLinesOfCode = $localize`:@@projlib.linesofcode:Lines of Code`;
  readonly projectLibraryCreatedBy = $localize`:@@projlib.createdby:Created By`;
  readonly projectLibraryAnalysisType = $localize`:@@projlib.analysistype:Analysis Type`;
  readonly projectLibraryAnalysisDepth = $localize`:@@projlib.analysisdepth:Analysis Depth`;
  readonly projectLibraryTLPAlgorithms = $localize`:@@projlib.tlpalgorithms:TLP Algorithms`;
  readonly projectLibraryProjectStatus = $localize`:@@projlib.projectstatus:Project Status`;

  // choose-columns-modal.component.html
  readonly chooseColumnsChoose = $localize`:@@ccm.choose:Choose Columns`;
  readonly chooseColumnsModalReset = $localize`:@@ccm.reset:Reset`;
  readonly chooseColumnsModalClose = $localize`:@@ccm.close:Close`;
  readonly chooseColumnsModalSave = $localize`:@@ccm.save:Save`;

  readonly extractConfigStatAnalysis = $localize`:@@extract.config.stat.analysis:Static Analysis`;
  readonly extractConfigStatDesc = $localize`:@@extract.config.stat.desc:Analyzes code and builds static analysis graphs and metrics. Static analysis is the process of understanding the code structure without executing it`;
  readonly extractConfigDynAnalysis = $localize`:@@extract.config.dyn.analysis:Dynamic Analysis`;
  readonly extractConfigDynDesc = $localize`:@@extract.config.dyn.desc:Analyzes code and builds dynamic analysis graphs and metrices. This is a run time analysis which uses the proprietary parallel execution engine to explore parallel opportunities in the code`;
  readonly extractConfigTaskLevel = $localize`:@@extract.config.task.level:Generate Task Level Parallel Algorithms`;
  readonly extractConfigTaskLevelDesc = $localize`:@@extract.config.task.level.desc:When enabled generates parallel algorithms for task level parallel code sections that are identified by dynamic analysis`;
  readonly extractConfigTaskLevelDepth = $localize`:@@extract.config.task.level.depth:Control Task Level Parallel Analysis Depth`;
  readonly extractConfigTaskLevelDepthDesc = $localize`:@@extract.config.task.level.depth.desc:Control the depth upto which code blocks are broken for task level parallel analysis. Analyze Function Level - This analysis limits breaking of the code to the function level. Analyze All Levels - This is a more rigorous analysis that breaks the code to the statement level`;
  readonly extractConfigAllLevels = $localize`:@@extract.config.all.levels:Analyze All Levels`;
  readonly extractConfigFunctionLevel = $localize`:@@extract.config.function.level:Analyze Function Level`;

  // i18n-language-picker.component.hsml
  readonly langPickerLanguage = $localize`:@@langpick.lang:Language`;
  readonly langPickerSpanish = $localize`:@@langpick.spanish:Spanish`;
  readonly langPickerJapanese = $localize`:@@langpick.japanese:Japanese`;
  //readonly extractionOptions: string[] = [
  //$localize`:@@alert.modal.cancel.btn: ["Analyze Function Level", "Analyze All Levels"];

  // multi-main-modal.component.html
  readonly multiMainModalTitle = $localize`:@@mms.title:Multi-Main Selector`;
  readonly multiMainModalSelectCol = $localize`:@@mms.select.col:Select`;
  readonly multiMainModalChkFnameCol = $localize`:@@mms.select.chkfname.col:Checksum File Name`;
  readonly multiMainModalfNameCol = $localize`:@@mms.filename.col:File Name`;
  readonly multiMainModalContBtn = $localize`:@@mms.filename.cont.btn:Continue`;
  readonly multiMainModalViewSrc = $localize`:@@mms.filename.viewsrc:Select a file to view source`;
  // file-compare.component.html
  readonly fileCompTitle = $localize`:@@filecomp.title:Compare Versions`;
  readonly fileCompIdField = $localize`:@@filecomp.id.field:Id`;
  readonly fileCompDateField = $localize`:@@filecomp.date.field:Commit Date`;
  readonly fileCompAuthorField = $localize`:@@filecomp.author.field:Author`;
  readonly fileCompSameContent = $localize`:@@filecomp.samecontent:The contents are identical`;
  readonly fileCompCommitMsg = $localize`:@@filecomp.commitmsg:Commit Message`;
  readonly fileCompCurrentFile = $localize`:@@filecomp.curfile:Current`;
  readonly fileCompSelCommit = $localize`:@@filecomp.selectedcommit:Selected Commit`;
  readonly fileCompCloseBtn = $localize`:@@filecomp.close.btn:Close`;

  // file-view.component.html
  readonly fileViewTitle = $localize`:@@fileview.title:Source Code`;
  readonly fileViewCompareBtn = $localize`:@@fileview.compare.btn:COMPARE`;

  // project-profile-modal.component.html
  readonly profileModalTitle = $localize`:@@ppm.title:Project Profiles`;
  readonly profileModalCloseBtn = $localize`:@@ppm.close.btn:CLOSE`;

  // source-code-editor.component.html
  readonly codeEditorLineWrapOpt = $localize`:@@sce.linewrap.opt:Line Wrap`;
  readonly codeEditorLineWrapHint = $localize`:@@sce.linewrap.hint:Wrap Source Lines`;
  readonly codeEditorLineNumOpt = $localize`:@@sce.linenum.opt:Line Numbers`;
  readonly codeEditorLineNumHint = $localize`:@@sce.linenum.hint:Show Line Numbers`;
  readonly codeEditorHighlightOpt = $localize`:@@sce.highlight.opt:Highlight`;
  readonly codeEditorHighlightHint = $localize`:@@sce.highlight.hint:Highlight words on selection`;
  readonly codeEditorThemeHint = $localize`:@@sce.theme.hint:Select Theme`;
  readonly codeEditorThemeLabel = $localize`:@@sce.theme.label:Theme`;
  readonly codeEditorCloseBtn = $localize`:@@sce.close.btn:Close`;
  readonly codeEditorSaveBtn = $localize`:@@sce.save.btn:Save`;
  readonly codeEditorTitle = $localize`:@@sce.title:Code Editor`;

  // data-pass-editor.component.html
  readonly dataEditorTitle = $localize`:@@dpe.title:Data Pass Editor`;
  readonly dataEditorTargetField = $localize`:@@dpe.target:Target`;
  readonly dataEditorPassCol = $localize`:@@dpe.pass.col:Passed`;
  readonly dataEditorParamValCol = $localize`:@@dpe.paramval.col:Parameter Value`;
  readonly dataEditorParamCol = $localize`:@@dpe.paramname.col:Parameter Name`;
  readonly dataEditorDataTypeCol = $localize`:@@dpe.datatype.col:Data Type`;
  readonly dataEditorParamDimCol = $localize`:@@dpe.paramdim.col:Parameter Dimensions`;
  readonly dataEditorCloseBtn = $localize`:@@dpe.close.btn:Close`;
  readonly dataEditorSaveBtn = $localize`:@@dpe.save.btn:Save`;
  readonly dataEditorSourcePlaceholder = $localize`:@@dpe.source.placeholder:Source`;

  // execution-list.component.html
  readonly execListIdCol = $localize`:@@exel.id.col:Id`;
  readonly execListNameCol = $localize`:@@exel.name.col:Name`;
  readonly execListTypeCol = $localize`:@@exel.type.col:Type`;
  readonly execListStatusCol = $localize`:@@exel.stat.col:Status`;
  readonly execListDateCol = $localize`:@@exel.date.col:Execution Date`;

  //user-role-permission-table.component.html
  readonly userRolePermHeader = $localize`:@@user.role.perm.header:User Role Permissions`;
  readonly userRolePermLabel = $localize`:@@user.role.perm.label:Select a user role to view/change permissions`;
  readonly userRolePermAdmin = $localize`:@@user.role.perm.admin:Admin`;
  readonly userRolePermDev = $localize`:@@user.role.perm.dev:Developer`;
  readonly userRolePermExec = $localize`:@@user.role.perm.exec:Executive`;
  readonly userRolePermName = $localize`:@@user.role.perm.name:Name`;
  readonly userRolePermCreate = $localize`:@@user.role.perm.create:Create`;
  readonly userRolePermRead = $localize`:@@user.role.perm.read:Read`;
  readonly userRolePermUpdate = $localize`:@@user.role.perm.update:Update`;
  readonly userRolePermDelete = $localize`:@@user.role.perm.delete:Delete`;

  //users-permission-table.component.html
  readonly userPermUserName = $localize`:@@user.perm.user.name:Username`;
  readonly userPermEmail = $localize`:@@user.perm.email:Email`;
  readonly userPermRole = $localize`:@@user.perm.role:Role`;

  //manage-permissions.component.html
  readonly managePermHeader = $localize`:@@manage.perm.users:Users`;

  //add-user-to-project-modal.component.html
  readonly addUserToProjectTitle = $localize`:@@add.user.to.project.title:Add User To Project`;
  readonly addUserToProjectUser = $localize`:@@add.user.to.project.label:User`;
  readonly addUserToProjectMsg = $localize`:@@add.user.to.project.msg:All available users have been added to this project. You may want to consider inviting more users to join your organization before adding them to a project.`;
  readonly addUserToProjectCancel = $localize`:@@add.user.to.project.cancel:CANCEL`;
  readonly addUserToProjectSave = $localize`:@@add.user.to.project.save:SAVE`;

  //add-user-to-project-modal.component.html
  readonly addUserToProjectListError = $localize`:@@add.user.to.project.list.error:An error occurred trying to get list of users!`;
  readonly addUserToProjectAddSuccess = $localize`:@@add.user.to.project.add.success:User was successfully added to project!`;
  readonly addUserToProjectAddError = $localize`:@@add.user.to.project.add.error:An error occurred trying to add user!`;

  //requirement-detail.component.html
  readonly requirementDetailTitle = $localize`:@@require.detail.title:Please select a Requirement to view details`;
  readonly requirementDetailAdd = $localize`:@@require.detail.add:Add`;
  readonly requirementDetailEdit = $localize`:@@require.detail.adeditd:Edit`;
  readonly requirementDetailRemove = $localize`:@@require.detail.remove:Remove`;
  readonly requirementDetailSelect = $localize`:@@require.detail.select:Select`;
  readonly requirementDetailName = $localize`:@@require.detail.name:Name`;
  readonly requirementDetailValue = $localize`:@@require.detail.value:Value`;
  readonly requirementDetailDimensions = $localize`:@@require.detail.dim:Dimensions`;
  readonly requirementDetailDataType = $localize`:@@require.detail.data.type:Data type`;
  readonly requirementDetailSelectOp = $localize`:@@require.detail.select.op:Select`;
  readonly requirementDetailNameOp = $localize`:@@require.detail.name.op:Name`;
  readonly requirementDetailValueOp = $localize`:@@require.detail.value.op:Value`;
  readonly requirementDetailDimensionsOp = $localize`:@@require.detail.dim.op:Dimensions`;
  readonly requirementDetailDataTypeOp = $localize`:@@require.detail.data.type.op:Data type`;
  readonly requirementDetailNew = $localize`:@@require.detail.new:New`;
  readonly requirementDetailEditTp = $localize`:@@require.detail.edit.tp:Edit`;
  readonly requirementDetailRemoveTp = $localize`:@@require.detail.remove.tp:Remove`;
  readonly requirementDetailSelectTp = $localize`:@@require.detail.select.tp:Select`;
  readonly requirementDetailTestName = $localize`:@@require.detail.test.name:Test Name`;
  readonly requirementDetailTestDesc = $localize`:@@require.detail.test.desc:Test Description`;

  //requirement-tree.component.html
  readonly requirementTreeForLevel = $localize`:@@require.detail.for.level:Requirement for level - `;

  //search.componente.ts
  readonly searchCompDescription = $localize`:@@search.comp.desc:Search for nodes in the graph`;

  //counts.component.ts
  readonly countNodesLabel = $localize`:@@counts.nodes.label:Nodes`;
  readonly countLinksLabel = $localize`:@@counts.links.label:Links`;
  readonly countFunctionLabel = $localize`:@@counts.function.label:Function`;
  readonly countFunctionCallLabel = $localize`:@@counts.function.call.label:Function Call`;
  readonly countDefaultLabel = $localize`:@@counts.default.label:Default`;
  readonly countBlockItemLabel = $localize`:@@counts.block.item.label:Code Block`;
  readonly countTrueLabel = $localize`:@@counts.true.label:True`;
  readonly countFalseLabel = $localize`:@@counts.false.label:False`;
  readonly countComponentsLabel = $localize`:@@counts.comps.label:Components`;
  readonly countControllerLabel = $localize`:@@counts.controller.label:Controller`;
  readonly countFlowsLabel = $localize`:@@counts.flows.label:Flows`;
  readonly countTerminatorLabel = $localize`:@@counts.terminator.label:Terminator`;
  readonly countAlgorithmLabel = $localize`:@@counts.algorithm.label:Algorithm`;
  readonly countFileScopeDataStoreLabel = $localize`:@@counts.fscope.dstore.label:File Scope Data Store`;
  readonly countDataLabel = $localize`:@@counts.data.label:DATA`;
  readonly countDummyLabel = $localize`:@@counts.dummy.label:DUMMY`;
  readonly countDecompLabel = $localize`:@@counts.decomp.label:DECOMP`;
  readonly countControlLabel = $localize`:@@counts.control.label:Control`;
  readonly countLocalDataStoreLabel = $localize`:@@counts.local.dstore.label:Local Data Store`;
  readonly countSharedControllerLabel = $localize`:@@counts.shared.controller.label:Share Controller`;
  readonly countSystemProcessLabel = $localize`:@@counts.system.process.label:System Process`;
  readonly countKernelLabel = $localize`:@@counts.kernel.label:Kernel`;
  readonly countSharedProcessLabel = $localize`:@@counts.shared.process.label:Shared Process`;
  readonly countDesignNodesLabel = $localize`:@@counts.design.nodes.label:Design Nodes`;
  readonly countDesignNodeLabel = $localize`:@@counts.design.node.label:Design Node`;
  readonly countContainsLabel = $localize`:@@counts.contains.label:contains`;
  readonly countTLPOpportunitiesLabel = $localize`:@@counts.tlp.opporunities.label:TLP Opportunities`;
  readonly countComponentLabel = $localize`:@@counts.component.label:Component`;
  readonly countNodeLabel = $localize`:@@counts.node.label:Node`;
  readonly countFlowLabel = $localize`:@@counts.flow.label:Flow`;
  readonly countProcessLabel = $localize`:@@counts.process.label:Process`;
  readonly countDataStoreLabel = $localize`:@@counts.data.store:Data Store`;
  readonly countFDataStoreLabel = $localize`:@@counts.f.data.store.label:F Data Store`;
  readonly countConnectorLabel = $localize`:@@counts.connector.label:Connector`;
  readonly countGlobalDataStoreLabel = $localize`:@@counts.global.data.store.label:Global Data Store`;
  readonly countFunctionPointerLabel = $localize`:@@counts.function.pointer.process.label:Function Pointer Process`;

  //white-list.componnt.ts
  readonly rootButtonLabel = $localize`:@@root.button.label:Root`;
  readonly rootButtonTooltip = $localize`:@@root.button.tooltip:Click to clear the root and show all components in the project. To root this diagram at a specific design level, use the design diagram, right click on a node and select "Explore".`;

  //navbar-menu.ts
  readonly navbarMenuProjectLibrary = $localize`:@@navbar.menu.project.library:Project Library`;
  readonly navbarMenuComponentLibrary = $localize`:@@navbar.menu.component.library:Component Library`;
  readonly navbarMenuGanttCharts = $localize`:@@navbar.menu.gantt.charts:Gantt Charts`;
  readonly navbarMenuUserStatus = $localize`:@@navbar.menu.user.status:User Status`;
  readonly navbarMenuAccountSettings = $localize`:@@navbar.menu.account.settings:Account Settings`;
  readonly navbarMenuActivityLog = $localize`:@@navbar.menu.activity.log:Activity Log`;
  readonly navbarMenuDebug = $localize`:@@navbar.menu.debug:Debug`;
  readonly navbarMenuToggleFullscreen = $localize`:@@navbar.menu.toggle.fullscreen:Toggle Fullscreen`;
  readonly navbarMenuLogout = $localize`:@@navbar.menu.logout:Logout`;

  //down-level.component.html
  readonly downLevelTooltip = $localize`:@@down.level.tooltip:Go down levels`;

  //up-level.component.html
  readonly upLevelTooltip = $localize`:@@up.level.tooltip:Go up levels`;

  //object-editor.component.ts
  readonly objEditorFormError = $localize`:@@ob.editor.form.error:You must enter a value`;
  readonly objEditorUpdateSuccessful = $localize`:@@ob.editor.form.update.success:Object updated successfully!`;
  readonly objEditorUpdateError = $localize`:@@ob.editor.form.update.error:Error updating object!`;
  readonly objEditorAddSuccessful = $localize`:@@ob.editor.form.add.success:Object added successfully!`;
  readonly objEditorAddError = $localize`:@@ob.editor.form.add.error:Error adding object!`;
  readonly objEditorDeleteSuccessful = $localize`:@@ob.editor.form.delete.success:Object deleted successfully!`;
  readonly objEditorDeleteError = $localize`:@@ob.editor.form.delete.error:Error deleting object!`;
  readonly objEditorSourceCodeError = $localize`:@@ob.editor.source.code.error:Source code cannot be empty!`;

  //file-manage-dialog.component.ts
  readonly fileManageSaveSuccessful = $localize`:@@file.manage.save.success:Changes saved successfully`;
  readonly fileManageSaveError = $localize`:@@file.manage.save.error:Error saving one or more files`;

  //create-free-range-app-dialog.component.html
  readonly createFreeRangeError = $localize`:@@create.free.range.error:Error creating application.`;
  readonly createFreeRangeEmptyError = $localize`:@@create.free.range.empty.error:Cannot be empty`;
  readonly createFreeRangeCharError = $localize`:@@create.free.range.char.error:Must be greater than 4 characters`;
  readonly createFreeRangeDescription = $localize`:@@create.free.range.desc:Description`;

  //file-view.component.ts
  readonly fileViewFetchingError = $localize`:@@file.view.fetch.error:An error occurred while fetching project files`;
  readonly fileViewFetchingCommitError = $localize`:@@file.view.fetch.commit.error:An error occurred while fetching commits for the project`;

  //project-profile.component.ts
  readonly projectProfileDeleteSuccess = $localize`:@@project.profile.delete.success:Deleted profile successfully`;
  readonly projectProfileDeleteError = $localize`:@@project.profile.delete.error:Error deleting profile`;
  readonly projectProfileAppliedSuccess = $localize`:@@project.profile.applied.success:Profile applied successfully!`;
  readonly projectProfileActivateError = $localize`:@@project.profile.activate.error:Error activating profile`;
  readonly projectProfileDeactivateSuccess = $localize`:@@project.profile.deactivate.success:'Profile deactivated successfully!`;
  readonly projectProfileDeactivateError = $localize`:@@project.profile.deactivate.error:Error deactivating profile`;
  readonly projectProfileCreateSuccess = $localize`:@@project.profile.create.success:Profile complete!`;
  readonly projectProfileCreateError = $localize`:@@project.profile.create.error:Error creating profile!`;
  readonly projectProfileBaselineError = $localize`:@@project.profile.baseline.error:Error setting baseline`;
  readonly projectProfileCustomProfileSuccess = $localize`:@@alert.custom.profile.success:Custom profile created successfully.`;

  //execution-request-dialog.component.ts
  readonly execRequestExecError = $localize`:@@exec.request.exec.error:Error occurred during execution`;
  readonly execRequestExecObjError = $localize`:@@exec.request.exec.obj.error:Error executing object. Try again later.`;
  readonly execRequestFilesUnavailError = $localize`:@@exec.request.files.unavail.error:Files are unavailable for download`;
  readonly execRequestFileDownloadError = $localize`:@@exec.request.file.download.error:Error occurred while downloading files`;
  readonly execRequestErrorOccurred = $localize`:@@exec.request.error:Error occurred during execution`;

  //execution-request-dialog.component.ts
  readonly execRequestOptionFiles = $localize`:@@exec.request.option.files:Input & Output`;
  readonly execRequestOptionsStdin = $localize`:@@exec.request.option.stdin:Standard Input Stream`;
  readonly execRequestOptionsStdOut = $localize`:@@exec.request.option.stdout:Standard Output Stream`;
  readonly execRequestOptionsStdErr = $localize`:@@exec.request.option.stderror:Standard Error Stream`;
  readonly execRequestNotStarted = $localize`:@@exec.request.option.not.started:Not Started`;

  //execution status
  readonly execStatusNotStarted = $localize`:@@exec.status.option.not.started:NOT STARTED`;
  readonly execStatusExecuting = $localize`:@@exec.status.option.executing:EXECUTING`;
  readonly execStatusCompleted = $localize`:@@exec.status.option.completed:COMPLETED`;
  readonly execStatusFailed = $localize`:@@exec.status.option.failed:FAILED`;
  readonly execStatusCancelled = $localize`:@@exec.status.option.cancelled:CANCELLED`;

  //activate-account.component.ts
  readonly activateAccountSuccess = $localize`:@@activate.account.success:Activation complete!`;

  //canvas2d-renderer.ts
  readonly canvas2dRenderNodeId = $localize`:@@canvase2d.renderer.node.id:ID`;
  readonly canvas2dRenderNodeType = $localize`:@@canvase2d.renderer.node.type:Type`;

  //build-info.component.ts
  readonly buildInfoCreated = $localize`:@@build.info.created:Created`;
  readonly buildInfoProject = $localize`:@@build.info.project:Project`;
  readonly buildInfoBranch = $localize`:@@build.info.branch:Branch`;
  readonly buildInfoRevision = $localize`:@@build.info.revision:Revision`;
  readonly buildInfoError = $localize`:@@build.info.error:Could not get build version!`;
  readonly buildInfoAbout = $localize`:@@build.info.about:About`;

  //build-version-modal.component
  readonly buildVersionTitle = $localize`:@@build.version.title:Build Version`;
  readonly buildVersionCreated = $localize`:@@build.version.timestamp:Timestamp`;
  readonly buildVersionoProject = $localize`:@@build.version.project:Project`;
  readonly buildVersionBranch = $localize`:@@build.version.branch:Branch`;
  readonly buildVersionRevision = $localize`:@@build.version.revision:Revision`;
  readonly buildVersionClose = $localize`:@@build.version.close:Close`;
  readonly buildVersionError = $localize`:@@build.version.error:Could not get build version!`;

  //category-list.component.html
  readonly categoryListFilterPH = $localize`:@@category.list.filter:Filter By Name`;
  readonly categoryListCreate = $localize`:@@category.list.create:CREATE`;
  readonly categoryListManageFiles = $localize`:@@category.list.manage.files:MANAGE FILES`;
  readonly categoryListViewComponents = $localize`:@@category.list.view.components:VIEW COMPONENTS`;
  readonly categoryListSelect = $localize`:@@category.list.select:Select`;
  readonly categoryListCategoryName = $localize`:@@category.list.cat.name: Category Name`;
  readonly categoryListCateogoryTags = $localize`:@@category.list.cat.tags:Category Tags`;
  readonly categoryListCreateTime = $localize`:@@category.list.create.time:Creation Date and Time`;
  readonly categoryListActions = $localize`:@@category.list.actions:Actions`;
  readonly categoryListManage = $localize`:@@category.list.manage:MANAGE`;

  //component-library.component.html
  readonly componentLibraryWorkspace = $localize`:@@component.lib.workspace:Workspace`;
  readonly componentLibraryCategory = $localize`:@@component.lib.category:Category`;
  readonly componentLibraryViewCategory = $localize`:@@component.lib.view.category:VIEW CATEGORY`;
  readonly componentLibraryExecStat = $localize`:@@component.lib.exec.stat:EXECUTION STATUS`;
  readonly componentLibraryCompType = $localize`:@@component.lib.comp.type:Component Type`;
  readonly componentLibraryFilterName = $localize`:@@component.lib.filter.name:Filter By Name`;
  readonly componentLibraryCreate = $localize`:@@component.lib.create:CREATE`;
  readonly componentLibraryTest = $localize`:@@component.lib.test:TEST`;
  readonly componentLibraryExecute = $localize`:@@component.lib.exec:EXECUTE`;
  readonly componentLibraryProfile = $localize`:@@component.lib.profile:PROFILE`;
  readonly componentLibraryExecProfile = $localize`:@@component.lib.exec.profile:EXECUTE PROFILE`;
  readonly componentLibrarySelect = $localize`:@@component.lib.select:Select`;
  readonly componentLibraryName = $localize`:@@component.lib.name:Component Name`;
  readonly componentLibraryType = $localize`:@@component.lib.type:Component Type`;
  readonly componentLibraryDesc = $localize`:@@component.lib.desc:Component Description`;
  readonly componentLibraryInputParams = $localize`:@@component.lib.input.params:Input Parameters`;
  readonly componentLibraryOutputParams = $localize`:@@component.lib.out.params:Output Parameters`;
  readonly componentLibraryActions = $localize`:@@component.lib.actions:Actions`;
  readonly componentLibraryManage = $localize`:@@component.lib.manage:MANAGE`;

  //test-procedure-detail.component.html
  readonly testProcTestName = $localize`:@@test.proc.test.name:Test Name`;
  readonly testProcNameReq = $localize`:@@test.proc.name.req:Name is required`;
  readonly testProcDesc = $localize`:@@test.proc.desc:Test Description`;
  readonly testProcResult = $localize`:@@test.proc.result:Last Test Result`;
  readonly testProcTimestamp = $localize`:@@test.proc.timestamp:Last Test Timestamp`;
  readonly testProcRuntime = $localize`:@@test.proc.runtime:Last Test RunTime (ms)`;
  readonly testProcName = $localize`:@@test.proc.name:Name`;
  readonly testProcType = $localize`:@@test.proc.type:Type`;
  readonly testProcValue = $localize`:@@test.proc.value:Value`;
  readonly testProcDimensions = $localize`:@@test.proc.dimen:Dimensions`;
  readonly testProcIndirections = $localize`:@@test.proc.indirect:Indirections`;
  readonly testProcOutName = $localize`:@@test.proc.out.name:Name`;
  readonly testProcOutType = $localize`:@@test.proc.out.type:Type`;
  readonly testProcOutValue = $localize`:@@test.proc.out.value:Value`;
  readonly testProcOutDimensions = $localize`:@@test.proc.out.dimens:Dimensions`;
  readonly testProcOutIndirections = $localize`:@@test.proc.out.indirect:Indirections`;
  readonly testProcIsReturn = $localize`:@@test.proc.is.return:Variable is Returned`;
  readonly testProcTestResult = $localize`:@@test.proc.test.result:Test Result`;
  readonly testProcTestTime = $localize`:@@test.proc.test.time:Test Timestamp`;
  readonly testProcExecTime = $localize`:@@test.proc.exec.time:Execution Time (ms)`;
  readonly testProcPass = $localize`:@@test.proc.pass:Pass`;
  readonly testProcFail = $localize`:@@test.proc.fail:Fail`;
  readonly testProcDetails = $localize`:@@test.proc.details:Details`;
  readonly testProcInputs = $localize`:@@test.proc.inputs:Inputs`;
  readonly testProcOutputs = $localize`:@@test.proc.outputs:Outputs`;
  readonly testProcRunHistory = $localize`:@@test.proc.run.history:Run History`;

  //test-procedure.component.html
  readonly testProcedureEditor = $localize`:@@test.procedure.editor:Test Procedure Editor`;
  readonly testProcedureRun = $localize`:@@test.procedure.run:Run`;
  readonly testProcedureNew = $localize`:@@test.procedure.new:New`;
  readonly testProcedureDelete = $localize`:@@test.procedure.delete:Delete`;
  readonly testProcedureSelect = $localize`:@@test.procedure.select:Select`;
  readonly testProcedureName = $localize`:@@test.procedure.name:Test Name`;
  readonly testProcedureSave = $localize`:@@test.procedure.save:SAVE`;
  readonly testProcedureAdd = $localize`:@@test.procedure.add:ADD`;
  readonly testProcedureCancel = $localize`:@@test.procedure.cancel:CANCEL`;

  //loop.component.html
  readonly loopsTitle = $localize`:@@loops.title:Manage Loops`;
  readonly loopsDelete = $localize`:@@loops.delete:Delete`;
  readonly loopsSave = $localize`:@@loops.save:Save`;
  readonly loopsAddNew = $localize`:@@loops.add.new:+Add New`;
  readonly loopsSelect = $localize`:@@loops.select:Select`;
  readonly loopsId = $localize`:@@loops.id:Loop-ID`;
  readonly loopsClose = $localize`:@@loops.close:Close`;
  readonly loopsType = $localize`:@@loops.type:Loop Type`;
  readonly loopsStartingCondition = $localize`:@@loops.start.cond:Starting Condition`;
  readonly loopsIncrement = $localize`:@@loops.increment:Increment`;
  readonly loopsCloseTermCondition = $localize`:@@loops.term.cond:Terminating Condition`;

  //search-attach-detail.component.html
  readonly searchAttachTitle = $localize`:@@search.attach.title:Search and Attach`;
  readonly searchAttachSelectElement = $localize`:@@search.attach.selec.element:Selected Element:`;
  readonly searchAttachObject = $localize`:@@search.attach.object:Attached object:`;
  readonly searchAttachDetach = $localize`:@@search.attach.detach:Detach`;
  readonly searchAttachTestProcs = $localize`:@@search.attachtest.procs:Test Procedures`;
  readonly searchAttachAttach = $localize`:@@search.attach.att:Attach`;
  readonly searchAttachView = $localize`:@@search.attach.view:View`;
  readonly searchAttachTestProc = $localize`:@@search.attachtest.proc:TestProcedures`;
  readonly searchAttachSelect = $localize`:@@search.attach.select:Select`;
  readonly searchAttachObjName1 = $localize`:@@search.attach.obj.name1:Object Name`;
  readonly searchAttachObjName2 = $localize`:@@search.attach.obj.name2:Object Name`;
  readonly searchAttachCategory = $localize`:@@search.attach.category:Category`;
  readonly searchAttachClose = $localize`:@@search.attach.close:Close`;
  readonly searchAttachFilterByWS = $localize`:@@search.attach.filt.by.ws:Filter by Workspaces`;
  readonly searchAttachFilterByCat = $localize`:@@search.attach.filt.by.cat:Filter by Category tag`;
  readonly searchAttachNoObj = $localize`:@@search.attach.no.obj:No Object Attached`;

  //search-attach-list.component.html
  readonly searchAttListDesignElement = $localize`:@@search.att..list.design.elem:Design Element`;
  readonly searchAttListObject1 = $localize`:@@search.att.list.obj1:Attached Object`;
  readonly searchAttListObject2 = $localize`:@@search.att.list.obj2:Attached Object`;
  readonly searchAttListAttach = $localize`:@@search.att.list.attach:Attach`;

  //attach-confirm-dialog.component.html
  readonly attachConfirmModalTitle = $localize`:@@attach.confirm.title:Confirm Attach ?`;
  readonly attachConfirmModalContent = $localize`:@@attach.confirm.content:This action will change the mapping of the elements to the objects. Please review the information below before you proceed.`;
  readonly attachConfirmModalDesignElement = $localize`:@@attach.confirm.d.elem: Design Element`;
  readonly attachConfirmModalCurrentObject = $localize`:@@attach.confirm.c.att.obj:Current Attached Object`;
  readonly attachConfirmModalNewObject = $localize`:@@attach.confirm.new.obj: New Object`;
  readonly attachConfirmModalYes = $localize`:@@attach.confirm.yes:Yes`;
  readonly attachConfirmModalCancel = $localize`:@@attach.confirm.cancel:Cancel`;

  //project-management.component.html
  readonly projectManageUserAssigned = $localize`:@@project.manage.user.assigned:Users Assigned To Project`;
  readonly projectManageAddUser = $localize`:@@project.manage.add.user: ADD USER`;
  readonly projectManageEmail = $localize`:@@project.manage.email:Email`;
  readonly projectManageActions = $localize`:@@project.manage.actions:Actions`;
  readonly projectManageRemove = $localize`:@@project.manage.remove:REMOVE`;
  readonly projectManageNoUsers = $localize`:@@project.manage.no.users:There are no users assigned to this project.`;
  readonly projectManageRemoveUser = $localize`:@@project.manage.remove.user:Are you user you want to remove this user from the project?`;
  readonly projectManageRemoveBtn = $localize`:@@project.manage.remove.btn:REMOVE`;
  readonly projectManageGetListError = $localize`:@@project.manage.get.list.error:An error occurred trying to get list of user from this project!`;
  readonly projectManageUserRemoved = $localize`:@@project.manage.user.removed:User was successfuly removed from project!`;
  readonly projectManageUserRemoveError = $localize`:@@project.manage.user.removed.error:'An error occurred trying to remove user!`;

  //functional-requirements-modal.component.html
  readonly functReqModalManage = $localize`:@@func.req.modal.manage:Manage Functional Requirements`;
  readonly functReqModalAddReq = $localize`:@@func.req.modal.add:ADD REQUIREMENT`;
  readonly functReqModalAssigned1 = $localize`:@@func.req.modal.assigned1:Assigned to`;
  readonly functReqModalSave = $localize`:@@func.req.modal.save:SAVE`;
  readonly functReqModalFuncReq = $localize`:@@func.req.modal.func.req:Functional Requirement`;
  readonly functReqModalAssigned2 = $localize`:@@func.req.modal.assigned2:Assigned to`;
  readonly functReqModalActions = $localize`:@@func.req.modal.actions:Actions`;
  readonly functReqModalDelete = $localize`:@@func.req.modal.delete:DELETE`;
  readonly functReqModalEdit = $localize`:@@func.req.modal.edit:EDIT`;
  readonly functReqModalNoReq = $localize`:@@func.req.modal.no.req:There are no functional requirements.`;
  readonly functReqModalCLose = $localize`:@@func.req.modal.close:CLOSE`;
  readonly functReqModalName = $localize`:@@func.req.modal.name:Requirement name`;
  //file-manage-dialog.component.html
  readonly fileManageTitle = $localize`:@@file.manage.title:Manage Files`;
  readonly fileManageCategory = $localize`:@@file.managecategory:Category : `;
  readonly fileManageFileName1 = $localize`:@@file.managefile.name1:File Name`;
  readonly fileManageAction1 = $localize`:@@file.manage.action1:Action`;
  readonly fileManageFileName2 = $localize`:@@file.manage.file.name2:File Name`;
  readonly fileManageInMemory = $localize`:@@file.manage.in.memory:In Memory`;
  readonly fileManageAction2 = $localize`:@@file.manage.action2:Action`;
  readonly fileManageClose = $localize`:@@file.manage.close:CLOSE`;
  readonly fileManageSave = $localize`:@@file.manage.save:SAVE`;
  readonly fileManageUpload = $localize`:@@file.manage.upload:Upload`;

  //source-compare-dialog.componente.html
  readonly sourceCompareTitle = $localize`:@@source.compare.title:Source Compare`;
  readonly sourceCompareCopy = $localize`:@@source.compare.copy:Copy Component`;

  //object-editor.component.html
  readonly objectEditorTitle = $localize`:@@object.editor.title:Component Editor`;
  readonly objectEditorObjectType = $localize`:@@object.editor.obj.type:Object Type`;
  readonly objectEditorCompName = $localize`:@@object.editor.comp.name:Component Name`;
  readonly objectEditorCategory = $localize`:@@object.editor.cat:Category`;
  readonly objectEditorCompDesc = $localize`:@@object.editor.comp.desc:Component Description`;
  readonly objectEditorInput = $localize`:@@object.editor.input:Input`;
  readonly objectEditorOutput = $localize`:@@object.editor.output:Output`;
  readonly objectEditorNotSysKern = $localize`:@@object.editor.not.sys.kern:Source Code not applicable for System Kernel`;
  readonly objectEditorDelete = $localize`:@@object.editor.delete:DELETE`;
  readonly objectEditorClose = $localize`:@@object.editor.close:CLOSE`;
  readonly objectEditorSave = $localize`:@@object.editor.save:SAVE`;
  readonly objectEditorAdd = $localize`:@@object.editor.add:ADD`;
  readonly objectEditorCompInfo = $localize`:@@object.editor.comp.info:Component Info`;
  readonly objectEditorIOParams = $localize`:@@object.editor.io.params:I/O Parameters`;
  readonly objectEditorSrcCd = $localize`:@@object.editor.src.cd:Source Code`;

  //category-editor.component.html
  readonly catEditTitle1 = $localize`:@@cat.edit.title1:Add Category`;
  readonly catEditTitle2 = $localize`:@@cat.edit.title2:Edit Category`;
  readonly catEditCatName = $localize`:@@cat.edit.name:Category Name`;
  readonly catEditDesc = $localize`:@@cat.edit.desc:Category Description`;
  readonly catEditTag = $localize`:@@cat.edit.tag:Category Tag`;
  readonly catEditDelete = $localize`:@@cat.edit.delete:DELETE`;
  readonly catEditClose = $localize`:@@cat.edit.close:CLOSE`;
  readonly catEditSave = $localize`:@@cat.edit.save:SAVE`;

  //profiler.component.html
  readonly profilerTitle1 = $localize`:@@profiler.title1:Profiler`;
  readonly profilerTitle2 = $localize`:@@profiler.title2:Profile result visualization`;
  readonly profilerCol1 = $localize`:@@profiler.col1:Dataset size`;
  readonly profilerCol2 = $localize`:@@profiler.col2:Speedup`;
  readonly profilerOptsVAxis = $localize`:@@profiler.opts.vaxis:Dataset, d`;
  readonly profilerOptsHAxis = $localize`:@@profiler.opts.haxis:Speedup, S(n)`;
  readonly profilerParams = $localize`:@@profiler.params:Parameters`;
  readonly profilerSelect = $localize`:@@profiler.select:Select`;
  readonly profilerParamName = $localize`:@@profiler.param.name:Parameter Name`;
  readonly profilerType = $localize`:@@profiler.type:Type`;
  readonly profilerDims = $localize`:@@profiler.dims:Dimensions`;
  readonly profilerCut = $localize`:@@profiler.cut:Cut Factor`;
  readonly profilerValue1 = $localize`:@@profiler.val1:Value`;
  readonly profilerValue2 = $localize`:@@profiler.val2:Value`;
  readonly profilerResults = $localize`:@@profiler.results:Results`;
  readonly profilerClose = $localize`:@@profiler.close:CLOSE`;
  readonly profilerProfile = $localize`:@@profiler.profile:PROFILE`;
  readonly profilerLoadGraph = $localize`:@@profiler.load.graph:Loading Graph...`;
  readonly profilerSpeedUp = $localize`:@@profiler.speed.up:Speed-up ,`;
  readonly profilerMaxXAxis = $localize`:@@profiler.max.x:Max X-axis`;
  readonly profilerMaxYAxis = $localize`:@@profiler.max.y:Max Y-axis`;
  readonly profilerMaxValue = $localize`:@@profiler.max.value:Max variable value`;
  readonly profilerApply = $localize`:@@profiler.apply:APPLY`;

  //gantt-chart-business-requirements.component.html
  readonly ganttChartFuncReq = $localize`:@@gantt.chart.func.req:Functional Requirements`;
  readonly ganttChartManage = $localize`:@@gantt.chart.manage:MANAGE FUNCTIONAL REQUIREMENTS`;
  readonly ganttChartNotAssigned = $localize`:@@gantt.chart.not.assigned:Tasks not assigned to functional requirements`;
  readonly ganttChartNoTasks = $localize`:@@gantt.chart.no.tasks:There are no tasks to display`;
  readonly ganttChartToggle = $localize`:@@gantt.chart.toggle:toggle`;

  //gantt-chart-project.component.html
  readonly ganttChartProjGroup = $localize`:@@gantt.chart.proj.group:Group By:`;
  readonly ganttChartProjFuncReq = $localize`:@@gantt.chart.proj.func.req:FUNCTIONAL REQUIREMENTS`;
  readonly ganttChartProjReset = $localize`:@@gantt.chart.proj.reset:RESET`;
  readonly ganttChartProjManage = $localize`:@@gantt.chart.proj.manage:MANAGE FUNCTIONAL REQUIREMENTS:`;
  readonly ganttChartProjAssigned = $localize`:@@gantt.chart.proj.assigned:Assigned`;
  readonly ganttChartProjInProgress = $localize`:@@gantt.chart.proj.in.progress:In Progress`;
  readonly ganttChartProjInReview = $localize`:@@gantt.chart.proj.in.review:In Review`;
  readonly ganttChartProjCompleted = $localize`:@@gantt.chart.proj.completed:Completed`;
  readonly ganttChartProjError = $localize`:@@gantt.chart.proj.error:An error occurred getting list of developers`;
  readonly ganttChartProjSuccess = $localize`:@@gantt.chart.proj.success:Successfully added task!`;
  readonly ganttChartProjError1 = $localize`:@@gantt.chart.proj.error1:An error occurred adding task!`;
  readonly ganttChartProjSuccess1 = $localize`:@@gantt.chart.proj.success1:Successfully removed task!`;
  readonly ganttChartProjError2 = $localize`:@@gantt.chart.proj.error2:An error occurred removing task!`;
  readonly ganttChartProjSuccess2 = $localize`:@@gantt.chart.proj.success2:Successfully updated task!`;
  readonly ganttChartProjError3 = $localize`:@@gantt.chart.proj.error3:An error occurred updating task!`;
  readonly ganttChartProjAlert = $localize`:@@gantt.chart.proj.alert:Tasks can only be linked from END => START`;
  readonly ganttChartProjSuccess3 = $localize`:@@gantt.chart.proj.success3:Successfully added task dependency!`;
  readonly ganttChartProjError4 = $localize`:@@gantt.chart.proj.error4:An error occurred adding task dependency!`;
  readonly ganttChartProjSuccess4 = $localize`:@@gantt.chart.proj.success4:Successfully removed task dependency!`;
  readonly ganttChartProjError5 = $localize`:@@gantt.chart.proj.error5:An error occurred removed task dependency!`;
  readonly ganttChartProj = $localize`:@@gantt.chart.proj.error5:An error occurred removed task dependency!`;
  readonly ganttChartProjError6 = $localize`:@@gantt.chart.proj.error6:Error! The task name is required!`;
  readonly ganttChartProjError7 = $localize`:@@gantt.chart.proj.error7:Error! The task description is required!`;
  readonly ganttChartProjError8 = $localize`:@@gantt.chart.proj.error8:Error! The task burn rate is required!`;
  readonly ganttChartProjError9 = $localize`:@@gantt.chart.proj.error9:Error! Only numbers are accepted for burn rate!`;
  readonly ganttChartProjError10 = $localize`:@@gantt.chart.proj.error10:Error! The task assignee is required!`;
  readonly ganttChartProjWarning = $localize`:@@gantt.chart.proj.warning:Warning! The task is outside the date range`;

  //cobol-control-flow-svg-renderer.component.ts
  readonly cobolCFStartLabel = $localize`:@@cobol.cf.start.label:Start`;
  readonly cobolCFEndLabel = $localize`:@@cobol.cf.End.label:End`;
  readonly cobolCFColorful = $localize`:@@cobol.cf.colorful:Colorful`;
  readonly cobolCFGrayscale = $localize`:@@cobol.cf.grayscale:Grayscale`;

  //project-dashboard.component.html
  readonly projectDashboardTitle = $localize`:@@project.dashboard.title:Project Dashboard`;

  //cub-object-parameter.ts
  readonly cubObjDesc = $localize`:@@cub.obj.desc:No description available`;

  //error-snackbar.service.ts
  readonly errorSnackbarClose = $localize`:@@error.snackbar.close:CLOSE`;
  readonly errorSnackbarDetails = $localize`:@@error.snackbar.details:DETAILS`;

  //shape.service.ts
  readonly shapeServiceInvalidTextWidth1 = $localize`:@@shape.service.invalid.text.width:Invalid text width.`;
  readonly shapeServiceInvalidTextWidth2 = $localize`:@@shape.service.invalid.text.width:Invalid text width.`;
  readonly shapeServiceInvalidTextWidth3 = $localize`:@@shape.service.invalid.text.width:Invalid text width.`;

  //confirmation-modal.component.ts
  readonly confModalTitle = $localize`:@@conf.modal.title:CONFIRMATION`;
  readonly confModalCfmBtn = $localize`:@@conf.modal.cfm.btn:YES`;

  //app.component.ts
  readonly appLogoutModalText = $localize`:@@app.modal.logout.text:Are you sure you want to end your session and sign out?`;

  //custom-profile-modal.component.html
  readonly customProfileTitle = $localize`:@@custom.profile.title:Custom Profile`;
  readonly customProfileDesc = $localize`:@@custom.profile.desc:Description`;
  readonly customProfileExecTime = $localize`:@@custom.profile.exec.time:Execution Time`;
  readonly customProfileCoresUsed = $localize`:@@custom.profile.cores.used:Cores Used`;
  readonly customProfileBaseline = $localize`:@@custom.profile.baseline:Baseline`;
  readonly customProfileCloseBtn = $localize`:@@custom.profile.close.btn:Close`;
  readonly customProfileAddBtn = $localize`:@@custom.profile.add.btn:ADD`;

  //execution-profile-dialog.component.html
  readonly executionProfileTitle = $localize`:@@execution.profile.title:Execution Profile`;
  readonly executionProfileCloseBtn = $localize`:@@execution.profile.close.btn:Close`;

  //execution-profiling.component.html
  readonly executionProfilingExec = $localize`:@@execution.profiling.exec:Profile Execution`;
  readonly executionProfilingTag = $localize`:@@execution.profiling.tag:Profile Tag`;
  readonly executionProfilingReqId = $localize`:@@execution.profiling.req.id: Request ID`;
  readonly executionProfilingTimeStamp = $localize`:@@execution.profiling.timestamp:Time Stamp`;
  readonly executionProfilingExecTime = $localize`:@@execution.profiling.exec.time: Execution Time (ms)`;
  readonly executionProfilingChange = $localize`:@@execution.profiling.change:Change`;

  //source-code-modal.component.html
  readonly sourceCodeConfirmTitle = $localize`:@@source.code.confirm.title:Code Editor`;
  readonly sourceCodeConfirmMsg = $localize`:@@source.code.confirm.msg:The source has unsaved changes. Do you want to save before closing?`;
  readonly sourceCodeConfirmYesBtn = $localize`:@@source.code.yes.btn:YES`;

  //cub-object.ts
  readonly objTypeAll = $localize`:@@obj.type.all:All`;
  readonly objTypeKernel = $localize`:@@obj.type.kernel:Kernel`;
  readonly objTypeAlgorithm = $localize`:@@obj.type.algorithm:Algorithm`;
  readonly objTypeSystemKernel = $localize`:@@obj.type.system.kernel:System Kernel`;
  readonly objTypeFunctionPointer = $localize`:@@obj.type.function.pointer:Function Pointer`;

  readonly execEngineDBStatusConnected = $localize`:@@exec.engine.db.status.connected:Connected`;
  readonly execEngineDBStatusDisconnected = $localize`:@@exec.engine.db.status.disconnected:Disconnected`;
  readonly execEngineDBStatusCheckFailed = $localize`:@@exec.engine.db.status.check.failed:Status check failed!`;

  //tlp-view.component.ts
  readonly tlpViewDecompFetchFailed = $localize`:@@tlp.view.decomp.failed:Error fetching TLP data`;
  readonly tlpViewGroupIdCol = $localize`:@@tlp.view.groupid.col:Group Id`;
  readonly tlpViewDecompIdCol = $localize`:@@tlp.view.decomp.col:Decomposition Id`;
  readonly tlpViewStatusCol = $localize`:@@tlp.view.status.col:Status`;
  readonly tlpViewElTypeCol = $localize`:@@tlp.view.eltype.col:Element Type`;
  readonly tlpViewElNameCol = $localize`:@@tlp.view.elname.col:Element Name`;
  readonly tlpViewGroupSizeCol = $localize`:@@tlp.view.size.col:Group Size`;
  readonly tlpViewProfileIdCol = $localize`:@@tlp.view.profileid.col:Profile Id`;
  readonly tlpViewRuntimeCol = $localize`:@@tlp.view.runtime.col: Group Run Time`;
  readonly tlpViewStatusLabel = $localize`:@@tlp.view.status.label:TLP Group Status`;
  readonly tlpViewGroupIdLabel = $localize`:@@tlp.view.groupid.label:TLP Group Id`;
  readonly tlpViewProfileHeader = $localize`:@@tlp.view.profile.header:Profile Runs`;
  readonly tlpViewMembersHeader = $localize`:@@tlp.view.mem.header:TLP Group members`;
  readonly tlpViewRevertBtn = $localize`:@@tlp.view.revert.btn:Revert`;
  readonly tlpViewSaveBtn = $localize`:@@tlp.view.save.btn:Save`;
  readonly tlpViewEnableTlpBtn = $localize`:@@tlp.view.enabletlp.btn:Enable All Groups`;
  readonly tlpViewDisableTlpBtn = $localize`:@@tlp.view.disabletlp.btn:Disable All Groups`;
  readonly tlpViewNavDecompBtn = $localize`:@@tlp.view.group.navdecomp.btn:Show in Decomposition View`;
  readonly tlpViewGroupTlpTip = $localize`:@@tlp.view.group.tip:Enabled if two or more elements are enabled`;
  readonly tlpViewStatusDisable = $localize`:@@tlp.view.stat.disable:Disabled`;
  readonly tlpViewStatusEnable = $localize`:@@tlp.view.stat.enable:Enabled`;
  readonly tlpViewSaveSuccess = $localize`:@@tlp.view.save.success:TLP data is saved successfully!`;
  readonly tlpViewSaveError = $localize`:@@tlp.view.save.error:Error saving TLP data`;
  readonly tlpViewRevertHint = $localize`:@@tlp.view.revert.hint:Revert all unsaved changes`;

  //gantt-chart-project-container.component.html
  //task-operation.component.html
  //gantt-chart-project-editor-modal.component.html
  //gantt-chart-project-overview.component.html
}
