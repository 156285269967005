import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'labelFormat'
})
export class LabelFormatPipe implements PipeTransform {
  /**
   * Formats a string by capitalizing the first letter and inserting spaces before uppercase letters.
   *
   * @param {string} value - the string to be formatted
   * @return {string} the formatted string
   */
  transform(value: string): string {
    if (value) {
      let val = '';

      val += value.charAt(0).toUpperCase();

      for (let i = 1; i < value.length; i++) {
        let current = value.charAt(i);

        if (current === current.toUpperCase()) {
          val += ' ';
        }

        val += current;
      }

      return val;
    }

    return value;
  }
}
