import { Injectable } from '@angular/core';
import { DrawingDefaults } from '../../../reuse/interfaces/drawing-defaults';
import { ColorsService } from 'src/app/shared/services/colors.service';
import { ControlFlow } from '../../../reuse/interfaces/control-flow.types';
import ControlFlowColors = ControlFlow.ControlFlowColors;

export interface SvgDefsOptions {
  id: number | string;
  className: string;
  markerRefX: number;
  defaults: DrawingDefaults;
  controlFlowDefaults?: DrawingDefaults;
  callGraphDefaults?: DrawingDefaults;
}

@Injectable()
export class SvgDefsService {
  svg: any;
  options: SvgDefsOptions;

  svgDefs: any;

  constructor(private colors: ColorsService) {}

  public addSvgDefs(svg, options: SvgDefsOptions) {
    // only add the defs once per svg
    if (svg.selectAll('defs').size() > 0) {
      return;
    }

    this.svg = svg;
    this.options = options;

    if (options.className && options.className.length > 0) {
      this.svgDefs = svg
        .append('svg:defs')
        .append('g')
        .classed(options.className, true);
    } else {
      this.svgDefs = svg.append('svg:defs');
    }

    this.addFonts();

    this.addNodeGradients();

    this.addMarkers([
      {
        id: 'red',
        color: 'red',
        refX: this.options.markerRefX,
        markerWidth: 12,
        markerHeight: 12
      },
      {
        id: 'green',
        color: 'green',
        refX: this.options.markerRefX,
        markerWidth: 12,
        markerHeight: 12
      },
      {
        id: 'black',
        color: 'black',
        refX: this.options.markerRefX,
        markerWidth: 12,
        markerHeight: 12
      },
      {
        id: 'black2',
        color: 'black',
        refX: 18,
        markerWidth: 6,
        markerHeight: 6
      },
      { id: 'red2', color: 'red', refX: 18, markerWidth: 6, markerHeight: 6 },
      {
        id: 'green2',
        color: 'green',
        refX: 18,
        markerWidth: 6,
        markerHeight: 6
      },
      {
        id: 'black3',
        color: 'black',
        refX: 100,
        markerWidth: 6,
        markerHeight: 6
      }
    ]);

    this.addJumpMarkers();

    this.addLoop();

    this.addAddIcon();

    this.addDataStore('localdatastore', this.colors.mptComponents.localdatastore);
    this.addDataStore('globaldatastore', this.colors.mptComponents.globaldatastore);
    this.addDataStore('filescopedatastore', this.colors.mptComponents.filescopedatastore);

    this.addTerminator('terminator');

    if (this.options.controlFlowDefaults) {
      this.addControlFlowSymbols();
    }
    if (this.options.callGraphDefaults) {
      this.addCallGraphSymbols();
    }
  }

  private addNodeGradients() {
    let self = this;
    let types: any[] = [
      { id: 'algorithm', baseColor: this.colors.mptComponents.algorithm },
      { id: 'controller', baseColor: this.colors.mptComponents.controller },
      { id: 'functionpointerprocess', baseColor: this.colors.mptComponents.functionpointerprocess },
      { id: 'kernel', baseColor: this.colors.mptComponents.kernel },
      {
        id: 'sharedcontroller',
        baseColor: this.colors.mptComponents.sharedcontroller
      },
      {
        id: 'sharedprocess',
        baseColor: this.colors.mptComponents.sharedprocess
      },
      {
        id: 'systemprocess',
        baseColor: this.colors.mptComponents.systemprocess
      },
      {
        id: 'function',
        baseColor: this.colors.mptCallGraph.function
      }
    ];

    let gradient = this.svgDefs
      .selectAll('radialGradient')
      .data(types)
      .enter()
      .append('radialGradient')
      .attr('id', function(d) {
        return 'gradient-' + d.id + '-' + self.options.id;
      })
      .attr('cx', '0.5')
      .attr('cy', '0.5')
      .attr('fx', '0.15')
      .attr('fy', '0.15')
      .attr('r', '0.9');

    gradient
      .append('stop')
      .attr('offset', '0%')
      .attr('stop-color', d => {
        return d.baseColor.C200;
      });

    gradient
      .append('stop')
      .attr('offset', '100%')
      .attr('stop-color', d => {
        return d.baseColor.C800;
      });
  }

  protected addMarkers(markers: any[]) {
    let self = this;
    this.svgDefs
      .selectAll('marker')
      .data(markers)
      .enter()
      .append('marker')
      .attr('id', function(d) {
        return 'marker-' + d.id + '-' + self.options.id;
      })
      .attr('viewBox', '0 -10 20 20')
      // .attr("refX", self.options.markerRefX)
      .attr('refX', function(d) {
        return d.refX;
      })
      .attr('refY', 0)
      .attr('markerWidth', d => {
        return d.markerWidth;
      })
      .attr('markerHeight', d => {
        return d.markerHeight;
      })
      .attr('orient', 'auto')
      .attr('fill', function(d) {
        return d.color;
      })
      .append('svg:path')
      .attr('d', 'M0,-10L20,0L0,10');
  }

  private addJumpMarkers() {
    let markerColors: string[] = ['red', 'green', 'blue'];

    let self = this;
    markerColors.forEach(color => {
      // add jump arrows
      this.svgDefs
        .append('marker')
        .attr('id', 'jumparrow' + color + '-' + self.options.id)
        .attr('viewBox', '0 0 20 20')
        .attr('refX', 18)
        .attr('refY', 10)
        .attr('markerWidth', 8)
        .attr('markerHeight', 6)
        .attr('orient', 'auto')
        .attr('fill', function() {
          return color;
        })
        .append('svg:path')
        .attr('d', 'M 0 0 L 20 10 L 0 20 z');

      // add dots for jumps arrow ends
      this.svgDefs
        .append('marker')
        .attr('id', 'jumpdot' + color + '-' + self.options.id)
        .attr('viewBox', '0 0 10 10')
        .attr('refX', 5)
        .attr('refY', 5)
        .attr('markerWidth', 5)
        .attr('markerHeight', 5)
        .attr('orient', 'auto')
        .attr('fill', function() {
          return color;
        })
        .append('svg:circle')
        .attr('cx', '5')
        .attr('cy', '5')
        .attr('r', '2')
        .attr('fill', function() {
          return color;
        });
    });
  }

  protected addFonts() {
    this.svgDefs
      .append('style')
      .attr('type', 'text/css')
      .text("@import url('https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i');");
  }

  protected addLoop() {
    let g = this.svgDefs
      .append('g')
      .attr('id', d => {
        return 'loop-icon' + this.options.id;
      })
      .attr('fill', '#ffffff');
    // .attr('transform', "translate(-15,-15)");

    g.append('g')
      .append('path')
      .attr(
        'd',
        `M427.408,19.697c-7.803-3.23-14.463-1.902-19.986,3.999l-37.116,36.834C349.94,41.305,326.672,26.412,300.5,15.848
		C274.328,5.285,247.251,0.003,219.271,0.003c-29.692,0-58.052,5.808-85.08,17.417c-27.03,11.61-50.347,27.215-69.951,46.82
		c-19.605,19.607-35.214,42.921-46.824,69.949C5.807,161.219,0,189.575,0,219.271c0,29.687,5.807,58.05,17.417,85.079
		c11.613,27.031,27.218,50.347,46.824,69.952c19.604,19.599,42.921,35.207,69.951,46.818c27.028,11.611,55.388,17.419,85.08,17.419
		c32.736,0,63.865-6.899,93.363-20.7c29.5-13.795,54.625-33.26,75.377-58.386c1.52-1.903,2.234-4.045,2.136-6.424
		c-0.089-2.378-0.999-4.329-2.711-5.852l-39.108-39.399c-2.101-1.711-4.473-2.566-7.139-2.566c-3.045,0.38-5.232,1.526-6.566,3.429
		c-13.895,18.086-30.93,32.072-51.107,41.977c-20.173,9.894-41.586,14.839-64.237,14.839c-19.792,0-38.684-3.854-56.671-11.564
		c-17.989-7.706-33.551-18.127-46.682-31.261c-13.13-13.135-23.551-28.691-31.261-46.682c-7.708-17.987-11.563-36.874-11.563-56.671
		c0-19.795,3.858-38.691,11.563-56.674c7.707-17.985,18.127-33.547,31.261-46.678c13.135-13.134,28.693-23.555,46.682-31.265
		c17.983-7.707,36.879-11.563,56.671-11.563c38.259,0,71.475,13.039,99.646,39.116l-39.409,39.394
		c-5.903,5.711-7.231,12.279-4.001,19.701c3.241,7.614,8.856,11.42,16.854,11.42h127.906c4.949,0,9.23-1.807,12.848-5.424
		c3.613-3.616,5.42-7.898,5.42-12.847V36.55C438.542,28.558,434.84,22.943,427.408,19.697z`
      );
  }

  private addAddIcon() {
    let g = this.svgDefs
      .append('g')
      .attr('id', d => {
        return 'add-icon' + this.options.id;
      })
      .attr('fill', '#000000')
      .attr('height', '18')
      .attr('width', '18');

    g.append('path')
      .attr(
        'd',
        'M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-2 10h-4v4h-2v-4H7v-2h4V7h2v4h4v2z'
      )
      .attr('fill', '#666666');

    g.append('path')
      .attr('d', 'M0 0h24v24H0z')
      .attr('fill', 'none');
  }

  private addTerminator(name) {
    let terminator = this.svgDefs
      .append('svg:g')
      .attr('id', name + this.options.id)
      .classed(name, true)
      .attr('transform', 'scale(2.2) translate (-123, -109)');

    terminator
      .append('svg:path')
      .attr('style', 'fill:#353564;fill-rule:evenodd;stroke:none;stroke-width:1.0420177;stroke-linejoin:round')
      .attr('points', '105.72538,119.79449 122.46385,113.17946 122.46385,89.758658 105.72538,96.373685')
      .attr('d', 'm 105.72538,96.373685 v 23.420805 l 16.73847,-6.61503 V 89.758658 Z')
      .attr('id', 'path11405');

    terminator
      .append('svg:path')
      .attr('style', 'fill:#e9e9ff;fill-rule:evenodd;stroke:none;stroke-width:1.0420177;stroke-linejoin:round')
      .attr('points', '139.65818,96.553843 139.65818,119.97464 122.46385,113.17946 122.46385,89.758658')
      .attr('d', 'm 122.46385,89.758658 17.19433,6.795185 v 23.420797 l -17.19433,-6.79518 z')
      .attr('id', 'path11415');

    terminator
      .append('svg:path')
      .attr('style', 'fill:#afafde;fill-rule:evenodd;stroke:none;stroke-width:1.0420177;stroke-linejoin:round')
      .attr('points', '122.91971,126.58967 139.65818,119.97464 122.46385,113.17946 105.72538,119.79449')
      .attr('d', 'm 105.72538,119.79449 17.19433,6.79518 16.73847,-6.61503 -17.19433,-6.79518 z')
      .attr('id', 'path11413');

    terminator
      .append('svg:path')
      .attr('style', 'fill:#666666;stroke:none')
      .attr('points', '122.91971,103.16887 139.65818,96.553843 122.46385,89.758658 105.72538,96.373685')
      .attr('d', 'm 105.72538,96.373685 17.19433,6.795185 16.73847,-6.615027 -17.19433,-6.795185 z')
      .attr('id', 'path11407');

    terminator
      .append('svg:path')
      .attr('style', 'fill:#333333;stroke:none')
      .attr('points', '122.91971,126.58967 139.65818,119.97464 139.65818,96.553843 122.91971,103.16887')
      .attr('d', 'm 122.91971,103.16887 v 23.4208 l 16.73847,-6.61503 V 96.553843 Z')
      .attr('id', 'path11411');

    terminator
      .append('svg:path')
      .attr('style', 'fill:#4d4d4d;stroke:none')
      .attr('points', '122.91971,103.16887 122.91971,126.58967 105.72538,119.79449 105.72538,96.373685')
      .attr('d', 'm 105.72538,96.373685 17.19433,6.795185 v 23.4208 l -17.19433,-6.79518 z')
      .attr('id', 'path11409');
  }

  private addDataStore(name, color) {
    // datastore colors in order below go bottom, middle, lid, top
    let dataStore = this.svgDefs
      .append('svg:g')
      .style('stroke', this.colors.mptComponents.localdatastore.C900)
      .style('stroke-width', 1)
      .attr('id', name + this.options.id)
      .classed(name, true);

    dataStore.attr('transform', 'scale(1.43) translate(-25,-21)');

    dataStore
      .append('svg:path')
      .attr(
        'd',
        'M50.455,8L50.455,8C49.724,3.538,39.281,0,26.5,0S3.276,3.538,2.545,8l0,0H2.5v0.5V20v0.5V21v11v0.5 V33v12h0.045c0.731,4.461,11.175,8,23.955,8s23.224-3.539,23.955-8H50.5V33v-0.5V32V21v-0.5V20V8.5V8H50.455z'
      )
      .style('fill', color.C800)
      .classed(name + 'bottom', true);

    dataStore
      .append('svg:g')

      .append('svg:path')
      .attr(
        'd',
        'M26.5,41c-13.255,0-24-3.806-24-8.5V45h0.045c0.731,4.461,11.175,8,23.955,8s23.224-3.539,23.955-8 H50.5V32.5C50.5,37.194,39.755,41,26.5,41z'
      )
      .style('fill', color.C800)
      .classed(name + 'bottom', true)

      .append('svg:path')
      .attr('d', 'M2.5,32v0.5c0-0.168,0.018-0.334,0.045-0.5H2.5z')
      .style('fill', color.C800)
      .classed(name + 'bottom', true)

      .append('svg:path')
      .attr('d', 'M50.455,32c0.027,0.166,0.045,0.332,0.045,0.5V32H50.455z')
      .style('fill', color.C800)
      .classed(name + 'bottom', true);

    dataStore
      .append('svg:g')

      .append('svg:path')
      .attr(
        'd',
        'M26.5,29c-13.255,0-24-3.806-24-8.5V33h0.045c0.731,4.461,11.175,8,23.955,8s23.224-3.539,23.955-8   H50.5V20.5C50.5,25.194,39.755,29,26.5,29z'
      )
      .style('fill', color.C600)
      .classed(name + 'middle', true)

      .append('svg:path')
      .attr('d', 'M2.5,20v0.5c0-0.168,0.018-0.334,0.045-0.5H2.5z')
      .style('fill', color.C600)
      .classed(name + 'middle', true)

      .append('svg:path')
      .attr('d', 'M50.455,20c0.027,0.166,0.045,0.332,0.045,0.5V20H50.455z')
      .style('fill', color.C600)
      .classed(name + 'middle', true);

    dataStore
      .append('svg:ellipse')
      .attr('cx', '26.5')
      .attr('cy', '8.5')
      .attr('rx', '24')
      .attr('ry', '8.5')
      .style('fill', color.C300)
      .classed(name + 'lid', true);

    dataStore
      .append('svg:g')

      .append('svg:path')
      .attr(
        'd',
        'M26.5,17c-13.255,0-24-3.806-24-8.5V21h0.045c0.731,4.461,11.175,8,23.955,8s23.224-3.539,23.955-8   H50.5V8.5C50.5,13.194,39.755,17,26.5,17z'
      )
      .style('fill', color.C500)
      .classed(name + 'top', true)

      .append('svg:path')
      .attr('d', 'M2.5,8v0.5c0-0.168,0.018-0.334,0.045-0.5H2.5z')
      .style('fill', color.C500)
      .classed(name + 'top', true)

      .append('svg:path')
      .attr('d', 'M50.455,8C50.482,8.166,50.5,8.332,50.5,8.5V8H50.455z')
      .style('fill', color.C500)
      .classed(name + 'top', true);
  }

  // add symbols for function, block, if, else, for, while
  // these all need to be rects (for dagre layout of edges)
  addControlFlowSymbols() {
    let self = this;

    let defaults = this.options.controlFlowDefaults;
    let cfColors: ControlFlowColors = defaults.other as ControlFlowColors;

    this.svgDefs
      .append('rect')
      .attr('id', 'function' + this.options.id)
      .attr('x', -30)
      .attr('y', -35)
      .attr('rx', 10) // rounded corners
      .attr('ry', 10) // rounded corners
      .attr('height', d => {
        return 60;
      })
      .attr('width', d => {
        return 60;
      })
      .style('stroke', cfColors.functionStrokeColor)
      .attr('stroke-width', defaults.strokeWidth)
      .style('fill', 'white');

    this.svgDefs
      .append('rect')
      .attr('id', 'blockitem' + this.options.id)
      .attr('x', -30)
      .attr('y', -35)
      .attr('rx', 10) // rounded
      .attr('ry', 10) // rounded
      .attr('width', 60)
      .attr('height', 60)
      .style('stroke', cfColors.blockItemStrokeColor)
      .attr('stroke-width', defaults.strokeWidth)
      .style('fill', 'white');

    this.svgDefs
      .append('rect')
      .attr('id', 'controlstatement' + this.options.id)
      .attr('x', -30)
      .attr('y', -35)
      .attr('rx', 10) // rounded corners
      .attr('ry', 10) // rounded corners
      .attr('height', d => {
        return 60;
      })
      .attr('width', d => {
        return 60;
      })
      .style('stroke', cfColors.controlStrokeColor)
      .attr('stroke-width', defaults.strokeWidth)
      .style('fill', 'white');

    // this.svgDefs
    // .append('rect')
    // .attr('id', 'if' + this.options.id)
    // .attr('x', -30)
    // .attr('y', -35)
    // .attr('rx', 10) // rounded corners
    // .attr('ry', 10) // rounded corners
    // .attr('height', d => {
    //   return 60;
    // })
    // .attr('width', d => {
    //   return 60;
    // })
    // .style('stroke', defaults.other.ifColor1)
    // .attr('stroke-width', defaults.strokeWidth)
    // .style('fill', defaults.other.ifColor2);

    // this.svgDefs
    //   .append('rect')
    //   .attr('id', 'else' + this.options.id)
    //   .attr('x', -30)
    //   .attr('y', -35)
    //   .attr('rx', 10) // rounded corners
    //   .attr('ry', 10) // rounded corners
    //   .attr('height', d => {
    //     return 60;
    //   })
    //   .attr('width', d => {
    //     return 60;
    //   })
    //   .style('stroke', defaults.other.ifColor1)
    //   .attr('stroke-width', defaults.strokeWidth)
    //   .style('fill', defaults.other.ifColor2);

    // this.svgDefs
    //   .append('rect')
    //   .attr('id', 'for' + this.options.id)
    //   .attr('x', -30)
    //   .attr('y', -35)
    //   .attr('rx', 10) // rounded corners
    //   .attr('ry', 10) // rounded corners
    //   .attr('height', d => {
    //     return 60;
    //   })
    //   .attr('width', d => {
    //     return 60;
    //   })
    //   .style('stroke', defaults.other.forColor1)
    //   .attr('stroke-width', defaults.strokeWidth)
    //   // .attr('stroke-dasharray', "4 1")
    //   .style('fill', defaults.other.forColor2);

    // this.svgDefs
    //   .append('rect')
    //   .attr('id', 'while' + this.options.id)
    //   .attr('x', -30)
    //   .attr('y', -35)
    //   .attr('rx', 10) // rounded corners
    //   .attr('ry', 10) // rounded corners
    //   .attr('height', d => {
    //     return 60;
    //   })
    //   .attr('width', d => {
    //     return 60;
    //   })
    //   .style('stroke', defaults.other.whileColor1)
    //   .attr('stroke-width', defaults.strokeWidth)
    //   // .attr('stroke-dasharray', "4 1")
    //   .style('fill', defaults.other.whileColor2);
  }

  addCallGraphSymbols() {
    let defaults = this.options.callGraphDefaults;
    this.svgDefs
      .append('rect')
      .attr('id', 'function' + this.options.id)
      .attr('x', -30)
      .attr('y', -35)
      .attr('rx', 10) // rounded corners
      .attr('ry', 10) // rounded corners
      .attr('height', d => {
        return 60;
      })
      .attr('width', d => {
        return 60;
      })
      .style('stroke', defaults.other.functionColor1)
      .attr('stroke-width', defaults.strokeWidth)
      .style('fill', defaults.other.functionColor2);
  }
}
